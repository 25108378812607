import React, { useEffect, useState } from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import BlogpostsOverview from 'fragments/Admin/BlogpostsOverview';
import axios from 'axios';
import Title from 'components/Typography/Title';
import { InertiaLink } from '@inertiajs/inertia-react';
import Eye from 'components/Icons/Eye';
import Delete from 'components/Icons/Delete';
import Interest from 'components/Icons/Interest';
import { format } from 'date-fns';
import SimpleDate, { getDate } from 'components/DateFormatter';
import Button from 'components/Forms/Components/Button';
import Success from 'components/Icons/Success';
import ValidateError from 'components/Icons/ValidateError';
import ValidateOk from 'components/Icons/ValidateOk';
import { Inertia } from '@inertiajs/inertia';

export default function Deposits() {

    const [users, setUsers] = useState(null as any);

    async function deleteUser(uuid) {
        if (confirm("Opravdu chcete tohoto uživatele smazat? Akce je nevratná")) {
            axios.delete('/api/users/delete', { data: { uuid: uuid } }).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    setUsers(users.filter((user) => (user.UUID != uuid)))
                }
            })
        }

    }

    useEffect(() => {
        axios.get('/api/users/all').then((res) => {
            console.log(res)
            setUsers(res?.data?.users)
        })
    }, []);

    return (
        <AdminLayout>
            <div className="min-h-screen  px-5 lg:px-32 pt-32">
                <div className="flex justify-between items-baseline">
                    <Title level={2}>Uživatelé</Title>
                    {/* <div className="inline-flex">
                        <Button theme="secondary" link="/admin/urokove-sazby/nova">+ Přidat</Button>
                    </div> */}

                </div>

                <div className="mt-4 overflow-x-auto w-full">
                    {users ?
                        <table className="table-auto data-table-design">
                            <thead>
                                <tr>
                                    <th className='w-5'>UUID</th>
                                    <th>Role</th>
                                    <th>Ověřovací kód</th>
                                    <th>E-mail</th>
                                    <th>Jméno</th>
                                    <th>Příjmení</th>
                                    <th>Datum narození</th>
                                    <th>Telefon</th>
                                    <th>Ulice</th>
                                    <th>Město</th>
                                    <th>PSČ</th>
                                    <th>Země</th>
                                    <th>Firma</th>
                                    <th>Datum registrace</th>
                                </tr>
                            </thead>

                            <tbody>
                                {users?.sort((user1, user2) => user1.role > user2.role).map((user, i) => (
                                    <tr>
                                        <td>{user?.UUID}</td>
                                        <td><State user={user} /></td>
                                        <td>{user.code}</td>
                                        <td> <EditOnClick user={user} edit="email" /></td>
                                        <td> <EditOnClick user={user} edit="firstName" /></td>
                                        <td> <EditOnClick user={user} edit="lastName" /></td>
                                        <td> <EditOnClick user={user} edit="birthDate" /></td>
                                        <td><EditOnClick user={user} edit="tel" /></td>
                                        <td> <EditOnClick user={user} edit="street" /></td>
                                        <td> <EditOnClick user={user} edit="city" /></td>
                                        <td> <EditOnClick user={user} edit="postalCode" /></td>
                                        <td> <EditOnClick user={user} edit="country" /></td>
                                        <td className={`${user?.companyInvoice ? "cursor-pointer font-semibold underline hover:text-white-70" : "cursor-default"}`} onClick={() => {
                                            user?.companyInvoice ? alert(`Název firmy: ${user.companyName}
IČO: ${user?.ICO}
DIČ: ${user?.DIC}`) : {}
                                        }}>{user?.companyInvoice ? user?.companyName : "--"}</td>
                                        <td> <SimpleDate>{user?.created_at}</SimpleDate> </td>



                                        <td>
                                            <span className="text-red-400 font-semibold cursor-pointer" onClick={() => deleteUser(user?.UUID)}>Smazat</span>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>


                        </table>
                        :
                        <>
                            <div className="w-full h-10 bg-white-10"></div>
                            <div className="h-4"></div>
                            <div className="w-full h-40 bg-white-10"></div>
                        </>
                    }
                </div>
            </div>
        </AdminLayout>
    );
}


function State({ user }) {

    return (
        <div className={(
            user.role == "client" ? " bg-green " :
                user.role == "preregistered" ? " bg-white-30 " :
                    user.role == "admin" ? " bg-blue-500 " : " bg-white-30 "
        ) + "  text-white font-semibold text-sm text-center px-4 rounded-full"}>{user?.role}</div>
    )

}

function Bool({ children }) {

    if (children) {
        return <ValidateOk />
    }
    else return <ValidateError />
}

function EditOnClick({ user, edit }: { user: any, edit: string }) {

    async function change() {
        let input = prompt("Pokud si přejete změnit tento údaj, zapište zde novou hodnotu:", user[edit])

        if (input != user[edit] && input !== null) {
            axios.post("/api/users/edit", {
                ...user,
                [edit]: input,
                updated_at: format(new Date(), "yyyy-MM-dd hh:mm:ss"),
                created_at: format(new Date(user.created_at), "yyyy-MM-dd hh:mm:ss")
            }).then(res => {
                console.log(res)
                if (res.status == 200) {
                    Inertia.visit("/admin/uzivatele")
                }
            })
        }
    }
    if (user[edit]) {
        return (
            <span style={{ cursor: "context-menu" }} onClick={() => change()} className='hover:text-white-70 w-full h-full'>{user[edit]}</span>
        )
    }
    else {
        return (
            <div style={{ cursor: "context-menu" }} onClick={() => change()} className='hover:text-white-70 w-full h-full text-center bg-white-10'>+</div>
        )
    }

}