import { InertiaLink } from '@inertiajs/inertia-react';
import GoBack from 'components/Icons/GoBack';
import LoggedUser from 'components/LoggedUser';
import React from 'react';

export default function AdminNav() {
    return (
        <div className="fixed inset-0 w-full h-20 px-5 lg:px-32  border-b border-white-10 bg-black flex justify-between items-center">
            <div className='cursor-pointer' onClick={() => history.back()}>
                <div className="flex items-center">
                    <GoBack /> <span className="ml-4">Zpět</span>
                </div>
            </div>
            <div className="space-x-8">
                <InertiaLink href="/admin/spravujeme">Hodnota portfolia</InertiaLink>
                <InertiaLink href="/admin/promo-kody">Promo kódy</InertiaLink>
                <InertiaLink href="/admin/urokove-sazby">Úrokové sazby</InertiaLink>
                <InertiaLink href="/admin/ulozky">Úložky</InertiaLink>
                <InertiaLink href="/admin/uzivatele">Uživatelé</InertiaLink>
                <InertiaLink href="/admin">Blog</InertiaLink>
            </div>
            <LoggedUser admin />
        </div>
    );
}
