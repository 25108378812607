import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const TermDeposit: FC<Props> = (props) => {
    const { width = '110', height = '110', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 110 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M55.1831 10.7184C58.1492 10.7184 60.5538 8.31904 60.5538 5.35922C60.5538 2.39941 58.1492 0 55.1831 0C52.217 0 49.8125 2.39941 49.8125 5.35922C49.8125 8.31904 52.217 10.7184 55.1831 10.7184Z"
                fill={color}
            />
            <path
                d="M79.9097 17.362C82.8758 17.362 85.2803 14.9626 85.2803 12.0028C85.2803 9.04296 82.8758 6.64355 79.9097 6.64355C76.9436 6.64355 74.5391 9.04296 74.5391 12.0028C74.5391 14.9626 76.9436 17.362 79.9097 17.362Z"
                fill={color}
            />
            <path
                d="M98.3589 35.447C101.325 35.447 103.73 33.0476 103.73 30.0877C103.73 27.1279 101.325 24.7285 98.3589 24.7285C95.3928 24.7285 92.9883 27.1279 92.9883 30.0877C92.9883 33.0476 95.3928 35.447 98.3589 35.447Z"
                fill={color}
            />
            <path
                d="M104.628 60.1745C107.595 60.1745 109.999 57.7751 109.999 54.8153C109.999 51.8555 107.595 49.4561 104.628 49.4561C101.662 49.4561 99.2578 51.8555 99.2578 54.8153C99.2578 57.7751 101.662 60.1745 104.628 60.1745Z"
                fill={color}
            />
            <path
                d="M98.3589 85.2722C101.325 85.2722 103.73 82.8727 103.73 79.9129C103.73 76.9531 101.325 74.5537 98.3589 74.5537C95.3928 74.5537 92.9883 76.9531 92.9883 79.9129C92.9883 82.8727 95.3928 85.2722 98.3589 85.2722Z"
                fill={color}
            />
            <path
                d="M79.9097 103.357C82.8758 103.357 85.2803 100.958 85.2803 97.9979C85.2803 95.0381 82.8758 92.6387 79.9097 92.6387C76.9436 92.6387 74.5391 95.0381 74.5391 97.9979C74.5391 100.958 76.9436 103.357 79.9097 103.357Z"
                fill={color}
            />
            <path
                d="M55.1831 110.001C58.1492 110.001 60.5538 107.601 60.5538 104.641C60.5538 101.682 58.1492 99.2822 55.1831 99.2822C52.217 99.2822 49.8125 101.682 49.8125 104.641C49.8125 107.601 52.217 110.001 55.1831 110.001Z"
                fill={color}
            />
            <path
                d="M30.0933 103.357C33.0594 103.357 35.4639 100.958 35.4639 97.9979C35.4639 95.0381 33.0594 92.6387 30.0933 92.6387C27.1272 92.6387 24.7227 95.0381 24.7227 97.9979C24.7227 100.958 27.1272 103.357 30.0933 103.357Z"
                fill={color}
            />
            <path
                d="M12.0152 85.2722C14.9813 85.2722 17.3858 82.8727 17.3858 79.9129C17.3858 76.9531 14.9813 74.5537 12.0152 74.5537C9.04904 74.5537 6.64453 76.9531 6.64453 79.9129C6.64453 82.8727 9.04904 85.2722 12.0152 85.2722Z"
                fill={color}
            />
            <path
                d="M5.37063 60.1745C8.33675 60.1745 10.7413 57.7751 10.7413 54.8153C10.7413 51.8555 8.33675 49.4561 5.37063 49.4561C2.40451 49.4561 0 51.8555 0 54.8153C0 57.7751 2.40451 60.1745 5.37063 60.1745Z"
                fill={color}
            />
            <path
                d="M12.0152 35.447C14.9813 35.447 17.3858 33.0476 17.3858 30.0877C17.3858 27.1279 14.9813 24.7285 12.0152 24.7285C9.04904 24.7285 6.64453 27.1279 6.64453 30.0877C6.64453 33.0476 9.04904 35.447 12.0152 35.447Z"
                fill={color}
            />
            <path
                d="M30.0933 17.362C33.0594 17.362 35.4639 14.9626 35.4639 12.0028C35.4639 9.04296 33.0594 6.64355 30.0933 6.64355C27.1272 6.64355 24.7227 9.04296 24.7227 12.0028C24.7227 14.9626 27.1272 17.362 30.0933 17.362Z"
                fill={color}
            />
            <path
                d="M54.999 54.6864C55.0201 54.6864 55.0371 54.6694 55.0371 54.6484C55.0371 54.6274 55.0201 54.6104 54.999 54.6104C54.978 54.6104 54.9609 54.6274 54.9609 54.6484C54.9609 54.6694 54.978 54.6864 54.999 54.6864Z"
                fill={color}
            />
            <path
                d="M55.1748 54.7332C55.1958 54.7332 55.2129 54.7162 55.2129 54.6952C55.2129 54.6742 55.1958 54.6572 55.1748 54.6572C55.1538 54.6572 55.1367 54.6742 55.1367 54.6952C55.1367 54.7162 55.1538 54.7332 55.1748 54.7332Z"
                fill={color}
            />
            <path
                d="M55.3076 54.8622C55.3287 54.8622 55.3457 54.8451 55.3457 54.8241C55.3457 54.8031 55.3287 54.7861 55.3076 54.7861C55.2866 54.7861 55.2695 54.8031 55.2695 54.8241C55.2695 54.8451 55.2866 54.8622 55.3076 54.8622Z"
                fill={color}
            />
            <path
                d="M55.3506 55.037C55.3716 55.037 55.3887 55.0199 55.3887 54.9989C55.3887 54.978 55.3716 54.9609 55.3506 54.9609C55.3296 54.9609 55.3125 54.978 55.3125 54.9989C55.3125 55.0199 55.3296 55.037 55.3506 55.037Z"
                fill={color}
            />
            <path
                d="M55.3076 55.2147C55.3287 55.2147 55.3457 55.1977 55.3457 55.1767C55.3457 55.1557 55.3287 55.1387 55.3076 55.1387C55.2866 55.1387 55.2695 55.1557 55.2695 55.1767C55.2695 55.1977 55.2866 55.2147 55.3076 55.2147Z"
                fill={color}
            />
            <path
                d="M55.1748 55.3436C55.1958 55.3436 55.2129 55.3266 55.2129 55.3056C55.2129 55.2846 55.1958 55.2676 55.1748 55.2676C55.1538 55.2676 55.1367 55.2846 55.1367 55.3056C55.1367 55.3266 55.1538 55.3436 55.1748 55.3436Z"
                fill={color}
            />
            <path
                d="M54.999 55.3905C55.0201 55.3905 55.0371 55.3735 55.0371 55.3525C55.0371 55.3315 55.0201 55.3145 54.999 55.3145C54.978 55.3145 54.9609 55.3315 54.9609 55.3525C54.9609 55.3735 54.978 55.3905 54.999 55.3905Z"
                fill={color}
            />
            <path
                d="M54.8232 55.3436C54.8443 55.3436 54.8613 55.3266 54.8613 55.3056C54.8613 55.2846 54.8443 55.2676 54.8232 55.2676C54.8022 55.2676 54.7852 55.2846 54.7852 55.3056C54.7852 55.3266 54.8022 55.3436 54.8232 55.3436Z"
                fill={color}
            />
            <path
                d="M54.6943 55.2147C54.7154 55.2147 54.7324 55.1977 54.7324 55.1767C54.7324 55.1557 54.7154 55.1387 54.6943 55.1387C54.6733 55.1387 54.6562 55.1557 54.6562 55.1767C54.6562 55.1977 54.6733 55.2147 54.6943 55.2147Z"
                fill={color}
            />
            <path
                d="M54.6475 55.037C54.6685 55.037 54.6856 55.0199 54.6856 54.9989C54.6856 54.978 54.6685 54.9609 54.6475 54.9609C54.6264 54.9609 54.6094 54.978 54.6094 54.9989C54.6094 55.0199 54.6264 55.037 54.6475 55.037Z"
                fill={color}
            />
            <path
                d="M54.6943 54.8622C54.7154 54.8622 54.7324 54.8451 54.7324 54.8241C54.7324 54.8031 54.7154 54.7861 54.6943 54.7861C54.6733 54.7861 54.6562 54.8031 54.6562 54.8241C54.6562 54.8451 54.6733 54.8622 54.6943 54.8622Z"
                fill={color}
            />
            <path
                d="M54.8232 54.7332C54.8443 54.7332 54.8613 54.7162 54.8613 54.6952C54.8613 54.6742 54.8443 54.6572 54.8232 54.6572C54.8022 54.6572 54.7852 54.6742 54.7852 54.6952C54.7852 54.7162 54.8022 54.7332 54.8232 54.7332Z"
                fill={color}
            />
            <path
                d="M57.0673 29.5496C60.28 28.6905 62.1865 25.3948 61.3257 22.1885C60.4649 18.9822 57.1626 17.0794 53.9499 17.9385C50.7372 18.7977 48.8307 22.0934 49.6915 25.2997C50.5523 28.506 53.8546 30.4088 57.0673 29.5496Z"
                fill={color}
            />
            <path
                d="M72.8251 33.785C76.0378 32.9259 77.9443 29.6302 77.0835 26.4239C76.2227 23.2175 72.9204 21.3148 69.7077 22.1739C66.495 23.033 64.5885 26.3287 65.4493 29.535C66.3102 32.7414 69.6124 34.6441 72.8251 33.785Z"
                fill={color}
            />
            <path
                d="M84.579 45.3133C87.7917 44.4542 89.6983 41.1585 88.8374 37.9522C87.9766 34.7459 84.6743 32.8431 81.4616 33.7022C78.2489 34.5614 76.3424 37.857 77.2032 41.0634C78.0641 44.2697 81.3663 46.1724 84.579 45.3133Z"
                fill={color}
            />
            <path
                d="M88.579 61.076C91.7917 60.2169 93.6983 56.9212 92.8374 53.7149C91.9766 50.5086 88.6743 48.6058 85.4616 49.4649C82.2489 50.324 80.3424 53.6197 81.2032 56.8261C82.0641 60.0324 85.3663 61.9351 88.579 61.076Z"
                fill={color}
            />
            <path
                d="M84.5829 77.075C87.7956 76.2159 89.7022 72.9202 88.8413 69.7139C87.9805 66.5076 84.6782 64.6048 81.4655 65.4639C78.2528 66.3231 76.3463 69.6188 77.2071 72.8251C78.068 76.0314 81.3702 77.9342 84.5829 77.075Z"
                fill={color}
            />
            <path
                d="M72.8251 88.6034C76.0378 87.7442 77.9443 84.4485 77.0835 81.2422C76.2227 78.0359 72.9204 76.1331 69.7077 76.9923C66.495 77.8514 64.5885 81.1471 65.4493 84.3534C66.3102 87.5597 69.6124 89.4625 72.8251 88.6034Z"
                fill={color}
            />
            <path
                d="M57.0673 92.8387C60.28 91.9796 62.1865 88.6839 61.3257 85.4776C60.4649 82.2713 57.1626 80.3685 53.9499 81.2276C50.7372 82.0867 48.8307 85.3824 49.6915 88.5887C50.5523 91.7951 53.8546 93.6978 57.0673 92.8387Z"
                fill={color}
            />
            <path
                d="M41.0712 88.6024C44.2839 87.7432 46.1904 84.4476 45.3296 81.2412C44.4688 78.0349 41.1665 76.1322 37.9538 76.9913C34.7411 77.8504 32.8346 81.1461 33.6954 84.3524C34.5562 87.5587 37.8585 89.4615 41.0712 88.6024Z"
                fill={color}
            />
            <path
                d="M29.5517 77.075C32.7644 76.2159 34.6709 72.9202 33.8101 69.7139C32.9492 66.5076 29.647 64.6048 26.4343 65.4639C23.2216 66.3231 21.315 69.6188 22.1759 72.8251C23.0367 76.0314 26.339 77.9342 29.5517 77.075Z"
                fill={color}
            />
            <path
                d="M25.3173 61.077C28.53 60.2179 30.4365 56.9222 29.5757 53.7158C28.7149 50.5095 25.4126 48.6068 22.1999 49.4659C18.9872 50.325 17.0807 53.6207 17.9415 56.827C18.8023 60.0333 22.1046 61.9361 25.3173 61.077Z"
                fill={color}
            />
            <path
                d="M29.5517 45.3133C32.7644 44.4542 34.6709 41.1585 33.8101 37.9522C32.9492 34.7459 29.647 32.8431 26.4343 33.7022C23.2216 34.5614 21.315 37.857 22.1759 41.0634C23.0367 44.2697 26.339 46.1724 29.5517 45.3133Z"
                fill={color}
            />
            <path
                d="M41.0712 33.785C44.2839 32.9259 46.1904 29.6302 45.3296 26.4239C44.4688 23.2175 41.1665 21.3148 37.9538 22.1739C34.7411 23.033 32.8346 26.3287 33.6954 29.535C34.5562 32.7414 37.8585 34.6441 41.0712 33.785Z"
                fill={color}
            />
            <circle cx="55.0018" cy="54.9999" r="9.75181" fill={color} />
        </svg>
    );
};

export default TermDeposit;
