import React from 'react';

import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';

export default function Earning() {
    return (
        <section className="px-6 lg:px-40 xl:px-100 2xl:px-140 py-10 lg:py-20">
            <Title level={2}>Spočítejte si výnos</Title>
            <Text className="mt-6">
                Investice je možná již od 50 000 Kč, maximální vklad není
                omezen.
            </Text>
            <div className="mt-10 border h-100" />
        </section>
    );
}
