import React from 'react';

import AppLayout from 'components/Layouts/AppLayout';
import Arrange from 'fragments/Profile/Arrange';
import Back from 'components/Back';
import LoggedUser from 'components/LoggedUser';
import TermDepositLogo from 'components/Logos/TermDepositLogo';

export default function TermDepositMeeting() {
    return (
        <AppLayout>
            <Back to="/profil" />
            <TermDepositLogo />
            <LoggedUser />
            <Arrange meeting="TermDeposit" />
        </AppLayout>
    );
}
