import React, { FC } from 'react';
import { InertiaLink } from '@inertiajs/inertia-react';

import GoBack from 'Icons/GoBack';

type Props = {
    text?: string;
    to?: string;
};

const Back: FC<Props> = (props) => {
    const { text = 'Zpět', to = '/#produkty' } = props;

    return (
        <InertiaLink href={to}>
            <div className="fixed lg:absolute z-40 top-12 lg:top-28 left-6 lg:left-20 xl:left-32 2xl:left-60 flex items-center space-x-4 text-base">
                <GoBack /> <span className="">{text}</span>
            </div>
        </InertiaLink>
    );
};

export default Back;
