import React, { FC, SVGProps } from "react";

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Account: FC<Props> = (props) => {
    const { width = "32", height = "32", color = "white" } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.66667 25.333C4.66667 21.2829 7.94991 17.9997 12 17.9997H20C24.0501 17.9997 27.3333 21.2829 27.3333 25.333V29.333C27.3333 30.4376 26.4379 31.333 25.3333 31.333H6.66667C5.5621 31.333 4.66667 30.4376 4.66667 29.333V25.333Z"
                stroke={color}
                strokeWidth="1.33333"
            />
            <circle
                cx="16.6654"
                cy="7.33333"
                r="6.66667"
                stroke={color}
                strokeWidth="1.33333"
            />
        </svg>
    );
};

export default Account;
