import { AppContext } from "components/Contexts/AppContextProvider";
import { useDebouncedCallback } from "components/Hooks/useDebouncedCallback";
import BTC from "components/Icons/BTC";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";


export default function AnimatedBalance({ currentValue, reset }) {

    const [oldValue, setOldValue] = useState(null as any)
    const [value, setValue] = useState(currentValue)
    const [numOfDigits, setNumOfDigits] = useState(1)
    const [empty, setEmpty] = useState(null as any);

    const { windowSize } = useContext(AppContext);

    const some = useDebouncedCallback((oldValue) => {
        if (value === oldValue && value === 0) {
            setEmpty(true)
        }
    }, 4000);

    useEffect(() => {
        if (value) {
            if (parseFloat(value).toFixed(9) != parseFloat(oldValue).toFixed(9)) {

                test(1)
                test(2)
                test(3)
                test(4)
                test(5)
                test(6)
                test(7)
                test(8)
                test(9)

                setTimeout(() => {
                    setOldValue(value)
                    // const test = ((value - oldValue) * 1000000000) + parseInt(parseFloat(value).toFixed(9).slice(-1))


                    // if (test < 10) { setNumOfDigits(1) } else
                    // if (test < 100) { setNumOfDigits(2) } else
                    // if (test < 1000) { setNumOfDigits(3) } else
                    // if (test < 10000) { setNumOfDigits(4) } else
                    // if (test < 100000) { setNumOfDigits(5) } else
                    // if (test < 100000) { setNumOfDigits(6) } else
                    // if (test < 100000) { setNumOfDigits(7) } else
                    // if (test < 100000) { setNumOfDigits(8) } else
                    // if (test < 100000) { setNumOfDigits(9) }  
                }, 1000)
            }
            // console.log(value)
        }

        // console.log(value)

        some(value)


    }, [value]);



    useEffect(() => {
        if (currentValue) {
            setOldValue(null as any)
            setValue(null as any)
        }
    }, [reset]);

    useEffect(() => {
        if (currentValue != value) {

            setValue(currentValue)

        }
    }, [currentValue]);

    function test(num) {
        if (value) {
            if ((parseFloat(value).toFixed(9)).charAt(num) == (parseFloat(oldValue).toFixed(9)).charAt(num)) {
                setNumOfDigits(10 - num)
            }
        }

    }

    var formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        // minimumFractionDigits: 2
      });


    return (
        <motion.p
            style={{ width: oldValue > 0 && value || empty ? (windowSize.width > 1024 ? !empty ? 258 : "auto" : !empty ? 250 : "auto") : (windowSize.width > 1024 ? 258 : 250) }}
            initial={false}
            transition={{ duration: 0.5 }}
            className="flex items-center justify-center rounded-3px font-light text-4xl monospace overflow-hidden">
            {oldValue > 0 && value || empty ?
                <>
                    <motion.div
                        animate={{ opacity: 1 }}
                        initial={{ opacity: 0 }}
                        className="flex w-full justify-center relative text-3xl lg:text-4xl font-extralight">

                        {/* <AnimatePresence>
                            {(parseFloat(value).toFixed(9) != parseFloat(oldValue).toFixed(9)) && (
                                <motion.div
                                    animate={{ y: -8, opacity: 1 }}
                                    initial={{ y: 20, opacity: 0 }}
                                    transition={{ duration: 0.7, type: "spring" }}
                                    className="absolute right-0 top-0 left-0 z-20 overflow-hidden overflow-ellipsis w-full flex justify-end  text-white-60"
                                >
                                    <span className="bg-black pt-2"> {(value).toFixed(9).slice(-numOfDigits)}</span>
                                </motion.div>
                            )}

                        </AnimatePresence> */}

                        <span>{empty ? "0.00" : formatter.format(oldValue)}{" "}</span>
                        {/* {!empty &&
                            <>
                                <span className=" text-white-60 absolute left-0 top-0">{parseFloat(oldValue).toFixed(2).slice(-2)}</span>{" "}
                            </>
                        } */}
                    </motion.div>

{/* 
                    <span className={"ml-2 lg:ml-4 flex-shrink-0"}>
                           Kč
                    </span> */}
                </>
                :
                <motion.div
                    animate={{ opacity: [0.1, 0.5, 0.1] }}
                    initial={{ opacity: 0 }}
                    transition={{ repeat: Infinity }}
                    className="w-full rounded bg-white-20 h-8 lg:h-10">
                </motion.div>
            }

        </motion.p>
    )
}