import Title from "components/Typography/Title";
import Text from "components/Typography/Text";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Triangle from "components/Icons/Triangle";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useEffect, useState } from "react";



export default function ExponencialGrow(props) {
    const { show } = props
    const [ref, inView] = useInView();

    const circles = Array.from(Array(24).keys())

    const [seen, setSeen] = useState(false);

    useEffect(() => {
        if (inView) {
            setSeen(true)
        }
    }, [inView]);

    return (
        <section
            id="nava-era"
            className=" relative min-h-screen lg:max-h-screen overflow-hidden flex items-center px-6 md:py-10 lg:px-20 xl:px-32 2xl:px-60 "
            ref={ref}
        >

            <div className="">
                {(isMobile ? (inView || seen) : show) &&
                    <>
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="">
                                <motion.div
                                    animate={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: 20 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{ ease: "easeOut", delay: 0.5 }}
                                    className="mb-3 md:mb-9">
                                    <Title>Nová generace internetu vzniká právě teď, vy můžete být u toho</Title>
                                </motion.div>
                                <motion.div
                                    animate={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: 20 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{ ease: "easeOut", delay: 0.7 }}
                                    className="mb-5 md:mb-20">
                                    <Text>Dynamický svět kryptoměn odkrývá každý den nové příležitosti i rizika. Naším cílem je dosahovat zhodnocení díky odpovědnému a rozhodnému přístupu.</Text>
                                </motion.div>
                            </div>
                        </div>
                        {/* <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ ease: "easeOut", delay: 1 }}
                            className="flex items-center gap-x-4 my-5 lg:my-0">
                            <div className="text-white">Termínovaná úložka</div>
                            <div className="text-green flex items-center gap-x-2">
                                <Triangle color="#6BBF5D" />
                                <div className="">6,17 p. a.</div>
                            </div>
                        </motion.div> */}
                        <div className="w-full flex items-center justify-between mt-10 lg:mt-0 h-20">
                            {circles.map((circle) => (
                                <div className={"relative lg:block" + (circle%2 == 0 && " hidden ")}>
                                    <motion.div
                                        animate={{ y: -(20 + (Math.pow(3.2, 1 + circle / (isMobile ? 9 : 6)))), opacity: 1 }}
                                        initial={{ y: 0, opacity: 0 }}
                                        transition={{ delay: circle * 0.1 + 1 }}
                                        className="absolute w-2 h-2 lg:w-4 lg:h-4 bg-white rounded-full"
                                    ></motion.div>
                                    <motion.div
                                        animate={{ y: 10 - ((Math.pow(1.1, circle))), opacity: 1 }}
                                        initial={{ y: 0, opacity: 0 }}
                                        transition={{ delay: circle * 0.1 + 1 }}
                                        className="absolute w-2 h-2 lg:w-4 lg:h-4 bg-white-30 rounded-full"
                                    ></motion.div>
                                </div>
                            ))}
                        </div>
                        {/* <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ ease: "easeOut", delay: 1.5 }}
                            className="flex items-center gap-x-4">
                            <div className="text-white">Běžné spoření</div>
                            <div className="text-green flex items-center gap-x-2">
                                <Triangle color="#6BBF5D" />
                                <div className="">2,10 p. m.</div>
                            </div>
                        </motion.div> */}
                    </>
                }

            </div>


        </section>
    )
}