import parseMD from 'parse-md';

import React from 'react';

import BlogPostCard from 'components/Cards/BlogPostCard';
import usePageProps from 'components/Hooks/usePageProps';
import BlogPostCardBig from 'components/Cards/BlogPostCardBig';
import { isMobile } from 'react-device-detect';

export default function Posts() {
    const { blogposts } = usePageProps();

    return (
        <section className="min-h-screen px-6 lg:px-20 pt-24 lg:pt-48 xl:px-32 2xl:px-60 pb-20 border-b border-white-30">
            {/* <div className="lg:hidden fixed z-30 left-0 w-full h-14 bg-black" /> */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                {blogposts.map((blogpost, i) => {
                    if (i === 0 && !isMobile) {
                        return (
                            <div className="lg:col-span-3">
                                <BlogPostCardBig
                                    date={parseMD(blogpost).metadata.date}
                                    coverImage={
                                        parseMD(blogpost).metadata.coverImage
                                    }
                                    slug={parseMD(blogpost).metadata.slug}
                                    subtitle={
                                        parseMD(blogpost).metadata.subtitle
                                    }
                                    title={parseMD(blogpost).metadata.title}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <BlogPostCard
                                    date={parseMD(blogpost).metadata.date}
                                    coverImage={
                                        parseMD(blogpost).metadata.coverImage
                                    }
                                    slug={parseMD(blogpost).metadata.slug}
                                    subtitle={
                                        parseMD(blogpost).metadata.subtitle
                                    }
                                    title={parseMD(blogpost).metadata.title}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        </section>
    );
}
