

export default function PhoneButton() {

    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <circle cx="28" cy="28" r="28" fill="black" />
                <circle cx="28" cy="28" r="27.5" stroke="white" />
            </g>
            <rect width="24" height="24" transform="translate(16 16)" fill="black" />
            <path d="M31.9403 17.8086H24.0703C22.9657 17.8086 22.0703 18.704 22.0703 19.8086V36.1786C22.0703 37.2832 22.9657 38.1786 24.0703 38.1786H31.9403C33.0449 38.1786 33.9403 37.2832 33.9403 36.1786V19.8086C33.9403 18.704 33.0449 17.8086 31.9403 17.8086Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.0094 33.3594H27.9994C27.6459 33.3594 27.3594 33.6459 27.3594 33.9994V34.0094C27.3594 34.3628 27.6459 34.6494 27.9994 34.6494H28.0094C28.3628 34.6494 28.6494 34.3628 28.6494 34.0094V33.9994C28.6494 33.6459 28.3628 33.3594 28.0094 33.3594Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <defs>
                <filter id="filter0_b_1341:1718" x="-4" y="-4" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1341:1718" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1341:1718" result="shape" />
                </filter>
            </defs>
        </svg>


    )
}