import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Delete: FC<Props> = (props) => {
    const { width = '24', height = '24', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8.465 11.88L9.88 10.465L12 12.585L14.12 10.465L15.535 11.88L13.415 14L15.535 16.12L14.12 17.535L12 15.415L9.88 17.535L8.465 16.12L10.585 14L8.465 11.88ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                fill={color}
            />
        </svg>
    );
};

export default Delete;
