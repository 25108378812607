import React from "react";

import Text from "components/Typography/Text";
import Title from "components/Typography/Title";

export default function FreerWorld() {
    return (
        <section id="uvod" className="relative grid place-items-center px-6 md:py-10 lg:px-20 xl:px-32 2xl:px-60 ">
            {/* <div className="absolute -z-10 inset-0 w-full h-full">
                <img
                    src="/assets/img/hills.png"
                    className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black-80 to-black" />
            </div> */}
            <div className="  lg:w-132">
                <Title level={2}>
                    Svobodnější svět, <br className="lg:hidden" /> ve kterém{" "}
                    <br className="hidden lg:block" />
                    jsme si na dosah
                </Title>
                <Text className="mt-6">
                    Blockchain nám umožňuje vzdát se konvenčních ekonomických,
                    politických a sociálních systémů kontrolovaných
                    centralizovanými institucemi. Smyslem je navrátit lidem
                    neodcizitelnou svobodu založenou na technologii a
                    decentralizaci s možností převzít kontrolu nad svými
                    prostředky.
                </Text>
            </div>
        </section>
    );
}
