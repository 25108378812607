import { useContext } from 'react';

import { Page } from '@inertiajs/inertia';
import PageContext from '@inertiajs/inertia-react/src/PageContext';

export default function usePageProps<T>(): T {
    const page = useContext<Page & { props: T }>(PageContext);
    const { props } = page;

    return props;
}
