import { ActualInterest, RangeInterest } from "components/Cards/DepositCard";
import { AppContext } from "components/Contexts/AppContextProvider";
import Button from "components/Forms/Components/Button";
import Title from "components/Typography/Title";
import NiceDate from "fragments/NiceDate";
import { motion } from "framer-motion";
import React, { FC, useContext } from "react";

type Props = {
    item: {
        number: string;
        amountBTC?: number;
        amountCzk?: number,
        expiration: string;
        interest: string;
        finalInterest: string;
        finalBilance: string;
        finalCzkBilance?: string
        payment?: string;
        interestBonus?: any,
        mode?: string
    };
    actualInterest?: any
};

const DepositDetail: FC<Props> = (props) => {
    const { item, actualInterest } = props;

    const { windowSize } = useContext(AppContext);

    const msStart = new Date(item.payment.replace(" ", "T")).getTime()
    const msEnd = new Date(item.expiration).getTime()
    const percentage = 100 / (msEnd - msStart) * (new Date().getTime() - msStart)
    console.log(percentage)

    return (
        <div className="relative lg:w-2/5 mt-16 lg:mt-0">
            <Title level={2}>Úložka č. {item.number}</Title>
            <div className="mt-6 border-t border-white-30 tracking-wide relative">
                <motion.div
                    animate={{ width: item.expiration ? percentage + "%" : 0, height: 2, y: -1 }}
                    initial={{ width: 0 }}
                    className="top-0 left-0 bg-green" />
                <div className="py-4 flex justify-between border-b border-white-30">
                    <span>Výše investice (v {item.amountBTC ? "BTC" : "Kč"})</span>
                    <span className="font-bold">{item.amountBTC ?? (item.amountCzk + " Kč")}</span>
                </div>
                <div className="py-4 flex justify-between border-b border-white-30">
                    <span>Datum zahájení</span>
                    <span className="font-bold">{item.payment ? <NiceDate date={item.payment.replace(" ", "T")} /> : "∞"}</span>
                </div>
                <div className="py-4 flex justify-between border-b border-white-30">
                    <span>Termín ukončení</span>
                    <span className="font-bold">{item.expiration ? <NiceDate date={item.expiration} /> : "∞"}</span>
                </div>
                <div className="py-4 flex justify-between border-b border-white-30">
                    <span>Bilance po uplynutí</span>
                    <span className="font-bold">{item.finalBilance ? Number(item.finalBilance).toFixed(9) : item.finalCzkBilance ? `${new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK' }).format(
                        Number(item.finalCzkBilance),
                    )}` : "Není známo"}</span>
                </div>
                <div className="py-4 flex justify-between border-b border-white-30">
                    <span>Zhodnocení p. m.</span>
                    <span className="font-bold text-green flex">
                        {(item.interest) ?? <RangeInterest bonus={item.interestBonus} />}%
                    </span>
                </div>
                <div className="py-4 flex justify-between border-b border-white-30">
                    <span>Zhodnocení (celkem)</span>
                    <span className="font-bold text-green">
                        {Number(item.finalInterest ?? actualInterest).toFixed(2)}%
                    </span>
                </div>
            </div>
            <div className="lg:w-max mt-8 hidden">
                <Button
                    link={`/profil/prodlouzit-ulozku/${item.number}`}
                    theme={windowSize.width < 640 ? "primary" : "secondary"}
                >
                    Prodloužit uložení
                </Button>
            </div>
        </div>
    );
};

export default DepositDetail;
