import { useEffect, useState } from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import axios from 'axios';
import Title from 'components/Typography/Title';
import { useForm } from '@inertiajs/inertia-react';
import { getDate } from 'components/DateFormatter';
import Button from 'components/Forms/Components/Button';
import Input from 'components/Forms/Components/Input';
import { Inertia } from '@inertiajs/inertia';
import { compound } from 'components/Hooks/InterestFunctions/variableInterest';
import Switch from 'components/Forms/Components/Switch';

export default function NewDeposit({ users }: {
    users: Array<any>
}) {

    const [months, setMonths] = useState(0);

    const form = useForm({
        number: null as any,
        amountBTC: null as any,
        finalBilance: null as any,
        interest: null as any,
        finalInterest: null as any,
        expiration: null as any,
        user_UUID: null as any,
        payment: new Date(),
        paymentId: null as any,
        paymentStatus: null as any,
        amountCzk: null as any,
        finalCzkBilance: null as any,
        type: "fixed",
        interestBonus: 0,
    })
    const { data, setData, errors, clearErrors, post } = form;

    const [currency, setCurrency] = useState("btc")
    const [searchActive, setSearchActive] = useState(false)
    const [mode, setMode] = useState("pm")

    function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    function create() {
        axios.post("/api/deposits/add", {
            ...data,
            interest: (((Math.pow(1 + data.interest / 100, 1 / 12)) - 1) * 100),
            mode: mode
        }).then(res => {
            console.log(res)
            if (res.status == 200) {
                Inertia.visit("/admin/ulozky")
            }
        }).catch((err) => {
            console.log(err)
            alert("Došlo k chybě")
        })
    }

    function changeTime(num) {
        var d = new Date();
        var newDate = (d.setMonth(d.getMonth() + num));
        console.log(new Date(new Date().getTime() + (2629800000 * num)).toISOString())
        setData({ ...data, expiration: new Date(newDate).toISOString().slice(0, 16), payment: new Date() })
        setMonths(num)
    }

    console.log("months", months)
    console.log("finalBilance", (data.amountCzk / 100 * (compound(data.interest, months) + 100)).toFixed(2))

    useEffect(() => {
        const isPm = (mode == "pm") ? true : false
        if (data.type == "fixed") {
            setData({
                ...data,
                finalBilance: (currency == "czk") ? null : data.amountBTC / 100 * (compound(data.interest, (isPm ? months : (months / 12))) + 100),
                finalInterest: compound(data.interest, (isPm ? months : (months / 12))),
                finalCzkBilance: !(currency == "czk") ? null : (data.amountCzk / 100 * (compound(data.interest, (isPm ? months : (months / 12))) + 100)).toFixed(2),
            })
        }

    }, [data.amountBTC, data.interest, data.amountCzk, mode]);

    useEffect(() => {
        var start = new Date(data.payment);
        var expiration = new Date(data.expiration)
        setMonths(monthDiff(start, expiration))
    }, [data.expiration, data.payment]);

    return (
        <AdminLayout>
            <div className="min-h-screen px-5 lg:px-32 xl:px-64 pt-32 overflow-hidden">
                <div className="rounded-lg bg-191919 mb-24">
                    <div className="px-6 py-8">
                        <div className="flex justify-between items-baseline">
                            <Title level={2}>Nová úložka</Title>
                        </div>
                        <div className="mt-4">
                            <form className='flex flex-col gap-4'>
                                <div className="w-full grid md:grid-cols-2 gap-4">
                                    <Input
                                        name="name"
                                        type="text"
                                        label="Název úložky"
                                        values={data}
                                        setValues={setData}
                                        error={errors.user_UUID}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <Switch options={[
                                        { text: "Úložka v BTC", value: "btc" },
                                        { text: "Úložka v CZK", value: "czk" }
                                    ]} value={currency} setValue={setCurrency} />
                                    <Switch options={[
                                        { text: "Termínovaná úložka", value: "fixed" },
                                        { text: "Úložka s variabilním úrokem", value: "variable" }
                                    ]} value={data.type} setValue={(to) => setData({ ...data, type: to })} />
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <Input
                                        name={(currency == "czk") ? "amountCzk" : "amountBTC"}
                                        type="number"
                                        label={(currency == "czk") ? "Výše úložky (Kč)" : "Množství (BTC)"}
                                        values={data}
                                        setValues={setData}
                                        error={errors.amountCzk}
                                    />
                                    <div className="relative">
                                        <Input
                                            onFocus={() => setSearchActive(true)}
                                            onBlur={() => setSearchActive(false)}
                                            name="user_UUID"
                                            type="text"
                                            label="UUID uživatele"
                                            values={data}
                                            setValues={setData}
                                            error={errors.user_UUID}
                                        />
                                        {(data?.user_UUID?.length > 0 && searchActive) &&
                                            <div className="border-white border grid grid-cols-1 absolute top-12 left-0 right-0 bg-191919 z-30">
                                                {users?.filter(usr =>
                                                    usr?.UUID?.toLowerCase().includes(data.user_UUID.toLowerCase()) ||
                                                    usr?.email?.toLowerCase().includes(data.user_UUID.toLowerCase()) ||
                                                    usr?.firstName?.toLowerCase().includes(data.user_UUID.toLowerCase()) ||
                                                    usr?.lastName?.toLowerCase().includes(data.user_UUID.toLowerCase())
                                                ).map((usr) => (
                                                    <div onPointerDown={() => setData({ ...data, user_UUID: usr?.UUID })} className="py-2 px-4 border-b border-white hover:text-opacity-75 cursor-pointer">
                                                        <div className="flex justify-between items-center w-full">
                                                            <div className="">{usr?.firstName} {usr?.lastName}</div>
                                                            <div className="">{usr?.UUID}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>

                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="grid grid-cols-1 gap-4">
                                        <Title level={3}>Doba úročení</Title>
                                        <Switch options={[
                                            { text: "Úrok p. m.", value: "pm" },
                                            { text: "Úrok p. a.", value: "pa" }
                                        ]} value={mode} setValue={setMode} />
                                        <Input
                                            name="interest"
                                            type="text"
                                            label="Úrok (p. m.)"
                                            values={data}
                                            setValues={setData}
                                            error={errors.interest}
                                        />
                                        <Switch options={[
                                            { text: "Nezaplacená", value: "" },
                                            { text: "Zaplacená", value: "paid" }
                                        ]} value={data.paymentStatus} setValue={(to) => setData({ ...data, paymentStatus: to })} />
                                    </div>
                                    <div className="grid grid-cols-1 gap-4">
                                        <Title level={3}>Datum expirace</Title>
                                        <Switch options={[
                                            { text: "12 měs. od teď", value: 12 },
                                            { text: "24 měs. od teď", value: 24 }
                                        ]} value={months.toFixed(1)} setValue={(to) => changeTime(to)} />
                                        <Input
                                            name="expiration"
                                            type="datetime-local"
                                            label="Expirace"
                                            values={data}
                                            setValues={setData}
                                            error={errors.expiration}
                                        />
                                        <Input
                                            name="payment"
                                            type="datetime-local"
                                            label="Začátek platnosti úložky"
                                            values={data}
                                            setValues={setData}
                                            error={errors.payment}
                                        />
                                    </div>
                                </div>
                                {data.type == "variable" &&
                                    <>
                                        <Input
                                            name="interestBonus"
                                            type="number"
                                            label="Zvýhodění úroku (v %)"
                                            values={data}
                                            setValues={setData}
                                            error={errors.interestBonus}
                                        />
                                    </>
                                }

                            </form>
                        </div>
                    </div>
                    <div className="px-6 py-8 bg-white-10 rounded-b-lg">
                        <div className="flex justify-between w-full">
                            <div className="flex gap-8">
                                {data.interest &&
                                    <div className="">
                                        <div className="">Finální úrok:</div>
                                        <div className="font-semibold text-lg">{compound(data.interest, ((mode == "pm") ? months : months / 12)).toFixed(2)} %</div>
                                    </div>
                                }
                                {((data.amountBTC || data.amountCzk) && data.interest) &&
                                    <div className="">
                                        <div className="">Finální bilance:</div>
                                        <div className="font-semibold text-lg">{(((currency == "czk") ? data.amountCzk : data.amountBTC) / 100 * (compound(data.interest, ((mode == "pm") ? months : months / 12)) + 100)).toFixed(2)} {(currency == "czk") ? "Kč" : "BTC"}</div>
                                    </div>
                                }
                            </div>
                            <div className="">
                                <div className="inline-flex">
                                    <button
                                        onPointerEnter={() => {
                                            console.log(((Math.pow(1 + data.interest / 100, 1 / 12)) - 1) * 100)
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            create()
                                        }} className='bg-green rounded-full py-3 px-10 font-semibold hover:bg-opacity-80'>Vytvořit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}


function State({ interest, previous }) {

    const now = new Date()

    if (getDate(interest.from) > now) {
        return (
            <div className="bg-blue-400 bg-opacity-50 text-white font-semibold text-sm text-center px-4 rounded-full">Budoucí</div>
        )
    }

    else if ((getDate(interest.from) < now) && (getDate(previous?.from) > now)) {
        return (
            <div className="bg-green text-white font-semibold text-sm rounded-full text-center px-4">Aktivní sazba</div>
        )
    }

    else if (getDate(interest.from) < now) {
        return (
            <div className=" bg-white bg-opacity-30 text-white font-semibold text-sm rounded-full text-center px-4">Minulá sazba</div>
        )
    }

    else return (
        <div className=""></div>
    )
}