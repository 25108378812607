import React, { useEffect, useRef, useState } from "react";

import AppLayout from "components/Layouts/AppLayout";
import Bloomberg from "fragments/Home/Bloomberg";
import FreerWorld from "fragments/Home/FreerWorld";
import Guide from "fragments/Home/Guide";
import Hero from "fragments/Home/Hero";
import Layout from "components/Layouts/Layout";
import NewEraOfTheInternet from "fragments/Home/NewEraOfTheInternet";
import Offering from "sharedFragments/Offering";
import PathToTheStars from "fragments/Home/PathToTheStars";
import Products from "fragments/Home/Products";
import SpektrumLogo from "components/Logos/SpektrumLogo";
import TechnologicRevolution from "fragments/Home/TechnologicRevolution";
import ProgressLoading from "fragments/Home/ProgressLoading";
import PhoneModal from "components/PhoneModal";
import ReactPageScroller from "react-page-scroller";
import Navbar from "components/Navbar";
import ContactUs from "sharedFragments/ContactUs";
import FAQ from "sharedFragments/FAQ";
import Footer from "components/Layouts/Footer";
import TechnologicRevolutionNew from "fragments/Home/TechnologicRevolutionNew";
import ScrollDownButton from "components/ScrollDownButton";
import { AnimatePresence, motion } from "framer-motion";
import ExponencialGrow from "fragments/Home/ExponencialGrow";
import { useDebouncedCallback } from "components/Hooks/useDebouncedCallback";
import Secure from "fragments/Home/Secure";
import BookMeeting from "fragments/Home/BookMeeting";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { getScrollPosition } from "components/Hooks/useScroll";
import { useScrollYPosition } from 'react-use-scroll-position';
import Calculator from "fragments/Home/Calculator";
import ContactAndFaq from "sharedFragments/ContactAndFaq";
import TechnologicRevolutionPhone from "fragments/Home/TechnologicRevolutionPhone";
import { isMobile } from "react-device-detect";
import Head from "components/Head";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Title from "components/Typography/Title";
import Text from "components/Typography/Text";
import Accordion from "components/Dropdowns/Accordion";

export default function Home() {


    return (

        <AppLayout>
            <Layout>

                <Navbar />
                <div className="mt-14 p-6 lg:p-20 xl:p-32 2xl:px-60 space-y-4 ">

                    <Title>Všeobecné obchodní podmínky</Title>
                    <Text>společnost xxx
                    </Text>
                    <div className="flex flex-col gap-y-px pb-32">
                        <TitleSection title="I. Definice">
                            <Text>
                                <ol>
                                    <li>„Podmínkami“ se rozumí tyto všeobecné obchodní podmínky, které upravují vztahy mezi Poskytovatelem a třetími osobami vznikající při používání webových stránek (dále jen „P/podmínky“ nebo také „VP“).</li>
                                    <li>„Webovými stránkami“ se rozumí web provozovaný a ve vlastnictví Poskytovatele umístěný pod doménou spektrum.fund, který má charakter autorského díla a databáze a Provozovatel k němu vykonává veškerá majetková práva (dále jen ,,webové stránky“).</li>
                                    <li>Klientem / Uživatelem“ se rozumí fyzická osoba, která dosáhla věku 18 let a je plně svéprávná, anebo právnická osoba, která je návštěvníkem, objednatelem, a/nebo příjemcem jakékoli služby poskytované Poskytovatelem prostřednictvím webových stránek (dále jen „K/klient“ a/nebo „U/uživatel“).</li>
                                    <li>„Službami“ se rozumí veškeré služby prezentované Poskytovatelem na webových stránkách.</li>
                                    <li>„Návštěvníkem“ se rozumí jakákoliv fyzická nebo právnická osoba, která navštíví webové stránky.</li>
                                    <li>„Profilem“ se rozumí uživatelský profil každého Klienta, který je spojený s Identifikačními Údaji, a který je přístupný Klientovi na webových stránkách po zadání Přístupových údajů a byl zřízen při Registraci.</li>
                                    <li>„Kryptoadresou“ se rozumí účet klienta v rámci jeho Profilu umožňující klientovi držet kryptoměnu. Na kryptoadrese jsou evidovány klientovi kryptoměny, které získal nákupem od Poskytovatele nebo Klientovi vlastní kryptoměny, které si zaslal do kryptoadresy (dále také jako „Kryptoadresa“). Kryptoadresa je provozována ze strany Poskytovatele.</li>
                                    <li>„Kryptoměnou“ se rozumí elektronicky uchovávaná jednotka bez ohledu na to, zda má nebo nemá emitenta, a která není peněžním prostředkem podle zákona o platebním styku, ale je přijímána jako platba za zboží nebo služby i jinou osobou odlišnou od jejího emitenta; Kryptoměna není považována za měnovou jednotku, elektronické peníze ani investiční nástroj. Pro účely těchto Podmínek se za kryptoměnu považuje zejména Bitcoin (BTC), Ethereum (ETH), Cardano (ADA) a stablecoiny (např. USDT, USDC, BUSD, UST nebo DAI) (dále také jako „virtuální měna“ a/nebo „kryptoměna“).</li>
                                    <li>„Smluvními stranami“ se rozumí Poskytovatel a Klient.</li>
                                    <li>„Smlouvou“ se rozumí smlouva o vzájemných právech a povinnostech Smluvních stran, jejíž nedílnou součástí jsou tyto Podmínky, se kterými vyslovuje Uživatel svůj výslovný souhlas aktivním jednáním při Registraci, a to zaškrtnutím příslušného políčka na webových stránkách při registraci, které je předpokladem pro založení profilu klienta. Zaškrtnutím políčka a dokončením registrace profilu je Smlouva uzavřena a účinná.</li>
                                    <li>„Registrací” se rozumí proces vyplňování osobních a Identifikačních údajů při zakládání nového profilu klienta, při kterém dochází k uzavření Smlouvy. Proces registrace je dále upraven těmito Podmínkami.</li>
                                    <li>„Kupní smlouvou“ se rozumí smlouva uzavřená mezi Poskytovatelem a Klientem prostřednictvím Webových stránek, jejíž předmětem je nákup/prodej kryptoměny směnou za bezhotovostní platbu z elektronické peněženky. Způsob uzavírání kupní smlouvy a její obsah je popsán v Příloze č. 1 k těmto podmínkám, která je její nedílnou součástí.</li>
                                    <li>„Směnou“ se rozumí nákup a/nebo prodej kryptoměny za prostředky z elektronické peněženky, uskutečněný na základě Kupní smlouvy.</li>
                                    <li>„Úložkou kryptoměny“ se rozumí jednorázová či opakovaná zápůjčka kryptoměny za podmínek Přílohy č. 1 k těmto podmínkách, která je jejich nedílnou součástí.</li>
                                    <li>„Občanským zákoníkem“ se rozumí zákon č. 89/2012 Sb., občanský zákoník, v platném znění.</li>
                                    <li>„AMLZ“ se rozumí zákon č. 253/2008 Sb., o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu, v platném znění.</li>
                                    <li>„GDPR“ se rozumí nařízení Evropského parlamentu a Rady (EU) 679/2016 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů), účinné od 25. května 2018.</li>
                                    <li>„ZDP“ je zákon č. 586/1992 Sb., o daních z příjmů, ve znění pozdějších předpisů;</li>
                                    <li>„Úřadem“ se rozumí Finanční analytický úřad se sídlem Washingtonova 1621/11, 11000 Praha 1.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="II. Úvodní ustanovení">
                            <Text>
                                <ol>
                                    <li>Podmínky upravují v souladu s ustanovením § 1751 odst. 1 občanského zákoníku vzájemná práva a povinnosti Smluvních stran vzniklé na základě Smlouvy nebo v souvislosti s ní.</li>
                                    <li>Tyto Podmínky upravují práva a povinnosti Uživatele v souvislosti s užíváním Služeb poskytovaných Poskytovatelem.</li>
                                    <li>Podmínky jsou pro Smluvní strany závazné po celou dobu trvání smluvního vztahu až do doby úplného vypořádání všech závazků a pohledávek vzniklých mezi Poskytovatelem a Klientem / Uživatelem.</li>
                                    <li>Struktura a členění těchto Podmínek do jednotlivých článků a nadpisů spolu s rozdělením pojmů a definic slouží pouze pro lepší orientaci v těchto Podmínkách. To znamená, že rozdělení článků není nikterak závazné pro výklad těchto Podmínek a žádný z uvedených pojmů v článku “pojmy a definice” není limitující pro daný pojem nebo definici pouze na rozsah popsaný.</li>
                                    <li>Poskytovatel si vyhrazuje právo měnit či doplňovat znění Podmínek, a to především z důvodu změny právní úpravy, tržních a ekonomických podmínek v oblasti obchodování s Kryptoměnou, za účelem umožnění řádného fungování Webových stránek anebo řádného poskytování služeb Provozovatelem. Změny a doplnění Podmínek nabývají platnosti a účinnosti jejich zveřejněním na Webových stránkách a od tohoto okamžiku jsou pro Smluvní strany závazné. Změny těchto Podmínek je Provozovatel povinen Klientovi oznámit elektronicky na e-mail Klientem uvedený, přičemž je Klientům stanovena lhůta v délce 15, slovy patnácti, dní k odmítnutí změny těchto Podmínek a k výpovědi závazku těmito Podmínkami stanovenému, a to rovněž prostřednictvím e-mailu.</li>
                                    <li>Tyto podmínky Poskytovatele obsahují smluvní podmínky, za kterých budou uzavírány mezi Poskytovatelem a Klientem jednotlivé transakce, zejména vedení kryptoadresy, nákup a prodej kryptoměn a úložky kryptoměn. Tyto podmínky jsou zejména popsány v příloze č. 1 těchto podmínek, která je její nedílnou součástí.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="III. Registrace klienta, uživatelský účet, verifikace klienta a uzavření Smlouvy">
                            <Text>
                                <ol>
                                    <li>Uzavřením Smlouvy vstupují smluvní strany do závazkového vztahu, který je upraven těmito podmínkami.</li>
                                    <li>Poskytovatel se zavazuje zaregistrovat na webových stránkách Klienta a vytvořit mu Profil v případě, že Klient splní všechny podmínky pro registraci (vč. požadovaných podmínek dle AMLZ).</li>
                                    <li>Provedení řádné registrace Uživatele na Webových stránkách je nutnou podmínkou pro uzavření Smlouvy, vytvoření Profilu a řádné využívání Služeb Uživatelem.</li>
                                    <li>Podmínkami registrace potenciálního Uživatele jsou:

                                        <ol type="a">
                                            <li>Fyzická osoba s minimálním věkem 18 let a svéprávnost nebo právnická osoba,</li>
                                            <li>Neexistující předešlá historie, jež by byla spojena s omezením služeb nebo blokací Profilu.</li>
                                        </ol>
                                    </li>
                                    <li>Jedna fyzická či právnická osoba je oprávněna se registrovat pouze jednou a zároveň Poskytovatel umožňuje takové fyzické či právnické osobě uvést pouze jeden E-mail Uživatele, mít pouze jednu adresu Kryptoadresy, které se nesmí shodovat s údaji jiného Uživatele, neboť se jedná o Identifikační údaje Uživatele.</li>
                                    <li>Podmínky zde uvedené platí pro Uživatele po celou dobu trvání Smlouvy, přičemž jakéhokoliv jejich porušení je považováno za podstatné porušení Smlouvy a Poskytovatel je oprávněn Profil zablokovat a má rovněž právo od Smlouvy odstoupit.</li>
                                    <li>Registrace se provádí vyplněním registračního formuláře na Webových stránkách, přičemž Uživatel je povinen o sobě vyplnit správné, pravdivé a úplné údaje. V případě jakýchkoliv změn v údajích Uživatele uvedených v Profilu je tento povinen bez zbytečného odkladu, nejpozději však do 3 pracovních dnů, uvést poskytnuté údaje do souladu se skutečným stavem.</li>
                                    <li>Uživatel bere na vědomí, že Poskytovatel je v souvislosti s poskytováním Služeb oprávněn kdykoliv v budoucnu měnit rozsah povinných údajů o Uživateli či dalších údajů o Uživateli, zejména v případě požadavků stanovených závaznými právními předpisy. Pokud Uživatel neposkytne Poskytovateli vyžádané údaje ve lhůtě jimi stanovené, je to považováno za podstatné porušení Smlouvy a Poskytovatel je oprávněn Profil zablokovat a má rovněž právo od Smlouvy odstoupit.</li>
                                    <li>Registrace Uživatele je zahájena odesláním vyplněného registračního formuláře Uživatelem v rámci Webových stránek, přičemž údaje Uživatele obsažené v odeslaném registračním formuláři jsou Poskytovatelem považovány za správné.</li>
                                    <li>Uživatel souhlasí s použitím komunikačních prostředků na dálku při uzavírání Smlouvy. Náklady vzniklé Uživateli při použití komunikačních prostředků na dálku v souvislosti s uzavřením Smlouvy (náklady na internetové připojení, náklady na telefonní hovory) si hradí takový Uživatel sám, přičemž tyto náklady se neliší od základní sazby.</li>
                                    <li>Webové stránky obsahují základní informace o Službě a její hlavní specifikaci.</li>
                                    <li>Souhlasem s těmito Podmínkami Uživatel, který je spotřebitelem dle Občanského zákoníku výslovně prohlašuje, že mu Poskytovateli byly před uzavřením Smlouvy poskytnuty údaje vyžadované § 1811 OZ, zejména, že:
                                        <ol>
                                            <li>byl seznámen se základními údaji o Poskytovateli, které jsou uvedeny v čl. 1 těchto Podmínek;</li>
                                            <li>byl srozumitelně seznámen těmito podmínkami se Službou a popisem jejích hlavních vlastností.</li>
                                        </ol>
                                    </li>
                                    <li>Uživatel, který je spotřebitelem dle Občanského zákoníku dále souhlasem s těmito Podmínkami výslovně prohlašuje, že mu Poskytovateli byly před uzavřením Smlouvy poskytnuty údaje vyžadované § 1820 OZ, zejména, že:
                                        <ol>
                                            <li>náklady na prostředky komunikace na dálku se neliší od základní sazby, kterou Uživatel hradí v závislosti na tarifu telekomunikačních služeb, které využívá. Poskytovatel si neúčtuje žádné další poplatky spojené s komunikací na dálku;</li>
                                            <li><span className="">Uživatel může od Smlouvy odstoupit ve lhůtě 14 dnů ode dne uzavření Smlouvy, a to bez uvedení důvodu.</span> Uplatnění odstoupení od Smlouvy zašle Uživatel na shora uvedenou adresu Poskytovatele. Formulář odstoupení od smlouvy je k dispozici na webových stránkách Provozovatele. Odstoupení je účinné vůči doručením odstoupení od smlouvy Poskytovateli.</li>
                                            <li>Uživatel nemůže odstoupit od Smlouvy či Kupní smlouvy, jsou-li splněny podmínky v § 1837 občanského zákoníku, tj. jestliže byla služba na základě Smlouvy či Kupní smlouvy splněna s jeho předchozím výslovným souhlasem před uplynutím lhůty pro odstoupení a těmito podmínkami Poskytovatel sdělil Uživateli, že v takovém případě nemá právo na odstoupení od smlouvy. Uživatel nemá právo na odstoupení od Kupní smlouvy na nákup či prodej kryptoměny, neboť služba závisí na výchylkách finančního trhu nezávisle na vůli Poskytovatele a k němuž může dojít během lhůty pro odstoupení od Kupní smlouvy.</li>
                                            <li>Uživatel výslovně prohlašuje, že dává Poskytovateli výslovný souhlas k naplnění účelu těchto VP.</li>
                                            <li>v případě nevyřízení stížností je oprávněn obrátit se na orgán dohledu nebo státního dozoru, tj. Ve vztahu k Poskytovateli zejména na Českou obchodní inspekci.</li>
                                        </ol>
                                    </li>
                                    <li>Informace o jednotlivých krocích vedoucích k uzavření Smlouvy jsou patrné z procesu Registrace na webových stránkách a Uživatel má možnost před vlastním odesláním registračního formuláře jej zkontrolovat a případně i upravit. Součástí registrace je potvrzení souhlasu s těmito podmínkami, které jsou v rámci potvrzení o registraci dle bodu 9 shora součástí potvrzení. Podmínky uzavírání Kupní smlouvy jsou popsány v <span className=" ">Příloze č. 1 k těmto podmínkám.</span></li>
                                    <li>Registrací Zákazník uděluje svůj souhlas s těmito Podmínkami a potvrzuje, že se seznámil se zásadami zpracování osobních údajů, které jsou k nalezení na webových stránkách v sekci ,,Ochrana osobních údajů”.</li>
                                    <li>Při registraci je nutné zadat heslo, které musí obsahovat minimální počet znaků, přičemž Uživatel je zcela odpovědný za bezpečné uchování hesla a zabránění neoprávněnému vniknutí do Profilu uživatele. Uživatel tedy nese odpovědnost za všechny následky způsobené odcizením hesla nebo neoprávněným vniknutím do Profilu Uživatele a/nebo využití Služeb třetí osobou jménem uživatele.</li>
                                    <li>Klient bere na vědomí, že Poskytovatel bude postupovat podle zákona č. 253/2008 Sb., o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu, v platném znění.</li>
                                    <li>Poskytovatel je oprávněn odmítnout registraci klienta v případě, že se tento nepodrobí identifikaci či kontrole dle AMLZ.</li>
                                    <li>Vyjádřením souhlasu s těmito podmínkami prohlašuje Klient, že není v likvidaci, není proti němu vedeno insolvenční řízení dle zákona č. 182/2006 Sb., o úpadku a způsobech jeho řešení (insolvenční zákon), v platném znění, po dobu platnosti smlouvy bude bezodkladně informovat o změnách, které v těchto oblastech nastanou. Oprávněná osoba odpovídá za všechny následky, které by vznikly z důvodu neohlášených změn.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="IV. Transakce">
                            <Text>
                                <ol>
                                    <li>Kurz nákupu nebo prodeje kryptoměny je kurz sdělený prostřednictvím webových stránek.</li>
                                    <li>Poskytovatel si vyhrazuje právo odmítnout uzavření transakce na nákup či prodej či úložku kryptoměn dle Kupní smlouvy.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="V. Vypořádání kryptoměn, elektronických peněz či prostředků">
                            <Text>
                                <ol>
                                    <li>Vypořádání je podrobně popsáno v rámci Přílohy č. 1, která je nedílnou součástí těchto podmínek.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="VI. Identifikace a kontrola Klienta podle AMLZ">
                            <Text>
                                <ol>
                                    <li>Poskytovatel je osobou povinnou ve smyslu § 2 odst. 1, písm. l) AMLZ a je proto povinen postupovat v souladu s tímto zákonem.</li>
                                    <li>Poskytovatel jako povinná osoba provede v souladu s ustanovením § 7 AMLZ identifikaci Klienta nejpozději tehdy, kdy je zřejmé, že hodnota obchodu překročí částku 1.000 EUR za období jednoho (1) měsíce, bez ohledu na tento limit rovněž vždy, pokud jde o podezřelý obchod, či vznik obchodního vztahu. Hodnoty jednotlivých obchodů se pro účely posouzení dosažení výše uvedeného limitu sčítají pro jednotlivé Klienty, Profily, jakož i e-mailové adresy.</li>
                                    <li>Klient bere na vědomí, že Poskytovatel může pro jednotlivé typy jím poskytovaných obchodů stanovit na základě hodnocení rizik hodnotu obchodu, při jejímž dosažení vždy provedou identifikaci Klienta, přičemž tato hodnota může být i nižší než částka 1.000 EUR.</li>
                                    <li>Podezřelým obchodem se rozumí obchod uskutečněný za okolností vyvolávajících podezření ze snahy o legalizaci výnosů z trestné činnosti nebo podezření, že v obchodu užité prostředky jsou určeny k financování terorismu, nebo že obchod jinak souvisí nebo je spojen s financováním terorismu, anebo jiná skutečnost, která by mohla takovému podezření nasvědčovat. Obchod se považuje za podezřelý vždy, pokud se Klient odmítá podrobit kontrole, jak je popsána níže.</li>
                                    <span className=" ">
                                        <li>Identifikace Klienta se provádí v souladu s ustanovením § 8 AMLZ následovně:
                                            <ol>
                                                <li>při identifikaci Klienta, který je fyzickou osobou, Poskytovatel identifikační údaje zaznamená a ověří ze dvou průkazů totožnosti, jsou-li v něm uvedeny, a dále zaznamená druh a číslo průkazů totožnosti, stát, popřípadě orgán, který jej vydal, a dobu jeho platnosti; současně ověří shodu podoby s vyobrazením v průkazu totožnosti a též ověří údaje v podpůrném dokladu, zda souhlasí s doklady totožnosti;</li>
                                                <li>při identifikaci Klienta, který je právnickou osobou, Poskytovatel identifikační údaje zaznamená a ověří z dokladu o existenci právnické osoby a v rozsahu podle písmene a) provede identifikaci fyzické osoby, která jejím jménem jedná v daném obchodu; je-li statutárním orgánem, jeho členem nebo ovládající osobou této právnické osoby jiná právnická osoba, zaznamená i její identifikační údaje.</li>
                                            </ol>
                                        </li>
                                        <li>V rámci identifikace Klienta Poskytovatel zjistí a zaznamená, zda Klient není politicky exponovanou osobou nebo zda není osobou, vůči níž Česká republika uplatňuje mezinárodní sankce podle zákona o provádění mezinárodních sankcí.</li>
                                    </span>
                                    <li>Při dalších obchodech s Klientem, který byl již identifikován, ověří Poskytovatel vhodným způsobem totožnost konkrétní jednající fyzické osoby.</li>
                                    <li>V době trvání obchodního vztahu nebo při dalších obchodech Poskytovatel kontroluje platnost a úplnost identifikačních údajů Klienta a zaznamenávají jejich změny.</li>
                                    <li>Klient poskytne Poskytovateli informace, které jsou k provedení identifikace nezbytné, včetně předložení příslušných dokladů. Poskytovatel může pro účely AMLZ takto získané informace zpracovávat.</li>
                                    <li>Identifikační údaje jsou poskytovány Klientem před úhradou peněžních prostředků jedním ze způsobů specifikovaných v příloze č. 1 k těmto podmínkám, za které je následně nabita elektronická peněženka. Předpokladem pro vložení peněžních prostředků je předložení všech požadovaných dokladů a dokumentů v rámci provádění identifikace v souladu s AMLZ.</li>
                                    <li>Poskytovatel jako povinná osoba provede v souladu s ustanovením § 9 AMLZ kontrolu Klienta před uskutečněním obchodu mimo obchodní vztah nejpozději v době, kdy je zřejmé, že dosáhne hodnoty 15.000 EUR nebo vyšší, s politicky exponovanou osobou, s osobou usazenou v zemi, kterou na základě označení Evropské komise nebo z jiného důvodu je třeba považovat za vysoce rizikovou a dále provede kontrolu, jde-li o podezřelý obchod, a při vzniku obchodního vztahu, a to před uskutečněním transakce, a to i v době trvání obchodního vztahu.</li>
                                    <li>Kontrola zahrnuje získání informací o účelu a zamýšlené povaze obchodu nebo obchodního vztahu, zjišťování vlastnické a řídící struktury Klienta a jeho skutečného majitele, pokud je Klientem právnická osoba, a přijetí opatření ke zjištění a ověření totožnosti skutečného majitele, průběžné sledování obchodního vztahu, včetně přezkoumávání obchodů prováděných v průběhu daného vztahu, přezkoumávání zdrojů peněžních prostředků nebo jiného majetku, kterého se obchod nebo obchodní vztah týká.</li>
                                    <li>Klient bere na vědomí, že Poskytovatel je oprávněn pro jednotlivé typy jím poskytovaných obchodů stanovit na základě hodnocení rizik hodnotu obchodu, při jejímž dosažení vždy provede kontrolu Klienta, přičemž tato hodnota může být i nižší než částky shora.</li>
                                    <li>Klient je povinen poskytnout Poskytovateli informace, které jsou k provedení kontroly nezbytné, včetně předložení příslušných dokladů.</li>
                                    <li>Poskytovatel odmítne uskutečnění obchodu nebo navázání obchodního vztahu anebo ukončí obchodní vztah v případě, že je dána identifikační povinnost a Klient se odmítne podrobit identifikaci, nebo neposkytne potřebnou součinnost při kontrole, anebo pokud z jiného důvodu nelze provést identifikaci anebo kontrolu Klienta, nebo má-li osoba provádějící identifikaci nebo kontrolu pochybnosti o pravdivosti informací poskytnutých Klientem nebo o pravosti předložených dokladů.</li>
                                    <li>Zjistí-li Poskytovatel podezřelý obchod, oznámí to Úřadu bez zbytečného odkladu, nejpozději však ve lhůtě stanovené zákonem.</li>
                                    <li>Pokud hrozí nebezpečí, že bezodkladnou realizací obchodu by mohlo být zmařeno nebo podstatně ztíženo zajištění výnosu z trestné činnosti nebo prostředků určených k financování terorismu, Poskytovatel může vyřídit objednávku Klienta týkající se podezřelého obchodu nejdříve po uplynutí 24 hodin od přijetí oznámení podezřelého obchodu Úřadem.</li>
                                    <li>Klient bere na vědomí, že hrozí-li nebezpečí ve smyslu odst. 17 a šetření podezřelého obchodu si pro složitost vyžaduje delší dobu, Úřad může rozhodnout (i) o prodloužení doby, na kterou se odkládá splnění příkazu Klienta, (ii) o odložení splnění příkazu Klienta nebo (iii) o zajištění majetku, který má být předmětem podezřelého obchodu, u Poskytovatele.</li>
                                    <li>V mezích tohoto článku provádí Poskytovatel identifikaci a kontrolu Klienta způsoby a prostředky, jak jsou popsány v článku III. Podmínek.</li>
                                    <li>Klient prohlašuje, že jím zaslané peněžní prostředky nebo virtuální měny nepocházejí z výnosů z trestné činnosti a účelem nákupu a prodeje virtuálních měn/elektronických peněz či nabití elektronické peněženky není legalizace výnosů z trestné činnosti či provedení transakcí směřujících k podpoře nebo financování terorismu ve smyslu příslušných ustanovení zákona č. 253/2008 Sb., o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu, v platném znění.</li>
                                    <li>V případě, že Klient zjistí jakékoli jednání ohrožující jeho Profil, zejména pokud jde o zneužití Klientského Profilu Klienta, přístupových údajů či hesla, je povinen bezodkladně o takové skutečnosti či jednání vyrozumět Poskytovatele.</li>
                                    <li>Pokud Poskytovatel zaznamená na Profilu klienta činnost, která by naznačovala podezření, že Profil je využíván pro legalizaci výnosů z trestné činnosti ve smyslu zákona č. 253/2008 Sb., o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu, v platném znění, mohl být použit při páchání trestné činnosti anebo v souvislosti s páchanou trestní či jakoukoliv jinou protizákonnou činností, je Klient povinen bezodkladně poskytnout Poskytovateli vysvětlení a případně i součinnost k odstranění zjištěných nedostatků a přijetí opatření na odstranění závadného stavu, pokud sám Klient bezodkladně nezjedná nápravu. Klient v této souvislosti bere na vědomí, že Poskytovatel je v takovém případě povinen vyrozumět příslušné orgány a znemožnit Klientovi dále využívat Klientský účet</li>
                                    <li>Klient bere na vědomí, že Poskytovatel je na základě ustanovení zákona AMLZ povinni přezkoumat zdroje peněžních prostředků, případně jiného majetku, který se vztahuje k transakcím. Na základě výše uvedených závazků Klient prohlašuje, že zdrojem peněžních prostředků či majetku je jeden či více z dále uvedených zdrojů:
                                        <ul>
                                            <li>příjem z podnikání nebo výdělečné činnosti</li>
                                            <li>půjčka</li>
                                            <li>dar</li>
                                            <li>výhra</li>
                                            <li>dědictví</li>
                                            <li>příjem z prodeje jiného majetku</li>
                                        </ul>
                                        Klient se zavazuje, že pokud ani jeden z výše uvedených zdrojů peněžních prostředků či majetku neodpovídá skutečnosti, neprodleně sdělí Poskytovateli konkrétní zdroj peněžních prostředků či majetku. Poskytovatel je oprávněn požadovat od Klienta doplnění získaných informací a dokladů.
                                    </li>
                                    <li>První identifikaci klienta, který je fyzickou osobou a každé fyzické osoby jednající jménem klienta, který je právnickou osobou provede Provozovatel za fyzické přítomnosti identifikovaného dle ustanovení § 8 odst. 1 AMLZ, a to výjimkami dle AMLZ kdy není fyzická přítomnost identifikovaného nezbytná.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="VII. Směna, úložka kryptoměny">
                            <Text>
                                <ol>
                                    <li>Postup při nákupu a prodeji či úložce (zápůjčce) kryptoměny je podrobně popsán v Příloze č. 1 těchto podmínek.</li>
                                    <li>Při Směně, jak je specifikována v čl. I. bod 15 podmínek (tj. nákup či prodej kryptoměny) neprovozuje Poskytovatel na webových stránkách směnárenskou činnost podle zákona č. 277/2013 Sb., o směnárenské činnosti, neposkytuje platební služby podle zákona č. 370/2017 Sb., o platebním styku, neposkytuje investiční služby podle zákona č. 256/2004 Sb., o podnikání na kapitálovém trhu, ani nezajišťuje kolektivní investování podle zákona č. 240/2013 Sb., o investičních společnostech a investičních fondech.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="IX. Odstoupení od Smlouvy">
                            <Text>
                                <ol>
                                    <li>V případě nákupu či prodeje kryptoměny nemůže Klient po připsání či odepsání kryptoměny na Kryptoadresu /z Kryptoadresy odstoupit od Kupní smlouvy v souladu s ustanovením § 1837 písm. b) občanského zákoníku, neboť předmětem Smlouvy je dodávka Virtuální měny, jejíž cena závisí na výchylkách finančního trhu nezávisle na vůli Provozovatele a k nimž může dojít během lhůty pro odstoupení od Smlouvy.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="X. Další práva a povinnosti Smluvních stran">
                            <Text>
                                <ol>
                                    <li>Klient je povinen při užívání Webových stránek dodržovat obecně závazné právní předpisy a Podmínky, jakož i respektovat práva Poskytovatele i třetích osob. Klient se zejména zavazuje, že nebude:
                                        <ol>
                                            <li>užívat Webové stránky způsobem způsobilým poškodit Poskytovatele, ostatní Klienty a/nebo třetí osoby;</li>
                                            <li>pozměňovat obsah Webových stránek, jakkoliv do nich zasahovat, ohrožovat a/nebo narušovat jejich provoz;</li>
                                            <li>používat programy, vybavení nebo jiné mechanismy a postupy, které by mohly mít negativní vliv na fungování Webových stránek;</li>
                                            <li>získávat neoprávněně osobní údaje jiných Klientů nebo skutečnosti, které jsou předmětem obchodního tajemství Poskytovatele; a</li>
                                            <li>používat nezabezpečený e-mail či sdělovat přístupové heslo ke svému účtu jakýmkoli třetím osobám.</li>
                                        </ol>
                                    </li>
                                    <li>Klient nese odpovědnost za jakoukoli újmu či škodu vzniklou v důsledku jeho jednání či opomenutí, které je v rozporu s dobrými mravy, obecně závaznými právními předpisy, Podmínkami a Smlouvou či Kupní smlouvou.</li>
                                    <li>Klient je oprávněn:
                                        <ol>
                                            <li>spravovat svůj klientský účet, zejména si určit (případně měnit) přístupové heslo ke svému účtu, a další nastavitelné parametry;</li>
                                            <li>obracet se kdykoli prostřednictvím e-mailu nebo prostřednictvím telefonické komunikace na klientskou podporu Poskytovatele, která mu poskytne potřebné informace, návody a doporučení a zabezpečí mu další pomoc nezbytnou pro využívání služeb Provozovatele, a to v českém či anglickém jazyce, podle aktuální dostupnosti pracovníků Poskytovatele;</li>
                                            <li>reklamovat v případech a způsoby stanovenými Podmínkami, jakož i obecně závaznými právními předpisy.</li>
                                        </ol>
                                    </li>
                                    <li>Uživatel je povinen chránit své Přístupové údaje pro přihlášení Uživatele do Profilu a pro přístup k Službě před zneužitím třetí osobou. V případě zjištění jakéhokoliv zneužití dle předchozí věty je Uživatel povinen neprodleně toto sdělit Poskytovateli a změnit heslo ke svému Profilu, je-li to možné.</li>
                                    <li>Poskytovatel si tímto vyhrazuje právo v případě mnohonásobného nesprávného zadání hesla k Profilu tento Profil dočasně zablokovat, případně pro ověření kontaktovat Uživatele skrze v Profilu uvedené kontaktní údaje.</li>
                                    <li>Uživatel vůči Poskytovateli prohlašuje, zaručuje a zavazuje se, že:
                                        <ol>
                                            <li>je plně svéprávný a tedy způsobilý k právnímu jednání a k jednání spojených s touto Smlouvou;</li>
                                            <li>neprovede registraci, pokud by jejím provedením ze strany Uživatele došlo k porušení právních předpisů;</li>
                                            <li>veškeré údaje, které o sobě poskytne, jsou pravdivé, úplné, přesné a správné v rámci registrace;</li>
                                            <li>nebude používat žádnou Službu nabízenou Poskytovateli v rozporu s platnými právními předpisy či nebude používat takovou Službu, pokud by jejím použitím ze strany Uživatele došlo k porušení platných právních předpisů;</li>
                                            <li>bude používat Služby pouze k účelu, k němuž jsou určeny dle těchto podmínek nebo Webového rozhraní;</li>
                                            <li>se před zahájením užívání jakékoliv Služby důkladně seznámil se Službou samotnou, resp. s její náplní, a vzal v potaz všechna rizika bezpečnostní i právní;</li>
                                            <li>se v souvislosti s registrací a užíváním Služeb seznámil se Smlouvou, resp. s obsahem těchto Podmínek, kterým rozumí a bez výhrad s nimi souhlasí;</li>
                                            <li>že Služby nebude používat k žádným aktivitám, které jsou nebo by mohly být v rozporu s platnými právními předpisy, Smlouvou, Podmínkami a/nebo oprávněnými zájmy Provozovatele či třetích osob,</li>
                                            <li>bude dodržovat Smlouvu a Kupní smlouvu vč. těchto podmínek,</li>
                                            <li>nebude činit to, co by mohlo jakkoliv narušit nebo poškodit Platformu včetně sítě a serverů, popřípadě samotné Webové rozhraní;</li>
                                            <li>nebude činit to, co by mohlo jakkoliv ohrozit, omezit nebo znemožnit poskytování Služeb;</li>
                                            <li>se nebude v souvislosti s užíváním Služby dopouštět žádného protiprávního ani neetického jednání;</li>
                                        </ol>
                                    </li>
                                    <li>Uživatel nese veškerou odpovědnost za dopad svého jednání spojeného s užíváním jakékoliv Služby poskytnuté Poskytovateli a s tímto výslovně souhlasí.</li>
                                    <li>Uživatel se dále zaručuje, zavazuje a je plně seznámen s tím, že není oprávněn:
                                        <ol>
                                            <li>užívat Službu v rozporu se Smlouvou (včetně Podmínek);</li>
                                            <li>užívat Službu způsobem způsobilým poškodit Poskytovatele či třetí osoby, zejména zakládáním duplicitních profilů jedním subjektem a/nebo jiné snaze o podvodné jednání;</li>
                                            <li>poškozovat dobré jméno nebo pověst Poskytovatele;</li>
                                            <li>získávat či aktivně využívat Profil nebo Přístupové údaje jiných Uživatelů;</li>
                                            <li>zneužívat, blokovat, modifikovat či jinak měnit jakoukoliv součást Služby;</li>
                                            <li>pokusit se narušit stabilitu, chod nebo data jakékoliv služby;</li>
                                            <li>vyvolat dojem, že uživatelem Služby je jiný subjekt, než Uživatel, nebo vytvářet Profily, jejichž parametry mohou budit pohoršení či jsou v rozporu s morálními nebo etickými pravidly;</li>
                                            <li>porušovat práva Poskytovatele nebo třetích osob jiným způsobem než je uvedeno v předchozích bodech Podmínek;</li>
                                            <li>pokusit se jednat protiprávním nebo zde zakázaným způsobem.</li>
                                        </ol>
                                    </li>
                                    <li>Uživatel je v rámci užívání Služeb povinen zachovávat práva Poskytovatele a třetích osob, zejména při nakládání s autorskými díly či s jinými předměty práv duševního vlastnictví. Povinnosti dodržovat práva duševního vlastnictví se mohou vztahovat i na Návštěvníka Webového Rozhraní.</li>
                                    <li>V případě, že Uživatel jakkoliv poruší tyto Podmínky, je to považováno za podstatné porušení Smlouvy a Poskytovatel je oprávněn omezit Služby nebo uživateli zakázat je využívat nebo zablokovat Profil Uživatele a je rovněž oprávněn od Smlouvy odstoupit.</li>
                                    <li>Poskytovatel vychází obvykle z toho, že údaje uvedené Uživatelem jsou správné.</li>
                                    <li>Poskytovatel upozorňuje Uživatele, že v souladu s platnými právními předpisy (zejména ZDP) mohou podléhat příjmy Uživatele z kryptoměn od určitého celkového ročního úhrnu dani z příjmu, přičemž uživatel bere na vědomí, že tato odpovědnost a povinnost je výhradně na straně Uživatele.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="XI. Reklamace">
                            <Text>
                                <ol>
                                    <li>V případě, že dojde na straně klienta ke zjištění jakýchkoli nestandardních okolností, zejména zcizení osobních dokladů, výpočetní techniky nebo jakýchkoli jiných událostí ovlivňujících bezpečnost či správnost prováděných transakcí, je povinen o tom neprodleně informovat Poskytovatele.</li>
                                    <li>Poskytovatel nenese žádnou odpovědnost v případě neprovedení transakce nákupu nebo prodeje virtuální měny, úložka kryptoměny, nákupu či prodeje elektronických peněz, nabití či zpětné výměny prostředků z elektronické peněženky, provedení plateb z elektronické peněženky, zejména z důvodu nedostatečné likvidity či pohybu trhu, přerušení dodávky proudu, internetového připojení dodavatelem, přerušení provozu burzy virtuálních měn nebo v případě událostí vyšší moci, v těchto případech bude klient neprodleně kontaktován v pracovní době pracovníkem klientského centra a vzájemné závazky z neprovedené transakce se strany zavazují vzájemně vypořádat bez zbytečného odkladu v dobré víře.</li>
                                    <li>Reklamace klienta za chybnou transakci je upravena v příloze č. 1 těchto podmínek.</li>

                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="XII. Omezení odpovědnosti Poskytovatele">
                            <Text>
                                <ol>
                                    <li>Poskytovatel neodpovídá za jakoukoli újmu či škodu vzniklou Klientovi v důsledku nedbalosti Klienta či třetí osoby anebo v důsledku jejich úmyslného jednání v rozporu dobrými mravy, platnými právními předpisy, Smlouvou, Podmínkami či Kupní smlouvou.</li>
                                    <li>Poskytovatel pak výslovně neodpovídá za jakoukoli újmu či škodu, která vznikla Klientovi uvedením chybných informací ze strany Klienta.</li>
                                    <li>Poskytovatel neodpovídá Uživateli zejména za tyto skutečnosti:
                                        <ol>
                                            <li>Webové Rozhraní a všechny Služby budou dostupné nepřetržitě 24 hodin denně a 7 dní v týdnu;</li>
                                            <li>Webové rozhraní a Služba budou poskytovány bez chyb a/nebo budou plně funkční.</li>
                                        </ol>
                                    </li>
                                    <li>Poskytovatel nenese odpovědnost ani nemůže nikterak Uživateli kompenzovat škody nebo jiné újmy způsobené v souvislosti se skutečnostmi uvedenými v předchozím odstavci tohoto článku těchto Podmínek.</li>
                                    <li>Poskytovatel neodpovídá Uživateli za jakoukoliv přímou či nepřímou újmu či škodu, která Uživateli vznikne v souvislosti s používáním Webového rozhraní.</li>
                                    <li>Poskytovatel zejména neodpovídá Uživateli za jakoukoliv přímou či nepřímou újmu či škodu způsobenou:
                                        <ol>
                                            <li>nefunkčností či nedostupností Webového rozhraní a/nebo Poskytovatelem poskytovaných Služeb a/nebo jejich přerušením a/nebo poruchou jednoho ze zde uvedených;</li>
                                            <li>stahováním informací zveřejněných na Portálu;</li>
                                            <li>ztrátou informací Uživatele a/nebo neoprávněným přístupem k informacím Uživatele.</li>
                                        </ol>
                                    </li>
                                    <li>Poskytovatel dále neodpovídá Uživateli za úplnost, správnost a pravdivost informací uvedených na webových stránkách a tudíž neodpovídá Uživateli za jakoukoliv přímou či nepřímou újmu či škodu, která Uživateli v souvislosti s tím vznikne, zejména neodpovídá Uživateli za jakoukoliv přímou či nepřímou újmu či škodu způsobenou neúplností, nepřesností, nepravdivostí či neaktuálností jakékoliv informace uvedené na Webových stránkách.</li>
                                    <li>Kliknutím na odkazy na webových stránkách může dojít k jeho opuštění a k přesměrování na Partnerské weby. Ve vztahu k Partnerským webům nenese Poskytovatel žádnou odpovědnost a neodpovídají Uživateli za jakoukoli přímou či nepřímou újmu či škodu v souvislosti s nimi vzniklou.</li>

                                    <li>Uživatel bere na vědomí, že je plně odpovědný za svá rozhodnutí a činy při využívání Služeb Poskytovatele.</li>
                                    <li>Poskytovatel dále neodpovídá za jakoukoli újmu či škodu vzniklou Klientovi:
                                        <ol>
                                            <li>v důsledku legislativních změn, včetně změn v interpretaci a aplikaci dosud platných právních předpisů;</li>
                                            <li>v důsledku rozhodnutí či opatření soudu, státního zastupitelství, policejního orgánu anebo jiných orgánů veřejné moci a státní správy a v důsledku výpadku sítě internet či P2P sítí Virtuální měny;</li>
                                            <li>úplnou či částečnou nedostupností nebo nefunkčností Webových stránek;</li>
                                            <li>nezákonným zneužitím Webových stránek;</li>
                                            <li>počítačovými viry;</li>
                                            <li>kybernetickými útoky;</li>
                                            <li>jinými událostmi vyšší moci, spočívajícími mimo vůli Poskytovatele;</li>
                                            <li>ukončením nabídky prodeje a/nebo nákupu některé nebo veškeré Virtuální měny, případně dalších Služeb; a</li>
                                            <li>přerušením provozu Webových stránek Poskytovateli (např. za účelem provedení údržby).</li>
                                        </ol>
                                    </li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="XIII. Ochrana osobních údajů">
                            <Text>
                                <ol>
                                    <li>Klient poskytuje v rámci vzájemných práv a povinností dle těchto Podmínek Poskytovateli své osobní údaje, když klient prohlašuje, že se podrobně seznámil s podmínkami ochrany osobních údajů na webových stránkách v sekci ,,Ochrana osobních údajů“ a s těmito souhlasí.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="XIV. Podmínky používání Webového rozhraní, obchodní sdělení a cookies">
                            <Text>
                                <ol>
                                    <li>Celý obsah přístupný na Webových stránkách, obsah Služby, jakož i webový obsah souvisí a všechny na ni publikované materiály (zejména texty, šablony, fotografie, loga, obrázky, videa atd.), včetně programového vybavení Webového rozhraní, je chráněn autorským právem a může být rovněž chráněn i právy třetích osob.</li>
                                    <li>Uživatel, byť ani jiný účastník, není oprávněn obsah dle předchozí věty Podmínek jakkoliv upravovat, měnit, kopírovat, šířit, rozmnožovat či používat k jakémukoliv účelu bez předchozího písemného souhlasu Poskytovatel.</li>
                                    <li>Uživatel bere na vědomí, že v případě jakéhokoliv porušení těchto Podmínek Uživatelem, je Poskytovatel oprávněn uplatňovat vůči Uživateli příslušné nároky z takového porušení, zejména pak nároky z autorských práv dle autorského zákona, včetně nároku na náhradu jakékoli majetkové a nemajetkové újmy takovým porušením způsobené.</li>
                                    <li>Poskytovatel má právo neustále monitorovat obsah Portálu k zajištění jeho chodu a vyhrazuje si právo bez předchozího upozornění smazat veškerý obsah Portálu, který je nebo může být shledán jako nevhodný a/nebo v rozporu s právními předpisy, jakož i má Poskytovatel právo na úpravu, změnu, smazání /a/nebo zákazu obsahu Portálu, který považuje jinak za závadný.</li>
                                    <li>Celý obsah včetně jednotlivých funkcionalit a software nemusí být provozován pouze Poskytovatelem, ale může být provozován jinými účastníky. Uživatel bere na vědomí, že autorská práva se mohou vztahovat i na některé části Webového rozhraní.</li>
                                    <li>Uživatel bere na vědomí a uzavřením Smlouvy souhlasí, že veškeré diskuze, komentáře, hodnocení a/nebo zprávy v diskuzním fóru a/nebo v jiných komunikačních kanálech týkajících se Portálu vkládá bez nároku na jakoukoliv odměnu, jsou veřejné, nikoliv soukromé, a mohou být tedy přečteny bez vědomí Uživatele, přičemž vyjadřují názory jejich autorů, nikoliv názory Poskytovatele a Poskytovatel neodpovídá za pravdivost informací v nich obsažených ani za soulad těchto informací s právními předpisy.</li>
                                    <li>Veškerá komunikace, která bude probíhat na Portálu (v diskuzních fórech, skupinách a/nebo jinde) se považuje za veřejnou, přičemž publikováním svých komentářů, zpráv a/nebo jiných informací na Portálu Uživatel poskytuje Poskytovateli právo používat tyto komentáře, zprávy a/nebo jiné informace Uživatele k reklamním účelům, propagaci, výzkumu trhu nebo k jinému účelu v rámci právních předpisů.</li>
                                    <li>Poskytovatel využívá cookies za účelem zlepšení podmínek poskytování svých služeb a jejich propagace.</li>
                                    <li>Cookies se rozumí malý soubor dat, který Webová stránka uloží do zařízení Klienta a Návštěvníka webových stránek, jehož účelem je zefektivnit zobrazovaný obsah a používání Webové stránky individuálně pro každého z Klientů a Návštěvníků. Používáním Webové stránky uděluje Klient a Návštěvník Poskytovateli souhlas s ukládáním souborů cookies na svém zařízení.</li>
                                    <li>Cookies dále umožňuje sledovat užívání služeb klientem na webových stránkách vč. IP adresy. Smyslem je poskytovat co nejkvalitnější služby a obsah. S tímto též klient souhlasí.</li>
                                    <li>Uzavřením Smlouvy uděluje Klient Poskytovateli souhlas se zasíláním obchodních sdělení Poskytovatele na emailovou adresu Klienta. Obchodním sdělením se rozumí zejména zasílání marketingových kampaní, newsletterů a obchodních nabídek. Při doručení obchodního sdělení má Návštěvník a Klient možnost vyjádřit svou vůli nedostávat další sdělení a Společnost je povinna tento projev vůle respektovat a další sdělení Návštěvníkovi a Klientovi nezasílat.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="XV. Ukončení smlouvy, Závěrečná ustanovení">
                            <Text>
                                <ol>
                                    <li>V případě, že Klient nemá žádné kryptoměny na kryptopeněžence, je oprávněn kdykoliv požádat Poskytovatele o ukončení smlouvy a zrušení jeho Klientského účtu.</li>
                                    <li>Klient prohlašuje, že byl před uzavřením těchto podmínek seznámen s podmínkami poskytování služeb ze strany Poskytovatele, virtuálních měn a obsahem platformy i těchto podmínek.</li>
                                    <li>Jestliže se některé ustanovení těchto podmínek, nebo jeho část ukáže jako neplatné, neúčinné nebo nevymahatelné, nebude tím dotčena platnost ani účinnost podmínek jako celku ani jejích zbývajících ustanovení, nebo jejich částí.</li>
                                    <li>Každá smluvní strana (jakožto případně dotčená strana) na sebe ve smyslu § 1765 odst. 2 zákona č. 89/2012 Sb., občanského zákoníku, v platném znění, přebírá nebezpečí změny okolností, tj. pro vyloučení pochybností se ustanovení § 1765 odst. 1 a § 1766 zákona č. 89/2012 Sb., občanského zákoníku, v platném znění ve vztazích mezi smluvními stranami založených těmito podmínkami neaplikují. Současně se pro vyloučení pochybností ve vztazích mezi smluvními stranami založených těmito podmínkami neaplikuje ustanovení § 1793 odst. 1 zákona č. 89/2012 Sb., občanského zákoníku, v platném znění.</li>
                                    <li>Poskytovatel si vyhrazuje právo kdykoliv a jakýmkoliv způsobem upravit Webové stránky či rozsah a podmínky nabídky produktů a služeb, a to i bez předchozího upozornění Klienta. Klient bere na vědomí, že tyto úpravy mohou mít vliv na charakter nabídky produktů a služeb, a zavazuje se, že v této souvislosti nebude uplatňovat náhradu jakékoli újmy či škody.</li>
                                    <li>Klient bere na vědomí, že v rámci obchodování s Virtuální měnou spolupracuje Poskytovatel s orgány veřejné moci a správy (např. s Českou národní bankou, Úřadem, policejními orgány apod.) a poskytují jim veškerou součinnost nezbytnou pro výkon jejich pravomocí, a v této souvislosti se zavazuje poskytovat obdobnou spolupráci a součinnost Poskytovateli.</li>
                                    <li>Veškeré spory vzniklé mezi Smluvními stranami se Smluvní strany zavazují řešit především vzájemnou dohodou a smírnými prostředky. V případě, že odstranění sporu smírnou cestou nebude možné, k řešení sporů jsou příslušné soudy České republiky. Provozovatel upozorňuje Klienta, že orgánem dozoru nad dodržováním povinností stanovených na ochranu spotřebitele je Česká obchodní inspekce.</li>
                                    <li>Veškeré právní vztahy vznikající v souvislosti s Webovými stránkami a jejich užíváním se řídí právním řádem České republiky, zejména Občanským zákoníkem. To platí i pro případy, kdy právní poměr Smluvních stran obsahuje mezinárodní (zahraniční) prvek.</li>
                                    <li>Smluvní strany výslovně prohlašují, že ujednání Podmínek odchylná od ustanovení Občanského zákoníku nebo tato ustanovení vylučující jsou takto sjednána vědomě a v souladu s dobrými mravy, veřejným pořádkem a s respektem ke všem právům a oprávněným zájmům Smluvních stran.</li>
                                    <li>Klient prohlašuje, že se před zahájením užívání Webových stránek a zadáním objednávky důkladně seznámil s těmito Podmínkami, plně jim porozuměl a bez výhrad souhlasí s jejich obsahem.</li>
                                    <li>Tyto Podmínky nabývají platnosti a účinnosti dne 1. 12. 2021.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                    </div>

                    <Title level={2} className="">Příloha č. 1 Všeobecných obchodních podmínek</Title>
                    <Text>Podmínky pro nákup/prodej/úložku kryptoměny</Text>
                    <div className="flex flex-col gap-y-px pb-32">
                        <TitleSection title="Definice pojmů">
                            <Text>
                                <ol>
                                    <li>Společností xxx (nebo jen Poskytovatel) se rozumí společnost xxx, zapsaná v obchodním rejstříku vedeném Krajským soudem v Ostravě, oddíl C, vložka 78290, která je právnickou osobou založenou v souladu s příslušnými ustanoveními zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů, a zákona č. 90/2012 Sb., o obchodních korporacích, ve znění pozdějších předpisů. V rámci své podnikatelské činnosti Poskytovatel poskytuje služby spojené s virtuální měnou, kterou se pro účely zákona AMLZ rozumí elektronicky uchovávaná jednotka bez ohledu na to, zda má nebo nemá emitenta, a která není peněžním prostředkem podle zákona o platebním styku, ale je přijímána jako platba za zboží nebo služby i jinou osobou odlišnou od jejího emitenta. Poskytovatel zejména zajišťuje nákup a prodej Kryptoměny směnou za prostředky z elektronické peněženky či službu úložky Kryptoměny.</li>
                                    <li>Webovými stránkami se rozumí webové stránky spektrum.fund / spektrum.finance.</li>
                                    <li>Kupní smlouvou se rozumí smlouva specifikovaná v čl. I. 12 VOP.</li>
                                    <li>Podmínkami se rozumí ustanovení uvedená v této Příloze č. 1 VOP, když tyto jsou nedílnou součástí Všeobecných obchodních podmínek pro webové stránky spektrum.fund (dále jen „VOP“) a jsou její nedílnou součástí.</li>
                                    <li>AML se rozumí zákon č. 253/2008 Sb., o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu, ve znění pozdějších předpisů.</li>
                                    <li>Zákonem o ochraně osobních údajů se rozumí zákon č. 101/2000 Sb., o ochraně osobních údajů a o změně některých zákonů, ve znění pozdějších předpisů.</li>
                                    <li>GDPR se rozumí Nařízení Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. 4. 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů).</li>
                                    <li>Pravidly ochrany soukromí se rozumí způsob nakládání s osobními údaji a zabezpečení jejich ochrany v souladu se Zákonem o ochraně osobních údajů, resp. s GDPR. Pravidla ochrany soukromí naleznete na webových stránkách v sekci „Ochrana osobních údajů“.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="Působnost podmínek">
                            <Text>
                                <ol>
                                    <li>Tyto podmínky stanovují pravidla nákupu/prodeje kryptoměn vč. služby úložky kryptoměny.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                        <TitleSection title="Kryptoadresa">
                            <>
                                <Title level={4}>A. Zřízení kryptoadresy/profilu</Title>
                                <Text>
                                    <ol>
                                        <li>Pro zřízení Kryptoadresy/profilu je nezbytné vyplnit v rámci registrace klienta na webových stránkách dle ustanovení VOP registrační údaje Klienta do registračního formuláře na webových stránkách a odsouhlasit VOP, jejichž součástí jsou i tyto podmínky. Součástí registrace klienta jsou podmínky identifikace a event. kontroly klienta dle čl. VII. VOP.</li>
                                        <li>Po dokončení registrace a založení profilu specifikovaného v čl. I. bod 6 VOP je Klientovi zpřístupněna Kryptoadresa/profil (viz čl. I bod 9 VOP).</li>
                                    </ol>
                                </Text>
                            </>
                        </TitleSection>
                        <TitleSection title="Směna prostředků za kryptoměnu">
                            <>
                                <Title level={4}>A. Nákup kryptoměny</Title>
                                <Text>
                                    <ol>
                                        <li>Klient je od Poskytovatele oprávněn zakoupit kryptoměnu dle individuální domluvy, ve které bude ujednáno množství a případný poplatek, společně s cenou, za kterou Klient od Poskytovatele kryptoměnu zakoupí. Klient od Poskytovatele kryptoměnu zakoupí na základě faktury. </li>
                                        <li>Do 24 hodin od provedení úhrady příslušné faktury bude se zakoupené množství kryptoměny zákazníkovi zobrazí ve webové aplikaci jako zůstatek. Poskytovatel kryptoměny pro Klienta zakoupené kryptoměny uloží na své peněžence, na které je bude držet až do Klientovi žádosti o jejich výběr nebo prodej.</li>
                                    </ol>
                                </Text>
                                <Title level={4}>B. Prodej kryptoměny</Title>
                                <Text>
                                    <ol>
                                        <li>Pokud požaduje Klient prodej kryptoměny, je možné tak učinit po individuální domluvě s Poskytovatelem. Klientovi před provedení prodeje bude sdělena cena kryptoměny, částka finančních prostředků, která bude připsána za požadovaný počet kryptoměny, případně poplatek za provedení prodeje v souladu s těmito podmínkami. Okamžikem souhlasu Klienta s nabídkou dochází k uzavření Kupní smlouvy specifikované v čl. I. bod 12 VOP.</li>
                                        <li>Do 3 pracovních dní po provedení objednávky vypořádá Poskytovateli příslušnou částku prostředků Klientovi smluveným způsobem. Současně odepíše příslušný počet kryptoměny ze zůstatku Klienta v rozhraní Klienta ve webové aplikaci. Klient bere na vědomí a souhlasí, že finanční částka prostředků za příslušný počet prodávané kryptoměny uvedený v objednávce se může lišit od vypořádané hodnoty, a to o změnu ceny kryptoměny v období ode dne odeslání objednávky do vypořádání objednávky.</li>
                                    </ol>
                                </Text>
                                <Title level={4}>C. Společné ustanovení k nákupu a prodeji kryptoměny</Title>
                                <Text>
                                    <ol>
                                        <li>Klient prohlašuje, že si je plně vědom rizik spojených s kryptoměnami. Poskytovatel upozorňuje, že výše částky, kterou se Klient rozhodne do nákupu kryptoměn vložit, by neměla být vyšší než suma, o kterou si může dovolit přijít. Klient si je vědom toho, že je zákazníkem Poskytovatele zcela dobrovolně, uvědomuje si, že je za obchod vykonaný na základě Kupní smlouvy odpovědný sám a že v případě jakékoliv vzniklé ztráty není Poskytovatel ani žádná třetí strana za ni odpovědná, a tedy ani povinná ji Klientovi uhradit.</li>
                                        <li>Při nákupu a prodeji kryptoměny za prostředky elektronické peněženky je Poskytovatel oprávněn si účtovat poplatek ve výši 1,5 % hodnoty transakce. Např. Půjde-li o nákup kryptoměny za prostředky elektronické peněženky v hodnotě 1.000,- CZK, bude k hodnotě nákupu připočten poplatek 15,- CZK, celková hodnota nákupu bude 1.015,- CKZ a bude proveden nákup kryptoměny za 1.000,- CZK a 15,- CZK bude strženo (započteno) jako poplatek za nákup. Při prodeji kryptoměny se postupuje obdobně.</li>
                                    </ol>
                                </Text>
                            </>
                        </TitleSection>
                        <TitleSection title="Úložka kryptoměny">

                            <Text>
                                <ol>
                                    <li>Klient je oprávněn využít službu úložky kryptoměny. Výběr doby úložky je ponechán na Klientovi z následujících variant: 3 měsíců, 6 měsíců, 12 měsíců a 24 měsíců nebo úložka s variabilním úrokem. Klient je oprávněn využít jednorázovou úložku kryptoměny, kdy množství uložené kryptoměny musí být uhrazeno do 25. dne kalendářního měsíce předcházejícího měsíce, od kterého bude úložka uskutečněna, jednorázová úložka kryptoměny je poskytována od minimální výše ekvivalentu 25.000,- CZK (dle aktuálních kurzů) a/nebo;</li>
                                    <li>Úložka kryptoměny je prováděna prostřednictvím k tomu určeného formuláře na Webových stránkách. Do objednávkového formuláře Klient zadá počet kryptoměny, kterou chce uložit a výběr doby úložky. V objednávkovém formuláři bude Klientovi před provedením úložky sdělen příslušný úrok v rámci úložky, a to v procentuálním vyjádření i vyjádření absolutním v množství kryptoměny (tj. uvedení počátečního stavu kryptoměny a konečného stavu počtu kryptoměny po uplynutí doby úložky). Okamžikem vyplnění a odeslání objednávkového formuláře od Klienta dochází k závaznému objednání služby úložky kryptoměny. Klient je následně přesměrován na platební bránu Coinbase Commerce, skrz kterou dojde k převodu kryptoměny. Úložka počíná být aktivní nejpozději následující pracovní den od připsání. Poskytovatel si vyhrazuje právo úložku neprovést a v takovém případě je odeslaná výše kryptoměny navrácena na kryptoadresu Klienta nejpozději do 14 pracovních dnů. </li>
                                    <li>Po provedení objednávky Klient obdrží obratem od Poskytovatele na webových stránkách textové potvrzení o objednávce úložky s předmětem objednávky a úložka bude vedena jako aktivní po přihlášení do rozhraní Klienta na webových stránkách.</li>
                                    <li>Úložkou se rozumí zapůjčení určitého počtu kryptoměny společnosti Poskytovatele na pevně stanovenou dobu, po kterou Klient nemá možnost s kryptoměny disponovat na své Kryptoadrese. Uložené kryptoměny se odepíší Klientovi z jeho účtu na Kryptoadrese na dobu úložky a tyto se zobrazí v kategorii „Seznam objednávek/úložek“.</li>
                                    <li>Poskytovatel se zavazuje v případě úložky kryptoměny zaplatit Klientovi garantovaný úrok z uložené kryptoměny v následující výši:
                                        <ol>
                                            <li>0,25	%	p.m.	v	případě	úložky	na	3 	měsíců,</li>
                                            <li>0,30	%	p.m.	v	případě	úložky	na	6	měsíců,</li>
                                            <li>0,40	%	p.m.	v	případě	úložky	na	12	měsíců a</li>
                                            <li>0,50	%	p.m.	v	případě	úložky	na	24	měsíců.</li>
                                        </ol>
                                        Úrok uvedený v tomto odstavci je v měsíčním vyjádření a úložka je úročena složeným úročením. Poskytovatel poskytuje úročení úroků náležících Klientovi.

                                    </li>
                                    <li>Příslušný úrok v příslušné výši z uložené kryptoměny náleží Klientovi za každý měsíc uplynulé úložky, přičemž Poskytovatel vyplatí Klientovi veškeré úroky až po uplynutí doby úložky spolu s vratkou úložky. Poskytovatel vyplatí úrok na Kryptoadresu Klienta v kryptoměně. Např. v případě úložky 1 Bitcoin na dobu 24 měsíců bude úrok 0,12 Bitcoin za celou dobu úložky vyplacen do 14 pracovních dnů od žádosti Klienta k vyplacení úložky. Žádost o výplatu úložky je Klient povinen zaslat ze svého e-mailu vedeného v uživatelském rozhraní webových stránek daného Klienta na adresu info@spektrum.fund</li>
                                    <li>Uloženou kryptoměnu se zavazuje Poskytovatel vrátit Klientovi na jeho Kryptoadresu nejpozději do 14 pracovních dnů od doručení e-mailu dle odstavce 6. uvedeného výše.</li>
                                    <li>Nehledě na předchozí ustanovení je Poskytovatel oprávněn poskytnout Klientovi úložku kryptoměny s variabilním úrokem, který bude určen v rozhraní uvedeném níže při každé objednávce. V takovém případě je Poskytovatel Klientovi vrátit úložku minimálně se spodní hranicí úroku a maximálně horní hranicí úroku uvedeného níže.
                                        <br />
                                        0,01 až 15 %	p.m.
                                        <br />
                                        Tato variabilní úložka je sjednána na dobu neurčitou s možností výpovědi Klienta bez udání důvodu s výpovědní dobou 30 dnů.
                                        <br />
                                        Poskytovatel je dále oprávněn poskytnout Klientovi úložku s úrokem, který bude určen další dohodou stran při dané objednávce.
                                    </li>
                                    <li>Klient je oprávněn požádat o předčasné ukončení úložky (vrácení kryptoměny). Vzor žádosti o předčasné ukončení úložky (dále jen „žádost o ukončení“) je k dispozici Klientovi ke stažení na webových stránkách. V případě žádosti o ukončení ze strany Klienta, vyplatí Poskytovatel uloženou kryptoměnu vč. úroku dosaženého před podáním žádosti ukončení, a to po odečtení poplatku za předčasné ukončení úložky (dále jen „poplatek za ukončení“). Úložka je úročena do konce kalendářního měsíce, ve kterém byla Poskytovateli doručena žádost o ukončení, přičemž není úročena od 1. dne měsíce následujícího po měsíci, ve kterém byla žádost o ukončení doručena. Poplatek za ukončení je vypočítán ze součtu množství uložené kryptoměny a úroku v závislosti na okamžiku, kdy byla žádost o ukončení doručena Poskytovateli. Výše poplatku za ukončení je stanovena vzorcem (2,00 % + (5,00 % * (M/12))), kde se do vzorce za proměnnou M dosadí počet celých měsíců zbývajících do původně sjednaného termínu konce úložky. Minimální výše poplatku za ukončení je 2,00 % a maximální výše poplatku za ukončení je 10,00 %.</li>
                                </ol>
                            </Text>

                        </TitleSection>
                        <TitleSection title="Společná ustanovení">
                            <>
                                <Title level={4}>A. Identifikace a kontrola klienta dle AML</Title>
                                <Text>
                                    <ol>
                                        <li>Identifikace a kontrola klienta je prováděna za podmínek AML a VOP.</li>
                                        <li>Poskytovatel si vyhrazuje právo kdykoliv i nad rámec situací uvedených ve VOP vyzvat Klienta k provedení identifikace či požadovat předložení dalších identifikačních údajů nebo dokladů, je-li toho třeba ke splnění jejích povinností dle příslušných ustanovení AML.</li>
                                    </ol>
                                </Text>
                                <Title level={4}>B. Poplatky</Title>
                                <Text>
                                    <ol>
                                        <li>Klient bere na vědomí a souhlasí s tím, že Služby mohou podléhat poplatkům popsaným v těchto podmínkách a že je Klient povinen tyto poplatky zaplatit.</li>
                                    </ol>
                                </Text>
                                <Title level={4}>C. Další práva a povinnosti Poskytovatele</Title>
                                <Text>
                                    <ol>
                                        <li>Poskytovatel neodpovídá Klientovi za škodu, která mu případně vznikla v důsledku ztráty hesla nebo jiných přístupových údajů k jeho Profilu na webových stránkách.</li>
                                        <li>Poskytovatel je oprávněn provádět systémová, technologická, bezpečnostní a jiné vylepšování služeb, webových stránek což Klient bere na vědomí.</li>
                                        <li>Poskytovatel je povinen provést systémová, technologická, bezpečností a jiné vylepšení služeb či webových stránek, pokud k tomu bude zavázán právními předpisy nebo ujednáními vyplývajícími ze smluvních závazků s poskytovateli platebních metod nebo na základě povinnosti smluvně převzaté.</li>
                                    </ol>
                                </Text>
                                <Title level={4}>D. Další práva a povinnosti Poskytovatele</Title>
                                <Text>
                                    <ol>
                                        <li>Klient je oprávněn reklamovat chybně provedené transakce ve lhůtě 3 dnů od skutečnosti, která je předmětem reklamace, a to prostřednictvím emailu na email uvedený na webových stránkách.</li>
                                        <li>Poskytovatel je povinen sdělit stanovisko k uplatněné reklamaci do 30 dnů od jejího obdržení, a to na e-mailovou adresu Klienta.</li>
                                        <li>Poskytovatel je povinen v případě oprávněné reklamace přijmout a provést nápravná opatření.</li>
                                    </ol>
                                </Text>
                            </>
                        </TitleSection>
                        <TitleSection title="Závěrečná ustanovení">
                            <Text>
                                <ol>
                                    <li>Tyto podmínky jsou nedílnou součástí VOP jako jejich Příloha č. 1. Obsah těchto podmínek je součástí obsahu VOP.</li>
                                </ol>
                            </Text>
                        </TitleSection>
                    </div>
                </div>
            </Layout>
        </AppLayout>

    );
}

function TitleSection({title, children}) {

    return (
        <div className="">
            <Title level={3} className="mb-2">{title}</Title>
            <div className="py-1">
                {children}
            </div>
        </div>
    )
}