import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const ClosedEye: FC<Props> = (props) => {
    const { width = '23', height = '10', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 23 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.2851 5.6879C7.42614 5.6879 4.0535 3.5927 2.24793 0.47168H0.285156C1.10323 2.19376 2.31378 3.6933 3.80122 4.8546L1.84386 6.812L3.07334 8.0414L5.27378 5.841C6.89285 6.7624 8.74394 7.3231 10.7177 7.4137V9.6001H12.4564V7.3711C14.3576 7.1895 16.1312 6.5706 17.6755 5.6162L20.1007 8.0414L21.3302 6.812L19.1028 4.5845C20.4379 3.4645 21.5291 2.06312 22.2851 0.47168H20.3224C18.5168 3.5927 15.1442 5.6879 11.2851 5.6879Z"
                fill={color}
            />
        </svg>
    );
};

export default ClosedEye;
