import React, { FC, SVGProps } from "react";

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Success: FC<Props> = (props) => {
    const { width = "12", height = "10", color = "white" } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 4.5L4.50161 8L11.2663 1"
                stroke={color}
                strokeWidth="2"
            />
        </svg>
    );
};

export default Success;
