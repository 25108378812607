import React from 'react';

import AppLayout from 'components/Layouts/AppLayout';
import Back from 'components/Back';
import HereWithYou from 'fragments/IndividualPortfolio/HereWithYou';
import Hero from 'fragments/IndividualPortfolio/Hero';
import IndividualPortfolioLogo from 'components/Logos/IndividualPortfolioLogo';
import Layout from 'components/Layouts/Layout';
import Offering from 'sharedFragments/Offering';

export default function IndividualPortfolio() {
    return (
        <AppLayout>
            <Layout>
                <Back />
                <IndividualPortfolioLogo />
                <Hero />
                <Offering />
                <HereWithYou />
            </Layout>
        </AppLayout>
    );
}
