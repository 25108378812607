import { format } from "date-fns";


export default function SimpleDate({ children }: { children: string }) {

    return (
        <span className="">
            {format(new Date(children.replace(" ", "T")), "dd. MM. yyyy hh:mm")}
        </span>
    )
}

export function getDate(date: string) {
    if (date) {
        return (new Date(date.replace(" ", "T")))
    }

}