import React from 'react';

import InsertCodeForm from 'components/Forms/InsertCodeForm';
import Title from 'components/Typography/Title';

export default function InsertCode() {
    return (
        <section className="relative min-h-almost100vh px-4 lg:px-0 grid place-items-center">
            <div className="w-full lg:w-148 mt-8 text-left">
                <Title level={2}>Prosím, zadejte kód</Title>
                <InsertCodeForm />
            </div>
        </section>
    );
}
