import { motion } from "framer-motion";
import React, { FC, SVGProps, useState } from "react";

type Props = {
    width?: number;
    height?: number;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Equivalent: FC<Props> = (props) => {

    const [clicked, setClicked] = useState(false);

    return (
        <motion.div onClick={() => setClicked(!clicked)}
        animate={{rotate: clicked ? 180 : 0}}
        style={{width: props?.width ?? 48, height: props?.width ?? 48}}
        className="items-center flex justify-center flex-shrink-0 cursor-pointer border border-white-30 hover:border-white-50 rounded-full">
            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.530859 12.9072V12.9088C0.515429 12.969 0.5 13.0307 0.5 13.0955V13.1001V13.1017V13.1032V13.1047V13.1063V13.1078V13.1094V13.1125C0.5 13.114 0.5 13.1156 0.5 13.1171V13.1202V13.1217V13.1248V13.1264V13.1294C0.5 13.1958 0.515429 13.2575 0.530859 13.3177V13.3192C0.561718 13.4272 0.623436 13.5275 0.700583 13.6139L4.31109 17.2213C4.58882 17.4975 5.03627 17.4975 5.314 17.2213C5.59173 16.9452 5.59173 16.4961 5.314 16.2215L2.907 13.8207H22.8573C23.2431 13.8207 23.5517 13.5028 23.5517 13.1125C23.5517 12.7221 23.2431 12.4058 22.8573 12.4058H2.907L5.314 10.005C5.59173 9.72876 5.59173 9.28131 5.314 9.00512C5.03627 8.72894 4.58882 8.72894 4.31109 9.00512L0.700583 12.6125C0.623436 12.6974 0.561718 12.7992 0.530859 12.9072ZM21.1447 4.10781H1.20976C0.80859 4.10781 0.5 4.42565 0.5 4.81601C0.5 5.20484 0.80859 5.52268 1.20976 5.52268H21.1447L18.7377 7.92352C18.4599 8.19971 18.4599 8.64716 18.7377 8.92335C19.0154 9.19954 19.4629 9.19954 19.7406 8.92335L23.3511 5.31594C23.4282 5.23107 23.49 5.12923 23.5208 5.02122V5.01969C23.5517 4.95951 23.5517 4.8978 23.5517 4.833V4.82835V4.82682V4.82373V4.82219V4.8191V4.81601C23.5517 4.81447 23.5517 4.81293 23.5517 4.81139V4.8083V4.80676V4.80367V4.80214V4.79905V4.7975C23.5517 4.73269 23.5517 4.67098 23.5208 4.6108V4.60927C23.49 4.50126 23.4282 4.39942 23.3511 4.31456L19.7406 0.707141C19.4629 0.430953 19.0154 0.430953 18.7377 0.707141C18.4599 0.983329 18.4599 1.43232 18.7377 1.70697L21.1447 4.10781Z" fill="white" />
            </svg>
        </motion.div>


    );
};

export default Equivalent;
