import { InertiaLink } from "@inertiajs/inertia-react";
import { AppContext } from "components/Contexts/AppContextProvider";
import CreateDepositForm from "components/Forms/CreateDepositForm";
import Help from "components/Helps/Help";
import GoBackButton from "components/Icons/GoBackButton";
import SpektrumLogo from "components/Icons/Spektrum";
import Title from "components/Typography/Title";
import React, { useContext } from "react";

export default function CreateDeposit() {
    const { user, windowSize } = useContext(AppContext);

    return (
        <section className="relative min-h-screen px-5 pt-20 pb-5 lg:px-20 xl:px-32 2xl:px-60">
            {/* <div className="mt-4 lg:mt-10 flex justify-between items-center">
                <div className="relative w-1/3">
                    <div
                        className="w-max"
                        onClick={() => window.history.back()}
                    >
                        
                            <GoBackButton />
                       
                    </div>
                </div>

                <div className="w-1/3 flex lg:justify-center">
                    <div className="hidden lg:block">
                        <InertiaLink href="/profil">
                            <SpektrumLogo
                                width={windowSize.width > 640 ? "90" : "48"}
                                height={windowSize.width > 640 ? "90" : "48"}
                                color={
                                    windowSize.width > 640
                                        ? "rgba(255,255,255,1)"
                                        : "#000"
                                }
                            />
                        </InertiaLink>
                    </div>
                </div>

                <div className="w-1/3" />
            </div> */}
            <CreateDepositForm />
        </section>
    );
}
