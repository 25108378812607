import React, { ReactNode, useEffect } from "react";

import AppContextProvider from "components/Contexts/AppContextProvider";
import Footer from "./Footer";
import Live from "components/Live";
import Head from 'components/Head'
import MenuContext from "components/Contexts/MenuContext";
import { isMobile } from "react-device-detect";


interface Props {
    children: ReactNode;
    fullScrollPage?: boolean
    white?: boolean
}

export default function AppLayout(props: Props) {
    const { children, fullScrollPage = false } = props;

    return (
        <AppContextProvider>
            <MenuContext>
                <Head>
                    <title>Spektrum - pevné úročení kryptoměn</title>
                </Head>
                {(isMobile || !fullScrollPage) ?
                    children
                    :
                    <main id="content" className={`relative text-base font-Satoshi overflow-x-hidden ${fullScrollPage && " scrollContent"}`}>
                        {/* <Live /> */}
                        {children}
                    </main>
                }

                <div id={"modal-view"} style={{ zIndex: 70 }}></div>
                <div id={"overlay-view"} className="z-50"></div>
            </MenuContext>
        </AppContextProvider>
    );
}
