import React, { ReactNode } from 'react';

import AdminNav from 'components/Navbar/AdminNav';
import AppContextProvider from 'components/Contexts/AppContextProvider';

interface Props {
    children: ReactNode;
}

export default function AdminLayout(props: Props) {
    const { children } = props;
    return (
        <AppContextProvider>
            <main className="relative text-base font-Satoshi">
                <AdminNav />
                {children}
            </main>
        </AppContextProvider>
    );
}
