import React from 'react';

import BasicCard from 'components/Cards/BasicCard';
import Button from 'components/Forms/Components/Button';
import Deposit from 'Icons/Deposit';
import Evaluation from 'Icons/Evaluation';
import Plan from 'Icons/Plan';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';

export default function Hero() {
    return (
        <section className="min-h-screen px-6 lg:px-40 xl:px-100 2xl:px-140 pt-72 lg:pt-80 pb-20">
            <div className="lg:hidden fixed top-8 left-0 w-full h-14 bg-black" />
            <Title level={2}>
                Rádi se postaráme o vaše kryptoměnové portfolio
            </Title>
            <Text className="mt-6">
                Sestavíme vám strategii na míru přesně dle vašich představ a
                cílů. Záleží jen na vás, jestli si zvolíte konzervativní způsob
                zhodnocování investic, nebo je pro vás lákavější dynamické tempo
                s vyšším potenciálem zhodnocení.
            </Text>
            <Text className="mt-4" style={{ color: 'white' }}>
                Individuální portfolia spravujeme od základního kapitálu
                1&nbsp;000 000&nbsp;Kč.
            </Text>
            <div className="my-12 lg:my-20 flex flex-col lg:flex-row space-y-16 lg:space-y-0 lg:space-x-8 lg:justify-between">
                <BasicCard
                    icon={<Plan />}
                    title="Společně si nadefinujeme&nbsp;cíle a&nbsp;strategii"
                />
                <BasicCard
                    icon={<Deposit />}
                    title="Složte vklad"
                    text="ve&nbsp;FIAT měně, v&nbsp;BTC, nebo&nbsp;v&nbsp;ETH"
                />
                <BasicCard
                    icon={<Evaluation />}
                    title="24/7 se staráme o&nbsp;vaše&nbsp;portfolio"
                />
            </div>
            <Text>
                Samotná investice probíhá na globální platformě Iconomi, která
                vám umožňuje automaticky kopírovat (stínovat) námi spravovanou
                strategii v reálném čase. Své prostředky i kryptoměny máte stále
                k&nbsp;dispozici a můžete je kdykoliv vybrat, my s&nbsp;nimi
                nijak nedisponujeme.
            </Text>
        </section>
    );
}
