import React, { useContext } from "react";

import { AppContext } from "components/Contexts/AppContextProvider";
import BeginForm from "components/Forms/BeginForm";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { RegisterContext } from "components/Contexts/RegisterContextProvider";

export default function Begin() {
    const { user } = useContext(AppContext);
    const { exists } = useContext(RegisterContext)

    return (
        !user && (
            <section
                id="zacit"
                className="grid place-items-center px-4 lg:px-0 py-20"
            >
                <div className="space-y-4 lg:space-y-8 lg:w-136 lg:mx-auto">
                    <div className="mb-8 lg:text-center">
                        <Title level={2}>
                            Začněte zadáním <br className="lg:hidden" /> e-mailu
                        </Title>
                        <Text className={" mt-4 lg:mt-5 " + ((exists != "no" && exists != "yes")  && " opacity-0 ")}>
                            {exists == "yes" ?
                            "Přihlaste se prosím ke svému účtu."
                            :
                            "Provedeme vás celým procesem investice a rádi zodpovíme vaše dotazy."
                            } 

                           
                        </Text>
                    </div>
                    <BeginForm />
                </div>
            </section>
        )
    );
}
