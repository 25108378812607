 

import React, { DetailedHTMLProps, HTMLAttributes, useContext } from 'react'

interface Props {
    options: Array<{ text: string | any, value: string | number | any }>,
    value: string|number,
    setValue?: (to) => void
}

function Switch(props: Props) {
    const { options, value, setValue } = props

    return (
        <div className="bg-gray-light p-1 border border-white rounded-sm h-14 flex">
            {options.map((option, i) => (
                <Option active={option.value == value} label={option.text} onClick={() => setValue(option.value)} />
            ))}
        </div>
    )
}

interface OptionProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    label: string,
    active: boolean
}

function Option(props: OptionProps) {

    const { label, active, ...rest } = props

    return (
        <div {...rest} className={`h-full w-full flex justify-center cursor-pointer items-center ${active ? "bg-white text-black font-semibold" : " text-gray-dark hover:opacity-75"}`}>{label}</div>
    )
}

export default Switch
