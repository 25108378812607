import React, { FC } from 'react';

import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';

type Props = {
    icon: JSX.Element;
    text?: string;
    title: string;
};

const BasicCard: FC<Props> = (props) => {
    const { icon, text, title } = props;
    return (
        <div className="flex-1 flex flex-col items-center lg:items-start text-center lg:text-left">
            <div className="flex-none w-20 lg:w-22 h-20 lg:h-22 grid place-items-center bg-white-10-opaque rounded-full">
                {icon}
            </div>
            <Title level={5} className="mt-5">
                {title}
            </Title>
            <Text className="mt-2">{text}</Text>
        </div>
    );
};

export default BasicCard;
