import useOnClickOutside from "components/Hooks/useOnClickOutside";
import ValidateOk from "components/Icons/ValidateOk";
import { motion } from "framer-motion";
import React, { FC, HTMLProps, useEffect, useRef, useState } from "react";

type Props = {
    className?: string;
    error: string | undefined;
    icon?: JSX.Element;
    label?: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    type?: string;
    values: any;
    setValues: any;
} & HTMLProps<HTMLInputElement>;

const Input: FC<Props> = (props) => {
    const {
        className = "",
        error,
        icon,
        label,
        name,
        placeholder,
        required = false,
        setValues,
        type = "text",
        values,
    } = props;

    const [active, setActive] = useState(false);

    const ref = useRef(null as any)
    const inputRef = useRef(null as any)

    useOnClickOutside(ref, () => setActive(false))

    function check(val) {
        console.log("check")
        if (name == "postalCode") {
            console.log("postal")
            if (val.target.value.length == 3) {
                console.log("ted")
                inputRef.current.value = val.target.value + " "
                setValues({ ...values, [name]: val.target.value })
            }
            else {
                inputRef.current.value = val.target.value
                setValues({ ...values, [name]: val.target.value })
            }
        }
        if( name == "amountBTC" && val.target.value.includes(",")) {

            console.log("je to ono")
            inputRef.current.value = val.target.value.replace(",", ".")
            setValues({ ...values, [name]: val.target.value })
        }
        else {
            inputRef.current.value = val.target.value
            setValues({ ...values, [name]: val.target.value })
        }

    }

    function validate() {
        if (type == "email") {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values[name])) {
                return (true)
            }
        }
        if (type == "date") {
            if ((values[name])) {
                return (true)
            }
        }
        if (name == "firstName") {
            if (values[name].length > 2) {
                return true
            }
        }
        if (name == "lastName") {
            if (values[name].length > 2) {
                return true
            }
        }
        if (name == "fullName") {
            if (values[name].length > 6) {
                return true
            }
        }
        if (name == "companyName") {
            if (values[name].length > 4) {
                return true
            }
        }
        if (name == "tel") {
            if (values[name].length > 6) {
                return true
            }
        }
        if (name == "ICO") {
            if (values[name].length >= 8) {
                return true
            }
        }
        if (name == "DIC") {
            if (values[name].length >= 10) {
                return true
            }
        }
        if (name == "street") {
            if (values[name].length >= 5) {
                return true
            }
        }
        if (name == "city") {
            if (values[name].length >= 2) {
                return true
            }
        }
        if (name == "postalCode") {
            if (values[name].length == 6) {
                return true
            }
        }
    }

    return (
        <div ref={ref} onPointerDown={() => setActive(true)} className="rounded-3px overflow-hidden w-full flex flex-col ">
            <label className={className + " relative lg:w-full flex overflow-hidden rounded-3px flex-col border-0 "}>
                {/* <span className="text-xs tracking-wide font-semibold">{`${label} ${required ? "*" : ""
                }`}</span> */}
                <input
                    {...props}
                    type={type}
                    name={name}
                    ref={inputRef}
                    value={values[name]}
                    placeholder={placeholder}
                    onPointerDown={() => setActive(true)}
                    onChange={(e) =>
                        check(e)
                    }
                    style={{ minHeight: "56px ", textAlign: "left" }}
                    className={` px-4 pt-6 /pb-2 text-left w-full flex justify-start z-auto bg-transparent placeholder-white-30 border-white-30 focus:border-white transition duration-200 rounded-3px focus:ring-0  ${error
                        ? " border-red-500 focus:border-red-500"
                        : " border-white-30 hover:border-white-50 focus:border-white"
                        }  
                        ${(active || placeholder || values[name]?.length > 0) ? "text-white" : "text-transparent"}
                        ${(type == "date") ? " pb-1 " : "pb-2"}
                        
                        `}
                    required={required}
                />
                {icon && (
                    <span className="absolute z-10 top-1/4 right-4">{icon}</span>
                )}

                <span className="absolute z-20 top-4 right-4">
                    {(validate()) && (<ValidateOk />
                    )}
                </span>

                {error && <p className="mt-2 text-red-600">{error}</p>}
                <motion.div
                    onPointerDown={() => setActive(true)}
                    initial={false}
                    animate={{ y: (active || placeholder || values[name]?.length > 0) ? 2 : 16, fontSize: (active || placeholder || values[name]?.length > 0) ? "12px" : "16px", color: (active || placeholder || values[name]?.length > 0) ? "white" : "gray", fontWeight: (active || placeholder || values[name]?.length > 0) ? 600 : 400 }}
                    className="absolute top-0 pointer-events-none left-0 right-0 bottom-0 flex px-4 text-white border-0 font-semibold">
                    {label}
                </motion.div>
            </label>
        </div>

    );
};

export default Input;
