import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import React, { FC, useEffect, useRef, useState } from 'react';

import './style.css';
import ValidateOk from 'components/Icons/ValidateOk';
import { motion } from 'framer-motion';
import useOnClickOutside from 'components/Hooks/useOnClickOutside';
import { valueScaleCorrection } from 'framer-motion/types/render/dom/projection/scale-correction';

type Props = {
    className?: string;
    error: string;
    label?: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    setValues: Function;
    values: any;
};

const InputPhone: FC<Props> = (props) => {
    const {
        className = '',
        error,
        label,
        name,
        placeholder,
        required = false,
        setValues,
        values,
    } = props;

    function validate() {

        if (name == "tel") {
            if (values[name].length > 9) {
                return true
            }
        }
    }

    const [active, setActive] = useState(false);

    useEffect(() => {
        console.log(active)
    }, [active]);

    const [isOpen, setOpen] = useState(false);

   

    const ref = useRef(null as any)

    useOnClickOutside(ref, () => setActive(false))

    return (
        <label ref={ref} className={`${className} lg:w-full z-30 flex relative cursor-text flex-col`} onPointerDown={ () =>setActive(true)}>
            {/* <span className="text-xs tracking-wide font-semibold">{`${label} ${required ? '*' : ''
                }`}</span> */}
            <PhoneInput
                inputProps={{ name: name }}
                country={'cz'}
                value={values.tel}
                onChange={(phone) =>
                    setValues({ ...values, [name]: `+${phone}` })
                }
                // enableSearch
                preferredCountries={['cz']}
                containerClass=""
                inputStyle={{
                    width: '100%',
                    height: '3.6rem',
                    paddingLeft: '5rem',
                    backgroundColor: 'black',
                    borderRadius: '3px',
                    fontFamily: 'Graphik',
                    fontSize: '1em',
                    paddingTop: "22px",
                    paddingBottom: "2px",
                    color: (!active && values[name] == "+420") ? "transparent" : "white"
                }}
                buttonStyle={{
                    backgroundColor: 'black',
                    borderTopLeftRadius: '3px',
                    borderBottomLeftRadius: '3px',
                }}
                dropdownStyle={{
                    top: '3rem',
                    left: '0',
                    backgroundColor: 'rgba(0,0,0,1)',
                    border: '1px solid rgba(255,255,255,0.3)',
                    borderBottomColor: '1px solid rgba(255,255,255,0.3)' ,
                    zIndex: 999999999
                }}
                searchStyle={{ backgroundColor: 'black' }}
            />
            {error && <p className="mt-2 text-red-600">{error}</p>}
            {(validate()) && (
                <span className="absolute z-10 top-4 right-4"><ValidateOk /></span>
            )}
            <motion.div
                onPointerDown={() => setActive(true)}
                initial={false}
                animate={{ x: 64, y: (!active && values[name] == "+420") ? 16 : 2, fontSize: !(!active && values[name] == "+420") ? "12px" : "16px", color: !(!active && values[name] == "+420") ? "white" : "gray", fontWeight: !(!active && values[name] == "+420") ? 600 : 400 }}
                className="absolute top-0 left-0 right-0 bottom-0 flex px-4 text-white border-0 font-semibold">
                {label}
            </motion.div>
        </label>
    );
};

export default InputPhone;
