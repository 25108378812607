import React from "react";

import AppLayout from "components/Layouts/AppLayout";
import RegisterContextProvider from "components/Contexts/RegisterContextProvider";
import SpektrumLogo from "components/Logos/SpektrumLogo";
import Steps from "fragments/Register/Steps";

export default function Register() {
    return (
        <AppLayout>
            <RegisterContextProvider>
                <Steps />
            </RegisterContextProvider>
        </AppLayout>
    );
}
