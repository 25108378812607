import React, { useEffect, useState } from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import BlogpostsOverview from 'fragments/Admin/BlogpostsOverview';
import axios from 'axios';
import Title from 'components/Typography/Title';
import { InertiaLink, useForm } from '@inertiajs/inertia-react';
import Eye from 'components/Icons/Eye';
import Delete from 'components/Icons/Delete';
import Interest from 'components/Icons/Interest';
import { format } from 'date-fns';
import SimpleDate, { getDate } from 'components/DateFormatter';
import Button from 'components/Forms/Components/Button';
import Input from 'components/Forms/Components/Input';
import { Inertia } from '@inertiajs/inertia';

export default function NewInterest() {

    const form = useForm({
        date_from: null as any,
        value: null as any
    })
    const { data, setData, errors, clearErrors, post } = form;

    function create() {
        axios.post("/api/interests/add", {
            value: data.value,
            date: data.date_from
        }).then(res => {
            console.log(res)
            if (res.status == 200) {
                Inertia.visit("/admin/urokove-sazby")
            }
        })
    }

    return (
        <AdminLayout>
            <div className="min-h-screen px-5 lg:px-32 pt-32">
                <div className="flex justify-between items-baseline">
                    <Title level={2}>Nová variabilní sazba</Title>
                    <div className="inline-flex">
                        <Button theme="primary" onClick={() => create()}>Vytvořit</Button>
                    </div>

                </div>

                <div className="mt-4">
                    <form>

                        <Input
                            name="date_from"
                            type="datetime-local"
                            label="Datum a čas platnosti od"
                            values={data}
                            setValues={setData}
                            error={errors.date_from}
                        />
                        <div className="h-4"></div>
                        <Input
                            name="value"
                            type="number"
                            label="Úroková sazba (0-100)"
                            values={data}
                            setValues={setData}
                            error={errors.date_from}
                        />

                    </form>
                </div>
            </div>
        </AdminLayout>
    );
}


function State({ interest, previous }) {

    const now = new Date()

    if (getDate(interest.from) > now) {
        return (
            <div className="bg-blue-400 bg-opacity-50 text-white font-semibold text-sm text-center px-4 rounded-full">Budoucí</div>
        )
    }

    else if ((getDate(interest.from) < now) && (getDate(previous?.from) > now)) {
        return (
            <div className="bg-green text-white font-semibold text-sm rounded-full text-center px-4">Aktivní sazba</div>
        )
    }

    else if (getDate(interest.from) < now) {
        return (
            <div className=" bg-white bg-opacity-30 text-white font-semibold text-sm rounded-full text-center px-4">Minulá sazba</div>
        )
    }

    else return (
        <div className=""></div>
    )
}