import React, { FC, HTMLProps, ReactNode } from "react";

type Props = {
    children: ReactNode;
    className?: string;
    level?: Number;
} & HTMLProps<HTMLHeadingElement>;

const Title: FC<Props> = (props) => {
    const { children, className = "", level = 1 } = props;

    let classname = " font-bold ";

    switch (level) {
        case 1:
            return (
                <h1 {...props} className={`${classname} text-4xl ${className}`}>
                    {children}
                </h1>
            );

        case 2:
            return (
                <h2 {...props} className={`${classname} text-3xl ${className}`}>
                    {children}
                </h2>
            );

        case 3:
            return (
                <h3 {...props} className={`${classname} text-2xl ${className}`}>
                    {children}
                </h3>
            );

        case 4:
            return (
                <h4 {...props} className={`${classname} text-xl  ${className}`}>
                    {children}
                </h4>
            );

        case 5:
            return (
                <h5 {...props} className={`${classname} text-lg  ${className}`}>
                    {children}
                </h5>
            );

        case 6:
            return (
                <h6
                    {...props}
                    className={`${classname} text-base ${className}`}
                >
                    {children}
                </h6>
            );

        default:
            return <></>;
    }
};

export default Title;
