import axios from "axios";
import { AppContext } from "components/Contexts/AppContextProvider";
import { interestsVariableArray } from "components/StaticData";
import NiceDate from "fragments/NiceDate";
import React, { FC, HTMLProps, useContext, useEffect, useState } from "react";

type Props = {
    amountBTC: number;
    isCzk?: boolean,
    expiration: string;
    id: string;
    interest: string;
    userUUID?: string;
    type:string;
    name?:string
} & HTMLProps<HTMLDivElement>;

const DepositCard: FC<Props> = (props) => {
    const { amountBTC, expiration, id, interest, userUUID, type, isCzk, name } = props;

    const { user } = useContext(AppContext)

    // const [name, setName] = useState(null as any);

    // useEffect(() => {
    //     if (userUUID != user.UUID) {
    //         axios.get("api/get-user-name/" + userUUID).then(res => {

    //             setName(res.data.name)
    //         })
    //     }

    // }, []);

    return (
        <div
            {...props}
            className="p-4 flex justify-between bg-white-10 hover:bg-white-20 rounded-3px tracking-wide cursor-pointer transition-all duration-300 ease-in-out"
        >
            <div className="space-y-1">
                <p className=""><strong>{name}</strong> <small className="text-white-50">({id})</small></p>
                {(type =="fixed") &&
                    <p className="font-sm text-white-60 ">

                        do <NiceDate date={new Date(expiration)} />

                    </p>
                }
                {(type =="variable") &&
                    <p className="text-sm text-white-60 ">

                        variabilní

                    </p>
                }
            </div>
            <div className="space-y-1">
                <p className="font-bold text-right">{!isCzk ? `${amountBTC} BTC` : `${amountBTC} Kč`}</p>
                <p className="text-sm text-white-60 text-right flex items-center justify-end">
                    {interest ?? <ActualInterest />}% p.m.
                </p>
            </div>
        </div>
    );
};

export function ActualInterest() {

    const [value, setValue] = useState(null as any);

    useEffect(() => {
        axios.get("api/interests/actual").then(res => {
            console.log(res?.data?.interests)
            if (res?.data?.interests) {
                setValue(res.data.interests.interest)
            }
        })
    }, []);

    return (
        <span className="">aktuálně {value ?? <div className="w-6 h-4 bg-white-10 rounded"></div>}</span>
    )
}

export function RangeInterest(props) {

    const { bonus } = props

    const [variablePercentage, setVariablePercentage] = useState(interestsVariableArray);

    return (
        <div className="flex">
            <span className={"flex " + ((bonus != 0 && bonus > 0) && " line-through text-white-30 font-normal")}>{variablePercentage ? variablePercentage[0] + " - " + variablePercentage[1] + " " : <div className="w-10 h-4 bg-white-10 rounded"></div>}</span>
            {(bonus != 0 && bonus > 0) &&
                <span className="flex ml-2">{variablePercentage ? variablePercentage[0] + Number(bonus) + " - " + (variablePercentage[1] + Number(bonus)) + " " : <div className="w-10 h-4 bg-white-10 rounded"></div>}</span>
            }
        </div>

    )
}

export default DepositCard;
