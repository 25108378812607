import React, { FC, HTMLProps } from 'react';
import { InertiaLink } from '@inertiajs/inertia-react';

import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import Button from 'components/Forms/Components/Button';
import BlogLink from '../../fragments/Blog/BlogLink';
import Date from 'fragments/Blog/FormatDate';
import FormatDate from 'fragments/Blog/FormatDate';
import { isMobile } from 'react-device-detect';

type Props = {
    date: string;
    coverImage: string;
    slug: string;
    subtitle: string;
    title: string;
} & HTMLProps<HTMLDivElement>;

const BlogPostCard: FC<Props> = (props) => {
    const { date, coverImage, slug, subtitle, title } = props;

    return (
  
                <div
                    {...props}
                    className="relative grid grid-cols-3 w-full h-120 overflow-hidden border border-white-30 hover:border-white transition-all ease-out duration-300"
                >
                    <div className="relative overflow-hidden rounded-3px h-100 col-span-2 w-full">

                        <img
                            src={coverImage}
                            className="absolute top-0 left-0 right-0 bottom-0 object-cover"
                            alt={coverImage}
                        />

                    </div>

                    <div className="px-6 h-full flex items-start flex-col justify-center">
                        <FormatDate>{date}</FormatDate>
                        <Title level={3} className="mt-1">
                            {title}
                        </Title>
                        <div className="mt-4 text-black-70">{subtitle}</div>
                        <BlogLink href={`/blog/${slug}`} />
                    </div>
                </div>
    );
};

export default BlogPostCard;
