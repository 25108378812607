import './LoadingSpinner.scss'

interface Props {
    width?: number,
    color?: string
}

export default function LoadingSpinner(props: Props) {

    const { width, color } = props

    return (
        <svg className="spinner" viewBox="0 0 50 50" width={width ?? 48} height={width ?? 48}>
            <circle className="path" cx="25" cy="25" r="20" fill="none" stroke={color ?? 'white'} stroke-width="5"></circle>
        </svg>
    )
}