import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const ActiveManagment: FC<Props> = (props) => {
    const { width = '110', height = '110', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 110 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M62.4136 7.22141C62.4136 11.2097 59.1739 14.4428 55.1774 14.4428C51.181 14.4428 47.9412 11.2097 47.9412 7.22141C47.9412 3.23313 51.181 0 55.1774 0C59.1739 0 62.4136 3.23313 62.4136 7.22141Z"
                fill={color}
            />
            <path
                d="M86.2068 13.6157C86.2068 17.604 82.967 20.8371 78.9706 20.8371C74.9742 20.8371 71.7344 17.604 71.7344 13.6157C71.7344 9.62742 74.9742 6.39429 78.9706 6.39429C82.967 6.39429 86.2068 9.62742 86.2068 13.6157Z"
                fill={color}
            />
            <path
                d="M96.7264 38.2433C100.723 38.2433 103.963 35.0102 103.963 31.0219C103.963 27.0336 100.723 23.8005 96.7264 23.8005C92.73 23.8005 89.4902 27.0336 89.4902 31.0219C89.4902 35.0102 92.73 38.2433 96.7264 38.2433Z"
                fill={color}
            />
            <path
                d="M110 54.8224C110 58.8107 106.76 62.0438 102.764 62.0438C98.7674 62.0438 95.5276 58.8107 95.5276 54.8224C95.5276 50.8342 98.7674 47.601 102.764 47.601C106.76 47.601 110 50.8342 110 54.8224Z"
                fill={color}
            />
            <path
                d="M96.7264 86.1995C100.723 86.1995 103.963 82.9663 103.963 78.9781C103.963 74.9898 100.723 71.7567 96.7264 71.7567C92.73 71.7567 89.4902 74.9898 89.4902 78.9781C89.4902 82.9663 92.73 86.1995 96.7264 86.1995Z"
                fill={color}
            />
            <path
                d="M86.2068 96.3843C86.2068 100.373 82.967 103.606 78.9706 103.606C74.9742 103.606 71.7344 100.373 71.7344 96.3843C71.7344 92.396 74.9742 89.1629 78.9706 89.1629C82.967 89.1629 86.2068 92.396 86.2068 96.3843Z"
                fill={color}
            />
            <path
                d="M55.1774 110C59.1739 110 62.4136 106.767 62.4136 102.779C62.4136 98.7903 59.1739 95.5572 55.1774 95.5572C51.181 95.5572 47.9412 98.7903 47.9412 102.779C47.9412 106.767 51.181 110 55.1774 110Z"
                fill={color}
            />
            <path
                d="M38.2656 96.3843C38.2656 100.373 35.0258 103.606 31.0294 103.606C27.0329 103.606 23.7932 100.373 23.7932 96.3843C23.7932 92.396 27.0329 89.1629 31.0294 89.1629C35.0258 89.1629 38.2656 92.396 38.2656 96.3843Z"
                fill={color}
            />
            <path
                d="M13.6284 86.1995C17.6249 86.1995 20.8646 82.9663 20.8646 78.9781C20.8646 74.9898 17.6249 71.7567 13.6284 71.7567C9.63198 71.7567 6.39223 74.9898 6.39223 78.9781C6.39223 82.9663 9.63198 86.1995 13.6284 86.1995Z"
                fill={color}
            />
            <path
                d="M14.4724 54.8224C14.4724 58.8107 11.2326 62.0438 7.23618 62.0438C3.23975 62.0438 0 58.8107 0 54.8224C0 50.8342 3.23975 47.601 7.23618 47.601C11.2326 47.601 14.4724 50.8342 14.4724 54.8224Z"
                fill={color}
            />
            <path
                d="M13.6284 38.2433C17.6249 38.2433 20.8646 35.0102 20.8646 31.0219C20.8646 27.0336 17.6249 23.8005 13.6284 23.8005C9.63198 23.8005 6.39223 27.0336 6.39223 31.0219C6.39223 35.0102 9.63198 38.2433 13.6284 38.2433Z"
                fill={color}
            />
            <path
                d="M38.2656 13.6157C38.2656 17.604 35.0258 20.8371 31.0294 20.8371C27.0329 20.8371 23.7932 17.604 23.7932 13.6157C23.7932 9.62742 27.0329 6.39429 31.0294 6.39429C35.0258 6.39429 38.2656 9.62742 38.2656 13.6157Z"
                fill={color}
            />
            <path
                d="M51.7137 24.6323C52.3652 27.0639 50.9181 29.5644 48.4815 30.2173C46.0449 30.8702 43.5414 29.4282 42.8899 26.9966C42.2383 24.565 43.6854 22.0644 46.122 21.4116C48.5586 20.7587 51.0621 22.2006 51.7137 24.6323Z"
                fill={color}
            />
            <path
                d="M67.2649 24.6438C67.9165 27.0754 66.4694 29.576 64.0328 30.2288C61.5962 30.8817 59.0927 29.4398 58.4412 27.0081C57.7896 24.5765 59.2367 22.076 61.6733 21.4231C64.1099 20.7702 66.6134 22.2122 67.2649 24.6438Z"
                fill={color}
            />
            <path
                d="M77.7021 37.9406C80.1387 37.2878 81.5858 34.7873 80.9342 32.3556C80.2827 29.924 77.7792 28.482 75.3426 29.1349C72.906 29.7878 71.4589 32.2883 72.1105 34.7199C72.762 37.1516 75.2655 38.5935 77.7021 37.9406Z"
                fill={color}
            />
            <path
                d="M88.5035 45.8804C89.155 48.3121 87.7079 50.8126 85.2713 51.4655C82.8347 52.1183 80.3312 50.6764 79.6797 48.2447C79.0281 45.8131 80.4752 43.3126 82.9118 42.6597C85.3484 42.0068 87.8519 43.4488 88.5035 45.8804Z"
                fill={color}
            />
            <path
                d="M85.5366 67.1794C87.9732 66.5265 89.4203 64.026 88.7687 61.5944C88.1172 59.1627 85.6137 57.7208 83.1771 58.3737C80.7405 59.0266 79.2934 61.5271 79.945 63.9587C80.5965 66.3903 83.1 67.8323 85.5366 67.1794Z"
                fill={color}
            />
            <path
                d="M80.7867 75.1076C81.4382 77.5393 79.9912 80.0398 77.5545 80.6927C75.1179 81.3456 72.6145 79.9036 71.9629 77.472C71.3114 75.0403 72.7584 72.5398 75.1951 71.8869C77.6317 71.234 80.1351 72.676 80.7867 75.1076Z"
                fill={color}
            />
            <path
                d="M64.0925 88.4783C66.5291 87.8254 67.9762 85.3249 67.3246 82.8933C66.6731 80.4616 64.1696 79.0197 61.733 79.6726C59.2964 80.3255 57.8493 82.826 58.5009 85.2576C59.1524 87.6892 61.6559 89.1312 64.0925 88.4783Z"
                fill={color}
            />
            <path
                d="M51.557 82.9397C52.2086 85.3714 50.7615 87.8719 48.3249 88.5248C45.8883 89.1776 43.3848 87.7357 42.7332 85.304C42.0817 82.8724 43.5288 80.3719 45.9654 79.719C48.402 79.0661 50.9055 80.5081 51.557 82.9397Z"
                fill={color}
            />
            <path
                d="M34.8719 80.755C37.3085 80.1021 38.7556 77.6016 38.1041 75.1699C37.4525 72.7383 34.9491 71.2963 32.5124 71.9492C30.0758 72.6021 28.6287 75.1026 29.2803 77.5343C29.9318 79.9659 32.4353 81.4079 34.8719 80.755Z"
                fill={color}
            />
            <path
                d="M30.2605 61.4866C30.912 63.9182 29.465 66.4187 27.0283 67.0716C24.5917 67.7245 22.0883 66.2825 21.4367 63.8509C20.7851 61.4193 22.2322 58.9188 24.6688 58.2659C27.1055 57.613 29.6089 59.0549 30.2605 61.4866Z"
                fill={color}
            />
            <path
                d="M27.0374 51.5162C29.474 50.8633 30.9211 48.3628 30.2696 45.9312C29.618 43.4995 27.1145 42.0576 24.6779 42.7105C22.2413 43.3633 20.7942 45.8639 21.4458 48.2955C22.0973 50.7271 24.6008 52.1691 27.0374 51.5162Z"
                fill={color}
            />
            <path
                d="M38.0353 32.4759C38.6868 34.9075 37.2397 37.408 34.8031 38.0609C32.3665 38.7138 29.8631 37.2718 29.2115 34.8402C28.5599 32.4086 30.007 29.908 32.4436 29.2552C34.8803 28.6023 37.3837 30.0442 38.0353 32.4759Z"
                fill={color}
            />
            <path
                d="M58.1233 37.7697C58.1233 39.2405 56.9285 40.4329 55.4546 40.4329C53.9807 40.4329 52.7859 39.2405 52.7859 37.7697C52.7859 36.2988 53.9807 35.1064 55.4546 35.1064C56.9285 35.1064 58.1233 36.2988 58.1233 37.7697Z"
                fill={color}
            />
            <path
                d="M66.898 40.1278C66.898 41.5987 65.7032 42.791 64.2294 42.791C62.7555 42.791 61.5607 41.5987 61.5607 40.1278C61.5607 38.657 62.7555 37.4646 64.2294 37.4646C65.7032 37.4646 66.898 38.657 66.898 40.1278Z"
                fill={color}
            />
            <path
                d="M70.7776 49.2104C72.2515 49.2104 73.4463 48.018 73.4463 46.5472C73.4463 45.0763 72.2515 43.8839 70.7776 43.8839C69.3038 43.8839 68.109 45.0763 68.109 46.5472C68.109 48.018 69.3038 49.2104 70.7776 49.2104Z"
                fill={color}
            />
            <path
                d="M75.6728 55.3246C75.6728 56.7955 74.478 57.9879 73.0042 57.9879C71.5303 57.9879 70.3355 56.7955 70.3355 55.3246C70.3355 53.8538 71.5303 52.6614 73.0042 52.6614C74.478 52.6614 75.6728 53.8538 75.6728 55.3246Z"
                fill={color}
            />
            <path
                d="M70.7776 66.8963C72.2515 66.8963 73.4463 65.704 73.4463 64.2331C73.4463 62.7623 72.2515 61.5699 70.7776 61.5699C69.3038 61.5699 68.109 62.7623 68.109 64.2331C68.109 65.704 69.3038 66.8963 70.7776 66.8963Z"
                fill={color}
            />
            <path
                d="M66.898 70.6524C66.898 72.1233 65.7032 73.3156 64.2294 73.3156C62.7555 73.3156 61.5607 72.1233 61.5607 70.6524C61.5607 69.1816 62.7555 67.9892 64.2294 67.9892C65.7032 67.9892 66.898 69.1816 66.898 70.6524Z"
                fill={color}
            />
            <path
                d="M55.4546 75.6738C56.9285 75.6738 58.1233 74.4815 58.1233 73.0106C58.1233 71.5398 56.9285 70.3474 55.4546 70.3474C53.9807 70.3474 52.7859 71.5398 52.7859 73.0106C52.7859 74.4815 53.9807 75.6738 55.4546 75.6738Z"
                fill={color}
            />
            <path
                d="M49.2176 70.6524C49.2176 72.1233 48.0228 73.3156 46.5489 73.3156C45.0751 73.3156 43.8803 72.1233 43.8803 70.6524C43.8803 69.1816 45.0751 67.9892 46.5489 67.9892C48.0228 67.9892 49.2176 69.1816 49.2176 70.6524Z"
                fill={color}
            />
            <path
                d="M40.1316 66.8963C41.6054 66.8963 42.8002 65.704 42.8002 64.2331C42.8002 62.7623 41.6054 61.5699 40.1316 61.5699C38.6577 61.5699 37.4629 62.7623 37.4629 64.2331C37.4629 65.704 38.6577 66.8963 40.1316 66.8963Z"
                fill={color}
            />
            <path
                d="M40.4428 55.3246C40.4428 56.7955 39.248 57.9879 37.7741 57.9879C36.3003 57.9879 35.1055 56.7955 35.1055 55.3246C35.1055 53.8538 36.3003 52.6614 37.7741 52.6614C39.248 52.6614 40.4428 53.8538 40.4428 55.3246Z"
                fill={color}
            />
            <path
                d="M40.1316 49.2104C41.6054 49.2104 42.8002 48.018 42.8002 46.5472C42.8002 45.0763 41.6054 43.8839 40.1316 43.8839C38.6577 43.8839 37.4629 45.0763 37.4629 46.5472C37.4629 48.018 38.6577 49.2104 40.1316 49.2104Z"
                fill={color}
            />
            <path
                d="M49.2176 40.1278C49.2176 41.5987 48.0228 42.791 46.5489 42.791C45.0751 42.791 43.8803 41.5987 43.8803 40.1278C43.8803 38.657 45.0751 37.4646 46.5489 37.4646C48.0228 37.4646 49.2176 38.657 49.2176 40.1278Z"
                fill={color}
            />
        </svg>
    );
};

export default ActiveManagment;
