import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const ArrowUp: FC<Props> = (props) => {
    const { width = '24', height = '24', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.1641 14L12.2807 9L7.3973 14"
                stroke={color}
                strokeWidth="2"
            />
        </svg>
    );
};

export default ArrowUp;
