import React, { FC, useEffect, useState } from 'react';

type Props = {
    checked?: boolean;
    children: any;
    className?: string;
    error: string | undefined;
    name: string;
    required?: boolean;
    setValues: Function;
    values: any;
};

const Checkbox: FC<Props> = (props) => {
    const {
        checked = false,
        children,
        className,
        error,
        name,
        required = false,
        setValues,
        values,
    } = props;

    const [isChecked, setChecked] = useState(checked);

    useEffect(() => {
        setValues({ ...values, [name]: isChecked });
    }, []);

    return (
        <label className={className + ' flex flex-col cursor-pointer'}>
            <div className="flex items-start lg:items-center">
                <input
                    type="checkbox"
                    name={name}
                    checked={isChecked}
                    className={`w-6 h-6 bg-transparent rounded-sm text-539648 focus:ring-0 focus:ring-offset-0 cursor-pointer ${
                        error ? 'border-red-500' : 'border-white-30 hover:border-white-50'
                    }`}
                    required={required}
                    onChange={(e) =>
                        setValues({ ...values, [name]: e.target.checked })
                    }
                    onClick={() => setChecked((prev) => !prev)}
                />
                <span className="ml-4 text-sm">{`${children} ${
                    required ? '*' : ''
                }`}</span>
            </div>
            {error && <p className="mt-2 text-red-600">{error}</p>}
        </label>
    );
};

export default Checkbox;
