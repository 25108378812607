import React from "react";

import AppLayout from "components/Layouts/AppLayout";
import LoginForm from "components/Forms/LoginForm";
import SpektrumLogo from "components/Logos/SpektrumLogo";
import Live from "components/Live";

export default function Login() {
    return (
        <AppLayout>
            <LoginForm />
        </AppLayout>
    );
}
