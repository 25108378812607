/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require("./bootstrap");

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// require('./components/Example');

import { render } from "react-dom";

// kick off the polyfill!
// smoothscroll.polyfill();

import createInertiaApp from "@inertiajs/inertia-react/src/createInertiaApp";

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
      name?: string;
    }
  };

createInertiaApp({
    resolve: (name) => require(`./pages/${name}`).default,
    setup({ el, App, props }) {
        render(<App {...props} />, el);
    },
});
