import React, { useContext, useEffect, useState } from "react";

import Button from "components/Forms/Components/Button";
import CallForm from "components/Forms/CallForm";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import CalculateDepositForm from "components/Forms/CalculateDepositForm";
import { AppContext } from "components/Contexts/AppContextProvider";
import { AnimatePresence, motion } from "framer-motion";
import AnimatedBalance from "fragments/Profile/AnimatedBalance";
import Triangle from "components/Icons/Triangle";
import AnimatedBalanceKc from "fragments/Profile/AnimatedBalanceKc";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import axios from "axios";

export default function (props) {
    const { goToPage } = props
    const [isScrolled, setScrolled] = useState(false);
    const [value, setValue] = useState(null as any);
    const { windowSize, user } = useContext(AppContext);
    const [i, setI] = useState(0);
    const [portfolioBase, setPortfolioBase] = useState(null as any);

    const [exchangeRate, setExchangeRate] = useState({
        BTC: null,
        USD: 22,
        EUR: 25,
    });

    const handleScroll = (e) => {
        if (window.scrollY > 0 && !isScrolled) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        axios.get("/api/portfolio/all").then(res => {
            console.log(res)
            if (res.status == 200) {
                if (res.data.values[0].display == "btc") {
                    setPortfolioBase(res.data.values[0].btc_value)
                }
                else {
                    setValue(res.data.values[0].czk_value)
                }
            }
        })

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };


    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (exchangeRate.BTC) {
                setValue((portfolioBase * exchangeRate.BTC) + (i * 0.3))
                setI(i + 1)
            }

        }, 1000)
    }, [value]);

    useEffect(() => {
        if (exchangeRate && portfolioBase) {
            setValue((portfolioBase * exchangeRate.BTC) + (i * 0.3))
            setI(i + 1)
        }
    }, [exchangeRate, portfolioBase]);

    useEffect(() => {
        axios
            .get(`https://api.coinbase.com/v2/exchange-rates?currency=BTC`)
            .then((res) => {
                setExchangeRate({
                    BTC: res.data.data.rates.CZK,
                    USD: res.data.data.rates.USD,
                    EUR: res.data.data.rates.EUR,
                })

            }
            )
            .catch((err) => {
                throw err;
            });
        // const interval = setInterval(() => {

        // }, 4000);
        // return () => {
        //     clearInterval(interval);
        // };
    }, []);

    function phoneRadius() {
        if ((windowSize.width / 2 - 38) > 170) {
            return 170
        }
        else {
            return (windowSize.width / 2 - 38)
        }
    }

    function getCircleCoordinates(angle) {
        var radius = windowSize.width > 1024 ? 220 : phoneRadius();
        var x = radius * Math.sin((Math.PI * 2 * angle) / 360);
        var y = radius * Math.cos((Math.PI * 2 * angle) / 360);

        return { x: x, y: y };
    }

    var formatter = new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: "CZK",
    });

    const circles = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];


    return (
        <section
            id="uvod"
            className="min-h-screen items-center px-6 py-32 lg:pt-36 lg:px-20 xl:px-32 2xl:px-60 flex flex-col justify-center lg:flex-row"
        >
            {/* Starý návrh  */}
            {/* <div className="lg:w-96 xl:w-108 lg:flex-none">
                <Title>
                    Spolehlivý partner <br className="hidden lg:block" /> v době
                    blockchainové <br className="hidden lg:block" />
                    <span className="font-thin">revoluce</span>
                </Title>
                <Text className="mt-6">
                    Internet změnil způsob, kterým si předáváme informace.
                </Text>
                <Text>
                    Blockchain vytvořil digitálním věcem pevnou hodnotu.
                </Text>

                <CallForm />
            </div> */}
            {/* nový návrh */}
            <div className="lg:flex-none lg:flex-grow flex justify-center lg:w-1/2 xl:w-1/2 2xl:w-2/5 2xl:pl-5 lg:pr-6 xl:pr-20 2xl:pr-6 3xl:pr-20 ">
                <div className="lg:w-4/5 w-full">
                    <Title>
                    Získejte na Bitcoin úrok až 1,1&nbsp;% měsíčně
                    </Title>
                    <Text className="mt-4 leading-7">
                        Spojujeme to nejlepší ze světa tradičních a decentralizovaných financí. Nadstandardní úrok a vysoká úroveň zabezpečení.
                    </Text>
                    <div className="mt-8 block lg:flex justify-start">

                        <Button link="/profil/nova-ulozka" theme="green">Nová úložka</Button>
                        <Link to="kalkulace" smooth>
                            <Button theme="link" onClick={() => {
                                goToPage(5)
                                scroll.scrollTo('kalkulace')
                            }}>Vypočítat výnos</Button>
                        </Link>
                    </div>
                </div>
            </div>
            {/* <AnimatePresence>
                {!isScrolled &&
                <motion.div
                exit={{opacity: 0, y: 20}}
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.2}}
                className="fixed bottom-0 z-10 right-0 left-0 p-5 lg:hidden">
                    <Button link={user ? "/profil" : "/registrace"} className="">
                        Začít
                    </Button>
                </motion.div>
                }
                
            </AnimatePresence> */}


            <div className="flex items-center justify-center w-full max-w-full lg:-ml-8 2xl:ml-0 lg:px-8 py-10">
                {/* <CalculateDepositForm /> */}
                <div className="grid lg:w-1/2 h-80 lg:h-140 place-items-center relative">
                    <div className="text-center z-10 space-y-4 flex flex-col  items-center">
                        <p className="font-bold flex items-center gap-x-2">Spravujeme aktiva v hodnotě</p>
                        <AnimatedBalanceKc currentValue={value} reset={false} />

                        <p className={(" text-green  monospace flex items-center")}>

                            <Triangle color="#6BBF5D" />


                            <span className="ml-2 mr-1 font-bold ">
                                Ø 0,44% měsíčně
                            </span>
                        </p>
                    </div>

                    {/* //
                    // tady je ten kolotoc
                    // */}


                    {circles.map((i) => (
                        <motion.div
                            style={{
                                y: getCircleCoordinates(i * -30 + 180).y,
                                x: getCircleCoordinates(i * -30 + 180).x,
                            }}
                            animate={{ opacity: [0.1, 1, 0.1] }}
                            exit={{ opacity: 0.1 }}
                            initial={{ opacity: 0.1 }}
                            transition={{
                                times: [0, 0.05, 1],
                                delay: i * 1,
                                duration: 12,
                                easings: "easeOut",
                                repeat: Infinity,
                            }}
                            className="absolute pointer-events-none z-0 top-0 bottom-0 left-0 right-0 flex items-center justify-center"
                        >
                            <motion.div
                                animate={{ width: windowSize.width > 1024 ? 48 : 24, height: windowSize.width > 1024 ? 48 : 24 }}
                                transition={{ duration: 0.5 }}
                                className="rounded-full bg-white"></motion.div>
                        </motion.div>
                    ))}
                </div>
            </div>

        </section>
    );
}
