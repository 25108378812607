import React from 'react';

import ActiveManagmentLogo from 'components/Logos/ActiveManagmentLogo';
import AppLayout from 'components/Layouts/AppLayout';
import Back from 'components/Back';
import DynamicEnvironment from 'fragments/ActiveManagement/DynamicEnvironment';
import Hero from 'fragments/ActiveManagement/Hero';
import Layout from 'components/Layouts/Layout';
import Market from 'fragments/ActiveManagement/Market';
import Offering from 'sharedFragments/Offering';

export default function ActiveManagment() {
    return (
        <AppLayout>
            <Layout>
                <Back />
                <ActiveManagmentLogo />
                <Hero />
                <Offering />
                <DynamicEnvironment />
                <Market />
            </Layout>
        </AppLayout>
    );
}
