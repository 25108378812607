import React, { ReactNode } from "react";

import Begin from "sharedFragments/Begin";
import ContactUs from "sharedFragments/ContactUs";
import FAQ from "sharedFragments/FAQ";
import Footer from "./Footer";
import LoggedUser from "components/LoggedUser";
import Navbar from "components/Navbar";
import Bloomberg from "fragments/Home/Bloomberg";

interface Props {
    children: ReactNode;
}

export default function Layout(props: Props) {
    const { children } = props;

    return (
        <>

            {children}
            {/* <Begin /> */}
            
        </>
    );
}
