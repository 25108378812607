import React, { useEffect, useRef, useState } from "react";

import AppLayout from "components/Layouts/AppLayout";
import Bloomberg from "fragments/Home/Bloomberg";
import FreerWorld from "fragments/Home/FreerWorld";
import Guide from "fragments/Home/Guide";
import Hero from "fragments/Home/Hero";
import Layout from "components/Layouts/Layout";
import NewEraOfTheInternet from "fragments/Home/NewEraOfTheInternet";
import Offering from "sharedFragments/Offering";
import PathToTheStars from "fragments/Home/PathToTheStars";
import Products from "fragments/Home/Products";
import SpektrumLogo from "components/Logos/SpektrumLogo";
import TechnologicRevolution from "fragments/Home/TechnologicRevolution";
import ProgressLoading from "fragments/Home/ProgressLoading";
import PhoneModal from "components/PhoneModal";
import ReactPageScroller from "react-page-scroller";
import Navbar from "components/Navbar";
import ContactUs from "sharedFragments/ContactUs";
import FAQ from "sharedFragments/FAQ";
import Footer from "components/Layouts/Footer";
import TechnologicRevolutionNew from "fragments/Home/TechnologicRevolutionNew";
import ScrollDownButton from "components/ScrollDownButton";
import { AnimatePresence, motion } from "framer-motion";
import ExponencialGrow from "fragments/Home/ExponencialGrow";
import { useDebouncedCallback } from "components/Hooks/useDebouncedCallback";
import Secure from "fragments/Home/Secure";
import BookMeeting from "fragments/Home/BookMeeting";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { getScrollPosition } from "components/Hooks/useScroll";
import { useScrollYPosition } from 'react-use-scroll-position';
import Calculator from "fragments/Home/Calculator";
import ContactAndFaq from "sharedFragments/ContactAndFaq";
import TechnologicRevolutionPhone from "fragments/Home/TechnologicRevolutionPhone";
import { isMobile } from "react-device-detect";

export default function Home() {

    // const container = useRef(null as any)
    // useScrollSnap({ ref: container, duration: 250, delay: 0 });
    const scrollY = useScrollYPosition();

    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(0);
    const [down, setDown] = useState(false);

    function changePage(page: number, force?: boolean) {
        if (scrollY < 100 && (!down || force)) {
            if (page >= 0 && page <= 8) {
                setPage(page)
            }
        }

    }

    useEffect(() => {
        console.log(down)
    }, [down]);

    useEffect(() => {
        // if (scrollY > 100) {
        //     setPage(6)
        // }
    }, [scrollY])

    useEffect(() => {
        const style = document.createElement("style");
        if (!isMobile) {
            document?.head?.appendChild(style);
            style.innerHTML = `@media screen and (min-width: 1024px) {
    
                html, body {
                    height: 100%;
                    overflow: hidden;
                }
                }}`;
        }
        return () => {
            var hs = document?.getElementsByTagName('style');
            console.log(hs)
            console.log(hs[hs.length - 1])
            document?.head?.removeChild(hs[hs.length - 1]);

        }
    }, []);

    return (
        <ReactScrollWheelHandler
            upHandler={(e) => changePage(nextPage - 1)}
            downHandler={(e) => changePage(nextPage + 1)}
            wheelConfig={[1, 90, 1, 1]}
            onPointerDown={() => setDown(true)}
            onPointerUp={() => {
                setTimeout(() => {
                    setDown(false)
                }, 100)

            }}
        >
            <AppLayout>
                <Layout>

                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: ((nextPage == 0 && !isMobile) || (isMobile && scrollY < 100)) ? 1 : 0 }}
                        exit={{ opacity: 0, transition: { duration: 0.25 } }}
                        transition={{ duration: 0.5 }}
                        className="fixed top-0 left-0 right-0 bottom-0"
                    >
                        <img className="object-cover w-full h-full" src="/assets/img/hills.jpg" />
                        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black-80 to-black" />
                    </motion.div>


                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: (nextPage == 3) ? 1 : 0 }}
                        exit={{ opacity: 0, transition: { duration: 0.25 } }}
                        transition={{ duration: 1 }}
                        className="fixed top-0 left-0 right-0 bottom-0"
                    >
                        <img className="object-cover w-full h-full" src="/assets/img/astronaut.png" />
                    </motion.div>

                    <Navbar technology changePage={(what: number) => changePage(what, true)} />
                    <PhoneModal />


                    <div className="hidden lg:block">
                        <ReactPageScroller blockScrollDown={nextPage < 5} blockScrollUp={nextPage < 5} onBeforePageScroll={(page) => setNextPage(page)} pageOnChange={(page) => setPage(page)} customPageNumber={page} animationTimer={500} animationTimerBuffer={10}>
                            <div className="flex min-h-screen items-center overflow-hidden  ">
                                <FreerWorld />
                            </div>
                            <NewEraOfTheInternet show={(page == 1 || nextPage == 1)} />
                            <TechnologicRevolution show={(page == 2 || nextPage == 2)} />
                            <PathToTheStars show={(page == 3 || nextPage == 3)} />
                            <section id="caste-dotazy" className="w-full min-h-screen items-center relative lg:max-h-screen overflow-hidden flex px-6 md:py-10 lg:px-20 xl:px-32 2xl:px-60">
                                <FAQ variant={1} />
                            </section>
                            {/* <Guide /> */}
                            <ExponencialGrow show={(page == 5 || nextPage == 5)} />
                            {/* <Secure show={(page == 2 || nextPage == 2)} /> */}
                            {/* <TechnologicRevolutionNew show={(page == 3 || nextPage == 3)} /> */}
                            {/* <BookMeeting show={(page == 4 || nextPage == 4)} /> */}
                            {/* <FreerWorld />
                    <PathToTheStars />
                    <Guide /> */}
                            <Calculator show={(page == 6 || nextPage == 6)} />
                            {/* <Bloomberg /> */}
                            <ContactAndFaq />
                            <Footer />

                        </ReactPageScroller>
                    </div>


                    {/* Mobil */}

                    <div className="block lg:hidden overflow-x-hidden">

                        <div className="flex min-h-screen items-center  ">
                            <FreerWorld />
                        </div>
                        <NewEraOfTheInternet show={(page == 1 || nextPage == 1)} />
                        <TechnologicRevolutionPhone noButton show={(page == 2 || nextPage == 2)} />
                        <PathToTheStars show={(page == 3 || nextPage == 3)} />
                        {/* <Guide /> */}
                        <section id="caste-dotazy" className="w-full items-center relative lg:max-h-screen overflow-hidden flex px-6 md:py-10 lg:px-20 xl:px-32 2xl:px-60">
                                <FAQ variant={1} />
                            </section>
                        <ExponencialGrow show={(page == 5 || nextPage == 5)} />
                        {/* <Secure show={(page == 2 || nextPage == 2)} /> */}
                        {/* <TechnologicRevolutionNew show={(page == 3 || nextPage == 3)} /> */}
                        {/* <BookMeeting show={(page == 4 || nextPage == 4)} /> */}
                        {/* <FreerWorld />
                    <PathToTheStars />
                    <Guide /> */}
                        <Calculator show={(page == 6 || nextPage == 6)} />
                        {/* <Bloomberg /> */}
                        <ContactAndFaq />
                        <Footer />

                    </div>
                </Layout>
            </AppLayout>
        </ReactScrollWheelHandler>

    );
}

function PageComponent({ children }) {
    return (<div>{children}</div>)
}