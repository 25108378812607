import React, { FC, useContext, useRef, useState } from "react";
import { InertiaLink } from "@inertiajs/inertia-react";

import { AppContext } from "./Contexts/AppContextProvider";
import Button from "./Forms/Components/Button";
import Settings from "Icons/Settings";
import UserAccount from "Icons/UserAccount";
import { AnimatePresence, motion } from "framer-motion";
import useOnClickOutside from "./Hooks/useOnClickOutside";

type Props = {
    admin?: boolean;
};

const LoggedUser: FC<Props> = (props) => {
    const { admin = false } = props;

    const [isSettingsOpen, setSettingsOpen] = useState(false);

    const { user } = useContext(AppContext);

    const ref = useRef(null as any)

    useOnClickOutside(ref, () => setSettingsOpen(false))

    return user ? (
        <>
            <div
                className={`${admin
                    ? ""
                    : "hidden lg:flex fixed lg:absolute z-10 right-6 top-12 lg:right-20 xl:right-32 2xl:right-60 lg:top-20"
                    } flex `}
            >
                <InertiaLink href="/profil" className="flex">
                    <UserAccount />
                    <span className="ml-4">{`${user.firstName} ${user.lastName}`}</span>
                </InertiaLink>
                <span
                    className="relative ml-8 cursor-pointer"
                    onClick={() => setSettingsOpen(true)}
                    ref={ref}
                >
                    <Settings />
                    <AnimatePresence>
                        {isSettingsOpen &&
                            <motion.div
                                exit={{ opacity: 0, y: -20, scale: 0.9 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                initial={{ opacity: 0, y: -20, scale: 0.9 }}
                                transition={{ duration: 0.15 }}
                                className={`absolute top-full right-0 w-max mt-2 border border-white-30 rounded-3px bg-black flex flex-col `}
                            >
                                <InertiaLink
                                    href="/auth/logout"
                                    method="post"
                                    as="button"
                                >
                                    <div className="p-4">Odhlásit se</div>
                                </InertiaLink>
                                {user.role === "admin" && (
                                    <InertiaLink href="/admin">
                                        <div className="p-4 border-t border-white-30">
                                            Administrace
                                        </div>
                                    </InertiaLink>
                                )}
                            </motion.div>
                        }
                    </AnimatePresence>
                </span>
            </div>
        </>
    ) : (
        <div className="hidden lg:block absolute right-20 xl:right-32 2xl:right-60 top-20">
            <Button link="/login" theme="modern">
                Přihlásit se
            </Button>
        </div>
    );
};

export default LoggedUser;
