import React, { useState } from 'react';

export const MenuContext = React.createContext<any>({
    opened: false,
    setOpened: () => {}
});

export default function App({ children }) {

    const [opened, setOpened] = useState(false);

    return (
        <MenuContext.Provider value={{opened, setOpened}}>
            {children}
        </MenuContext.Provider>
    )
}