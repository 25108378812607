import { InertiaLink } from "@inertiajs/inertia-react";
import { AppContext } from "components/Contexts/AppContextProvider";
import Button from "components/Forms/Components/Button";
import Help from "components/Helps/Help";
import BTC from "components/Icons/BTC";
import Triangle from "components/Icons/Triangle";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import AnimatedBalance from "./AnimatedBalance";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import DepositDetail from "./DepositDetail";
import Deposits from "./Deposits";
import Navbar from "components/Navbar";
import axios from "axios";
import { getVariableActualValue } from "./ValueFunctions/VariableInterestValue";

const exchangeRate = {
    BTC: 1332287.38,
    //BTC: 1000000,
    USD: 21.88,
    EUR: 25.38,
};

export default function PortfolioValue() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [showDeposit, setShowDeposit] = useState(null as any);
    const [currentBilance, setCurrentBilance] = useState(0);
    const [currentProfitCZK, setCurrentProfitCZK] = useState(0);
    const [currentInterest, setCurrentInterest] = useState(0);
    const [allInterests, setAllInterests] = useState(null as any);
    const [inKc, setInKc] = useState(false);
    const [refresh, setRefresh] = useState(Math.random());

    const { user, windowSize } = useContext(AppContext);

    const [deposits, setDeposits] = useState(user.deposits.data as any);

    function phoneRadius() {
        if ((windowSize.width / 2 - 38) > 170) {
            return 170
        }
        else {
            return (windowSize.width / 2 - 38)
        }
    }

    function getCircleCoordinates(angle) {
        var radius = windowSize.width > 1024 ? 220 : phoneRadius();
        var x = radius * Math.sin((Math.PI * 2 * angle) / 360);
        var y = radius * Math.cos((Math.PI * 2 * angle) / 360);

        return { x: x, y: y };
    }

    var formatter = new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: "CZK",
    });

    const circles = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];


    const makeValidTimeFormat = (date): string => {
        // if (!date) {
        //     return ""
        // }
        const splitted = date.split(". ");
        return `${(splitted[2].length > 1) ? splitted[2] : "0" + splitted[2]}-${(splitted[1].length > 1) ? splitted[1] : "0" + splitted[1]}-${splitted[0]}`;
    };

    function getRemamingDays(date) {
        const processed = new Date(date)
        const today = new Date()
        const diffTime = Math.abs(processed.getTime() - today.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // 
        // 

        return "Zbývá " + (diffDays > 1 ? diffDays : "∞") + " dní"
    }

    // 

    function isAmbassadorDeposit(deposit) {
        if (deposit.user_UUID != user.UUID) {
            return true
        }
        else return false
    }

    function depositCoef(deposit) {
        if (!isAmbassadorDeposit(deposit)) {
            return 1
        }
        else {
            return 0.8
        }
    }

    const average = arr => arr.reduce((a, b) => a + b, 0) / arr.length;

    useEffect(() => {
        window.scroll(0, 0)
        if (allInterests) {
            const interval = setInterval(() => {
                const now = new Date().getTime();
                let actualValue = 0;
                let profitCZK = 0;
                let actualInterest = 0;


                if (showDeposit == null) {
                    let interests = [] as any

                    deposits.filter((item) => (item.paymentStatus == "paid") && (((item.amountBTC != null) && !inKc) || (inKc && item.amountCzk))).forEach((deposit) => {
                        let finalOrKc = deposit.finalCzkBilance ?? deposit.finalBilance
                        let amountOrKc = deposit.amountCzk ?? deposit.amountBTC
                        if (deposit.type == "variable") {
                            const { finalValue, finalInterest } = getVariableActualValue(deposit, allInterests)
                            actualValue += (isAmbassadorDeposit(deposit) ? ((Number(finalValue - amountOrKc)) * depositCoef(deposit)) : finalValue)
                            profitCZK += (Number(finalValue - amountOrKc) * depositCoef(deposit)) * (inKc ? 1 : exchangeRate.BTC)
                            interests.push(finalInterest * depositCoef(deposit))
                        }
                        else if (new Date(deposit.expiration?.replace(" ", "T")) >= new Date() && deposit.expiration) {
                            actualValue += isAmbassadorDeposit(deposit) ? 0 : Number(amountOrKc);
                            const createdAt = new Date(deposit.payment.replace(" ", "T")).getTime();
                            const expiration = new Date(
                                deposit.expiration?.replace(" ", "T")
                            ).getTime();

                            const coef =
                                (now - createdAt) / (expiration - createdAt) / 100;

                            actualValue += coef * Number(finalOrKc) * depositCoef(deposit);
                            profitCZK +=
                                coef * Number(finalOrKc) * (inKc ? 1 : exchangeRate.BTC) * depositCoef(deposit);

                            interests.push(100 / Number(amountOrKc) * (coef * Number(finalOrKc) * depositCoef(deposit)))
                        }
                    });

                    actualInterest = average(interests)
                } else {
                    let finalOrKc = showDeposit.finalCzkBilance ?? showDeposit.finalBilance
                    let amountOrKc = showDeposit.amountCzk ?? showDeposit.amountBTC
                    let isKc = showDeposit.amountCzk ? true : false
                    setInKc(isKc)
                    if (showDeposit?.ended) {
                        actualInterest += Number(showDeposit?.finalInterest)
                        actualValue += Number(finalOrKc)
                        profitCZK += Number(amountOrKc) * (isKc ? 1 : exchangeRate.BTC) * Number(finalOrKc * actualInterest)
                    }
                    else if (showDeposit.type == "variable") {
                        // ToDo                        
                        const { finalValue, finalInterest } = getVariableActualValue(showDeposit, allInterests)
                        actualValue += (isAmbassadorDeposit(showDeposit) ? (Number(finalValue - amountOrKc) * depositCoef(showDeposit)) : finalValue)
                        profitCZK += (Number(finalValue - amountOrKc) * depositCoef(showDeposit)) * (isKc ? 1 : exchangeRate.BTC)
                        actualInterest += finalInterest * depositCoef(showDeposit)
                    }
                    else {
                        let fakeActualValue = Number(
                            amountOrKc
                        );
                        actualValue += isAmbassadorDeposit(showDeposit) ? 0 : Number(
                            amountOrKc
                        );
                        const createdAt = new Date(
                            showDeposit.payment?.replace(" ", "T")
                        ).getTime();
                        const expiration = showDeposit.expiration ? new Date(showDeposit?.expiration?.replace(" ", "T")
                        ).getTime() : 0;

                        const coef = (expiration - createdAt) / (expiration - createdAt) / 100;

                        profitCZK +=
                            coef *
                            Number(finalOrKc) *
                            (isKc ? 1 : exchangeRate.BTC) * depositCoef(showDeposit);

                        actualValue +=
                            coef * Number(finalOrKc) * depositCoef(showDeposit);
                        fakeActualValue +=
                            coef * Number(finalOrKc) * depositCoef(showDeposit);

                        actualInterest += (100 / Number(amountOrKc) * fakeActualValue) - 100;
                    }
                }
                setCurrentInterest(actualInterest);
                setCurrentProfitCZK(profitCZK);
                setCurrentBilance(actualValue);
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [showDeposit, allInterests, inKc]);

    useEffect(() => {
        axios.get("api/interests/all").then(res => {
            if (res.data) {
                setAllInterests(res.data?.interests)
            }
        })
        axios.get('api/ambassador-deposits/' + user.UUID).then(res => {
            if (res?.data?.deposits && (deposits == user.deposits.data)) {
                let array = deposits
                array.concat(res?.data?.deposits)
                setDeposits(array.concat(res?.data?.deposits))
            }
        })
    }, []);

    return (
        <>
            <Navbar back={showDeposit ? true : false} backFunc={() => setShowDeposit(null)} noDot />
            <section className="relative min-h-screen flex flex-col pt-20 px-5 lg:px-20 xl:px-32 2xl:px-60 pb-20 lg:pb-0 overflow-hidden">
                {isDropdownOpen && (
                    <div
                        onClick={() => setDropdownOpen(false)}
                        className="absolute z-10 inset-0 w-full h-full"
                    />
                )}
                <div className="mt-10 flex flex-col lg:flex-row justify-between">
                    {true ? (
                        <>
                            <div className=" lg:w-1/2 h-80 lg:h-140 grid place-items-center relative">
                                <div className="text-center z-10 space-y-4 flex flex-col  items-center">
                                    <p className="font-bold flex items-center gap-x-2">{showDeposit == null ? (
                                        "Vaše portfolio"
                                    ) : (
                                        <>
                                            {(!showDeposit?.ended && showDeposit.type != "variable") &&
                                                <motion.div
                                                    animate={{ opacity: [1, 0.1] }}
                                                    transition={{ repeat: Infinity, duration: 2 }}
                                                    className="flex-none w-1.5 h-1.5 rounded-full bg-white" />
                                            }

                                            <span>
                                                {(showDeposit?.ended || showDeposit.type == "variable") ? "" : getRemamingDays(showDeposit?.expiration?.replace(" ", "T"))}
                                            </span>
                                        </>
                                    )}</p>
                                    <AnimatedBalance currentValue={currentBilance} inKc={inKc} reset={showDeposit} />

                                    <p className={((currentProfitCZK > 0) ? " text-green " : " text-white ") + "  monospace flex items-center"}>
                                        {(currentProfitCZK > 0) &&
                                            <Triangle color="#6BBF5D" />
                                        }

                                        <span className="ml-2 mr-1 font-bold ">
                                            {formatter.format(
                                                (isNaN(currentProfitCZK) ? 0 : currentProfitCZK)
                                            )}
                                        </span>{" "}
                                        ({(isNaN(currentInterest) ? 0 : currentInterest).toFixed(2)}
                                        %)
                                    </p>
                                    {!showDeposit &&
                                        <div className="flex items-center gap-2">
                                            <div onClick={() => setInKc(true)} className={`w-2 h-2 cursor-pointer rounded-full ${inKc ? "bg-white-90" : "bg-white-20"}`}></div>
                                            <div onClick={() => setInKc(false)} className={`w-2 h-2 cursor-pointer rounded-full ${!inKc ? "bg-white-90" : "bg-white-20"}`}></div>
                                        </div>
                                    }
                                </div>

                                {/* //
                    // tady je ten kolotoc
                    // */}


                                {circles.map((i) => (
                                    <motion.div
                                        style={{
                                            y: getCircleCoordinates(i * 30).y,
                                            x: getCircleCoordinates(i * 30).x,
                                        }}
                                        animate={{ opacity: (showDeposit?.ended || !currentBilance) ? [0.1, 0.1, 0.1] : [0.1, (windowSize.width < 370 ? 0.5 : 1), 0.1] }}
                                        exit={{ opacity: 0.1 }}
                                        initial={{ opacity: 0.1 }}
                                        transition={{
                                            times: [0, 0.05, 1],
                                            delay: (showDeposit?.ended || !currentBilance) ? 0 : i * 1,
                                            duration: (showDeposit?.ended || !currentBilance) ? 1 : 12,
                                            easings: "easeOut",
                                            repeat: Infinity,
                                        }}
                                        className="absolute pointer-events-none z-0 top-0 bottom-0 left-0 right-0 flex items-center justify-center"
                                    >
                                        <motion.div
                                            animate={{ width: showDeposit ? windowSize.width > 1024 ? 32 : 16 : windowSize.width > 1024 ? 48 : 24, height: showDeposit ? windowSize.width > 1024 ? 32 : 16 : windowSize.width > 1024 ? 48 : 24 }}
                                            transition={{ duration: 0.5 }}
                                            className="rounded-full bg-white"></motion.div>
                                    </motion.div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className=" text-center space-y-4">
                            <p className="font-bold flex items-center justify-center space-x-2">
                                <div className="flex-none w-1.5 h-1.5 rounded-full bg-white" />
                                <span>
                                    {deposits[showDeposit]
                                        ? deposits[showDeposit].expiration
                                        : ""}
                                </span>
                            </p>
                            <p className="flex items-center justify-center font-light text-4xl tracking-wide">
                                <span>
                                    {currentBilance
                                        .toLocaleString("cs-CZ", {
                                            minimumFractionDigits:
                                                deposits.length > 0
                                                    ? 9
                                                    : 2,
                                        })
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                </span>{" "}
                                <span className="ml-4 transform rotate-12">
                                    <BTC width="24" height="32" />
                                </span>
                            </p>
                            <p className={" text-green flex items-center justify-center space-x-2"}>
                                <Triangle color="#6BBF5D" />
                                <span className="font-bold">
                                    {currentProfitCZK
                                        .toLocaleString("cs-CZ", {
                                            minimumFractionDigits: 3,
                                        })
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                                    Kč
                                </span>
                                <span>
                                    (
                                    {currentInterest.toLocaleString("cs-CZ", {
                                        minimumFractionDigits: 6,
                                    })}
                                    %)
                                </span>
                            </p>
                        </div>
                    )}
                    {showDeposit == null && (
                        <InertiaLink href="/profil/nova-ulozka">
                            <div className="sm:w-max mx-auto mt-12 lg:hidden">
                                <Button theme="green" link="/profil/nova-ulozka">Nová úložka</Button>
                            </div>
                        </InertiaLink>
                    )}
                    {deposits.filter((item => item.paymentStatus == "paid")).length > 0 ? (
                        showDeposit != null ? (
                            <DepositDetail item={showDeposit} actualInterest={currentInterest} />
                        ) : (
                            <Deposits
                                items={deposits.filter((item => item.paymentStatus == "paid"))}
                                setItem={setShowDeposit}
                            />
                        )
                    ) : (
                        <div className="lg:w-2/5 mt-10 lg:mt-0 flex items-center max-w-full">
                            <div className="flex flex-col space-y-4 max-w-full">
                                <Title level={3}>
                                    Prozatím nemáte žádné úložky
                                </Title>
                                <Text>
                                    Začněte zhodnocovat svůj bitcoin během několika
                                    kroků. Zařídit úložku trvá 2 minuty.
                                </Text>
                                <div className="sm:w-max block">
                                    <Button link="/profil/nova-ulozka" theme="secondary" className="w-full block md:w-auto">Nová úložka</Button>
                                </div>
                                <Help background />
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
