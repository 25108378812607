import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const SpektrumLogo: FC<Props> = (props) => {
    const { width = '110', height = '110', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={width}
            viewBox="0 0 110 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M55.1285 23.868C57.1297 23.868 58.7521 22.2418 58.7521 20.2359C58.7521 18.23 57.1297 16.6038 55.1285 16.6038C53.1273 16.6038 51.505 18.23 51.505 20.2359C51.505 22.2418 53.1273 23.868 55.1285 23.868Z"
                fill={color} />
            <path
                d="M72.4687 28.5376C74.4699 28.5376 76.0922 26.9115 76.0922 24.9056C76.0922 22.8996 74.4699 21.2735 72.4687 21.2735C70.4675 21.2735 68.8452 22.8996 68.8452 24.9056C68.8452 26.9115 70.4675 28.5376 72.4687 28.5376Z"
                fill={color}
            />
            <path
                d="M85.411 41.2502C87.4122 41.2502 89.0345 39.6241 89.0345 37.6182C89.0345 35.6122 87.4122 33.9861 85.411 33.9861C83.4098 33.9861 81.7875 35.6122 81.7875 37.6182C81.7875 39.6241 83.4098 41.2502 85.411 41.2502Z"
                fill={color}
            />
            <path
                d="M89.8138 58.6321C91.815 58.6321 93.4373 57.0059 93.4373 55C93.4373 52.9941 91.815 51.3679 89.8138 51.3679C87.8126 51.3679 86.1902 52.9941 86.1902 55C86.1902 57.0059 87.8126 58.6321 89.8138 58.6321Z"
                fill={color}
            />
            <path
                d="M85.411 76.2737C87.4122 76.2737 89.0345 74.6475 89.0345 72.6416C89.0345 70.6357 87.4122 69.0095 85.411 69.0095C83.4098 69.0095 81.7875 70.6357 81.7875 72.6416C81.7875 74.6475 83.4098 76.2737 85.411 76.2737Z"
                fill={color}
            />
            <path
                d="M72.4687 88.9858C74.4699 88.9858 76.0922 87.3596 76.0922 85.3537C76.0922 83.3478 74.4699 81.7216 72.4687 81.7216C70.4675 81.7216 68.8452 83.3478 68.8452 85.3537C68.8452 87.3596 70.4675 88.9858 72.4687 88.9858Z"
                fill={color}
            />
            <path
                d="M55.1285 93.6557C57.1297 93.6557 58.7521 92.0296 58.7521 90.0236C58.7521 88.0177 57.1297 86.3915 55.1285 86.3915C53.1273 86.3915 51.505 88.0177 51.505 90.0236C51.505 92.0296 53.1273 93.6557 55.1285 93.6557Z"
                fill={color}
            />
            <path
                d="M37.5305 88.9858C39.5317 88.9858 41.154 87.3597 41.154 85.3538C41.154 83.3478 39.5317 81.7217 37.5305 81.7217C35.5293 81.7217 33.907 83.3478 33.907 85.3538C33.907 87.3597 35.5293 88.9858 37.5305 88.9858Z"
                fill={color}
            />
            <path
                d="M24.8452 76.2737C26.8464 76.2737 28.4687 74.6475 28.4687 72.6416C28.4687 70.6357 26.8464 69.0095 24.8452 69.0095C22.844 69.0095 21.2217 70.6357 21.2217 72.6416C21.2217 74.6475 22.844 76.2737 24.8452 76.2737Z"
                fill={color}
            />
            <path
                d="M20.1863 58.6321C22.1875 58.6321 23.8098 57.006 23.8098 55.0001C23.8098 52.9941 22.1875 51.368 20.1863 51.368C18.1851 51.368 16.5628 52.9941 16.5628 55.0001C16.5628 57.006 18.1851 58.6321 20.1863 58.6321Z"
                fill={color}
            />
            <path
                d="M24.8452 41.2502C26.8464 41.2502 28.4687 39.6241 28.4687 37.6182C28.4687 35.6122 26.8464 33.9861 24.8452 33.9861C22.844 33.9861 21.2217 35.6122 21.2217 37.6182C21.2217 39.6241 22.844 41.2502 24.8452 41.2502Z"
                fill={color}
            />
            <path
                d="M37.5305 28.5378C39.5317 28.5378 41.154 26.9117 41.154 24.9058C41.154 22.8998 39.5317 21.2737 37.5305 21.2737C35.5293 21.2737 33.907 22.8998 33.907 24.9058C33.907 26.9117 35.5293 28.5378 37.5305 28.5378Z"
                fill={color}
            />
            <path
                d="M28.7314 12.193C30.1609 12.193 31.3196 11.0315 31.3196 9.59864C31.3196 8.16583 30.1609 7.0043 28.7314 7.0043C27.302 7.0043 26.1432 8.16583 26.1432 9.59864C26.1432 11.0315 27.302 12.193 28.7314 12.193Z"
                fill={color}
            />
            <path
                d="M24.3276 27.2403C26.0429 27.2403 27.4335 25.8465 27.4335 24.1271C27.4335 22.4077 26.0429 21.0139 24.3276 21.0139C22.6123 21.0139 21.2217 22.4077 21.2217 24.1271C21.2217 25.8465 22.6123 27.2403 24.3276 27.2403Z"
                fill={color}
            />
            <path
                d="M43.7446 16.0847C45.4599 16.0847 46.8505 14.6909 46.8505 12.9715C46.8505 11.2522 45.4599 9.85834 43.7446 9.85834C42.0293 9.85834 40.6387 11.2522 40.6387 12.9715C40.6387 14.6909 42.0293 16.0847 43.7446 16.0847Z"
                fill={color}
            />
            <path
                d="M66.518 16.0847C68.2333 16.0847 69.6238 14.6909 69.6238 12.9715C69.6238 11.2522 68.2333 9.85834 66.518 9.85834C64.8027 9.85834 63.4121 11.2522 63.4121 12.9715C63.4121 14.6909 64.8027 16.0847 66.518 16.0847Z"
                fill={color}
            />
            <path
                d="M85.9302 27.2405C87.6455 27.2405 89.0361 25.8466 89.0361 24.1273C89.0361 22.4079 87.6455 21.0141 85.9302 21.0141C84.2149 21.0141 82.8243 22.4079 82.8243 24.1273C82.8243 25.8466 84.2149 27.2405 85.9302 27.2405Z"
                fill={color}
            />
            <path
                d="M97.3169 46.9578C99.0323 46.9578 100.423 45.5639 100.423 43.8446C100.423 42.1252 99.0323 40.7314 97.3169 40.7314C95.6016 40.7314 94.2111 42.1252 94.2111 43.8446C94.2111 45.5639 95.6016 46.9578 97.3169 46.9578Z"
                fill={color}
            />
            <path
                d="M97.3169 69.5283C99.0323 69.5283 100.423 68.1345 100.423 66.4151C100.423 64.6957 99.0323 63.3019 97.3169 63.3019C95.6016 63.3019 94.2111 64.6957 94.2111 66.4151C94.2111 68.1345 95.6016 69.5283 97.3169 69.5283Z"
                fill={color}
            />
            <path
                d="M85.9302 89.2449C87.6455 89.2449 89.0361 87.8511 89.0361 86.1317C89.0361 84.4123 87.6455 83.0185 85.9302 83.0185C84.2149 83.0185 82.8243 84.4123 82.8243 86.1317C82.8243 87.8511 84.2149 89.2449 85.9302 89.2449Z"
                fill={color}
            />
            <path
                d="M66.2602 100.401C67.9755 100.401 69.366 99.0074 69.366 97.2881C69.366 95.5687 67.9755 94.1749 66.2602 94.1749C64.5448 94.1749 63.1543 95.5687 63.1543 97.2881C63.1543 99.0074 64.5448 100.401 66.2602 100.401Z"
                fill={color}
            />
            <path
                d="M43.7446 100.401C45.4599 100.401 46.8505 99.0074 46.8505 97.2881C46.8505 95.5687 45.4599 94.1749 43.7446 94.1749C42.0293 94.1749 40.6387 95.5687 40.6387 97.2881C40.6387 99.0074 42.0293 100.401 43.7446 100.401Z"
                fill={color}
            />
            <path
                d="M24.3276 88.986C26.0429 88.986 27.4335 87.5921 27.4335 85.8727C27.4335 84.1534 26.0429 82.7595 24.3276 82.7595C22.6123 82.7595 21.2217 84.1534 21.2217 85.8727C21.2217 87.5921 22.6123 88.986 24.3276 88.986Z"
                fill={color}
            />
            <path
                d="M12.9409 69.5282C14.6562 69.5282 16.0467 68.1343 16.0467 66.415C16.0467 64.6956 14.6562 63.3018 12.9409 63.3018C11.2255 63.3018 9.83499 64.6956 9.83499 66.415C9.83499 68.1343 11.2255 69.5282 12.9409 69.5282Z"
                fill={color}
            />
            <path
                d="M12.9409 46.9577C14.6562 46.9577 16.0467 45.5639 16.0467 43.8445C16.0467 42.1251 14.6562 40.7313 12.9409 40.7313C11.2255 40.7313 9.83499 42.1251 9.83499 43.8445C9.83499 45.5639 11.2255 46.9577 12.9409 46.9577Z"
                fill={color}
            />
            <path
                d="M55.1293 5.18867C56.5587 5.18867 57.7175 4.02715 57.7175 2.59434C57.7175 1.16152 56.5587 0 55.1293 0C53.6998 0 52.541 1.16152 52.541 2.59434C52.541 4.02715 53.6998 5.18867 55.1293 5.18867Z"
                fill={color}
            />
            <path
                d="M81.2685 12.193C82.698 12.193 83.8568 11.0315 83.8568 9.59864C83.8568 8.16583 82.698 7.0043 81.2685 7.0043C79.8391 7.0043 78.6803 8.16583 78.6803 9.59864C78.6803 11.0315 79.8391 12.193 81.2685 12.193Z"
                fill={color}
            />
            <path
                d="M100.423 31.3918C101.852 31.3918 103.011 30.2303 103.011 28.7975C103.011 27.3647 101.852 26.2032 100.423 26.2032C98.9935 26.2032 97.8347 27.3647 97.8347 28.7975C97.8347 30.2303 98.9935 31.3918 100.423 31.3918Z"
                fill={color}
            />
            <path
                d="M107.412 57.5946C108.841 57.5946 110 56.4331 110 55.0003C110 53.5675 108.841 52.4059 107.412 52.4059C105.982 52.4059 104.824 53.5675 104.824 55.0003C104.824 56.4331 105.982 57.5946 107.412 57.5946Z"
                fill={color}
            />
            <path
                d="M100.423 84.0565C101.852 84.0565 103.011 82.895 103.011 81.4622C103.011 80.0294 101.852 78.8679 100.423 78.8679C98.9935 78.8679 97.8347 80.0294 97.8347 81.4622C97.8347 82.895 98.9935 84.0565 100.423 84.0565Z"
                fill={color}
            />
            <path
                d="M81.2685 102.995C82.698 102.995 83.8568 101.834 83.8568 100.401C83.8568 98.9681 82.698 97.8065 81.2685 97.8065C79.8391 97.8065 78.6803 98.9681 78.6803 100.401C78.6803 101.834 79.8391 102.995 81.2685 102.995Z"
                fill={color}
            />
            <path
                d="M55.1293 110C56.5587 110 57.7175 108.838 57.7175 107.406C57.7175 105.973 56.5587 104.811 55.1293 104.811C53.6998 104.811 52.541 105.973 52.541 107.406C52.541 108.838 53.6998 110 55.1293 110Z"
                fill={color}
            />
            <path
                d="M28.7314 103.255C30.1609 103.255 31.3196 102.093 31.3196 100.66C31.3196 99.2277 30.1609 98.0662 28.7314 98.0662C27.302 98.0662 26.1432 99.2277 26.1432 100.66C26.1432 102.093 27.302 103.255 28.7314 103.255Z"
                fill={color}
            />
            <path
                d="M9.8356 83.7969C11.265 83.7969 12.4238 82.6354 12.4238 81.2026C12.4238 79.7698 11.265 78.6083 9.8356 78.6083C8.40616 78.6083 7.24738 79.7698 7.24738 81.2026C7.24738 82.6354 8.40616 83.7969 9.8356 83.7969Z"
                fill={color}
            />
            <path
                d="M2.5882 57.5946C4.01763 57.5946 5.17642 56.4331 5.17642 55.0003C5.17642 53.5675 4.01763 52.4059 2.5882 52.4059C1.15876 52.4059 -3.05176e-05 53.5675 -3.05176e-05 55.0003C-3.05176e-05 56.4331 1.15876 57.5946 2.5882 57.5946Z"
                fill={color}
            />
            <path
                d="M9.8356 31.3918C11.265 31.3918 12.4238 30.2303 12.4238 28.7975C12.4238 27.3647 11.265 26.2032 9.8356 26.2032C8.40616 26.2032 7.24738 27.3647 7.24738 28.7975C7.24738 30.2303 8.40616 31.3918 9.8356 31.3918Z"
                fill={color}
            />
        </svg>
    );
};

export default SpektrumLogo;
