import React from 'react';

import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';

export default function BuyBitcoin() {
    return (
        <section className="px-6 lg:px-40 xl:px-100 2xl:px-140 pt-10 pb-20 lg:py-20 border-b border-white-30">
            <Title level={2}>Chcete nakoupit Bitcoin?</Title>
            <Text className="mt-6">
                Investice je možná již od 50 000 Kč, maximální vklad není
                omezen.
            </Text>
            <Title level={2} className="mt-12">
                Máte vlastní Bitcoin?
            </Title>
            <Text className="mt-6">
                Po registraci vás celým procesem investice provedeme. Bitcoin
                příjmáme na jedné z&nbsp;našich adres. Po&nbsp;uplynutí úložky
                vám Bitcoin zašleme zpět.
            </Text>
            <div className="w-min mt-12 grid grid-rows-3 grid-cols-2 gap-x-5 gap-y-10 items-center">
                <div className="w-max text-2xl font-semibold">0,3%</div>
                <div className="w-max ml-16 text-white-60 text-base">
                    měsíčně při úložce na 6 měsíců
                </div>
                <div className="w-max text-2xl font-semibold">0,4%</div>
                <div className="w-max ml-16 text-white-60 text-base">
                    měsíčně při úložce na 12 měsíců
                </div>
                <div className="w-max text-2xl font-semibold">0,5%</div>
                <div className="w-max ml-16 text-white-60 text-base">
                    měsíčně při úložce na 24 měsíců
                </div>
            </div>
        </section>
    );
}
