import usePageProps from "components/Hooks/usePageProps";
import React, {
    createContext,
    FC,
    ReactNode,
    useEffect,
    useState,
} from "react";

type Props = {
    children: ReactNode;
};

export const AppContext = createContext<{
    loading: boolean;
    user: null | {
        UUID: string;
        role: "preregistered" | "client" | "admin";
        firstName: string;
        lastName: string;
        deposits: {
            data: [
                {
                    number: string;
                    amountBTC: number;
                    created_at: string;
                    expiration: string;
                    finalBilance: string;
                    interest: string;
                    finalInterest: string;
                    payment: string;
                    paymentStatus: string;
                    type: string;
                }
            ];
        };
    };
    windowSize: {
        width: number;
        height: number;
    };
}>({
    loading: false,
    user: null,
    windowSize: {
        width: 0,
        height: 0,
    },
});

const AppContextProvider: FC<Props> = (props) => {
    const { children } = props;

    const [loading, setLoading] = useState(true);

    const [windowSize, setWindowSize] = useState<{
        width: number;
        height: number;
    }>({
        width: 0,
        height: 0,
    });

    const { user } = usePageProps();

    // console.log("user: ", user);

    function changeWindowSize() {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        window.addEventListener("resize", changeWindowSize);

        return () => {
            window.removeEventListener("resize", changeWindowSize);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => setLoading(false), 5500);
    }, []);

    return (
        <AppContext.Provider value={{ loading, user, windowSize }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
