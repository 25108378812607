import React from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import CreateBlogPost from 'fragments/Admin/CreateBlogPost';

export default function Blog() {
    return (
        <AdminLayout>
            <CreateBlogPost />
        </AdminLayout>
    );
}
