import React, { useEffect, useState } from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import BlogpostsOverview from 'fragments/Admin/BlogpostsOverview';
import axios from 'axios';
import Title from 'components/Typography/Title';
import { InertiaLink, useForm } from '@inertiajs/inertia-react';
import Eye from 'components/Icons/Eye';
import Delete from 'components/Icons/Delete';
import Interest from 'components/Icons/Interest';
import { format } from 'date-fns';
import SimpleDate, { getDate } from 'components/DateFormatter';
import Button from 'components/Forms/Components/Button';
import Input from 'components/Forms/Components/Input';
import { Inertia } from '@inertiajs/inertia';

export default function NewInterest() {

    const form = useForm({
        btc_value: null as any,
        czk_value: null as any,
        display: "btc",
        created_at: new Date(),
        updated_at: new Date()
    })
    const { data, setData, errors, clearErrors, post } = form;

    function create() {
        axios.post("/api/portfolio/add", data).then(res => {
            console.log(res)
            if (res.status == 200) {
                Inertia.visit("/admin/spravujeme")
            }
        })
    }

    return (
        <AdminLayout>
            <div className="min-h-screen px-5 lg:px-32 pt-32">
                <div className="flex justify-between items-baseline">
                    <Title level={2}>Zapsat novou výši portfolia</Title>
                    <div className="inline-flex">
                        <Button theme="primary" onClick={() => create()}>Vytvořit</Button>
                    </div>

                </div>

                <div className="mt-4">
                    <form>
                        <div className="py-5">
                            <Title level={4}>Na úvodní stránce zobrazovat</Title>
                            <input type="radio" onChange={(el) => {
                                setData({
                                    ...data,
                                    display: el.target.value
                                })
                            }} id="display_btc" name="type" value="btc" />
                            <label className='ml-2' htmlFor="display_btc">Hodnotu portfolia vypočítávat dynamicky z BTC</label><br />
                            <input type="radio" onChange={(el) => {
                                setData({
                                    ...data,
                                    display: el.target.value
                                })
                            }} id="display_czk" name="type" value="czk" />
                            <label className='ml-2' htmlFor="display_czk">Zobrazit fixní hodnotu CZK</label><br />
                        </div>
                        {data.display == "btc" ?
                            <Input
                                name="btc_value"
                                type="number"
                                label="Hodnota portfolia v BTC"
                                values={data}
                                setValues={setData}
                                error={errors.btc_value}
                            />
                            :
                            <Input
                                name="czk_value"
                                type="number"
                                label="Hodnota portfolia v CZK"
                                values={data}
                                setValues={setData}
                                error={errors.czk_value}
                            />
                        }


                    </form>
                </div>
            </div>
        </AdminLayout>
    );
}
