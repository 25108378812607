import React from 'react';

import AppLayout from 'components/Layouts/AppLayout';
import Back from 'components/Back';
import BuyBitcoin from 'fragments/TermDeposit/BuyBitcoin';
import Earning from 'fragments/TermDeposit/Earning';
import Hero from 'fragments/TermDeposit/Hero';
import Layout from 'components/Layouts/Layout';
import TermDepositLogo from 'components/Logos/TermDepositLogo';

export default function TermDeposit() {
    return (
        <AppLayout>
            <Layout>
                <Back />
                <TermDepositLogo />
                <Hero />
                <Earning />
                <BuyBitcoin />
            </Layout>
        </AppLayout>
    );
}
