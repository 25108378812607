import Title from "components/Typography/Title"
import Text from "components/Typography/Text"
import { motion } from "framer-motion"
import Button from "components/Forms/Components/Button"
import { isMobile } from "react-device-detect"
import { Link } from "react-scroll"


export default function BookMeeting(props) {

    const { show, changePage } = props


    return (
        <section
            id="meeting"
            className="relative min-h-screen w-full flex items-center px-6 md:py-10 lg:px-20 xl:px-32 2xl:px-60"
            style={isMobile ? { backgroundImage: "url(/assets/img/earth.jpg)", backgroundPosition: "top", backgroundSize: "cover" } : undefined}
        >

            {(show || isMobile) &&
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="lg:pr-20">
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ ease: "easeOut", delay: 0.5 }}
                            className="mb-3 md:mb-9">
                            <Title>Jsme vaší branou pro vstup do světa digitálních aktiv</Title>
                        </motion.div>
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ ease: "easeOut", delay: 0.7 }}
                            className="mb-5">
                            <Text>Světem digitálních aktiv žijeme od roku 2016. Spojujeme profesní zkušenosti z oblasti investic, tradingu, práva, daňového poradenství a poradenství. Jsme empatickým partnerem velkým i menším investorům, který vám pomůže se v problematice snadno zorientovat.</Text>
                        </motion.div>
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ ease: "easeOut", delay: 1.2 }}
                            className="inline-flex">
                            <Link to="kontakt" smooth>
                                <Button onClick={changePage}>Domluvit si schůzku</Button>
                            </Link>

                        </motion.div>
                    </div>
                </div>
            }
        </section>
    )
}