import React, { useEffect, useState } from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import BlogpostsOverview from 'fragments/Admin/BlogpostsOverview';
import axios from 'axios';
import Title from 'components/Typography/Title';
import { InertiaLink } from '@inertiajs/inertia-react';
import Eye from 'components/Icons/Eye';
import Delete from 'components/Icons/Delete';
import Interest from 'components/Icons/Interest';
import { format } from 'date-fns';
import SimpleDate, { getDate } from 'components/DateFormatter';
import Button from 'components/Forms/Components/Button';
import ValidateOk from 'components/Icons/ValidateOk';
import ValidateError from 'components/Icons/ValidateError';
import AnimatedBalance from 'fragments/Profile/AnimatedBalance';
import AnimatedBalanceKc from 'fragments/Profile/AnimatedBalanceKc';

export default function PromoCodes() {

    const [values, setValues] = useState(null as any);
    const [value, setValue] = useState(0);


    async function deleteValue(id) {
        if (confirm("Opravdu chcete tuto hodnotu portfolia odstranit? Hodnota zmizí z historie. Minimálně jednu položku je potřeba ponechat pro funkčnosti frontendu. Akce je nevratná")) {
            axios.delete('/api/portfolio/delete', { data: { id: id } }).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    setValues(values.filter(value => value.id != id))
                }
            })
        }
    }

    var formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    useEffect(() => {
        axios.get('/api/portfolio/all').then((res) => {
            console.log(res)
            setValues(res?.data?.values)
        })
    }, []);

    useEffect(() => {
        if (values) {
            axios
                .get(`https://api.coinbase.com/v2/exchange-rates?currency=BTC`)
                .then((res) => {
                    if (!values[0].btc_price) {
                        setValue((values[0].btc_value * res.data.data.rates.CZK))
                    }
                }
                )
                .catch((err) => {
                    throw err;
                });
        }
        console.log(values)
    }, [values]);

    return (
        <AdminLayout>
            <div className="min-h-screen  px-5 lg:px-32 pt-32">
                <div className="flex justify-between items-baseline">
                    <Title level={2}>Aktuálně spravujeme</Title>
                    <div className="inline-flex">
                        <Button theme="secondary" link="/admin/spravujeme/pridat">Upravit</Button>
                    </div>

                </div>

                <div className="mt-4 overflow-x-auto w-full">

                    <div className="grid grid-cols-3 gap-x-5">
                        <div className="flex items-center h-32">
                            <AnimatedBalance currentValue={(values && value) ? values[0].btc_value : 0} reset={() => { }} />
                        </div>
                        <div className="flex items-center h-32">
                            <AnimatedBalanceKc currentValue={values ? values[0].czk_value ?? value : 0} reset={() => { }} />
                        </div>
                        <div className="flex items-center h-32">
                            Zadáno v:&nbsp;
                            {values &&
                                <State value={values[0]} />
                            }
                        </div>
                    </div>

                </div>
            </div>
        </AdminLayout>
    );
}


function State({ value }) {

    const now = new Date()

    if (value.display == "btc") {

        return (
            <div className="bg-green text-white font-semibold text-sm text-center px-4 rounded-full">BTC</div>
        )

    }
    else {
        return (
            <div className="bg-white-30 text-white font-semibold text-sm text-center px-4 rounded-full">CZK</div>
        )
    }


}

function Bool({ children }) {

    if (!children) {
        return <ValidateOk />
    }
    else return <ValidateError />
}