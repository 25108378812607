import React, { FC, SVGProps } from "react";

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const PayFailed: FC<Props> = (props) => {
    const { width = "86", height = "86", color = "#FF2E2E" } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.6824 85.0683C66.2115 85.0683 85.2678 66.0251 85.2459 42.5341C85.224 19.0432 66.1322 0 42.6031 0C19.0741 0 0.0177493 19.0432 0.0396441 42.5341C0.061539 66.0251 19.1534 85.0683 42.6824 85.0683Z" fill="#1A1A1A" />
            <path d="M42 53C47.5228 53 52 48.5228 52 43C52 37.4772 47.5228 33 42 33C36.4772 33 32 37.4772 32 43C32 48.5228 36.4772 53 42 53Z" stroke="#FF2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M42 39V43" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M42 47H42.01" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default PayFailed;
