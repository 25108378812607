import { Inertia } from "@inertiajs/inertia";
import { InertiaLink } from "@inertiajs/inertia-react";
import { AppContext } from "components/Contexts/AppContextProvider";
import Button from "components/Forms/Components/Button";
import Close from "components/Icons/Close";
import UserAccount from "components/Icons/UserAccount";
import { motion } from "framer-motion";
import { useContext } from "react";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export default function MobileNav(props) {

    const { changePage, home, closeMenu } = props
    const { user } = useContext(AppContext)

    function setMobileMenuOpen(what) {

    }

    return (
        <>
            <div className="flex w-full z-50 h-full items-center justify-center pb-20 flex-col space-y-4 text-center font-semibold text-xl">
                {home ?
                    <Link to="uvod" smooth={true} spy={true} hashSpy={true} offset={0} duration={500} >
                        <span className="cursor-pointer z-50" onClick={() => {
                            changePage ? changePage(0) : {}
                            home ? scroll.scrollTo('uvod') : Inertia.visit("/")
                            closeMenu()
                        }}>
                            Úvod
                        </span>
                    </Link>
                    :
                    <InertiaLink href="/">
                        <span className="cursor-pointer z-50">
                            Úvod
                        </span>
                    </InertiaLink>
                }

                <InertiaLink href="/technologie">
                    <span className="cursor-pointer z-50" onClick={() => closeMenu()}>
                        Technologie
                    </span>
                </InertiaLink>
                <InertiaLink href="/profil">
                    <span onClick={() => closeMenu()} className="">
                        Profil
                    </span>
                </InertiaLink>
                {/* <InertiaLink href="/blog">
                    <span onClick={() => closeMenu()}>
                        Blog
                    </span>
                </InertiaLink> */}
                {/* <InertiaLink href="/#produkty">
                        <span onClick={() => setMobileMenuOpen(false)}>
                            Produkty
                        </span>
                    </InertiaLink> */}
                {home ?
                    <Link className="cursor-pointer" to="kontakt" smooth={true} spy={true} hashSpy={true} offset={0} duration={500}>
                        <span onClick={() => {
                            changePage ? changePage(6) : {}
                            closeMenu()
                        }} className=" z-50">
                            Kontakt
                        </span>
                    </Link>
                    :
                    <InertiaLink href="/#kontakt" onClick={() => {
                        closeMenu()
                    }} className=" z-50">
                        Kontakt
                    </InertiaLink>
                }



            </div>
            {!user ?
                <motion.div
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    className="fixed bottom-0 left-0 right-0 p-5 z-50">
                    <Button link="/profil">
                        Přihlásit se
                    </Button>
                </motion.div>
                :
                <>
                    <InertiaLink href="/auth/logout" method="post" className="text-lg font-semibold flex w-full justify-center items-center">
                        <span onClick={() => closeMenu()} className="">
                            Odhlásit se
                        </span>
                    </InertiaLink>
                    <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        className="fixed bottom-0 left-0 right-0 p-5 z-50">
                        <Button link="/profil">
                            <div className="flex items-center">
                                <UserAccount color="black" />
                                <div className="ml-2">{user.firstName + " " + user.lastName}</div>
                            </div>
                        </Button>
                    </motion.div>
                </>
            }

        </>
    )
}