import { compound, secondsFromMonthly } from "components/Hooks/InterestFunctions/variableInterest"


export function getVariableActualValue(deposit?: any, interests?: any) {
    if (deposit && interests) {
        const todayOrEnded = deposit.expiration ? new Date(deposit.expiration) : new Date()
        const depositStartDate = new Date(deposit?.payment?.replace(" ", "T"))

        let portfolio = 0
        let finterest = 0
        interests.sort((a, b) => new Date(a.from.replace(" ", "T")) > new Date(b.from.replace(" ", "T")) ? 1 : -1).map((interest, i) => {
            const interestStartDate = new Date(interest.from.replace(" ", "T"))
            const nextStartDate = interests[i + 1] ? new Date(interests[i + 1]?.from.replace(" ", "T")) : todayOrEnded
            if (todayOrEnded >= interestStartDate && depositStartDate < nextStartDate) {
                const msInInterest = ((nextStartDate.getTime() - interestStartDate.getTime()) - Math.max(depositStartDate.getTime() - interestStartDate.getTime(), 0))
                // console.log("denní úrok", interest.interest/2629800000)
                const months = msInInterest / 2629800000
                const monthInerest = (1 + (interest.interest + Number(deposit.interestBonus)) / 100)
                // console.log("bonus", Number(deposit.interestBonus), interest.interest)
                if (portfolio == 0) {
                    portfolio = deposit.amountBTC * (Math.pow(monthInerest, months))
                }
                else {
                    portfolio = portfolio * (Math.pow(monthInerest, months))
                }

                if (finterest == 0) {
                    finterest = (Math.pow(monthInerest, months))
                }
                else {
                    finterest = finterest * (Math.pow(monthInerest, months))
                }
                console.log(deposit, portfolio, finterest)
                // console.log("částka v dané době", portfolio)
                // console.log("úrok celkem:", compound(interest.interest/100, msInInterest/2629800000)
            }

        })
        return { finalValue: portfolio, finalInterest: (finterest-1)*100 }
    }
    else return { finalValue: 0, finalInterest: 0 }
}