import MenuOverlay from "components/MenuOverlay";
import Modal from "components/Modal";
import { AnimatePresence, motion } from "framer-motion";
import React, { FC, SVGProps, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

type Props = {
    width?: number
    height?: number
    color?: string
    overlay?: any
    children?: any
    setOpened?: any
    opened?: boolean
} & SVGProps<SVGSVGElement>;

const Meatballs: FC<Props> = (props) => {

    const [clicked, setClicked] = useState(false);

    const { width = 48, height = 48, color = "white", children, setOpened, opened, className } = props;
    const dots = [0, 1, 2]

    useEffect(() => {
        if (setOpened) {
            setOpened(clicked)
        }
    }, [clicked]);

    useEffect(() => {
        if(opened != clicked) {
            setClicked(opened)
        }
    }, [opened]);

    return (
        <>
            <div onClick={() => setClicked(children ? !clicked : false)} style={{ width: width, height: height }} className={"flex flex-shrink-0 relative z-50 justify-center items-center gap-x-1 rounded-full border border-white-30 hover:border-white-60 cursor-pointer transition-all duration-300 ease-in-out " + className}>

                {dots.map((dot, i) => (
                    <AnimatePresence>
                        {!clicked &&
                            <Dot i={i} clicked={clicked} />
                        }
                    </AnimatePresence>
                ))
                }
                <AnimatePresence>
                    {clicked && children &&
                        <motion.div
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: 20, opacity: 0 }}
                            initial={{ y: 20, opacity: 0 }}
                            className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L19 19" stroke="white" stroke-width="2" />
                                <path d="M1 19L19 1" stroke="white" stroke-width="2" />
                            </svg>
                        </motion.div>
                    }
                </AnimatePresence>


            </div>

            <MenuOverlay opened={clicked && children} setClose={() => { setClicked(false) }}>
                <AnimatePresence>
                    {clicked && children &&
                        <div
                            className=" w-full h-full z-50">

                            <motion.div
                                animate={{ opacity: 1 }}
                                initial={{ opacity: 0 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.14 }}
                                className="bg-black w-full h-full z-20">
                            </motion.div>
                            <motion.div
                                animate={{ width: isMobile ? 2000 : 3000, height: isMobile ? 2000 : 3000, x: 1000, y: -1000, opacity: 1 }}
                                initial={{ width: 0, height: 0, x: -16, y: 16, opacity: 1 }}
                                exit={{ width: 0, height: 0, x: -16, y: 16, opacity: 0 }}
                                transition={{ duration: 0.4 }}
                                className="fixed right-5 top-5 rounded-full pointer-events-none bg-white-10 z-30"></motion.div>
                            <motion.div
                                animate={{ opacity: 1 }}
                                initial={{ opacity: 0 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.14 }}
                                onPointerUp={() => {}}
                                className="w-full h-full block pt-18 z-50">
                                {children}
                            </motion.div>



                        </div>
                    }
                </AnimatePresence>
            </MenuOverlay>

        </>
    );
};

export default Meatballs;

function Dot({ i, clicked }) {

    return (
        <motion.div
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            initial={{ y: -20, opacity: 0 }}
            transition={{ duration: (i * 0.1) + 0.1 }}
            className="w-1 h-1 rounded-full bg-white">

        </motion.div>
    )
}