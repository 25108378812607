import React from 'react';

import NewsletterForm from 'components/Forms/NewsletterForm';
import Title from 'components/Typography/Title';

export default function Newsletter() {
    return (
        <section className="px-6 md:py-10 lg:px-20 xl:px-32 2xl:px-60 py-20 border-b border-white-30">
            <div className="flex flex-col lg:w-136">
                <Title level={2}>Odebírat newsletter</Title>
                <NewsletterForm />
            </div>
        </section>
    );
}
