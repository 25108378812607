
import { createPortal } from 'react-dom'
import { useState, useEffect, useContext, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion';

export default function DropdownPortal({ children, x, y }) {

    const [visibleOnLoad, setVisibleOnLoad] = useState(false)
    const size = useWindowSize();



    if (typeof window !== 'undefined') {
        const modalView = document.getElementById("overlay-view")


        if (modalView) {
            return createPortal(

                        <motion.div 
                        exit={{opacity: 0}}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        className={" z-40 fixed top-0 bottom-0 left-0 right-0  flex justify-start items-start "}>
                            <motion.div
                            animate={{x: x + 20, y: y + 64}}
                            initial={false}
                            >
                                {children}
                            </motion.div>

                        </motion.div>



                , modalView
            )
        }
        else {
            return null as any
        }

    }
    else {
        return null as any
    }


}

// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: null as any,
        height: null as any,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}