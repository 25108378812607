import { Inertia } from "@inertiajs/inertia";
import { InertiaLink } from "@inertiajs/inertia-react";
import { AnimatePresence, motion } from "framer-motion";

import React, { FC, HTMLProps, ReactNode, useEffect, useState } from "react";
import route from "ziggy-js";

type Props = {
    disabled?: boolean;
    children: ReactNode;
    className?: string;
    link?: string;
    theme?: string;
    dot?: boolean
    noSubmit?: boolean
} & HTMLProps<HTMLButtonElement>;

const Button: FC<Props> = (props) => {
    const {
        disabled = false,
        children,
        className = "",
        link = null,
        theme = "primary",
        dot,
        noSubmit
    } = props;

    const [hovering, setHovering] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (hovering) {
                Inertia.visit(link)
            }
        }, 2000)

        if (!hovering) {
            clearTimeout(timeout)
        }

        return () => clearTimeout(timeout)
    }, [hovering]);

    let classname = " w-full px-8 whitespace-nowrap py-3 rounded-full ";

    switch (theme) {
        case "primary":
            classname += " bg-white text-black font-semibold ";
            break;

        case "green":
            classname += " bg-green-darker hover:bg-green text-white font-semibold ";
            break;

        case "link":
            classname += " bg-transparent text-white-70 hover:text-white underline font-normal ";
            break;


        case "secondary":
            classname += " bg-transparent border border-white-30 hover:border-white-50 transition duration-150 tracking-wide ";
            break;

        case "invisible":
            classname +=
                " text-white-60 hover:text-white transition-all ease-in-out duration-200";
            break;

        case "modern":
            classname +=
                " group hover:text-white flex justify-between items-center tracking-wide transition-all ease-in-out duration-200";
            break;

        case "success":
            classname +=
                " bg-green font-bold tracking-wide transition-all ease-in-out duration-200";
            break;

        case "custom":
            break;

        default:
            break;
    }

    if (link)
        return (
            <div className="w-full relative rounded-3px" onClick={() => { props?.onClick ?? {} }} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
                <InertiaLink
                    href={link}
                    className={
                        classname +
                        " flex items-center h-12 justify-center " +
                        className
                    }
                >
                    {theme == "secondary" &&
                        <>


                            {hovering &&
                                <div className="absolute top-0 left-0 right-0 px-6 flex justify-start" >
                                    <motion.div
                                        initial={{ width: 0 }}
                                        animate={{ width: "100%" }}
                                        exit={{ width: 0, transition: { delay: 1.5 } }}
                                        transition={{ duration: 0.5, delay: 0, ease: "linear" }}
                                        className="h-px bg-white"
                                    />
                                </div>
                            }

                            {hovering &&
                                <div className="absolute top-0 right-0 bottom-0" >
                                    <svg className="transform -rotate-90" width={48} height={48}>
                                        <motion.circle
                                            animate={{ strokeDashoffset: 75, strokeDasharray: 150 }}
                                            exit={{ strokeDashoffset: 138, strokeDasharray: 150, transition: { duration: 0.5, delay: 1 } }}
                                            initial={{ strokeDashoffset: 150, strokeDasharray: 150 }}
                                            transition={{ duration: 0.5, delay: 0.5, ease: "linear" }}
                                            cx={24} cy={24} r={24} fill="transparent" stroke="white" stroke-width="4"></motion.circle>
                                    </svg>
                                </div>
                            }

                            {hovering &&
                                <div className="absolute bottom-0 left-0 right-0 px-6 flex justify-end" >
                                    <motion.div
                                        initial={{ width: 0 }}
                                        animate={{ width: "100%" }}
                                        exit={{ width: 0, transition: { delay: 0.5 } }}
                                        transition={{ duration: 0.5, delay: 1, ease: "linear" }}
                                        className="h-px bg-white"
                                    />
                                </div>
                            }

                            {hovering &&
                                <div className="absolute top-0 left-0 bottom-0" >
                                    <svg className="transform rotate-90" width={48} height={48}>
                                        <motion.circle
                                            animate={{ strokeDashoffset: 75, strokeDasharray: 150 }}
                                            exit={{ strokeDashoffset: 138, strokeDasharray: 150, transition: { duration: 0.5 } }}
                                            initial={{ strokeDashoffset: 150, strokeDasharray: 150 }}
                                            transition={{ duration: 0.5, delay: 1.5, ease: "linear" }}
                                            cx={24} cy={24} r={24} fill="transparent" stroke="white" stroke-width="4"></motion.circle>
                                    </svg>
                                </div>
                            }

                        </>
                    }
                    {theme == "modern" ? (
                        <>

                            <span className="flex-none">{children}</span>
                            <div className="ml-4 relative flex-none p-5 grid place-items-center rounded-full border border-white-30 /group-hover:border-white-60 transition-all duration-200 ease-in-out">
                                <AnimatePresence>
                                    {hovering &&
                                        <div className="absolute top-0 left-0 right-0 bottom-0 transform -rotate-90" >
                                            <svg>
                                                <motion.circle
                                                    animate={{ strokeDashoffset: 0, strokeDasharray: 138 }}
                                                    exit={{ strokeDashoffset: 138, strokeDasharray: 138, transition: { duration: 0.5 } }}
                                                    initial={{ strokeDashoffset: 138, strokeDasharray: 138 }}
                                                    transition={{ duration: 2, easings: "easeInOut" }}
                                                    cx={22} cy={22} r={22} fill="transparent" stroke="white" stroke-width="4"></motion.circle>
                                            </svg>
                                        </div>
                                    }
                                </AnimatePresence>

                                <motion.div
                                    animate={{ opacity: [1, 0.2] }}
                                    transition={{ duration: 1, repeat: Infinity }}
                                    className="w-1 h-1 bg-white rounded-full" />
                            </div>
                        </>
                    ) : (theme == "secondary") ? (
                        <div className="flex items-center relative" >
                            {dot &&
                                <motion.div
                                    animate={{ opacity: [1, 0.2] }}
                                    transition={{ duration: 1, repeat: Infinity }}
                                    className="w-1 mr-2 h-1 bg-white rounded-full" />
                            }

                            {children}
                        </div>
                    ) : (
                        <div className="flex items-center">
                            {dot &&
                                <motion.div
                                    animate={{ opacity: [1, 0.2] }}
                                    transition={{ duration: 1, repeat: Infinity }}
                                    className="w-1 mr-2 h-1 bg-white rounded-full" />
                            }
                            {children}
                        </div>

                    )}
                </InertiaLink>
            </div>
        );
    else
        return (
            <button
                {...props}
                type={noSubmit ? "button" :"submit"}
                className={`${classname} flex items-center justify-center focus:outline-none ${className}`}
                disabled={disabled}
            >
                {theme == "modern" ? (
                    <>
                        <span className="flex-none">{children}</span>
                        <div className="ml-4 flex-none p-6 grid place-items-center rounded-full border border-white-30 group-hover:border-white-60 transition-all duration-200 ease-in-out">
                            <div className="w-1 h-1 bg-white rounded-full" />
                        </div>
                    </>
                ) : (
                    children
                )}
            </button>
        );
};

export default Button;
