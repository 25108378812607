import React, { useEffect, useState } from 'react';

import FAQDropdown from 'components/Dropdowns/FAQDropdown';
import Text from 'components/Typography/Text';
import axios from 'axios';
import { compound } from 'components/Hooks/InterestFunctions/variableInterest';
import { interestsVariableArray } from 'components/StaticData';

export default function FAQ(props) {

    const { variant = 0 } = props

    const [maxInterest, setMaxInterest] = useState(interestsVariableArray[1]);

    const faqs = [
        // variant 0
        [
            { question: "Zatím nevlastním kryptoměny, mohu termínovanou úložku využít?", answer: "Termínovanou úložku můžete využít i v případě, že zatím žádný bitcoin, ani jiné kryptoměny, nevlastníte. Kontaktujte nás, zajistíme Vám nákup kryptoměn a následně si můžete zvolit, po jakou dobu u nás budou uloženy." },
            { question: `Jak získat na kryptoměny úrok až ${compound(maxInterest, 12).toFixed(2)} % ročně?`, answer: `Úložky umožňují jednotlivcům i společnostem získávat složené úročení na kryptoměny až do výše ${compound(maxInterest, 12).toFixed(2)} % ročně. Vytvořte si účet, trvá to necelé 2 minuty, pokud si nebudete vědět rady, kontaktujte nás a my vás celým procesem provedeme.` },
            { question: "Je to bezpečné?", answer: "Děláme vše pro to, abychom zajistili maximální úroveň bezpečnosti všech aktiv, která spravujeme. Všechna aktiva jsou uložena dle unikátního klíče na sérii sofwarových a hardwarových peněženek, pro obchodování využíváme najednou několik různých platforem a burz. " }
        ],
        // variant 1
        [
            { question: "Co to je blockchain a jak funguje?", answer: "Blockchain je speciální druh distribuované decentralizované databáze, lze si jej představit jako účetní knihu, která je globálně zálohovaná v paměti tisíců různých počítačů. Uchovává záznamy o všech transakcích, které uživatelé sítě vykonali. Díky robustnosti je je prakticky nemožné takovou databázi hacknout a i v případě výpadku je možné všechna data opětovně obnovit ze zálohy, stačí aby zůstal zachován záznam alespoň na jednom z nezávislých uzlů. " },
            { question: "Mají kryptoměny skutečná využití?", answer: "Ano, nejde pouze o spekulativní aktivum. Virtuální aktiva dokáží zajistit digitálním předmětům unikátnost a řeší problém autorských práv u digitálních předmětů (fenomén NFT), chrání digitální identitu, prostřednictvím tzv. smart contractů dokáží na základě algoritmu vyhodnotit, zda smluvní strany dodržely své dohody (obdoba automatického notáře nebo advokátní zástavy) pak je to celý segment DeFi (decentralizované finance). Vznikají protokoly, které umožňují např. investice do tokenizovaných akcií na burzách po celém světě, automatizované půjčky a poskytování likvidity (obdoba Zonky). Segment je srovnatelný s internetem v 90. letech — velká většina využití nám zatím není známa a teprve budou objevena. Dynamický svět kryptoměn každý den přináší nové příležitosti i rizika. Rádi vám pomůžeme se v tomto světě zorientovat." },
            { question: "Jak funguje těžba kryptoměn? Je to udržitelné?", answer: 'Těžba kryptoměn znamená proces získávání odměny v dané kryptoměně výměnou za úspěšné řešení komplexních kryptografických rovnic za pomocí (supervýkonných) počítačů. Zjednodušeně řečeno — transakce, které uživatelé provádějí v sítí (převody kryptoměn mezi adresami) se sdružují do bloků ("block"), tyto bloky se následně spojují do řetězců ("chain"), odsud tedy vychází název "blockchain", což je veřejná decentralizovaná účetní kniha. Aby se v ekosystému vytvořily nové mince ("coins"), je třeba řešit komplikované matematické úkony, které umožňují potvrzování provedených transakcí a následně jejich nahrávání na blockchain. Jako odměnu za tuto práci, dostávají těžaři odměnu v podobě mincí, které se nově do oběhu uvolňují. Této metodě se tedy přezdívá těžba "mining". Množství spotřebované energie je přímo úměrné s úrovní zabezpečení sítě, nejedná se tedy o vadu, naopak — spotřeba umožňuje vytvoření jednoho z nejbezpečnějších a nejspolehlivějších transakčních systémů na světě, nesrovnatelně bezpečnější a přesnější než jakákoliv banka. Těžit kryptoměny lze snadno a kdekoliv, proto těžaři vyhledávají místa s nejlevnějšími cenami energie, což jsou např. místa, kde se energie ztrácí a pro těžbu je využívána zbytková energie, která by jinak byla ztracena, nebo vynaložena nesmyslně. Obecným trendem je přechod k udržitelným zdrojům. V roce 2021 byla založena organizace Bitcoin Mining Council, z jejíchž vyplývá, že ≅ 67 % energie použité pro těžbu Bitcoinu pochází z obnovitelných zdrojů, dlouhodobým cílem komunity je podíl obnovitelných zdrojů zvyšovat.' },
            { question: "Podporuji nákupem kryptoměn šedou ekonomiku?", answer: "Bitcoin ve svém blockchainu uchovává veškeré záznamy o transakcích. V minulosti byl, stejně jako hotovost, využíván i pro nezákonné účely, to se však dnes děje minimálně. V blockchainu lze snadno všechny transakce dohledat, využití pro nelegální činnost je tedy z pohledu dnešního vývoje velmi riskantní podobně jako platba kreditní kartou. Bitcoin není anonymní, ale pseudonymní - to znamená, že ačkoliv v síti nevystupujete pod svým jménem, lze vás v transakcích dohledat podle čísla peněženek. " },
            { question: "Co způsobuje volatilitu kryptoměn?", answer: "Narozdíl od jiných aktiv lze kryptoměny prodat v takřka jakémkoliv objemu v řádu vteřin, existuje-li na druhé straně dostatek likvidity. Narozdíl od burz nebo bank neexistují žádné přestávky a omezení, ani centrální autority. Likvidita tohoto nového druhu aktiv i vzhledem k tomu, že jejich tržní kapitalizace je stále poměrně malá, způsobuje to, že v kryptoměnách jsou běžné a standardní obousměrné cenové výkyvy v rozsahu desítek procent denně. Tím, jak se bude rozšiřovat nadále růst počet uživatelů a tedy i tržní kapitalizace kryptoměn, vše se postupně ustálí. I přes vysokou volatilitu však v průměru např. Bitcoin dosahuje zhodnocení o více než 200 % ročně. " },
            { question: "Jak funguje zdanění výnosů z kryptoměn v České republice?", answer: "Výnos z kryptoměn se v České republice zdaňuje obdobně jako jakýkoliv jiný příjem. K samotnému zdanění dochází při realizaci zisku — tedy prodeji kryptoměn za české koruny. Disponujeme vlastními daňovými poradci a účetními, v případě zájmu nás neváhejte kontaktovat a rádi vám v tomto směru poradíme." }
        ]
    ]

    const [opened, setOpened] = useState(false as any);

    function setThisActive(what: number) {
        if (opened === what) {
            setOpened(false)
        }
        else {
            setOpened(Number(what))
        }
    }

    return (
        <div className="lg:pl-5 py-5 lg:py-20 flex w-full flex-col gap-y-0">
            <Text >Nejčastější otázky</Text>
            {faqs[variant].map((faq, i) =>
                <FAQDropdown
                    opened={opened === i}
                    setActive={() => setThisActive(i)}
                    question={faq.question}
                    answer={faq.answer}
                />
            )}


        </div>
    );
}
