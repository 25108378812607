import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Interest: FC<Props> = (props) => {
    const { width = '32', height = '32', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="11.3346"
                cy="11.3337"
                r="8"
                stroke={color}
                strokeWidth="1.33333"
            />
            <circle
                cx="24.668"
                cy="22"
                r="5.33333"
                stroke={color}
                strokeWidth="1.33333"
            />
            <circle
                cx="11.3333"
                cy="26.0003"
                r="2.66667"
                stroke={color}
                strokeWidth="1.33333"
            />
            <path
                d="M17.3359 6.66699L5.33594 16.0003"
                stroke={color}
                strokeWidth="1.33333"
            />
            <path
                d="M28 18.667L21.3333 25.3337"
                stroke={color}
                strokeWidth="1.33333"
            />
            <path
                d="M13.3359 24L9.33594 28"
                stroke={color}
                strokeWidth="1.33333"
            />
        </svg>
    );
};

export default Interest;
