import React from "react";

import PersonalDataForm from "components/Forms/PersonalDataForm";
import Title from "components/Typography/Title";

export default function PersonalData() {
    return (
        <section className=" px-4 lg:px-0 pt-14">
            <PersonalDataForm />
        </section>
    );
}
