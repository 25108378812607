import { animate, motion, useMotionValue } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import Button from "components/Forms/Components/Button";

export default function TechnologicRevolution(props) {

    const { show } = props
    const [ref, inView] = useInView();
    const [shift, setShift] = useState(0);
    const [viewed, setViewed] = useState(false);

    function getCircleCoordinates(angle) {
        var radius = 270;
        var x = radius * Math.sin((Math.PI * 2 * angle) / 360);
        var y = radius * Math.cos((Math.PI * 2 * angle) / 360);

        return { x: x, y: y };
    }

    useEffect(() => {
        if (show && viewed == false) {
            // setViewed(true);
            const controls = animate(180, 0, {
                duration: 2.5,
                onUpdate(value) {
                    setShift(value);
                },
            });
            return () => controls.stop();
        }
        else if (viewed) {
            setShift(0)
        }
    }, [show]);

    return (
        <div className="">
            <section
                id="technologie"
                className="min-h-screen relative  items-center flex justify-center overflow-hidden"
            >
                <div className="absolute top-10 lg:top-48 h-screen" ref={ref}></div>
                <div className="">
                    {(show) && (
                        <div className="block relative text-center">
                            <motion.div
                                animate={{ opacity: 1, y: 0 }}
                                initial={{ opacity: 0, y: 20 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ ease: "easeOut" }}
                                className=" text-white-60"
                            >
                                Technologická revoluce
                            </motion.div>
                            <div className="text-3xl font-semibold mt-2 flex flex-col ">
                                <motion.div
                                    animate={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: 20 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{
                                        ease: "easeOut",
                                        delay: 0.4,
                                        duration: 0.5,
                                    }}
                                    className=""
                                >
                                    Blockchain
                                </motion.div>
                                <motion.div
                                    animate={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: 20 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{
                                        ease: "easeOut",
                                        delay: 0.8,
                                        duration: 0.5,
                                    }}
                                    className=""
                                >
                                    tvoří infrastrukturu
                                </motion.div>
                                <motion.div
                                    animate={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: 20 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{
                                        ease: "easeOut",
                                        delay: 1.2,
                                        duration: 0.5,
                                    }}
                                    className="mb-5"
                                >
                                    pro inovace
                                </motion.div>
                            </div>
                            <motion.div
                                animate={{ opacity: 1, y: 0 }}
                                initial={{ opacity: 0, y: 20 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{
                                    ease: "easeOut",
                                    delay: 1.4,
                                    duration: 0.5,
                                }}
                                className="absolute left-0 right-0 inline-flex justify-center"
                            >
                                <Button link="/technologie" theme="secondary">Více o technologii</Button>
                            </motion.div>
                            <motion.div
                                style={{
                                    y: getCircleCoordinates(180 + shift).y,
                                    x: getCircleCoordinates(180 + shift).x,
                                }}
                                animate={{ scale: 1 }}
                                initial={{ scale: 0 }}
                                transition={{ delay: 1.5, duration: 0.5 }}
                                className="absolute top-0 bottom-0 left-0 right-0  flex justify-center w-full flex-col items-center gap-5"
                            >
                                <div className="relative  flex justify-center w-full flex-row items-center gap-5">
                                    <motion.div
                                        animate={{ opacity: 1, y: 0 }}
                                        initial={{ opacity: 0, y: 20 }}
                                        transition={{
                                            delay: 2.5,
                                            duration: 0.5,
                                            ease: "easeOut",
                                        }}
                                        className=" text-transparent lg:block  absolute hidden -top-12"
                                    >
                                        Mobilita
                                    </motion.div>
                                    <img
                                        src="/assets/img/mobilita.png"
                                        width={120}
                                        height={120}
                                    />
                                </div>
                            </motion.div>
                            <motion.div
                                style={{
                                    y: getCircleCoordinates(120 + shift).y,
                                    x: getCircleCoordinates(120 + shift).x,
                                }}
                                animate={{ scale: 1 }}
                                initial={{ scale: 0 }}
                                transition={{ delay: 1.2, duration: 0.5 }}
                                className="absolute top-0 bottom-0 left-0 right-0 flex justify-center w-full flex-row items-center gap-5"
                            >
                                <div className="relative  flex justify-center w-full flex-row items-center gap-5">
                                    <motion.div
                                        animate={{ opacity: 1, y: 0 }}
                                        initial={{ opacity: 0, y: 20 }}
                                        transition={{
                                            delay: 2.5,
                                            duration: 0.5,
                                            ease: "easeOut",
                                        }}
                                        className="  text-transparent lg:block absolute hidden -right-8"
                                    >
                                        Velká data
                                    </motion.div>
                                    <img
                                        src="/assets/img/velka_data.png"
                                        width={120}
                                        height={120}
                                    />
                                </div>
                            </motion.div>
                            <motion.div
                                style={{
                                    y: getCircleCoordinates(70 + shift).y,
                                    x: getCircleCoordinates(70 + shift).x,
                                }}
                                animate={{ scale: 1 }}
                                initial={{ scale: 0 }}
                                transition={{ delay: 0.9, duration: 0.5 }}
                                className="absolute top-0 bottom-0 left-0 right-0  flex justify-center w-full flex-row items-center gap-5"
                            >
                                <div className="relative  flex justify-center w-full flex-row items-center gap-5">
                                    <motion.div
                                        animate={{ opacity: 1, y: 0 }}
                                        initial={{ opacity: 0, y: 20 }}
                                        transition={{
                                            delay: 2.5,
                                            duration: 0.5,
                                            ease: "easeOut",
                                        }}
                                        className="  text-transparent lg:block absolute hidden -right-16"
                                    >
                                        Strojové učení
                                    </motion.div>
                                    <img
                                        src="/assets/img/strojove_uceni.png"
                                        width={120}
                                        height={120}
                                    />
                                </div>
                            </motion.div>
                            <motion.div
                                style={{
                                    y: getCircleCoordinates(20 + shift).y,
                                    x: getCircleCoordinates(20 + shift).x,
                                }}
                                animate={{ scale: 1 }}
                                initial={{ scale: 0 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                className="absolute top-0 bottom-0 left-0 right-0 "
                            >
                                <div className="relative  flex justify-center w-full flex-row items-center gap-5">
                                    <motion.div
                                        animate={{ opacity: 1, y: 0 }}
                                        initial={{ opacity: 0, y: 20 }}
                                        transition={{
                                            delay: 2.5,
                                            duration: 0.5,
                                            ease: "easeOut",
                                        }}
                                        className="  text-transparent lg:block absolute hidden -right-8"
                                    >
                                        Robotika
                                    </motion.div>
                                    <img
                                        src="/assets/img/robotika.png"
                                        width={120}
                                        height={120}
                                    />
                                </div>
                            </motion.div>
                            <motion.div
                                style={{
                                    y: getCircleCoordinates(330 + shift).y,
                                    x: getCircleCoordinates(330 + shift).x,
                                }}
                                animate={{ scale: 1 }}
                                initial={{ scale: 0 }}
                                transition={{ delay: 0.3, duration: 0.5 }}
                                className="absolute top-0 bottom-0 left-0 right-0  flex justify-center w-full flex-row items-center gap-5"
                            >
                                <div className="relative  flex justify-center w-full flex-row items-center gap-5">
                                    <motion.div
                                        animate={{ opacity: 1, y: 0 }}
                                        initial={{ opacity: 0, y: 20 }}
                                        transition={{
                                            delay: 2.5,
                                            duration: 0.5,
                                            ease: "easeOut",
                                        }}
                                        className="  text-transparent lg:block absolute hidden -left-3"
                                    >
                                        Cloud
                                    </motion.div>
                                    <img
                                        src="/assets/img/cloud.png"
                                        width={120}
                                        height={120}
                                    />
                                </div>
                            </motion.div>
                            <motion.div
                                style={{
                                    y: getCircleCoordinates(230 + shift).y,
                                    x: getCircleCoordinates(230 + shift).x,
                                }}
                                animate={{ scale: 1 }}
                                initial={{ scale: 0 }}
                                transition={{ delay: 1.8, duration: 0.5 }}
                                className="absolute top-0 bottom-0 left-0 right-0  flex justify-center w-full flex-row items-center gap-5"
                            >
                                <div className="relative  flex justify-center w-full flex-row items-center gap-5">
                                    <motion.div
                                        animate={{ opacity: 1, y: 0 }}
                                        initial={{ opacity: 0, y: 20 }}
                                        transition={{
                                            delay: 2.5,
                                            duration: 0.5,
                                            ease: "easeOut",
                                        }}
                                        className="  text-transparent lg:block absolute hidden -left-16"
                                    >
                                        Metaverse
                                    </motion.div>
                                    <img
                                        src="/assets/img/metaverse.png"
                                        width={120}
                                        height={120}
                                    />
                                </div>
                            </motion.div>
                            <motion.div
                                style={{
                                    y: getCircleCoordinates(280 + shift).y,
                                    x: getCircleCoordinates(280 + shift).x,
                                }}
                                animate={{ scale: 1 }}
                                initial={{ scale: 0 }}
                                transition={{ delay: 2, duration: 0.5 }}
                                className="absolute top-0 bottom-0 left-0 right-0  flex justify-center w-full flex-row items-center gap-5"
                            >
                                <div className="relative  flex justify-center w-full flex-row items-center gap-5">
                                    <motion.div
                                        animate={{ opacity: 1, y: 0 }}
                                        initial={{ opacity: 0, y: 20 }}
                                        transition={{
                                            delay: 2.5,
                                            duration: 0.5,
                                            ease: "easeOut",
                                        }}
                                        className="  text-transparent lg:block absolute hidden -left-16"
                                    >
                                        Internet věcí
                                    </motion.div>
                                    <img
                                        src="/assets/img/internet_veci.png"
                                        width={120}
                                        height={120}
                                    />
                                </div>
                            </motion.div>
                        </div>
                    )}
                </div>
            </section>
        </div>

    );
}
