import React, { useEffect, useState } from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import BlogpostsOverview from 'fragments/Admin/BlogpostsOverview';
import axios from 'axios';
import Title from 'components/Typography/Title';
import { InertiaLink } from '@inertiajs/inertia-react';
import Eye from 'components/Icons/Eye';
import Delete from 'components/Icons/Delete';
import Interest from 'components/Icons/Interest';
import { format } from 'date-fns';
import SimpleDate, { getDate } from 'components/DateFormatter';
import Button from 'components/Forms/Components/Button';
import ValidateOk from 'components/Icons/ValidateOk';
import ValidateError from 'components/Icons/ValidateError';

export default function PromoCodes() {

    const [codes, setCodes] = useState(null as any);

    async function deleteCode(id) {
        if (confirm("Opravdu chcete tento promo kód odstranit? Akce je nevratná")) {
            axios.delete('/api/codes/delete', { data: { id: id } }).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    setCodes(codes.filter(code => code.id != id))
                }
            })
        }
    }

    async function user(uuid) {
        axios.get('/api/users/' + uuid).then(res => {
            console.log(res)
            alert(`
Jméno a příjmení: ${res.data.user.firstName} ${res.data.user.lastName} 
Email: ${res.data.user.email} 
Telefon: ${res.data.user.tel} 
Adresa: ${res.data.user.street}, ${res.data.user.city} ${res.data.user.postalCode} 
Firma: ${res.data.user.companyInvoice ? res.data.user.companyName + ", " + res.data.user.ICO : "Ne"}`)
        })
    }

    useEffect(() => {
        axios.get('/api/codes/all').then((res) => {
            console.log(res)
            setCodes(res?.data?.codes)
        })
    }, []);

    return (
        <AdminLayout>
            <div className="min-h-screen  px-5 lg:px-32 pt-32">
                <div className="flex justify-between items-baseline">
                    <Title level={2}>Promo kódy</Title>
                    <div className="inline-flex">
                        <Button theme="secondary" link="/admin/promo-kody/novy">+ Přidat</Button>
                    </div>

                </div>

                <div className="mt-4 overflow-x-auto w-full">
                    {codes ?
                        <table className="table-auto data-table-design">
                            <thead>
                                <tr>
                                    <th className='w-5'>ID</th>
                                    <th>Kód</th>
                                    <th>Typ</th>
                                    <th>Stav</th>
                                    <th>Úrokové sazby</th>
                                    <th>Lze použít více než jednou</th>
                                    <th>Platný do</th>
                                    <th>Přiřazený ambassador UUID</th>
                                    <th>Velikost úroku pro ambassadora</th>
                                </tr>
                            </thead>

                            <tbody>
                                {codes?.map((code, i) => (
                                    <tr>
                                        <td>{code?.id}</td>
                                        <td className='uppercase font-semibold text-lg'> {code?.code}</td>
                                        <td>{code?.type}</td>
                                        <td><State code={code} /></td>
                                        <td> {code?.percentages}</td>
                                        <td> <Bool>{code?.use_once}</Bool> </td>
                                        <td> {code?.valid_to ? <SimpleDate>{code?.valid_to}</SimpleDate> : "--"} </td>
                                        <td> <span>{code.ambassador_UUID} </span>{code.ambassador_UUID && <span onClick={() => user(code?.user_UUID)} className='hover:text-white-70'>
                                            {/* <Eye /> */}
                                        </span>}</td>
                                        <td> {code?.ambassador_coefficient}</td>
                                        <td>
                                            <span className="text-red-400 font-semibold cursor-pointer" onClick={() => deleteCode(code?.id)}>Smazat</span>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>


                        </table>
                        :
                        <>
                            <div className="w-full h-10 bg-white-10"></div>
                            <div className="h-4"></div>
                            <div className="w-full h-40 bg-white-10"></div>
                        </>
                    }
                </div>
            </div>
        </AdminLayout>
    );
}


function State({ code }) {

    const now = new Date()

    if (getDate(code.valid_to) > now || !code.valid_to) {

        return (
            <div className="bg-green text-white font-semibold text-sm text-center px-4 rounded-full">Platný</div>
        )

    }
    else {
        return (
            <div className="bg-white-30 text-white font-semibold text-sm text-center px-4 rounded-full">Neplatný</div>
        )
    }


}

function Bool({ children }) {

    if (!children) {
        return <ValidateOk />
    }
    else return <ValidateError />
}