import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const UserAccount: FC<Props> = (props) => {
    const { width = '20', height = '22', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 21.0011L1.79 18.1211C4.4 8.62109 15.6 8.62109 18.21 18.1211L19 21.0011"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 10.9805C12.7614 10.9805 15 8.74189 15 5.98047C15 3.21904 12.7614 0.980469 10 0.980469C7.23858 0.980469 5 3.21904 5 5.98047C5 8.74189 7.23858 10.9805 10 10.9805Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="bevel"
            />
        </svg>
    );
};

export default UserAccount;
