import React, { FC, useState } from 'react';

import ArrowUp from 'Icons/ArrowUp';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { AnimatePresence, motion } from 'framer-motion';

type Props = {
    answer: String;
    className?: string;
    question: String;
    opened?: boolean;
    setActive?: any;
};

const FAQDropdown: FC<Props> = (props) => {
    const { answer, className = '', question, opened, setActive } = props;

    return (
        <div className={`${className} transition-all duration-300`}>
            <div
                onClick={() => setActive()}
                className="flex items-start my-4 justify-between cursor-pointer"
            >
                <Title level={3} className="w-3/4">
                    {question}
                </Title>
                <span
                    className={`transform ${opened ? '' : 'rotate-180'
                        } transition-all duration-300 ease-in-out`}
                >
                    <ArrowUp />
                </span>
            </div>
            <AnimatePresence>
                {opened &&
                    <motion.div
                        animate={{ opacity: 1, y: 0, height: "auto" }}
                        exit={{ opacity: 0, y: -20, height: 0 }}
                        initial={{ opacity: 0, y: -20, height: 0 }}
                        transition={{ease: "easeInOut"}}
                      >
                        <Text>{answer}</Text>
                    </motion.div>
                }

            </AnimatePresence>

        </div>
    );
};

export default FAQDropdown;
