import React from 'react';
import { useForm } from '@inertiajs/inertia-react';

import Title from 'components/Typography/Title';
import Input from 'components/Forms/Components/Input';
import Button from 'components/Forms/Components/Button';

export default function CreateBlogPost() {
    const form = useForm<{
        name: string;
        markdown: File | null;
        images: FileList | null;
    }>({
        name: '',
        markdown: null,
        images: null,
    });

    const { data, setData, errors, post } = form;

    const handleSubmit = (e) => {
        e.preventDefault();

        post('/api/create_blogpost');
    };

    return (
        <section className="min-h-screen px-5 lg:px-32  py-32">
            <Title level={2}>Vytvořit blogpost</Title>
            <p className="mt-4">
                Pro obeznámení se s markdownem je k dispozici{' '}
                <a
                    href="https://www.markdownguide.org/basic-syntax/"
                    className="underline"
                    target="blank"
                >
                    tato pěkná dokumentace
                </a>
                . Extended verze je na již uvedeném linku v menu, nebo také{' '}
                <a
                    href="https://www.markdownguide.org/extended-syntax/"
                    className="underline"
                    target="blank"
                >
                    zde.
                </a>
            </p>
            <p className="mt-4">
                Vytvoření příspěvku na blog můžeš{' '}
                <a
                    href="https://pandao.github.io/editor.md/en.html"
                    className="underline"
                    target="blank"
                >
                    zde na <span className="italic">pandao.github.io</span>
                </a>{' '}
                nebo{' '}
                <a
                    href="https://dillinger.io/"
                    className="underline"
                    target="blank"
                >
                    zde na <span className="italic">dillinger.io</span>
                </a>
                , kde si budeš moct vytvořit markdown soubor, který potom
                nahraješ sem. Vytvoř, ulož s příponou{' '}
                <span className="italic">.md</span>, nahraj a je hotovo.
            </p>
            <p className="mt-4">
                Aby blogpost fungoval a server se nezhroutil,{' '}
                <span className="font-semibold tracking-wide">
                    je zapotřebí přidat metadata
                </span>{' '}
                do markdownu podle následujícího vzoru. Stačí kurzorem vybrat,
                zkopírovat a vložit do editoru.
            </p>
            <div className="w-max mx-auto my-4 p-4 bg-white-10">
                <code className="flex flex-col">
                    <p>---</p>
                    <span>slug: blogpost-test</span>
                    <span>title: Blogpost test</span>
                    <span>
                        subtitle: Strucny popis, o cem prispevek bude. Zkratka a
                        jednoduse subtitle.
                    </span>
                    <span>
                        coverImage:
                        https://spektrum.fund/storage/blogposts/blogpost-test/blogpost-test-1.jpg
                    </span>
                    <span>date: 1.1.2000</span>
                    <p>autor: stojdl@paradigma.so</p>
                    <p>---</p>
                </code>
            </div>
            <p>
                Také kvůli přehlednosti prosím, aby název markdownu, který
                vyplníš níže,{' '}
                <span className="font-semibold tracking-wide">
                    se shodoval se slugem
                </span>{' '}
                uvedeném v hlavičce (metadatech) souboru. Název souboru u Tebe v
                počítači může být jakýkoliv, protože se stejně potom přejmenuje
                na již zmíněný a Tebou vyplněný název markdownu.
            </p>
            <form onSubmit={handleSubmit} className="mt-4 flex flex-col ">
                <Input
                    type="text"
                    name="name"
                    label="Název markdownu"
                    values={data}
                    setValues={setData}
                    error={errors.name}
                    className="my-4"
                />
                <span className="mb-2 text-xs font-semibold">Markdown</span>
                <input
                    type="file"
                    name="markdown"
                    accept=".md"
                    onChange={(e) =>
                        setData({ ...data, markdown: e.target.files[0] })
                    }
                />
                <p className="mt-4">
                    Co obrázků se týče, tak můžeš jich vybrat více. Opět na
                    názvu nezáleží, obrázek se zpracuje a přejmenuje se na název
                    markdownu, přidá se číslovka za každý přidaný obrázek a
                    uloží se s příponou <span className="italic">.jpg</span>.
                    Takže v případě názvu markdownu{' '}
                    <span className="bg-white-10 p-0.5">
                        <code>vysoka-uroven-zabezpeceni</code>
                    </span>{' '}
                    se první přidaný obrázek bude jmenovat takto:{' '}
                    <span className="bg-white-10 p-0.5">
                        <code>vysoka-uroven-zabezpeceni-1.jpg</code>
                    </span>
                    , druhý{' '}
                    <span className="bg-white-10 p-0.5">
                        <code>vysoka-uroven-zabezpeceni-2.jpg</code>
                    </span>{' '}
                    atd... A to právě znamená, že tyto nahrané obrázky budeš
                    moct použít na libovolných místech v blogpostu přesně podle
                    tohoto vzoru:
                </p>
                <p className="w-max mx-auto mt-2 bg-white-10 p-4">
                    <code>
                        ![](https://spektrum.fund/storage/blogposts/vysoka-uroven-zabezpeceni/vysoka-uroven-zabezpeceni-1.jpg)
                    </code>
                </p>
                <span className="mt-4 mb-2 text-xs font-semibold">Obrázky</span>
                <input
                    type="file"
                    name="images[]"
                    accept="image/*"
                    onChange={(e) =>
                        setData({
                            ...data,
                            images: e.target.files,
                        })
                    }
                    multiple
                />
                <div className="mt-4 w-52">
                    <Button type="submit">Vytvořit blogpost</Button>
                </div>
            </form>
        </section>
    );
}
