import React, { useContext } from "react";
import { useForm } from "@inertiajs/inertia-react";

import Button from "./Components/Button";
import CheckBox from "./Components/CheckBox";
import CountrySelect from "./Components/CountrySelect";
import DatePicker from "./Components/DatePicker";
import Divider from "../Divider";
import Input from "./Components/Input";
import InputPhone from "./Components/InputPhone";
import PasswordInput from "./Components/PasswordInput";
import { RegisterContext } from "components/Contexts/RegisterContextProvider";
import Text from "../Typography/Text";
import Title from "components/Typography/Title";
import axios from "axios";
import { Inertia } from "@inertiajs/inertia";

export default function PersonalDataForm() {
    const { code, checkCode } = useContext(RegisterContext);

    const form = useForm({
        code: code ?? checkCode,
        firstName: "",
        lastName: "",
        birthDate: "",
        tel: "+420",
        password: "",
        password_confirmation: "",
        street: "",
        city: "",
        postalCode: "",
        country: "",
        companyInvoice: false,
        companyName: "",
        ICO: "",
        DIC: ""
    });
    const { data, setData, errors, clearErrors, post } = form;

    const handleSubmit = async (e) => {
        e.preventDefault();

        clearErrors();

        let request = data
        request.code = checkCode

        axios.post("/auth/register", request).then(res => {
            if (res.status == 201) {
                console.log(res)
                Inertia.visit("/profil")
            }
            console.log(res)
        });
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="mt-0 lg:mt-12 lg:w-176 lg:mx-auto pb-4 lg:pb-20"
        >
            <Title level={2} className="mb-6">
                Vytvořte si heslo
            </Title>
            <div className="space-y-2">
                <div className="space-y-2 lg:space-y-0 lg:space-x-8 lg:flex">
                    <PasswordInput
                        name="password"
                        label="Heslo"
                        values={data}
                        setValues={setData}
                        error={errors.password}
                        required
                    />
                    <PasswordInput
                        name="password_confirmation"
                        label="Potvrzení hesla"
                        className={" transition duration-200 " + (data.password.length >= 8 ? "" : "opacity-0")}
                        values={data}
                        setValues={setData}
                        error={errors.password_confirmation}
                        required
                    />
                </div>
            </div>
            <Title level={2} className="mb-6 mt-10">
                Osobní údaje
            </Title>
            <div className="space-y-2">
                <div className="space-y-2 lg:space-y-0 lg:space-x-8 lg:flex">
                    <Input
                        name="firstName"
                        label="Jméno"
                        values={data}
                        setValues={setData}
                        error={errors.firstName}
                        required
                    />
                    <Input
                        name="lastName"
                        label="Příjmení"
                        values={data}
                        setValues={setData}
                        error={errors.lastName}
                        required
                    />
                </div>
                <div className="space-y-2 lg:space-y-0 lg:space-x-8 lg:flex">
                    <Input
                        name="birthDate"
                        type="date"
                        label="Datum narození"
                        className="h-15"
                        error={errors.birthDate}
                        values={data}
                        setValues={setData}
                        required
                    />
                    <InputPhone
                        name="tel"
                        label="Telefonní číslo"
                        values={data}
                        setValues={setData}
                        error={errors.tel}
                        required
                    />
                </div>

            </div>
            {/* <Divider className="mt-4 lg:mt-8">Fakturační&nbsp;údaje</Divider>
            <Text className="mt-2 lg:mt-5">
                V případě, že se v budoucnu rozhodnete využívat některou z
                našich služeb, kde je budeme potřebovat.
            </Text> */}
            <CheckBox
                name="companyInvoice"
                values={data}
                setValues={setData}
                error={errors.companyInvoice}
                className="mt-6"
            >
                Doplnit firemní údaje
            </CheckBox>
            {data.companyInvoice && (
                <div className="mt-6 space-y-4">
                    <div className="lg:flex lg:space-x-8">
                        <Input
                            required
                            name="companyName"
                            label="Jméno firmy"
                            values={data}
                            setValues={setData}
                            error={errors.companyName}
                        />
                        <div className="hidden lg:block w-full" />
                    </div>
                    <div className="space-y-4 lg:flex lg:space-y-0 lg:space-x-8">
                        <Input
                            required
                            name="ICO"
                            label="IČO"
                            values={data}
                            setValues={setData}
                            error={errors.ICO}
                        />
                        <Input
                            required
                            name="DIC"
                            label="DIČ"
                            values={data}
                            setValues={setData}
                            error={errors.DIC}
                        />
                    </div>
                </div>

            )}
            <div className="mt-4 space-y-2">
                <div className="space-y-2 lg:space-y-0 lg:space-x-8 lg:flex">
                    <Input
                        name="street"
                        label="Ulice a č.p."
                        values={data}
                        setValues={setData}
                        error={errors.street}
                        required
                    />
                    <Input
                        name="city"
                        label="Město"
                        values={data}
                        setValues={setData}
                        error={errors.city}
                        required
                    />
                </div>
                <div className="space-y-2 lg:space-y-0 lg:space-x-8 lg:flex">
                    <Input
                        name="postalCode"
                        label="PSČ"
                        values={data}
                        setValues={setData}
                        error={errors.postalCode}
                        required
                    />
                    <CountrySelect
                        name="country"
                        label="Stát"
                        value={data.country}
                        values={data}
                        setValues={setData}
                        error={errors.country}
                        required
                    />
                </div>
            </div>
            <div className="mt-6 lg:w-52">
                <Button className="lg:w-full">Dokončit</Button>
            </div>
            {/* <div className="mt-4 text-xs">* Toto pole je povinné.</div> */}
        </form>
    );
}
