import React, { useContext } from "react";

import InsertCode from "fragments/Register/InsertCode";
import PersonalData from "fragments/Register/PersonalData";
import { RegisterContext } from "components/Contexts/RegisterContextProvider";
import Begin from "sharedFragments/Begin";
import GoBackButton from "components/Icons/GoBackButton";
import SpektrumLogo from "components/Icons/Spektrum";
import { AppContext } from "components/Contexts/AppContextProvider";
import { motion } from "framer-motion";

export default function Steps() {
    const { step, setStep } = useContext(RegisterContext);
    const { windowSize } = useContext(AppContext);

    return (
        <div className="relative">
            <motion.div
                initial={{ width: 0 }}
                animate={{
                    width: step == 1
                        ? "25%"
                        : step == 2
                            ? "50%"
                            : step == 3
                                ? "75%"
                                : "100%"
                }}
                transition={{easings: "linear"}}
                className={`fixed lg:absolute z-40 left-0 top-0 h-px bg-white ${step == 1
                        ? "w-1/4"
                        : step == 2
                            ? "w-1/2"
                            : step == 3
                                ? "w-3/4"
                                : "w-full"
                    } transition-all duration-300 ease-in-out`}
            />
            <div className="fixed top-0 left-0 right-0 h-px bg-white-30"></div>
            <div className="static z-30 w-full h-20 lg:h-30 px-4 lg:px-20 xl:px-32 2xl:px-60 flex justify-between items-center bg-transparent">
                <div
                    onClick={() => {
                        if (step == 1) {
                            return window.history.back();
                        } else {
                            setStep((prev) => prev - 1);
                        }
                    }}
                    className="w-16"
                >
                    <GoBackButton />
                </div>
                <SpektrumLogo width={windowSize.width < 1024 ? "48" : "64"} />
                <div className="w-16" />
            </div>

            {step === 1 && <Begin />}
            {step === 2 && <InsertCode />}
            {step === 3 && <PersonalData />}
        </div>
    );
}
