import { format } from "date-fns";


export default function NiceDate({date}) {



    return(
        <>
        {format(new Date(date), 'd. M. yyyy')}
        </>
    )
}