import React from 'react';

import ContactForm from 'components/Forms/ContactForm';
import Title from 'components/Typography/Title';
import Text from 'components/Typography/Text';

export default function ContactUs() {
    return (
        <div
            className="lg:pr-10 my-auto py-5 lg:py-20"
        >
            <div className=" lg:my-auto">
                <Text>Jsme tu pro Vás</Text>
                <Title className="mt-8" level={2}>Potřebujete se na něco zeptat?</Title>
                <Text className="mt-5">Zanechejte nám na sebe kontakt a my se vám nejpozději následující pracovní den ozveme.</Text>
                <ContactForm />
            </div>
        </div>
    );
}
