import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Eye: FC<Props> = (props) => {
    const { width = '23', height = '15', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 23 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7844 9.6274C13.0805 9.6274 14.1312 8.5766 14.1312 7.2805C14.1312 5.9843 13.0805 4.93359 11.7844 4.93359C10.4882 4.93359 9.43752 5.9843 9.43752 7.2805C9.43752 8.5766 10.4882 9.6274 11.7844 9.6274ZM11.7844 11.6274C14.1851 11.6274 16.1312 9.6812 16.1312 7.2805C16.1312 4.87975 14.1851 2.93359 11.7844 2.93359C9.38362 2.93359 7.4375 4.87975 7.4375 7.2805C7.4375 9.6812 9.38362 11.6274 11.7844 11.6274Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.05104 7.28024C4.82946 10.2512 8.07728 12.2352 11.7851 12.2352C15.493 12.2352 18.7408 10.2512 20.5192 7.28024C18.7408 4.30917 15.493 2.3252 11.7851 2.3252C8.07728 2.3252 4.82946 4.30916 3.05104 7.28024ZM22.7851 7.28024C20.8317 3.16816 16.6404 0.325195 11.7851 0.325195C6.92987 0.325195 2.73858 3.16815 0.785156 7.28024C2.73858 11.3922 6.92987 14.2352 11.7851 14.2352C16.6404 14.2352 20.8317 11.3922 22.7851 7.28024Z"
                fill={color}
            />
        </svg>
    );
};

export default Eye;
