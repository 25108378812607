import React, { useContext, useEffect, useRef, useState } from "react";

import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { AppContext } from "components/Contexts/AppContextProvider";
import { isMobile } from "react-device-detect";

export default function PathToTheStars(props) {

    const {show} = props

    const [ref, inView] = useInView();

    const {windowSize} = useContext(AppContext)

    const [viewed, setViewed] = useState(false);

    const [height, setHeight] = useState(null as any);

    const insideRef = useRef(null as any)

    useEffect(() => {
        if (insideRef) {
            setHeight(insideRef?.current?.offsetHeight)
        }
    }, [insideRef]);

    useEffect(() => {
        if(inView) {
            setViewed(true)
        }
    }, [inView]);

    return (
        <section id="vyvoj" className="relative lg:max-h-screen overflow-hidden min-h-screen  px-6 lg:py-10 lg:px-0 flex flex-col justify-center items-center">
            <div className="absolute top-10 lg:top-0 " ref={ref}></div>

             <div className="lg:hidden absolute -z-10 inset-0 w-full h-full">
                <img
                    src={"/assets/img/phone_stars.jpg"}
                    className="w-full h-full object-cover object-right-top"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-black via-black-50 to-black-20" />
            </div> 
            {(inView || (viewed && isMobile) || show) &&
                <>
                    <div className="lg:w-160 lg:text-center" ref={insideRef}>
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{
                                ease: "easeOut",
                                delay: 0.4,
                                duration: 0.5,
                            }}
                            className="">
                            <Title level={2}>
                                Digitální aktiva jsou teprve na začátku{" "}
                                <br className="hidden lg:block" />
                                své cesty ke hvězdám
                            </Title>
                        </motion.div>
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{
                                ease: "easeOut",
                                delay: 0.8,
                                duration: 0.5,
                            }}
                            className="">
                            <Text className="mt-6 lg:mt-8">
                            Před námi je ještě dlouhá cesta k tomu, abychom dokázali vytěžit maximum z příležitostí, které blockchain ukrývá. Vlak prozatím neujel a ještě stojí na stanici. Dnešní podobu a rozšířenost technologie a povědomí o ní lze přirovnat k internetu v 90. letech.
                            </Text>
                        </motion.div>

                    </div>
                    <div className="mt-10 lg:mt-32">
                        <div className=" grid grid-cols-2 w-128 transform -translate-x-10" >
                            <div className="">
                                <div className="w-64 h-64 relative">
                                    <motion.div
                                        animate={{ opacity: 1, y: 0 }}
                                        initial={{ opacity: 0, y: 0 }}
                                        exit={{ opacity: 0, y: 0 }}
                                        transition={{
                                            ease: "easeOut",
                                            delay: 0.4,
                                            duration: 0.5,
                                        }}
                                        className="absolute top-0 left-0 right-0 bottom-0 rounded-full border-2 border-white-30"
                                    >
                                    </motion.div>
                                    <motion.div
                                        animate={{ scale: 0.5, y: "25%", borderWidth: 4, opacity: 1 }}
                                        initial={{ scale: 1, y: 0, borderWidth: 2, opacity: 0 }}
                                        transition={{
                                            ease: "easeOut",
                                            delay: 0.8,
                                            duration: 0.5,
                                        }}
                                        className="absolute top-0 left-0 right-0 bottom-0 rounded-full border-2 border-white-60"
                                    >
                                    </motion.div>
                                    <motion.div
                                        animate={{ scale: 0.15, y: "42.5%", borderWidth: 15, opacity: 1 }}
                                        initial={{ scale: 1, y: 0, borderWidth: 2, opacity: 0 }}
                                        transition={{
                                            ease: "easeOut",
                                            delay: 0.8,
                                            duration: 0.5,
                                        }}
                                        className="absolute top-0 left-0 right-0 bottom-0 rounded-full border-2 border-white-90"
                                    >
                                    </motion.div>
                                </div>
                            </div>
                            <div className="ml-8 relative">
                                <motion.div
                                    animate={{ width: 70, x: -84 }}
                                    initial={{ width: 0, x: -84 }}
                                    transition={{
                                        ease: "easeOut",
                                        delay: 0.4,
                                        duration: 0.5,
                                    }}
                                    className="absolute top-6 border-t-2 border-white-30">
                                </motion.div>
                                <motion.div
                                    animate={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: 20 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{
                                        ease: "easeOut",
                                        delay: 0.4,
                                        duration: 0.5,
                                    }}
                                    className="font-semibold">
                                    <div className=" text-white-50 text-sm md:text-base w-40 md:w-auto">Tržní kapitalizace <span className="hidden md:inline-block"> všech titulů</span> S&P 500</div>
                                    <div className="">40 bilionů amerických dolarů</div>
                                </motion.div>
                                <motion.div
                                    animate={{ width: 95, x: -110, top: 150 }}
                                    initial={{ width: 0, x: -110, top: 150 }}
                                    transition={{
                                        ease: "easeOut",
                                        delay: 1.2,
                                        duration: 0.5,
                                    }}
                                    className="absolute border-t-2 border-white-60">
                                </motion.div>
                                <motion.div
                                    animate={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: 20 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{
                                        ease: "easeOut",
                                        delay: 1.2,
                                        duration: 0.5,
                                    }}
                                    className="absolute bottom-20 font-semibold mt-20">
                                    <div className=" text-white-50 text-sm md:text-base  ">Tržní kapitalizace zlata</div>
                                    <div className="">11,35 bilionů amerických dolarů</div>
                                </motion.div>
                                <motion.div
                                    animate={{ width: 125, x: -142, top: 235 }}
                                    initial={{ width: 0, x: -142, top: 235 }}
                                    transition={{
                                        ease: "easeOut",
                                        delay: 1.6,
                                        duration: 0.5,
                                    }}
                                    className="absolute border-t-2 border-white-90">
                                </motion.div>
                                <motion.div
                                    animate={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: 20 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{
                                        ease: "easeOut",
                                        delay: 1.6,
                                        duration: 0.5,
                                    }}
                                    className="absolute bottom-0 font-semibold">
                                    <div className=" text-white-50 lg:whitespace-nowrap text-sm md:text-base  w-40 md:w-auto">Tržní kapitalizace všech digitálních aktiv</div>
                                    <div className="">2,45 bilionů amerických dolarů</div>
                                </motion.div>
                            </div>

                        </div>
                    </div>
                </>
            }

        </section>
    );
}
