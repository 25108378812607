import { animate, motion, useMotionValue } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import Button from "components/Forms/Components/Button";
import { useScrollPosition, useScrollXPosition, useScrollYPosition } from 'react-use-scroll-position';

export default function TechnologicRevolutionPhone(props) {

    const { noButton } = props


    const [ref, inView] = useInView();
    const [shift, setShift] = useState(0);
    const [viewed, setViewed] = useState(false);

    const items = [
        { name: "Mobilita", img: "/assets/img/mobilita.png" },
        { name: "Velká data", img: "/assets/img/velka_data.png" },
        { name: "Strojové učení", img: "/assets/img/strojove_uceni.png" },
        { name: "Robotika", img: "/assets/img/robotika.png" },
        { name: "Cloud", img: "/assets/img/cloud.png" },
        { name: "Internet věcí", img: "/assets/img/internet_veci.png" },
        { name: "Metaverse", img: "/assets/img/metaverse.png" },
    ]

    useEffect(() => {
        if (inView && viewed == false) {
            // setViewed(true);
            const controls = animate(180, 0, {
                duration: 2.5,
                onUpdate(value) {
                    setShift(value);
                },
            });
            return () => controls.stop();
        }
        else if (viewed) {
            setShift(0)
        }
    }, [inView]);

    return (
        <div className="">
            <section
                id="technologie"
                className="relative p-5 "
            >
                <div className="">
                    <motion.div
                        animate={{ opacity: 1, y: 0 }}
                        initial={{ opacity: 0, y: 20 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ ease: "easeOut" }}
                        className=" text-white-60"
                    >
                        Technologická revoluce
                    </motion.div>
                    <div className="text-3xl font-semibold mt-2 flex flex-col ">
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{
                                ease: "easeOut",
                                delay: 0.4,
                                duration: 0.5,
                            }}
                            className=""
                        >
                            Blockchain
                        </motion.div>
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{
                                ease: "easeOut",
                                delay: 0.8,
                                duration: 0.5,
                            }}
                            className=""
                        >
                            tvoří infrastrukturu
                        </motion.div>
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{
                                ease: "easeOut",
                                delay: 1.2,
                                duration: 0.5,
                            }}
                            className="mb-5"
                        >
                            pro inovace
                        </motion.div>
                    </div>

                    <div className="">
                        {items.map(item =>
                            <Item item={item} />
                        )}
                    </div>
                    {!noButton &&
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{
                                ease: "easeOut",
                                delay: 1.4,
                                duration: 0.5,
                            }}
                            className="mt-5"
                        >
                            <Button link="/technologie" theme="secondary">Více o technologii</Button>
                        </motion.div>
                    }

                </div>
            </section>
        </div>

    );
}

function Item({ item }) {

    const ref = useRef(null as any)

    const [visible, setVisible] = useState(false);

    const y = useScrollYPosition()

    useEffect(() => {

        if (ref) {
            const fromTop = ref?.current?.getBoundingClientRect().top


            if (fromTop > 130 && fromTop < 300) {
                setVisible(true)
            }
            else {
                setVisible(false)
            }

        }
    }, [y]);

    return (
        <motion.div
            className="flex items-center mt-5 gap-x-5"
            ref={ref}
            animate={{
                opacity: visible ? 1 : 0.5,
                x: visible ? 0 : -50,
            }}
            transition={{ duration: visible ? 0.3 : 1, ease: "easeInOut" }}
        >
            <img src={item.img} width={90} height={90} />
            <div className={visible ? "font-semibold" : ""}>{item.name}</div>
        </motion.div>
    )
}
