import React from 'react';

import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';

export default function HereWithYou() {
    return (
        <section className="px-6 lg:px-40 xl:px-100 2xl:px-140 pt-10 pb-20 lg:py-20 lg:text-center border-b border-white-30">
            <Title level={2}>Jsme tu pro vás</Title>
            <Text className="mt-6">
                Náš tým se kryptoměnám a investicím věnuje řadu let. Vývoj trhu
                neustále sledujeme vývoj trhu i makroekonomické faktory. Jsme
                vám k dispozici kdykoliv potřebujete a rádi zodpovíme všechny
                vaše dotazy. Napište nám e-mail, nebo vyplňte formulář a my se
                vám ozveme zpět.
            </Text>
            <Title level={4} className="mt-12">
                Za naši práci mluví naše výsledky. Dle nich se odvíjí i naše
                odměna.
            </Title>
            <div className="mt-8 w-min lg:w-full grid grid-rows-3 grid-cols-2 gap-y-4 gap-x-20 lg:gap-0 lg:flex lg:justify-center items-center">
                <div className="w-max text-2xl font-semibold">0,5%</div>
                <div className="w-max lg:ml-4 text-white-60">
                    vstupní poplatek
                </div>
                <div className="w-max lg:ml-12 text-2xl font-semibold">25%</div>
                <div className="w-max lg:ml-4 text-white-60">
                    z měsíčního zisku
                </div>
                <div className="w-max lg:ml-12 text-2xl font-semibold">
                    0,5%
                </div>
                <div className="w-max lg:ml-4 text-white-60">při výběru</div>
            </div>
        </section>
    );
}
