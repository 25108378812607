import React from 'react';

import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';

export default function DynamicEnvironment() {
    return (
        <section className="px-6 lg:px-40 xl:px-100 2xl:px-140 py-10 lg:py-20">
            <div className="min-h-screen grid place-items-center">karty</div>
            <div className="py-20 space-y-8 lg:space-y-10">
                <div className="space-y-4 lg:space-y-5">
                    <Title level={2}>
                        V dynamickém prostředí kryptoměn jsou klíčem
                        k&nbsp;úspěchu informace
                    </Title>
                    <Text>
                        Naše alokační strategie kombinuje poznatky z technické a
                        fundamentální analýzy doplněné o výstupy z&nbsp;analýzy
                        sentimentu, to vše v rámci makroekonomického prostředí,
                        které sledujeme.
                    </Text>
                </div>
                <div className="space-y-4 lg:space-y-5">
                    <Title level={4}>Fundamentální indikátory</Title>
                    <Text>
                        Nejdříve posuzujeme fundamentální hodnotu každého
                        projektu. Je pro nás podmínkou, aby každý projekt
                        splňoval vysoké nároky na kvalitu, disponoval silným
                        vývojářským týmem s dostatkem kapitálu a fungoval v
                        dlouhodobém horizontu.
                    </Text>
                </div>
                <div className="space-y-4 lg:space-y-5">
                    <Title level={4}>Technické indikátory</Title>
                    <Text>
                        Pozorně sledujeme fundamentální MAs a RSIs, stejně jako
                        další pokročilé indikátory. Podle těchto indikátorů se
                        následně rozhodujeme o načasování našeho vstupu.
                    </Text>
                </div>
                <div className="space-y-4 lg:space-y-5">
                    <Title level={4}>Sentiment</Title>
                    <Text>
                        Vzhledem k povaze kryptoměn hraje významnou roli pro
                        rozhodování lidská psychologie a davové chování. Naše
                        rozhodnutí stavíme zároveň na analýze chování ostatních
                        investorů.
                    </Text>
                </div>
                <div className="space-y-4 lg:space-y-5">
                    <Title level={4}>Diverzifikace</Title>
                    <Text>
                        Nikdy nesázíme na jednu kartu. Většinu našeho portfolia
                        alokujeme do Bitcoinu, Etherea a dalších velkých
                        projektů. Zbylou část dedikujeme slibným DeFi
                        start-upům.
                    </Text>
                </div>
            </div>
        </section>
    );
}
