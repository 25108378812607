import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Evaluation: FC<Props> = (props) => {
    const { width = '32', height = '32', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.33203 26.6667L8.41536 17.9394L15.9987 22.6667L22.6654 17.3333L25.9987 6"
                stroke={color}
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.0586 9.93457L26.2302 5.03559L21.3312 7.86402"
                stroke={color}
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Evaluation;
