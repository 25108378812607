import { CountryDropdown } from 'react-country-region-selector';

import React, { FC, useRef, useState } from 'react';
import ValidateOk from 'components/Icons/ValidateOk';
import useOnClickOutside from 'components/Hooks/useOnClickOutside';
import { motion } from 'framer-motion';

type Props = {
    className?: string;
    error: string;
    label?: String;
    name: string;
    required?: boolean;
    value: string;
    values: any;
    setValues: any;
};

const CountrySelect: FC<Props> = (props) => {

    const countries = [
        "Česká repubilka",
        "Slovakia",
        "United Kingdom",
        "Germany",
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia (Plurinational State of)",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands [Malvinas]",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom of Great Britain and Northern Ireland",
        "United States Minor Outlying Islands",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
    ]

    const {
        className,
        error,
        label,
        name,
        required = false,
        value,
        values,
        setValues,
    } = props;

    const [active, setActive] = useState(false);

    const ref = useRef(null as any)

    useOnClickOutside(ref, () => setActive(false))

    function validate() {
        if (values[name]) {
            return true
        }
    }

    function handleChange(e) {
        if (e.target.value == "") {
            setValues({ ...values, [name]: null })
        }
        else {
            setValues({ ...values, [name]: e.target.value })
        }

    }

    return (
        <div ref={ref} onPointerDown={() => setActive(true)} className="rounded-3px overflow-hidden w-full flex flex-col cursor-pointer ">
            <label className={className + " relative lg:w-full flex overflow-hidden rounded-3px flex-col cursor-pointer border-0 "}>
                {/* <span className="text-xs tracking-wide font-semibold">
                {`${label} ${required ? '*' : ''}`}
            </span> */}
                <select onChange={(e) => handleChange(e)} defaultValue={""} value={values[name] ? values[name] : ""} className={` px-4 z-10 pt-6 pb-2 bg-transparent placeholder-white-30 cursor-pointer border-white-30 focus:border-white rounded-3px focus:ring-0  ${error
                    ? " border-red-500 focus:border-red-500"
                    : " border-white-30 hover:border-white-50 focus:border-white"
                    }`}>
                   

                    <option hidden selected disabled value=""></option>
                    {
                        countries?.map((o, i) =>
                            <option  >{o}</option>
                        )
                    }
                </select>
                {/* <CountryDropdown
                name={name}
                value={value}
                onChange={(val) => setValues({ ...values, [name]: val })}
                defaultOptionLabel=""
                priorityOptions={['CZ', 'SK', 'GB', 'DE']}
                classes={` px-4 z-20 pt-6 pb-2 bg-transparent placeholder-white-30 cursor-pointer border-white-30 focus:border-white rounded-3px focus:ring-0  ${error
                    ? " border-red-500 focus:border-red-500"
                    : " border-white-30 focus:border-white"
                    }`}
            /> */}
                {error && <p className="mt-2 text-red-600">{error}</p>}
                <span className="absolute z-20 top-4 right-4">
                    {(validate()) && (<ValidateOk />
                    )}
                </span>
                <motion.div
                    onPointerDown={() => setActive(true)}
                    initial={false}
                    animate={{ y: (values[name]) ? 2 : 16, fontSize: (values[name]) ? "12px" : "16px" }}
                    className="absolute top-0 left-0 right-0 bottom-0 flex px-4 text-white border-0 font-semibold">
                    {label}
                </motion.div>
            </label>
        </div>
    );
};

export default CountrySelect;
