import Title from "components/Typography/Title"
import Text from "components/Typography/Text"
import { AnimatePresence, motion } from "framer-motion"
import { getCircleCoordinates } from "components/Hooks/getCircleCoordinates";
import { isMobile } from "react-device-detect";
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "components/Hooks/useWindowSize";
import { useEffect, useState } from "react";


export default function Secure(props) {

    const { show } = props
    const [ref, inView] = useInView();
    const { width } = useWindowSize()

    const [seen, setSeen] = useState(false);

    useEffect(() => {
        if (inView) {
            setSeen(true)
        }
    }, [inView]);

    function radiusBase() {

        return width / 2 - 30
    }



    const circles = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    return (
        <section
            id="secure"
            className="relative min-h-screen flex items-center px-6 md:py-10 lg:px-20 xl:px-32 2xl:px-60"
            ref={ref}>
            {(isMobile ? (inView || seen) : show) &&
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="grid w-full grid-cols-1 lg:grid-cols-3">
                    <div className="relative h-64 lg:h-auto">
                        <motion.div
                            animate={{ rotate: [180, 60, 30, -90, 180, 240, 270, 120, 300, 30, 90, 0, 180] }}
                            transition={{ duration: 24, times: [0.083, 0.083 * 2, , 0.083 * 3, , 0.083 * 4, , 0.083 * 5, , 0.083 * 6, , 0.083 * 7, , 0.083 * 8, , 0.083 * 9, , 0.083 * 10, , 0.083 * 11, , 0.083 * 12], delay: 1 }}
                            className="absolute pointer-events-none z-0 top-0 bottom-0 left-0 right-0 flex items-center justify-center"
                        >
                            {circles.map((i) => (
                                <motion.div
                                    style={{
                                        y: getCircleCoordinates((-i * 30) - 180, isMobile ? radiusBase() - 20 : 164).y,
                                        x: getCircleCoordinates(-i * 30 - 180, isMobile ? radiusBase() - 20 : 164).x,
                                    }}

                                    animate={{ opacity: 1 }}
                                    initial={{ opacity: 0.3 }}
                                    transition={{ delay: 25 }}
                                    className="absolute pointer-events-none z-0 top-0 bottom-0 left-0 right-0 flex items-center justify-center"
                                >
                                    <motion.div

                                        className="rounded-full bg-white w-4 h-4"></motion.div>
                                </motion.div>
                            ))}
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="absolute pointer-events-none z-0 top-0 bottom-0 left-0 right-0 flex items-center justify-center"

                        >
                            {circles.map((i) => (
                                <motion.div
                                    style={{
                                        y: getCircleCoordinates((-i * 30) - 180, isMobile ? radiusBase() - 64 : 110).y,
                                        x: getCircleCoordinates((-i * 30) - 180, isMobile ? radiusBase() - 64 : 110).x,
                                    }}

                                    className="absolute pointer-events-none z-0 top-0 bottom-0 left-0 right-0 flex items-center justify-center"
                                >
                                    <motion.div
                                        animate={{ opacity: 1 }}
                                        initial={{ opacity: 0.3 }}
                                        transition={{ delay: i * 2 + 3 }}
                                        className={"rounded-full w-6 h-6 bg-white"}></motion.div>
                                </motion.div>
                            ))}
                        </motion.div>

                    </div>
                    <div className=" lg:col-span-2 flex items-center  mt-20">
                        <div className="lg:p-20">
                            <motion.div
                                animate={{ opacity: 1, y: 0 }}
                                initial={{ opacity: 0, y: 20 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ ease: "easeOut", delay: 0.5 }}
                                className="mb-3 md:mb-9">
                                <Title>Vysoká úroveň zabezpečení je naší hlavní prioritou</Title>
                            </motion.div>
                            <motion.div
                                animate={{ opacity: 1, y: 0 }}
                                initial={{ opacity: 0, y: 20 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ ease: "easeOut", delay: 0.7 }}
                                className="mb-5 md:mb-20">
                                <Text>Všechna aktiva jsou uložena dle unikátního klíče na sérii softwarových a hardwarových peněženek chráněných několika úrovněmi autorizace. Aktiva zůstávají i po dobu úložky výlučně vašim majetkem.</Text>
                            </motion.div>
                        </div>
                    </div>
                </motion.div>
            }


        </section>
    )


}