import ReactMarkdown from 'react-markdown';
import parseMD from 'parse-md';

import React from 'react';

import Facebook from 'Icons/Facebook';
import Linkedin from 'Icons/Linkedin';
import Mail from 'Icons/Mail';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import Twitter from 'Icons/Twitter';
import usePageProps from 'components/Hooks/usePageProps';
import FormatDate from './FormatDate';

export default function Post() {
    const { blogpost } = usePageProps();

    const { metadata, content } = parseMD(blogpost);

    console.log(metadata);

    return (
        <section className="min-h-screen px-6 lg:px-40 xl:px-100 2xl:px-140 pt-24 lg:pt-48 pb-20 border-b border-white-30">
            {/* <div className="lg:hidden fixed z-30 top-8 left-0 w-full h-14 bg-black" /> */}
            <FormatDate>{metadata.date}</FormatDate>
            <Title level={2} className="mt-4">
                {metadata.title}
            </Title>
            <div className="mt-4 text-black-70">{metadata.subtitle}</div>
            <div className="my-5 rounded-3px overflow-hidden relative w-full h-100">
                <img
                    src={metadata.coverImage}
                    className="absolute top-0 left-0 right-0 bottom-0 object-cover"
                    alt={metadata.coverImage}
                />
            </div>
            <ReactMarkdown children={content} className="mt-4 markdown-body" />
            {/* <Text className="mt-8">... popisek ...</Text>
            <div className="mt-8">... fotka ...</div>
            <div className="mt-8">... post ...</div> */}
            <div className="mt-12 py-12 border-t border-b border-white-30 flex justify-between">
                <p>Share this</p>
                <span className="flex space-x-4">
                    <Facebook /> <Twitter /> <Linkedin /> <Mail />
                </span>
            </div>
            <div className="pt-6">
                <div className="flex lg:flex-row-reverse justify-between space-x-4 lg:space-x-reverse">
                    
                    <div className="lg:ml-4 lg:w-full">
                        <div className="lg:w-full lg:flex lg:justify-between">
                            <div>
                                <Title level={4}>Matěj Baránek</Title>
                                <p className="text-black-60 text-sm">Autor</p>
                            </div>
                            <p className="font-bold flex">
                                <Mail />
                                <span className="ml-4">
                                    {metadata.autor}
                                </span>
                            </p>
                        </div>
                        <p className=" hidden lg:block text-black-60">
                            zakladatel Spektra
                            <span className="ml-4 underline cursor-pointer">
                                Čti více
                            </span>
                        </p>
                    </div>

 

                    <img
                        src="/assets/img/cloud.png"
                        alt="ProfilePicture"
                        className="object-cover w-16 h-16 lg:w-32 lg:h-32"
                    />
                </div>
                <p className="mt-4 lg:hidden text-white-60">
                    ... popisek autora ...
                    <span className="ml-4 underline cursor-pointer">
                        Čti více
                    </span>
                </p>
            </div>
        </section>
    );
}
