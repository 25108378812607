import React, { FC, HTMLProps, ReactNode } from 'react';

type Props = {
    children: ReactNode;
    className?: String;
} & HTMLProps<HTMLParagraphElement>;

const Text: FC<Props> = (props) => {
    const { children, className = '' } = props;
    return (
        <p {...props} className={' text-white-60 text-base ' + className}>
            {children}
        </p>
    );
};

export default Text;
