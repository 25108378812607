import DropdownPortal from "components/DropdownPortal";
import useOnClickOutside from "components/Hooks/useOnClickOutside";
import ArrowUp from "components/Icons/ArrowUp";
import MenuOverlay from "components/MenuOverlay";
import Modal from "components/Modal";
import { AnimatePresence, motion } from "framer-motion";
import React, { FC, HTMLProps, useRef, useState } from "react";

import "./styles.css";

type Props = {
    className?: string;
    error: string | undefined;
    icon?: JSX.Element;
    label?: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    selectedUnit: string;
    setSelectedUnit: any;
    type?: string;
    units: string[];
    setUnits: any;
    values: any;
    setValues: any;
} & HTMLProps<HTMLInputElement>;

const InputWithUnitsModern: FC<Props> = (props) => {
    const {
        className = "",
        error,
        icon,
        label,
        name,
        placeholder,
        required = false,
        setValues,
        selectedUnit,
        setSelectedUnit,
        type = "text",
        units,
        setUnits,
        values,
        value
    } = props;

    const [isOpen, setOpen] = useState(false);

    const [active, setActive] = useState(false);

    const ref = useRef(null as any)
    const dropRef = useRef(null as any)

    useOnClickOutside(ref, () => setActive(false))

    return (
        <>
            {isOpen && (
                <div
                    className="absolute z-10 -left-4 top-0 w-full h-full"
                    onClick={() => setOpen(false)}
                />
            )}
            <div ref={ref} className="rounded-3px  w-full flex flex-col ">
                <label className={className + " relative w-full flex  rounded-3px flex-col border-0 "}>

                    {/* <span className="text-xs tracking-wide font-semibold">{`${label} ${
                    required ? "*" : ""
                }`}</span> */}
                    <div className="flex w-full">
                        <input
                            {...props}
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            value={value ?? ""}
                            onChange={(e) =>
                                setValues({ ...values, [name]: e.target.value })
                            }
                            onPointerDown={() => setActive(true)}
                            style={{ minHeight: "56px " }}
                            className={` px-0 z-20 pt-6 flex-grow flex-shrink text-2xl monospace w-5 pb-2 bg-transparent placeholder-white-30 border-black focus:border-black rounded-l-3px focus:ring-0  ${error
                                ? " border-red-500 focus:border-red-500"
                                : " border-black focus:border-black"
                                }`}
                            required={required}
                        />
                        <div
                            onClick={() => setOpen((prev) => !prev)}
                            className={" relative z-20 flex-grow-0 flex-shrink-0 pl-6 py-4 text-2xl font-semibold flex items-center border-t border-r border-b rounded-r-3px border-black " + (units.length > 1 && " cursor-pointer")}
                            ref={dropRef}
                        >
                            {units.length > 1 &&
                                <span className="mr-2 transform rotate-180">
                                    <ArrowUp />
                                </span>
                            }

                            <span>{selectedUnit}</span>
                            <AnimatePresence>
                                {isOpen && (
                                    <DropdownPortal x={dropRef.current.getBoundingClientRect().x} y={dropRef.current.getBoundingClientRect().y} >
                                        <motion.div
                                            initial={{ opacity: 0, y: -20, scale: 0.8 }}
                                            animate={{ opacity: 1, y: 0, scale: 1 }}
                                            exit={{ opacity: 0, y: -20, scale: 0.8 }}
                                            style={{ minWidth: 128 }}
                                            transition={{ duration: 0.2 }}
                                            className="  w-full rounded-3px cursor-pointer z-50 text-base bg-191919 shadow-lg">
                                            {units.filter(unit => unit != selectedUnit).map(
                                                (unit, i) =>
                                                    <div className="hover:bg-white-10 transition duration-200">
                                                        <div
                                                            onClick={() =>
                                                                setSelectedUnit(unit)
                                                            }
                                                            className={"mx-5 py-4  " + (i != 0 ? " border-t border-white-30 " : " border-none")}
                                                        >
                                                            {unit}
                                                        </div>
                                                    </div>


                                            )}
                                        </motion.div>
                                    </DropdownPortal>
                                )}
                            </AnimatePresence>
                        </div>
                    </div>

                    {error && <p className="mt-2 text-red-600">{error}</p>}
                    <motion.div
                        onPointerDown={() => setActive(true)}
                        initial={false}
                        animate={{ y: (active || values[name] > 0) ? 2 : 24, fontSize: (active || values[name] > 0) ? "12px" : "16px", color: (active || values[name] > 0) ? "white" : "gray", fontWeight: (active || values[name] > 0) ? 600 : 400 }}
                        className="absolute top-0 left-0 right-0 bottom-0 flex px-0 text-white border-0 font-semibold">
                        {label}
                    </motion.div>
                </label>
            </div>
        </>
    );
};

export default InputWithUnitsModern;
