import React, { useState } from 'react';
import { useForm } from '@inertiajs/inertia-react';

import Button from './Components/Button';
import CheckBox from './Components/CheckBox';
import Input from './Components/Input';
import InputPhone from './Components/InputPhone';
import TextArea from './Components/TextArea';
import route from 'ziggy-js';
import Gdpr from './Gdpr';
import axios from 'axios';

export default function ContactForm() {
    const [isFormSent, setFormSent] = useState(false);
    const [clicked, setClicked] = useState(null as any);

    const form = useForm({
        fullName: '',
        tel: '+420',
    });

    const { data, setData, errors, clearErrors, post } = form;

    const handleSubmit = async (e) => {
        e.preventDefault();

        clearErrors();

        setClicked(true)

        if (!clicked) {
            axios.post('/api/call_me_with_name', data).then((res) => {
                if (res.status == 200) {
                    setData({
                        fullName: '',
                        tel: '+420'
                    })
                    setFormSent(true)
                    setClicked(false)
                }
                else {
                    setFormSent(false)
                }
            });
        }


    };

    return (
        <>
            {isFormSent ? (
                <div className="mt-6 space-y-4">
                    <p>Děkujeme. V nejbližší době se Vám ozveme.</p>
                    <p
                        className="underline cursor-pointer"
                        onClick={() => setFormSent(false)}
                    >
                        Zeptat se na něco dalšího
                    </p>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="mt-6 space-y-2 ">
                        <div className="space-y-2 lg:space-y-0 lg:flex lg:space-x-4">
                            <Input
                                name="fullName"
                                label="Jméno a příjmení"
                                value={data.fullName}
                                values={data}
                                setValues={setData}
                                error={errors.fullName}
                                className="lg:w-full"
                                required
                            />
                            <InputPhone
                                name="tel"
                                label="Telefonní číslo"
                                placeholder="777 777 777"
                                values={data}
                                setValues={setData}
                                error={errors.tel}
                                className="lg:w-full"
                            />
                        </div>
                    </div>
                    <div className="mt-6 mb-4 text-sm">
                        Nebo nám napište na <a className="font-semibold underline" href="mailto:info@spektrum.fund">info@spektrum.fund</a>
                    </div>
                    <div className="block lg:inline-flex">
                        <Button className={"my-4 " + (clicked ? " bg-white-30 text-black cursor-wait " : " ")}>{isFormSent ? "Odesílání" : "Zavolejte mi"}</Button>
                    </div>

                    {/* <div className="mt-4 text-xs">* Toto pole je povinné.</div> */}
                    <Gdpr />
                </form>
            )}
        </>
    );
}
