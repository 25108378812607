import React from 'react';

import Button from 'components/Forms/Components/Button';
import Help from 'components/Helps/Help';
import Text from 'components/Typography/Text';

interface Props {
    meeting: 'ActiveManagment' | 'IndividualPortfolio' | 'TermDeposit';
}

export default function Arrange(props: Props) {
    const { meeting } = props;

    return (
        <section className="px-6 lg:px-40 xl:px-100 2xl:px-140 pt-72 lg:pt-80 pb-20">
            <div className="lg:hidden fixed top-8 left-0 w-full h-14 bg-black" />
            <div>
                {meeting == 'ActiveManagment' && (
                    <Text>
                        ... aktivni sprava ... Bezkonkurenční výhodou kryptoměn
                        je možnost zakoupit i prodat je v rámci několika vteřin
                        v jakémkoliv objemu, což u jiných aktiv není možné. Vy
                        jste tak schopni reagovat na vývoj trhu okamžitě.
                        Blockchainová síť zpracovává bez přerušení každý den
                        miliardy transakcí. V případě Bitcoinu, největší
                        kryptoměny z hlediska tržní kapitalizace, je i v dobách
                        největšího růstu zcela běžný krátkodobý propad i o
                        desítky procent. Průměrné roční zhodnocení však dosahuje
                        více než 200 %. Volatilita nám umožňuje ve správný čas
                        kryptoměny prodat a následně znovu nakoupit, díky čemuž
                        těžíme z cyklické povahy trhu a následně i dosahujeme
                        vyššího kumulovaného zhodnocení. Za naše výsledky hovoří
                        fakt, že naše diverzifikovaná strategie dosahuje
                        dlouhodobě lepšího zhodnocení než Bitcoin.
                    </Text>
                )}
                {meeting == 'IndividualPortfolio' && (
                    <Text>
                        ... individualni portfolio ... Bezkonkurenční výhodou
                        kryptoměn je možnost zakoupit i prodat je v rámci
                        několika vteřin v jakémkoliv objemu, což u jiných aktiv
                        není možné. Vy jste tak schopni reagovat na vývoj trhu
                        okamžitě. Blockchainová síť zpracovává bez přerušení
                        každý den miliardy transakcí. V případě Bitcoinu,
                        největší kryptoměny z hlediska tržní kapitalizace, je i
                        v dobách největšího růstu zcela běžný krátkodobý propad
                        i o desítky procent. Průměrné roční zhodnocení však
                        dosahuje více než 200 %. Volatilita nám umožňuje ve
                        správný čas kryptoměny prodat a následně znovu nakoupit,
                        díky čemuž těžíme z cyklické povahy trhu a následně i
                        dosahujeme vyššího kumulovaného zhodnocení. Za naše
                        výsledky hovoří fakt, že naše diverzifikovaná strategie
                        dosahuje dlouhodobě lepšího zhodnocení než Bitcoin.
                    </Text>
                )}
                {meeting == 'TermDeposit' && (
                    <Text>
                        ... terminovana ulozka ... Bezkonkurenční výhodou
                        kryptoměn je možnost zakoupit i prodat je v rámci
                        několika vteřin v jakémkoliv objemu, což u jiných aktiv
                        není možné. Vy jste tak schopni reagovat na vývoj trhu
                        okamžitě. Blockchainová síť zpracovává bez přerušení
                        každý den miliardy transakcí. V případě Bitcoinu,
                        největší kryptoměny z hlediska tržní kapitalizace, je i
                        v dobách největšího růstu zcela běžný krátkodobý propad
                        i o desítky procent. Průměrné roční zhodnocení však
                        dosahuje více než 200 %. Volatilita nám umožňuje ve
                        správný čas kryptoměny prodat a následně znovu nakoupit,
                        díky čemuž těžíme z cyklické povahy trhu a následně i
                        dosahujeme vyššího kumulovaného zhodnocení. Za naše
                        výsledky hovoří fakt, že naše diverzifikovaná strategie
                        dosahuje dlouhodobě lepšího zhodnocení než Bitcoin.
                    </Text>
                )}
            </div>
            <div className="mt-8 lg:mt-12 flex flex-col lg:flex-row lg:items-center">
                <div className="lg:w-52">
                    <Button>Sjednat schůzku</Button>
                </div>
                <Help className="mt-8 lg:mt-0 lg:ml-8" />
            </div>
        </section>
    );
}
