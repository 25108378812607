import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const IndividualPortfolio: FC<Props> = (props) => {
    const { width = '110', height = '110', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 110 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M62.4136 7.22141C62.4136 11.2097 59.1739 14.4428 55.1774 14.4428C51.181 14.4428 47.9412 11.2097 47.9412 7.22141C47.9412 3.23314 51.181 0 55.1774 0C59.1739 0 62.4136 3.23314 62.4136 7.22141Z"
                fill={color}
            />
            <path
                d="M86.2068 13.6157C86.2068 17.604 82.967 20.8371 78.9706 20.8371C74.9742 20.8371 71.7344 17.604 71.7344 13.6157C71.7344 9.62742 74.9742 6.39429 78.9706 6.39429C82.967 6.39429 86.2068 9.62742 86.2068 13.6157Z"
                fill={color}
            />
            <path
                d="M96.7264 38.2433C100.723 38.2433 103.963 35.0102 103.963 31.0219C103.963 27.0336 100.723 23.8005 96.7264 23.8005C92.73 23.8005 89.4902 27.0336 89.4902 31.0219C89.4902 35.0102 92.73 38.2433 96.7264 38.2433Z"
                fill={color}
            />
            <path
                d="M110 54.8224C110 58.8107 106.76 62.0438 102.764 62.0438C98.7674 62.0438 95.5276 58.8107 95.5276 54.8224C95.5276 50.8342 98.7674 47.601 102.764 47.601C106.76 47.601 110 50.8342 110 54.8224Z"
                fill={color}
            />
            <path
                d="M96.7264 86.1995C100.723 86.1995 103.963 82.9663 103.963 78.9781C103.963 74.9898 100.723 71.7567 96.7264 71.7567C92.73 71.7567 89.4902 74.9898 89.4902 78.9781C89.4902 82.9663 92.73 86.1995 96.7264 86.1995Z"
                fill={color}
            />
            <path
                d="M86.2068 96.3843C86.2068 100.373 82.967 103.606 78.9706 103.606C74.9742 103.606 71.7344 100.373 71.7344 96.3843C71.7344 92.396 74.9742 89.1629 78.9706 89.1629C82.967 89.1629 86.2068 92.396 86.2068 96.3843Z"
                fill={color}
            />
            <path
                d="M55.1774 110C59.1739 110 62.4136 106.767 62.4136 102.779C62.4136 98.7903 59.1739 95.5572 55.1774 95.5572C51.181 95.5572 47.9412 98.7903 47.9412 102.779C47.9412 106.767 51.181 110 55.1774 110Z"
                fill={color}
            />
            <path
                d="M38.2656 96.3843C38.2656 100.373 35.0258 103.606 31.0294 103.606C27.0329 103.606 23.7932 100.373 23.7932 96.3843C23.7932 92.396 27.0329 89.1629 31.0294 89.1629C35.0258 89.1629 38.2656 92.396 38.2656 96.3843Z"
                fill={color}
            />
            <path
                d="M13.6284 86.1995C17.6249 86.1995 20.8646 82.9663 20.8646 78.9781C20.8646 74.9898 17.6249 71.7567 13.6284 71.7567C9.63198 71.7567 6.39223 74.9898 6.39223 78.9781C6.39223 82.9663 9.63198 86.1995 13.6284 86.1995Z"
                fill={color}
            />
            <path
                d="M14.4724 54.8224C14.4724 58.8107 11.2326 62.0438 7.23618 62.0438C3.23975 62.0438 0 58.8107 0 54.8224C0 50.8342 3.23975 47.601 7.23618 47.601C11.2326 47.601 14.4724 50.8342 14.4724 54.8224Z"
                fill={color}
            />
            <path
                d="M13.6284 38.2433C17.6249 38.2433 20.8646 35.0102 20.8646 31.0219C20.8646 27.0336 17.6249 23.8005 13.6284 23.8005C9.63198 23.8005 6.39223 27.0336 6.39223 31.0219C6.39223 35.0102 9.63198 38.2433 13.6284 38.2433Z"
                fill={color}
            />
            <path
                d="M38.2656 13.6157C38.2656 17.604 35.0258 20.8371 31.0294 20.8371C27.0329 20.8371 23.7932 17.604 23.7932 13.6157C23.7932 9.62742 27.0329 6.39429 31.0294 6.39429C35.0258 6.39429 38.2656 9.62742 38.2656 13.6157Z"
                fill={color}
            />
            <circle cx="55.0018" cy="54.9998" r="9.75177" fill={color} />
        </svg>
    );
};

export default IndividualPortfolio;
