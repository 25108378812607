import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Mail: FC<Props> = (props) => {
    const { width = '24', height = '24', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 5H4C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5Z"
                stroke={"currentColor"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="bevel"
            />
            <path
                d="M2.57812 5.58984L10.8081 13.8098C10.9939 13.9958 11.2144 14.1433 11.4572 14.244C11.7 14.3446 11.9603 14.3964 12.2231 14.3964C12.486 14.3964 12.7462 14.3446 12.989 14.244C13.2318 14.1433 13.4524 13.9958 13.6381 13.8098L21.6381 5.80984"
                stroke={"currentColor"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Mail;
