import Scrollspy from "react-scrollspy";

import React, { useContext, useEffect, useState } from "react";
import { InertiaLink } from "@inertiajs/inertia-react";

import Close from "Icons/Close";
import Hamburger from "Icons/Hamburger";
import SpektrumLogo from "components/Icons/Spektrum";
import Meatballs from "components/Icons/Meatballs";
import MobileNav from "./MobileNav";
import { MenuContext } from "components/Contexts/MenuContext";
import Button from "components/Forms/Components/Button";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Inertia } from "@inertiajs/inertia";
import DesktopNav from "./DesktopNav";
import { isMobile } from "react-device-detect";
import GoBackButton from "components/Icons/GoBackButton";

export default function Navbar(props) {

    const { changePage, home, technology, inverted, back, backFunc, noDot } = props

    const actualPath = window.location.pathname;

    const [opened, setOpened] = useState(false);

    useEffect(() => {
        if (opened) {
            document.body.style.overflow = 'hidden';
            document.body.style.maxHeight = '-webkit-fill-available;';
        }
        else {
            document.body.style.overflow = 'visible';
            document.body.style.maxHeight = 'unset';
        }

    }, [opened])

    return (
        <>
            <div className={" z-50 w-full fixed top-0 right-0 text-white  " + ((inverted && !opened) && " invert filter ")}>
                <div
                    className={`px-6 relative py-4 flex justify-between ${opened ? "bg-transparent " : "bg-opacity-0 "}  transition duration-150 `}
                >
                    {(!opened && !inverted) &&
                        <div className="block absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-black-70 to-transparent"></div>
                    }
                    {(!opened && inverted) &&
                        <div className="block absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-transparent "></div>
                    }
                    {!back &&
                        <div className="z-50 cursor-pointer transform hover:scale-105 scale-100 transition duration-150" onClick={() => {
                            changePage ? changePage(0) : {}
                            home ? scroll.scrollTo('uvod') : inverted ? Inertia.visit("/blog") : Inertia.visit("/")
                            setOpened(false)
                        }}>
                            <SpektrumLogo width="48" height="48" />
                        </div>
                    }

                    {back &&
                        <div className="z-50" onClick={() => backFunc ? backFunc() : history.back()}>
                            <GoBackButton />
                        </div>
                    }
                    <div className="z-50 flex items-center gap-x-4 ">
                        <Button link="/profil/nova-ulozka" theme="secondary" dot={!noDot} className="hidden sm:block">Nová úložka</Button>
                        <Meatballs opened={opened} setOpened={(what: boolean) => setOpened(what)} className="">
                            {isMobile ?
                                <MobileNav changePage={(to) => changePage(to, true)} home={home} closeMenu={() => setOpened(false)} />
                                :
                                <DesktopNav closeMenu={() => setOpened(false)} changePage={(to) => changePage(to, true)} home={home} />
                            }

                        </Meatballs>


                    </div>


                </div>
            </div>



            {(technology && !opened) && (
                <div className="hidden lg:block w-full h-12 fixed z-50 bottom-0 left-0">
                    <Scrollspy
                        items={[
                            "podstata",
                            "princip",
                            "vyuziti",
                            "vyvoj",
                            "caste-dotazy",
                        ]}
                        currentClassName="text-white"
                        className="w-full h-full px-20 xl:px-32 2xl:px-60 flex justify-between items-center bg-white-10-opaque text-white-60"
                    >
                        <li onClick={() => changePage ? changePage(0) : {}} className="hover:text-white transition-all duration-300 ease-out cursor-pointer">
                            Úvod
                        </li>
                        <li onClick={() => changePage ? changePage(1) : {}} className="hover:text-white transition-all duration-300 ease-out cursor-pointer">
                            Princip
                        </li>
                        <li onClick={() => changePage ? changePage(2) : {}} className="hover:text-white transition-all duration-300 ease-out cursor-pointer">
                            Využití
                        </li>
                        <li onClick={() => changePage ? changePage(3) : {}} className="hover:text-white transition-all duration-300 ease-out cursor-pointer">
                            Vývoj
                        </li>
                        <li onClick={() => changePage ? changePage(4) : {}} className="hover:text-white transition-all duration-300 ease-out cursor-pointer">
                            Časté dotazy
                        </li>
                        {/* <li className="hover:text-white transition-all duration-300 ease-out">
                            <InertiaLink href="/#produkty">
                                Produkty
                            </InertiaLink>
                        </li> */}
                        {/* <li className="hover:text-white transition-all duration-300 ease-out cursor-pointer">
                            <Link to="kontakt" smooth={true} spy={true} hashSpy={true} offset={0} duration={500} activeClass="text-white" >Kontakt
                            </Link>
                        </li> */}
                        {/* <li className="hover:text-white transition-all duration-300 ease-out">
                            <InertiaLink href="/profil">Profil</InertiaLink>
                        </li> */}
                        {/* <li className="hover:text-white transition-all duration-300 ease-out">
                            <InertiaLink href="/blog">Blog</InertiaLink>
                        </li> */}
                    </Scrollspy>
                </div>
            )}
        </>
    );
}
