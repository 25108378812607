import React from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import BlogpostsOverview from 'fragments/Admin/BlogpostsOverview';

export default function Overview() {
    return (
        <AdminLayout>
            <BlogpostsOverview />
        </AdminLayout>
    );
}
