import React, { FC, SVGProps } from "react";

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const GoBack: FC<Props> = (props) => {
    const { width = "17", height = "16", color = "white" } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 7L5 11L9 7" stroke={color} strokeWidth="2" />
            <path
                d="M16 15.5V6.5C16 3.46243 13.5376 1 10.5 1V1C7.46243 1 5 3.46243 5 6.5V10"
                stroke={color}
                strokeWidth="2"
            />
        </svg>
    );
};

export default GoBack;
