import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const ValidateOk: FC<Props> = (props) => {
    const { width = '24', height = '24', color = '#539648' } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={color} />
            <path d="M7 12.5L10.5016 16L17.2663 9" stroke="white" stroke-width="2" />
        </svg>

    );
};

export default ValidateOk;
