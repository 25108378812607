import React from 'react';

import AppLayout from 'components/Layouts/AppLayout';
import Back from 'components/Back';
import BlogLayout from 'components/Layouts/BlogLayout';
import Posts from 'fragments/Blog/Posts';
import SpektrumBlogLogo from 'components/Logos/SpektrumBlogLogo';
import Navbar from 'components/Navbar';
import { useInView } from 'react-intersection-observer';
import { useScrollYPosition } from 'react-use-scroll-position';
import { AnimatePresence, motion } from 'framer-motion';

export default function Blog() {

    const [ref, inView] = useInView()
    const scrollY = useScrollYPosition()

    return (
        <AppLayout white>
            <BlogLayout>
                <div ref={ref}>
                    <Navbar inverted={inView} />
                    <AnimatePresence>
                        {(inView && scrollY > 50) &&
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="fixed top-0 left-0 right-0 h-20 bg-gradient-to-b z-40 from-black-10 to-transparent filter  "></motion.div>
                        }
                    </AnimatePresence>
                    <Posts />
                </div>
            </BlogLayout>
        </AppLayout>
    );
}
