import { formatDistance } from "date-fns";



export default function FormatDate({ children }) {

    function date() {
        if (false) {
            return formatDistance(
                new Date(children),
                new Date(),
                { addSuffix: true }
            )
        }
        else {
            return children
        }

    }


    return (
        <p className="text-sm">{date()} </p>
    )
}