import React, { FC } from "react";

type Props = {
    className?: string
    wrap?: boolean
    background?: boolean
};

const Help: FC<Props> = (props) => {
    const { className, wrap, background } = props;
    return (
        <div className={`${className} flex flex-wrap items-center ${background && "bg-white-20 rounded-3px p-4"}`}>
            {/* <div className="flex-none w-12 h-12 rounded-full bg-white-30"></div> */}
            {/* <div className="ml-4"> */}
            <div className="flex items-center gap-4">
                <img src="/assets/img/matej.jpg" className="rounded-full" width={36} height={36} />
                <p>Potřebujete poradit?{wrap ? <br /> : " "}<a href="tel:+420777705416" className="underline">+420&nbsp;777&nbsp;705&nbsp;416</a> <span className="text-xs">(po-pá, 9-18)</span> </p>
            </div>
        </div>
    );
};

export default Help;
