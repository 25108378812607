import React, { FC, SVGProps } from "react";

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Triangle: FC<Props> = (props) => {
    const { width = "7", height = "5", color = "white" } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 7 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.5 0L0 5H7L3.5 0Z" fill={color} />
        </svg>
    );
};

export default Triangle;
