import React from 'react';

import Account from 'Icons/Account';
import BasicCard from 'components/Cards/BasicCard';
import Button from 'components/Forms/Components/Button';
import Deposit from 'Icons/Deposit';
import Evaluation from 'Icons/Evaluation';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';

export default function Hero() {
    return (
        <section className="min-h-screen px-6 lg:px-40 xl:px-100 2xl:px-140 pt-72 lg:pt-80 pb-20">
            <div className="lg:hidden fixed top-8 left-0 w-full h-14 bg-black" />

            <Title level={2}>Objevte s námi svět kryptoměn</Title>
            <Text className="mt-6">
                Prvním krokem pro vás může být stínování našeho
                diverzifikovaného portfolia. To permanentně monitorujeme a
                rebalancujeme, abychom dosáhli stabilně co nejlepších výsledků.
            </Text>
            <Text className="mt-4" style={{ color: 'white' }}>
                Naše diverzifikovaná strategie dosahuje konstantně lepších
                výsledků než Bitcoin. Počáteční vklad může být již 250&nbsp;Kč,
                většina investorů začíná s 25&nbsp;000&nbsp;Kč.
            </Text>
            <div className="my-12 lg:my-20 flex flex-col lg:flex-row space-y-16 lg:space-y-0 lg:space-x-8 lg:justify-between">
                <BasicCard
                    icon={<Account />}
                    title="Vytvořte si účet"
                    text="Celým procesem investice vás provedeme."
                />
                <BasicCard
                    icon={<Deposit />}
                    title="Složte vklad"
                    text="V Bitcoinu, Etheru, nebo v&nbsp;Eurech."
                />
                <BasicCard
                    icon={<Evaluation />}
                    title="Vytvořte si účet"
                    text="24/7 monitorujeme vývoj a diverzifikujeme riziko."
                />
            </div>
            <Text>
                Samotná investice probíhá na auditované globální platformě
                Iconomi, která umožňuje automatizovaně stínovat naše portfolio v
                reálném čase. Digitální aktiva zůstávají ve vašem vlastnictví,
                pouze kopírují rozhodnutí našich specialistů, kteří se
                kryptoměnami zabývají celý den, abyste vy nemuseli. Svůj kapitál
                si můžete kdykoliv vybrat.
            </Text>
        </section>
    );
}
