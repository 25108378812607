import { Link, useForm, usePage } from "@inertiajs/inertia-react";
import axios from "axios";
import { AppContext } from "components/Contexts/AppContextProvider";
import Help from "components/Helps/Help";
import usePageProps from "components/Hooks/usePageProps";
import BTC from "components/Icons/BTC";
import Equivalent from "components/Icons/Equivalent";
import LoadingSpinner from "components/Icons/LoadingSpinner";
import Success from "components/Icons/Success";
import Triangle from "components/Icons/Triangle";
import Modal from "components/Modal";
import { interestsVariableArray } from "components/StaticData";
import Title from "components/Typography/Title";
import { parse } from "postcss";
import React, { useContext, useEffect, useState } from "react";
import { toMaxFixed } from "./CalcDepositForm";
import Button from "./Components/Button";
import Input from "./Components/Input";
import InputWithUnits from "./Components/InputWithUnits";
import InputWithUnitsModern from "./Components/InputWithUnitsModern";
import PromoCode from "./Components/PromoCode";

export default function CreateDepositForm() {
    const initialDepositAndInterest = [
        {
            months: 24,
            interestInPercentage: 0.5,
        },
        {
            months: 12,
            interestInPercentage: 0.4,
        },
        {
            months: 6,
            interestInPercentage: 0.3,
        },
        {
            months: 3,
            interestInPercentage: 0.25,
        },
    ]
    const [variablePercentage, setVariablePercentage] = useState(interestsVariableArray);
    const [variableAddition, setVariableAddition] = useState(0);
    const [termDepositAndInterest, setTermDepositAndInterest] = useState(initialDepositAndInterest)
    const [units, setUnits] = useState(["Kč", "USD", "EUR"]);
    const [selectedUnit, setSelectedUnit] = useState("Kč");
    const [cryptoUnits, setCryptoUnits] = useState(["BTC"]);
    const [selectedCryptoUnit, setSelectedCryptoUnit] = useState("BTC");
    const [firstTime, setFirstTime] = useState(true);
    const [step, setStep] = useState(1);
    const [active, setActive] = useState(null as any);
    const [sentURL, setSentUrl] = useState(null as any)
    const [openModal, setOpenModal] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [mode, setMode] = useState("fixed");
    const [voucherCode, setVoucherCode] = useState(null as any);

    const [swaped, setSwaped] = useState(false);

    const [termDepositAndInterestIndex, setTermDepositAndInterestIndex] =
        useState(0);
    const [exchangeRate, setExchangeRate] = useState({
        BTC: 1300000,
        USD: 22,
        EUR: 25,
    });

    const { windowSize, user } = useContext(AppContext);
    const { number } = usePageProps<any>();

    console.log(number);

    const form = useForm({
        amountBTC: null,
        equivalent: null,
        expiration: new Date(),
        finalBilance: 0,
        interest: 0.5,
        finalInterest: 0,
        payMethod: 0,
        user_UUID: user.UUID,
    });

    const { data, setData, errors, clearErrors, post } = form;

    const handleSubmit = async (e) => {
        e.preventDefault();
    };

    function updateInterests(data: any) {

        setTermDepositAndInterest([
            {
                months: 24,
                interestInPercentage: data[0],
            },
            {
                months: 12,
                interestInPercentage: data[1],
            },
            {
                months: 6,
                interestInPercentage: data[2],
            },
            {
                months: 3,
                interestInPercentage: data[3],
            },
        ])
    }

    async function submit() {
        if ((data.equivalent >= 25000 && selectedUnit == "Kč") || (data.equivalent >= 1000 && selectedUnit == "USD") || (data.equivalent >= 1000 && selectedUnit == "EUR")) {
        // if (true) {
            if (!sentURL) {

                clearErrors();
                const response = await axios.post("/api/create_deposit", {
                    ...data,
                    type: mode,
                    interestBonus: (variableAddition),
                    voucherCode: voucherCode,
                })

                if (response) {
                    console.log(response)
                    console.log(response.data.data.hosted_url)
                    // window.open(response.data.data.hosted_url)
                    window.location.href = response.data.data.hosted_url
                    setSentUrl(response.data.data.hosted_url)
                    setOpenModal(true)
                }
            }
            else if (sentURL) {
                window.open(sentURL)
            }
        }
        else {
            alert("Zvolte prosím vyšší částku úložky")
        }




    }

    function transCurrency() {
        switch (selectedUnit) {
            case "Kč": return { currency: "CZK", location: "cs-CZ" }
            case "USD": return { currency: "USD", location: "en-US" }
            case "EUR": return { currency: "EUR", location: "de-DE" }
            default: return { currency: "CZK", location: "cs-CZ" }
        }
    }

    var formatter = new Intl.NumberFormat(transCurrency().location, {
        style: 'currency',
        currency: transCurrency().currency,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const CalculateFinalBilance = (): number => {
        let newBilance = Number(data.amountBTC);

        for (
            let i = 0;
            i < termDepositAndInterest[termDepositAndInterestIndex].months;
            i++
        ) {
            const interest =
                (newBilance *
                    termDepositAndInterest[termDepositAndInterestIndex]
                        .interestInPercentage) /
                100;

            newBilance += interest;
        }

        return newBilance;
    };

    function nextStep() {
        // if ((data.equivalent >= 25000 && selectedUnit == 0) || (data.equivalent >= 1000 && selectedUnit == 1) || (data.equivalent >= 1000 && selectedUnit == 2)) {
        if (true) {
            setStep(2)
        }
        else {
            alert("Zvolte prosím vyšší částku úložky")
        }
    }

    function compound(value: number, times: number) {
        const timesArray = new Array(times).fill(0)

        let sum = value

        timesArray.map(repeat => {
            sum += value + (sum / 100 * value)
        })

        return sum
    }

    useEffect(() => {
        if (!firstTime && ((active == "czk") || !active)) {
            let newAmountBTC =
                data.equivalent /
                (selectedUnit == "Kč"
                    ? exchangeRate.BTC
                    : selectedUnit == "USD"
                        ? exchangeRate.USD
                        : exchangeRate.EUR);

            setData({ ...data, amountBTC: data.equivalent ? newAmountBTC.toFixed(9) : "" });
        } else setFirstTime(false);

        CalculateFinalBilance();
    }, [data.equivalent, termDepositAndInterestIndex]);

    useEffect(() => {
        let newEquivalent =
            data.amountBTC *
            (selectedUnit == "Kč"
                ? exchangeRate.BTC
                : selectedUnit == "USD"
                    ? exchangeRate.USD
                    : exchangeRate.EUR);

        const finalBilance = CalculateFinalBilance();

        const now = new Date();
        const newExpiration = new Date(
            new Date().setMonth(
                now.getMonth() +
                termDepositAndInterest[termDepositAndInterestIndex].months
            ) + 86400000
        );

        // .toLocaleDateString()

        setData({
            ...data,
            amountBTC:(data["amountBTC"]?.replace(",", ".")),
            equivalent: (data.amountBTC > 0 ? (((active == "btc") || !active) ? parseFloat((newEquivalent).toFixed(2)) : data.equivalent) : null),
            expiration: newExpiration,
            finalBilance: finalBilance,
            finalInterest:
                data.amountBTC == 0
                    ? 0
                    : (finalBilance / data.amountBTC) * 100 - 100,
        });

    }, [data.amountBTC, termDepositAndInterestIndex, selectedUnit]);

    useEffect(() => {
        axios
            .get(`https://api.coinbase.com/v2/exchange-rates?currency=BTC`)
            .then((res) =>
                setExchangeRate({
                    BTC: res.data.data.rates.CZK,
                    USD: res.data.data.rates.USD,
                    EUR: res.data.data.rates.EUR,
                })
            )
            .catch((err) => {
                throw err;
            });
        // const interval = setInterval(() => {

        // }, 4000);
        // return () => {
        //     clearInterval(interval);
        // };

    }, []);

    return (
        <form
            onSubmit={handleSubmit}
            className="mt-6 lg:mt-20 lg:flex w-full gap-x-10 "
        >
            {console.log(exchangeRate)}
            {(step == 1 || windowSize.width > 640) && (
                <div className="flex flex-col lg:w-3/5 lg:pr-20  ">
                    <div className="space-y-6">
                        <Title level={2} className="block">
                            Nová úložka
                        </Title>
                        <div className={`flex ${swaped ? " flex-col-reverse  " : " flex-col  "}  lg:justify-between items-center justify-center lg:gap-x-4`}>
                            <InputWithUnitsModern
                                name="amountBTC"
                                label="Množství BTC"
                                values={data}
                                setValues={setData}
                                value={(data.amountBTC)}
                                error={errors.amountBTC}
                                units={cryptoUnits}
                                setUnits={setCryptoUnits}
                                selectedUnit={selectedCryptoUnit}
                                setSelectedUnit={setSelectedCryptoUnit}
                                icon={<div className="transform rotate-12"> <BTC /></div>}
                                onFocus={() => setActive("btc")}
                                onBlur={() => setActive(null as any)}
                            />
                            <div className="relative w-full">
                                <div className="justify-center  z-10 h-12 flex items-center relative" onClick={() => setSwaped(!swaped)}>
                                    <div className="px-5 bg-black">
                                        <Equivalent width={48} height={48} />
                                    </div>


                                </div>
                                <div className="absolute select-none top-0 left-0 right-0 bottom-0 z-0 w-full my-auto h-px bg-white-30  flex-shrink-0"></div>
                            </div>

                            <InputWithUnitsModern
                                name="equivalent"
                                label="Ekvivalent"
                                values={data}
                                setValues={setData}
                                value={data.equivalent}
                                error={errors.equivalent}
                                units={units}
                                setUnits={setUnits}
                                selectedUnit={selectedUnit}
                                setSelectedUnit={setSelectedUnit}
                                onFocus={() => setActive("czk")}
                                onBlur={() => setActive(null as any)}
                            />
                        </div>

                        {((data.equivalent >= 25000) || (data.equivalent >= 1000 && selectedUnit != "Kč") || !data.equivalent) ?
                            <DontOwnAny />

                            :
                            <p className="w-max h-5 bi mt-2 text-xs text-red-500 ">
                                Minimální výše termínované úložky je {selectedUnit == "Kč" ? "25 000" : "1000 "} {selectedUnit}
                            </p>
                        }

                        <div className="grid grid-cols-2 font-semibold">
                            <div onClick={() => setMode("fixed")} className={"py-5 border-b " + (mode == "fixed" ? "text-white border-white " : "text-white-50 cursor-pointer border-white-50 hover:border-white-70")}>
                                Fixní zhodnocení
                            </div>
                            <div onClick={() => setMode("variable")} className={"py-5 border-b " + (mode == "variable" ? "text-white border-white " : "text-white-50 cursor-pointer border-white-50 hover:border-white-70")}>
                                Variabilní zhodnocení
                            </div>
                        </div>

                        {mode == "fixed" ?
                            <div className="mt-6 flex flex-col">
                                <span className="text-xs font-bold">
                                    Úrok a doba uložení
                                </span>
                                <div className="mt-2 flex flex-col overflow-auto lg:grid lg:grid-cols-4 gap-x-4 gap-y-3">
                                    {termDepositAndInterest.map((item, i) => (
                                        <div
                                            onClick={() => {
                                                setData({
                                                    ...data,
                                                    interest:
                                                        termDepositAndInterest[i]
                                                            .interestInPercentage,
                                                });
                                                setTermDepositAndInterestIndex(i);
                                            }}
                                            className={`group p-4 border ${i == termDepositAndInterestIndex
                                                ? "border-white"
                                                : "border-white-30 hover:border-white-60"
                                                } rounded-3px cursor-pointer flex lg:flex-col items-center lg:items-start gap-y-1 gap-x-2 transition-all duration-200 ease-in-out`}
                                        >
                                            <p
                                                className={`w-6 h-6 rounded-full grid place-items-center border ${i == termDepositAndInterestIndex
                                                    ? "border-white bg-green"
                                                    : "border-white-30 group-hover:border-white-60"
                                                    }`}
                                            >
                                                {i ==
                                                    termDepositAndInterestIndex && (
                                                        <Success />
                                                    )}
                                            </p>
                                            {item.interestInPercentage != initialDepositAndInterest[i].interestInPercentage &&
                                                <p className="lg:mt-2 line-through text-white-50 ">
                                                    {initialDepositAndInterest[i].interestInPercentage}
                                                    %&nbsp;p.m.
                                                </p>
                                            }
                                            <p className="lg:mt-2">
                                                {item.interestInPercentage}
                                                %&nbsp;p.m.
                                            </p>
                                            <p>{item.months}&nbsp;{item.months > 4 ? "měsíců" : "měsíce"}</p>
                                        </div>
                                    ))}
                                    <div className="">
                                        <div className="w-1"></div>
                                    </div>
                                </div>

                            </div>
                            :
                            <div className="">
                                <div className="font-semibold mt-2 text-white ">{(variablePercentage[0]+variableAddition).toFixed(1).toString()}–{(variablePercentage[1]+variableAddition).toFixed(1)}% p.m.</div>
                                <div className="text-sm text-white-50 mt-2">Úložka s variabilním zhodnocením se uzavírá na dobu neurčitou s výpovědní lhůtou
                                    30 dní.</div>
                            </div>
                        }
                        <PromoCode setVoucherCode={(to) => setVoucherCode(to)} setNewValues={(values) => updateInterests(values)} setType={setMode} setVariableInterest={setVariableAddition} />

                    </div>
                </div>
            )}
            {(true || windowSize.width > 640) && (
                <div className="lg:w-2/5 pt-px lg:p-6 lg:bg-white-10 rounded-3px">
                    <Title level={2} className="hidden lg:block">
                        Rekapitulace
                    </Title>
                    <div className="mt-6 border-t border-white-30 tracking-wide">
                        <div className="py-4 flex justify-between border-b border-white-30">
                            <span>Výše investice (v BTC)</span>
                            <span className="font-bold">
                                {data.amountBTC == null ? 0 : toMaxFixed(Number(data.amountBTC), 9)}
                            </span>
                        </div>
                        {mode == "fixed" &&
                            <>
                                <div className="py-4 flex justify-between border-b border-white-30">
                                    <span>Termín ukončení</span>
                                    <span className="font-bold">{(data.expiration).toLocaleDateString()}</span>
                                </div>
                                <div className="py-4 flex justify-between border-b border-white-30">
                                    <span>Bilance po uplynutí</span>
                                    <span className="font-bold">
                                        {Number(data.finalBilance).toFixed(
                                            data.finalBilance == 0 ? 1 : 9
                                        )}
                                    </span>
                                </div>
                            </>
                        }
                        <div className="py-4 flex justify-between flex-wrap border-b border-white-30">
                            <span>Zhodnocení <span className="text-sm">{mode == "fixed" ? "(p. m.)" : "(měsíčně)"}</span></span>
                            <span className="font-bold text-green flex items-center gap-x-2">

                                {data.amountBTC != 0 && data.amountBTC != null ? (
                                    <Triangle color="#6BBF5D" />
                                ) : (
                                    <></>
                                )}
                                <div className="">  {mode == "fixed" ? data.interest : `${(variablePercentage[0]+variableAddition).toFixed(1)} - ${(variablePercentage[1]+variableAddition).toFixed(1)}`}% (
                                    {(selectedUnit == "Kč"
                                        ? formatter.format(
                                            data.amountBTC * exchangeRate.BTC / 100 * (mode == "fixed" ? data.interest : variablePercentage[0]+variableAddition))
                                        : selectedUnit == "USD"
                                            ? formatter.format(data.amountBTC * exchangeRate.USD / 100 * data.interest)
                                            : formatter.format(data.amountBTC * exchangeRate.EUR / 100 * data.interest)
                                    )
                                    }
                                    {
                                        (mode == "variable") && (
                                            " - " +
                                            (selectedUnit == "Kč"
                                                ? formatter.format(
                                                    data.amountBTC * exchangeRate.BTC / 100 * (variablePercentage[1]+variableAddition))
                                                : selectedUnit == "USD"
                                                    ? formatter.format(data.amountBTC * exchangeRate.USD / 100 * data.interest)
                                                    : formatter.format(data.amountBTC * exchangeRate.EUR / 100 * data.interest)
                                            )
                                        )
                                    }
                                    )</div>

                            </span>
                        </div>
                        <div className="py-4 flex flex-wrap justify-between border-b border-white-30">
                            <span>Zhodnocení <span className="text-sm">{mode == "fixed" ? "(celkem)" : "(ročně)"}</span></span>
                            <span className="font-bold text-green flex items-center gap-x-2">

                                {data.amountBTC != 0 && data.amountBTC != null ? (
                                    <Triangle color="#6BBF5D" />
                                ) : (
                                    <></>
                                )}
                                {mode == "fixed" ? (data.finalInterest
                                    ? Number(data.finalInterest).toFixed(
                                        data.finalInterest == 0 ? 1 : 2
                                    )
                                    : 0) :

                                    (compound(variablePercentage[0], 12).toFixed(2) + " - " + compound(variablePercentage[1]+variableAddition, 12).toFixed(2))}
                                % ({(selectedUnit == "Kč"
                                    ? formatter.format(mode == "fixed" ? (data.finalBilance * exchangeRate.BTC -
                                        data.amountBTC * exchangeRate.BTC) : (data.amountBTC * exchangeRate.BTC / 100 * compound(variablePercentage[0]+variableAddition, 12)))
                                    : selectedUnit == "USD"
                                        ? formatter.format((data.finalBilance * exchangeRate.USD -
                                            data.amountBTC * exchangeRate.USD))
                                        : formatter.format((data.finalBilance * exchangeRate.EUR -
                                            data.amountBTC * exchangeRate.EUR))
                                )}

                                {mode == "variable" &&
                                    " - " +
                                    (selectedUnit == "Kč"
                                        ? formatter.format((data.amountBTC * exchangeRate.BTC / 100 * compound(variablePercentage[1]+variableAddition, 12)))
                                        : selectedUnit == "USD"
                                            ? formatter.format((data.amountBTC * exchangeRate.USD / 100 * compound(variablePercentage[1]+variableAddition, 12)))
                                            : formatter.format((data.amountBTC * exchangeRate.EUR / 100 * compound(variablePercentage[1]+variableAddition, 12)))
                                    )
                                })
                            </span>
                        </div>
                    </div>
                    <div className="mt-2 text-sm text-white-60">
                    Úložka se začíná úročit ihned po připsání, nejpozději však do 24 hodin. Po kliknutí na tlačítko níže budete přesměrování na platební bránu. Uvedený přepočet na Kč je odvozen z aktuálního kurzu, úrok je vyplácen v kryptoměně.
                    </div>
                    <Button
                        theme="custom"
                        type="submit"
                        onClick={() => submit()}
                        className={`mt-6 font-semibold tracking-wide ${data.amountBTC != 0 && data.amountBTC != null && data.equivalent >= 25000
                            ? "bg-green text-white"
                            : "bg-white text-black"
                            } transition-all ease-in-out duration-200`}
                    >
                        {!clicked ?

                            <>
                                Termínovaně uložit
                                <span
                                    className={`ml-1 font-normal tracking-wide ${data.amountBTC != 0 && data.amountBTC != null && data.equivalent >= 25000
                                        ? "text-white-80"
                                        : "text-black-60"
                                        } transition-all ease-in-out duration-200`}
                                >
                                    {data.amountBTC == null ? 0 : toMaxFixed(Number(data.amountBTC), 9)} BTC
                                </span>
                            </>
                            :
                            <LoadingSpinner />
                        }
                    </Button>
                </div>
            )}
            {windowSize.width < 640 && (
                <div
                    className={`absolute ${step == 1 ? "-z-10" : ""
                        } inset-0 h-14 px-7 flex justify-between items-center`}
                >
                    {step == 1 ? (
                        <div className="w-15" />
                    ) : (
                        <div
                            className=" w-15 h-15 rounded-full"
                            onClick={() => step == 2 && setStep(1)}
                        />
                    )}

                    {/* <div className="font-bold tracking-wide">
                        {step == 1 ? "Spočítejte si svůj výnos" : "Rekapitulace"}
                    </div> */}
                    <div className="w-15" />
                </div>
            )}

            {/* <Modal opened={openModal} setClose={() => setOpenModal(false)}>
                <div className="block w-screen md:w-64 lg:w-128 min-h-almost100vh relative bg-white rounded-md">
                    <iframe src={sentURL} width='100%' height='100%'></iframe>
                </div>
            </Modal> */}

        </form>
    );
}


export function DontOwnAny() {

    const [hovered, sethovered] = useState(false);

    return (
        <p
            onPointerEnter={() => sethovered(true)}
            onPointerLeave={() => sethovered(false)}
            className="w-max bi mt-2 text-xs h-5 ">

            {hovered ?
                <div className="text-white max-w-full whitespace-pre-wrap">Nevadí. Zajistíme Vám nákup Bitcoinu, prosím, <br />kontaktujte nás na <a href="mailto:info@spektrum.fund" className="underline cursor-pointer" >info@spektrum.fund</a> nebo <a href="tel:+420777705416" className="underline cursor-pointer" >+420 777 705 416.</a></div> :
                <div className="underline text-white-60" >Nevlastníte prozatím žádný Bitcoin?</div>
            }

        </p>
    )
}