import parseMD from 'parse-md';

import React from 'react';
import { InertiaLink, useForm } from '@inertiajs/inertia-react';
import usePageProps from 'components/Hooks/usePageProps';

import Delete from 'components/Icons/Delete';
import Eye from 'components/Icons/Eye';
import Title from 'components/Typography/Title';
import Button from 'components/Forms/Components/Button';

export default function BlogpostsOverview() {
    const { blogposts, images } = usePageProps();

    const form = useForm({
        slug: '',
    });
    const { data, setData, post } = form;

    const handleDelete = (e, slug) => {
        e.preventDefault();

        setData({ slug: slug });

        post('/api/delete_blogpost');
    };

    return (
        <section className="min-h-screen px-5 lg:px-32  pt-32">
            {images}
            <div className="flex justify-between items-baseline">
                    <Title level={2}>Články na blogu</Title>
                    <div className="inline-flex">
                        <Button theme="secondary" link="/admin/vytvorit-blogpost">+ Přidat</Button>
                    </div>

                </div>
            <div className="mt-4">
                {blogposts.map((blogpost) => (
                    <div className="p-4 border-b border-white-30 hover:bg-white-10 flex justify-between transition-all duration-300">
                        <span>{parseMD(blogpost).metadata.title}</span>
                        <div className="flex space-x-8 items-center">
                            <InertiaLink
                                href={`/blog/${
                                    parseMD(blogpost).metadata.slug
                                }`}
                                className="transform hover:scale-150 transition-all duration-300 ease-in-out"
                            >
                                <span>
                                    <Eye />
                                </span>
                            </InertiaLink>
                            <form
                                onSubmit={(e) =>
                                    handleDelete(
                                        e,
                                        parseMD(blogpost).metadata.slug
                                    )
                                }
                                className="flex transform hover:scale-150 transition-all duration-300 ease-in-out"
                            >
                                <button
                                    type="submit"
                                    className="focus:outline-none"
                                >
                                    <Delete />
                                </button>
                            </form>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
