import { InertiaLink } from "@inertiajs/inertia-react";
import { AppContext } from "components/Contexts/AppContextProvider";
import CreateDepositForm from "components/Forms/CreateDepositForm";
import CreateKcDepositForm from "components/Forms/CreateKcDepositForm";
import Help from "components/Helps/Help";
import GoBackButton from "components/Icons/GoBackButton";
import SpektrumLogo from "components/Icons/Spektrum";
import Title from "components/Typography/Title";
import React, { useContext } from "react";

export default function CreateKcDeposit() {
    const { user, windowSize } = useContext(AppContext);

    return (
        <section className="relative min-h-screen px-5 pt-20 pb-5 lg:px-20 xl:px-32 2xl:px-60">
            <CreateKcDepositForm />
        </section>
    );
}
