import ContactUs from "./ContactUs";
import FAQ from "./FAQ";



export default function ContactAndFaq() {


    return (
        <section
            id="kontakt"
            className="relative min-h-almost100vh flex items-center px-6 md:py-10 lg:px-20 xl:px-32 2xl:px-60">
            <div className="lg:grid block w-full lg:grid-cols-2">
                <ContactUs />
                <FAQ />
            </div>
        </section>
    )
}