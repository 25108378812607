import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const ValidateError: FC<Props> = (props) => {
    const { width = '24', height = '24', color = '#539648' } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#EF4444" />
            <path d="M7.13307 17.0326L12.0828 12.0828M17.0326 7.13307L12.0828 12.0828M12.0828 12.0828L6.94975 6.94975L16.8492 16.8492" stroke="white" stroke-width="2" />
        </svg>


    );
};

export default ValidateError;
