import { Inertia } from "@inertiajs/inertia";
import { InertiaLink } from "@inertiajs/inertia-react";
import { AppContext } from "components/Contexts/AppContextProvider";
import Button from "components/Forms/Components/Button";
import Close from "components/Icons/Close";
import UserAccount from "components/Icons/UserAccount";
import { motion } from "framer-motion";
import { useContext } from "react";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import CallMiniForm from "./CallMiniForm";

export default function MobileNav(props) {

    const { changePage, home, closeMenu } = props
    const { user } = useContext(AppContext)

    function setMobileMenuOpen(what) {

    }

    function getCircleCoordinates(angle) {
        var radius = 250;
        var x = radius * Math.sin((Math.PI * 2 * angle) / 360);
        var y = radius * Math.cos((Math.PI * 2 * angle) / 360);

        return { x: x, y: y };
    }

    const circles = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    return (
        <div className="w-full">
            <div className="px-6 w-full md:py-10 lg:px-20 xl:px-32 2xl:px-60">


                <div className="grid grid-cols-2 w-full ">
                    <div className=" w-full z-50 h-full flex items-start justify-center flex-col space-y-8 text-center font-semibold text-2xl ">
                        {home ?
                            <Link to="uvod" smooth={true} spy={true} hashSpy={true} offset={0} duration={500} >
                                <span className="cursor-pointer z-50" onClick={() => {
                                    changePage ? changePage(0) : {}
                                    home ? scroll.scrollTo('uvod') : Inertia.visit("/")
                                    closeMenu()
                                }}>
                                    Úvod
                                </span>
                            </Link>
                            :
                            <InertiaLink href="/">
                                <span className="cursor-pointer z-50">
                                    Úvod
                                </span>
                            </InertiaLink>
                        }

                        <InertiaLink href="/technologie">
                            <span className="cursor-pointer z-50" onClick={() => setMobileMenuOpen(false)}>
                                Technologie
                            </span>
                        </InertiaLink>
                        <InertiaLink href="/profil">
                            <span onClick={() => setMobileMenuOpen(false)} className="">
                                Profil
                            </span>
                        </InertiaLink>
                        <InertiaLink href="/blog">
                            <span onClick={() => setMobileMenuOpen(false)}>
                                Blog
                            </span>
                        </InertiaLink>
                        {/* <InertiaLink href="/#produkty">
                        <span onClick={() => setMobileMenuOpen(false)}>
                            Produkty
                        </span>
                    </InertiaLink> */}
                        <Link className="cursor-pointer" to="kontakt" smooth={true} spy={true} hashSpy={true} offset={0} duration={500}>
                            <span onClick={() => {
                                changePage ? changePage(6) : {}
                                closeMenu()
                            }} className=" z-50">
                                Kontakt
                            </span>
                        </Link>
                        <div className=""></div>
                        <div className=""></div>
                        {!user ?
                            <InertiaLink href="/registrace" className="underline">
                                <span onClick={() => closeMenu()}>
                                    Přihlásit se
                                </span>
                            </InertiaLink>
                            :
                            <>
                                <InertiaLink href="/profil" className="underline flex items-center">
                                    <UserAccount />
                                    <span onClick={() => closeMenu()} className="ml-2">
                                        {user.firstName + " " + user.lastName}
                                    </span>
                                </InertiaLink>
                                <InertiaLink href="/auth/logout" method="post" className="text-lg font-semibold flex items-center">
                                    <span onClick={() => closeMenu()} className="">
                                        Odhlásit se
                                    </span>
                                </InertiaLink>
                            </>
                        }


                    </div>
                    <div className="">
                        <div className="grid lg:w-1/2 h-80 lg:h-140 place-items-center relative">
                            <div className=" z-10 space-y-4 flex flex-col  items-center">

                                <CallMiniForm />
                            </div>

                            {/* //
                    // tady je ten kolotoc
                    // */}


                            {circles.map((i) => (
                                <motion.div
                                    style={{
                                        y: getCircleCoordinates(i * -30 + 180).y,
                                        x: getCircleCoordinates(i * -30 + 180).x,
                                    }}
                                    animate={{ opacity: [0.1, 1, 0.1] }}
                                    exit={{ opacity: 0.1 }}
                                    initial={{ opacity: 0.1 }}
                                    transition={{
                                        times: [0, 0.05, 1],
                                        delay: i * 1,
                                        duration: 12,
                                        easings: "easeOut",
                                        repeat: Infinity,
                                    }}
                                    className="absolute pointer-events-none z-0 top-0 bottom-0 left-0 right-0 flex items-center justify-center"
                                >
                                    <motion.div
                                        animate={{ width: 32, height: 32 }}
                                        transition={{ duration: 0.5 }}
                                        className="rounded-full bg-white"></motion.div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function setOpened(arg0: boolean) {
    throw new Error("Function not implemented.");
}
