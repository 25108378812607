import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Deposit: FC<Props> = (props) => {
    const { width = '32', height = '32', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="28.6654"
                y="11.3337"
                width="16"
                height="26.6667"
                rx="2"
                transform="rotate(90 28.6654 11.3337)"
                stroke={color}
                strokeWidth="1.33333"
            />
            <path
                d="M25.3333 7.33366C26.4379 7.33366 27.3333 8.22909 27.3333 9.33366L27.3333 11.3337L4.66667 11.3337L4.66667 9.33366C4.66667 8.22909 5.5621 7.33366 6.66667 7.33366L25.3333 7.33366Z"
                stroke={color}
                strokeWidth="1.33333"
            />
            <path
                d="M22.6654 3.33366C23.7699 3.33366 24.6654 4.22909 24.6654 5.33366L24.6654 7.33366L7.33203 7.33366L7.33203 5.33366C7.33203 4.22909 8.22746 3.33366 9.33203 3.33366L22.6654 3.33366Z"
                stroke={color}
                strokeWidth="1.33333"
            />
            <rect
                x="23.3333"
                y="11.3337"
                width="16"
                height="16"
                transform="rotate(90 23.3333 11.3337)"
                stroke={color}
                strokeWidth="1.33333"
            />
            <rect
                x="22.0013"
                y="7.33366"
                width="4"
                height="13.3333"
                transform="rotate(90 22.0013 7.33366)"
                stroke={color}
                strokeWidth="1.33333"
            />
            <rect
                x="20.6654"
                y="3.33366"
                width="4"
                height="10.6667"
                transform="rotate(90 20.6654 3.33366)"
                stroke={color}
                strokeWidth="1.33333"
            />
            <path
                d="M15.9987 24.667C13.4214 24.667 11.332 22.5777 11.332 20.0003C11.332 17.423 13.4214 15.3337 15.9987 15.3337C18.576 15.3337 20.6654 17.423 20.6654 20.0003C20.6654 22.5777 18.576 24.667 15.9987 24.667Z"
                stroke={color}
                strokeWidth="1.33333"
            />
        </svg>
    );
};

export default Deposit;
