import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Linkedin: FC<Props> = (props) => {
    const { width = '24', height = '24', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.0333 18.096V12.816C16.0333 12.816 15.7452 11.712 14.6892 11.76C13.6333 11.808 13.2972 11.976 12.8173 12.552V18.096H10.1052V9.71998H12.7932V10.944C12.7932 10.944 13.6572 9.23998 15.4812 9.35998C17.1372 9.47998 18.3852 10.488 18.6492 12.696H18.7212V18.096H16.0333ZM6.88925 8.63998C6.00125 8.63998 5.28125 7.91998 5.28125 7.00798C5.28125 6.11998 6.00125 5.37598 6.88925 5.37598C7.77725 5.37598 8.49725 6.09598 8.49725 7.00798C8.49725 7.89598 7.77725 8.63998 6.88925 8.63998ZM8.23325 18.096H5.54525V9.71998H8.23325V18.096Z"
                fill={"currentColor"}
            />
        </svg>
    );
};

export default Linkedin;
