import React, { FC, useRef, useState } from "react";

import ClosedEye from "Icons/ClosedEye";
import Eye from "Icons/Eye";
import ValidateOk from "components/Icons/ValidateOk";
import useOnClickOutside from "components/Hooks/useOnClickOutside";
import { motion } from "framer-motion";

type Props = {
    className?: string;
    error: string;
    label?: String;
    name: string;
    required?: boolean;
    values: any;
    setValues: any;
};



const PasswordInput: FC<Props> = (props) => {
    const {
        className,
        error,
        label,
        name,
        required = false,
        values,
        setValues,
    } = props;

    const [showPassword, setShowPassword] = useState(false);


    const [active, setActive] = useState(false);

    const ref = useRef(null as any)

    useOnClickOutside(ref, () => setActive(false))

    function validate() {
        if(name == "password_confirmation") {
            if(values[name] == values["password"] && values[name]?.length >= 8) {
                return true
            }
            else {
                return false
            }
        }
        if (values[name]?.length >= 8) {
            return true
        }
    }


    return (
        <div ref={ref} onPointerDown={() => setActive(true)} className="rounded-3px overflow-hidden w-full flex flex-col ">
            <label className={className + " relative lg:w-full flex overflow-hidden rounded-3px flex-col border-0 "}>

                {/* <span className="text-xs tracking-wide font-semibold">{`${label} ${required ? "*" : ""
                }`}</span> */}
                <input
                    type={showPassword ? "text" : "password"}
                    name={name}

                    onPointerDown={() => setActive(true)}
                    onChange={(e) =>
                        setValues({ ...values, [name]: e.target.value })
                    }
                    required={required}
                    className={` px-4 z-20 pt-6 pb-2 bg-transparent placeholder-white-30 border-white-30 hover:border-white-50 focus:border-white rounded-3px focus:ring-0  ${error
                        ? " border-red-500 focus:border-red-500"
                        : " border-white-30 focus:border-white"
                        }`}
                />
                <motion.div
                    animate={{ right: validate() ? 36 : 0 }}
                    initial={false}
                    onClick={() => setShowPassword((prev) => !prev)}
                    className={" absolute z-20 cursor-pointer "}
                    style={{ border: "0px solid black" }}
                >
                    <div className={"px-4 pb-4 " + (showPassword ? " pt-7 " : " pt-5 ")}>
                          {showPassword ? <ClosedEye /> : <Eye />}
                    </div>
                  
                </motion.div>
                {error && <p className="mt-2 text-red-600">{error}</p>}
                <span className="absolute z-20 top-4 right-4">
                    {(validate()) && (<ValidateOk />
                    )}
                </span>
                <motion.div
                    onPointerDown={() => setActive(true)}
                    initial={false}
                    animate={{ y: (active || values[name]?.length > 0) ? 2 : 16, fontSize: (active || values[name]?.length > 0) ? "12px" : "16px", color: (active || values[name]?.length > 0) ? "white" : "gray", fontWeight: (active || values[name]?.length > 0) ? 600 : 400 }}
                    className="absolute top-0 left-0 select-none right-0 bottom-0 flex px-4 text-white border-0 font-semibold">
                    {label}
                </motion.div>
            </label>
        </div>
    );
};

export default PasswordInput;
