import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Facebook: FC<Props> = (props) => {
    const { width = '24', height = '24', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6875 14.2909H13.5625C13.3281 14.2909 13.1719 14.4545 13.1719 14.7V21H9.34375V14.7C9.34375 14.4545 9.1875 14.2909 8.95313 14.2909H7V10.2818H8.875C9.10938 10.2818 9.26563 10.1182 9.26563 9.87273V7.66364C9.26563 6.6 9.65625 3 13.7969 3H17V6.35455H14.8125C14.1875 6.35455 13.4063 6.76364 13.4063 7.90909V9.46364C13.1719 9.46364 11.8438 9.62727 11.8438 9.87273C11.8438 10.1182 13.1719 10.2818 13.4063 10.2818H13.7969H15.9062H17L16.6875 14.2909Z"
                fill={"currentColor"}
            />
            <rect x="10.8008" y="7" width="3" height="4" fill="currentColor" />
        </svg>
    );
};

export default Facebook;
