import Button from "components/Forms/Components/Button"
import Tick from "components/Icons/Tick"
import Text from "components/Typography/Text"
import Title from "components/Typography/Title"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"


export default function SuccessModal({ opened }) {

    const [visible, setVisible] = useState(opened)

    function close(){
        window.history.pushState("", "", '/profil');
        setVisible(false)
    }

    return (
        <AnimatePresence>
            {visible &&
                <motion.div
                exit={{opacity: 0}}
                animate={{opacity: 1}}
                initial={{opacity: 0}}
                className="fixed top-0 left-0 right-0 bottom-0 flex items-end md:items-center justify-center z-40">
                    <div className="absolute top-0 left-0 bottom-0 right-0 bg-black-50 z-30"></div>
                    <motion.div
                exit={{opacity: 0, y: "100%"}}
                animate={{opacity: 1,  y: 0}}
                initial={{opacity: 0 ,y: "25%"}}
                transition={{easings: "easeInOut"}}
                 className="w-full relative md:w-1/2 lg:w-1/3 z-40 p-5 md:p-10 flex flex-col gap-y-5 rounded-3px overflow-hidden md:text-center md:justify-center items-center" style={{backgroundColor: "#1A1A1A"}}>
                        
                        <Title level={2}>Vklad proběhl úspěšně</Title>
                        <Text>Úložka se začne úročit ihned po připsání, nejpozději však do 24 hodin.</Text>
                        <div onClick={() => close()} className="bg-green-darker hover:bg-green w-full md:w-auto justify-center py-4 font-semibold cursor-pointer rounded-3px text-white flex items-center gap-x-4 px-9">
                            <Tick />
                            <div>Dokončit</div>
                        </div>
                        <motion.div
                        animate={{width: "100%"}}
                        initial={{width: 0}}
                        transition={{duration: 5, easings: "linear"}}
                        onAnimationComplete={() => close()}
                        className="h-px bg-white-50 absolute top-0 left-0">
                        </motion.div>
                        <div   className="h-px w-full bg-white-30 absolute top-0 left-0"></div>
                    </motion.div>
                </motion.div>
            }

        </AnimatePresence>

    )
}