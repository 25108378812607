import { useForm } from "@inertiajs/inertia-react";
import axios from "axios";
import Button from "components/Forms/Components/Button";
import InputPhone from "components/Forms/Components/InputPhone";
import { useState } from "react";


export default function CallMiniForm() {

    const [isFormSent, setFormSent] = useState(false);
    const [clicked, setClicked] = useState(null as any);

    const form = useForm({
        tel: '+420',
    });

    const { data, setData, errors, clearErrors, post } = form;

    const handleSubmit = async (e) => {
        e.preventDefault();

        clearErrors();

        setClicked(true)

        if (!clicked) {
            axios.post('/api/call_me', data).then((res) => {
                if (res.status == 200) {
                    setData({
                        tel: '+420'
                    })
                    setFormSent(true)
                    setClicked(false)
                }
                else {
                    setFormSent(false)
                }
            });
        }


    };

    return (
        <form onSubmit={handleSubmit} className="block">
            {isFormSent ?
                <p className="text-sm mt-2">Děkujeme. Brzy se Vám ozveme a probereme s vámi, jaký způsob práce s kryptoměnami je pro vás nejvhodnější</p>
                :
                <>
                    <p className="text-sm font-semibold my-2">Zanechte nám své tel. č.</p>
                    <InputPhone
                        name="tel"
                        label="Telefonní číslo"
                        placeholder="777 777 777"
                        values={data}
                        setValues={setData}
                        error={errors.tel}
                        className="lg:w-full"
                    />
                    <p className="text-sm mt-2">Zavoláme vám zpět nejpozději následující pracovní den a probereme s vámi, jaký způsob práce s kryptoměnami je pro vás nejvhodnější</p>
                    <div className="block lg:inline-flex">
                        <Button className={"my-2 " + (clicked ? " bg-white-30 text-black cursor-wait " : " ")}>{isFormSent ? "Odesílání" : "Zavolejte mi"}</Button>
                    </div>
                </>
            }

        </form>
    )
}