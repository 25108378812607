import ReactPinField from "react-pin-field";

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "@inertiajs/inertia-react";

import Button from "./Components/Button";
import { RegisterContext } from "components/Contexts/RegisterContextProvider";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import ValidateOk from "components/Icons/ValidateOk";
import ValidateError from "components/Icons/ValidateError";
import { PinInput, PinInputField } from "@chakra-ui/pin-input"
import { HStack } from "@chakra-ui/react";

export default function InsertCodeForm() {
    const [isFormSent, setFormSent] = useState(false);
    const [error, setError] = useState(null as any);
    const [ok, setOk] = useState(false)
    const [time, setTime] = useState(30 as any);

    const form = useForm({ code: "" });
    const { data, setData, errors, clearErrors, post } = form;

    const { setCode, setStep, checkCode } = useContext(RegisterContext);

    useEffect(() => {
        if (data.code == checkCode.toString()) {
            setOk(true)
            setTimeout(() => {
                setStep(3)
            }, 650);
        }
        else {
            setOk(false)
        }
        if (data.code != checkCode.toString() && data.code.length == 6) {
            setError("Nesprávný kód")
        }
        else {
            setError(null)
        }
    }, [data.code]);

    useEffect(() => {
        setTimeout(() => {
            if (time > 1) {
                setTime(time - 1)
            }
            else {
                setTime(null)
            }

        }, 1000)
    }, [time]);

    function resendEmail() {
        if (time == null) {
            setTime(60)
            axios.post("/api/repeat-validation", { code: checkCode }).then(res => {
                console.log(res)
            })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setCode(data.code);

        clearErrors();
        setError(false)

        axios.post("/api/insert_code", data).then((res) => {
            console.log(res)
            if (res.data.error) {
                setError(res.data.error)
            }
            if (res.data.code == data['code']) {
                setStep(3)
            }
        });

        console.log(errors);
    };

    return (
        <form className="mt-6 lg:mt-8" onSubmit={handleSubmit}>
            <div className="flex justify-between items-center">
                <div className="flex w-full items-center justify-between space-x-1 lg:space-x-5">
                    <HStack>
                        <PinInput placeholder="" type="number" onChange={(value:string) => setData({code: value})}>
                            <PinInputField className={error ? "border-err" : ok ? "border-succ" :""} />
                            <PinInputField className={error ? "border-err" : ok ? "border-succ" :""} />
                            <PinInputField className={error ? "border-err" : ok ? "border-succ" :""} />
                            <PinInputField className={error ? "border-err" : ok ? "border-succ" :""} />
                            <PinInputField className={error ? "border-err" : ok ? "border-succ" :""} />
                            <PinInputField className={error ? "border-err" : ok ? "border-succ" :""} />
                        </PinInput>
                    </HStack>
                    {/* <ReactPinField
                        length={6}
                        validate="0123456789"
                        onChange={(value) => setData({ code: value })}
                        type="number"
                        className={`w-full lg:w-20 text-2xl font-thin lg:font-normal lg:text-base h-16 lg:h-14 px-0 lg:px-8.5 text-center rounded-3px bg-black grid place-items-center focus:ring-0 ${error
                            ? "border-red-500 focus:border-red-500"
                            : ok ? "border-green focus:border-green" : "border-white-30 focus:border-white"
                            } `}
                        required
                    /> */}


                </div>
                <div className={"w-10 lg:w-16 h-10 flex items-center justify-end flex-shrink-0 " + (ok || error ? "opacity-100" : " opacity-0")}>
                    {ok ?
                        <ValidateOk width="32px" height="32px" />
                        :
                        <ValidateError width="32px" height="32px" />

                    }

                </div>
            </div>


            <p onClick={() => resendEmail()} className={"mt-4 lg:mt-5 " + (time > 0 ? " opacity-30 cursor-not-allowed" : " underline cursor-pointer")}>Odeslat e-mail znovu {time && "(" + time + " s)"} </p>

            <motion.div
                animate={{ opacity: ok ? 1 : 0 }}
                initial={false}
                className="fixed bottom-5 left-5 right-5 md:static mt-6 lg:mt-12 lg:w-52">
                <Button className="bg-green text-white pointer-events-none">Ověřeno</Button>
            </motion.div>


        </form>
    );
}
