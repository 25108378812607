import React, { FC, SVGProps } from "react";

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const GoBackButton: FC<Props> = (props) => {
    const { width = "16", height = "16", color = "white" } = props;

    return (
        <div className=" w-12 h-12 flex items-center justify-center border border-white-30 hover:border-white-60 rounded-full transition-all duration-300 ease-in-out cursor-pointer">
            <svg
                width={width}
                height={height}
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M3 7H18" stroke={color} strokeWidth="2" />
                <path d="M8 1L2 7L8 13" stroke={color} strokeWidth="2" />
            </svg>
        </div>
    );
};

export default GoBackButton;
