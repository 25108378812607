import React from 'react';

import TermDeposit from 'Icons/TermDeposit';

export default function TermDepositLogo() {
    return (
        <div className="absolute top-28 left-6 lg:left-1/2 flex flex-col lg:items-center lg:transform lg:-translate-x-1/2 space-y-5">
            <div>
                <TermDeposit width="90" height="90" />
            </div>
            <p className="tracking-widest text-base uppercase">
                Termínovaná úložka
            </p>
        </div>
    );
}
