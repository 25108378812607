import React, { useEffect, useState } from 'react';

import AdminLayout from 'components/Layouts/AdminLayout';
import BlogpostsOverview from 'fragments/Admin/BlogpostsOverview';
import axios from 'axios';
import Title from 'components/Typography/Title';
import { InertiaLink } from '@inertiajs/inertia-react';
import Eye from 'components/Icons/Eye';
import Delete from 'components/Icons/Delete';
import Interest from 'components/Icons/Interest';
import { format } from 'date-fns';
import SimpleDate, { getDate } from 'components/DateFormatter';
import Button from 'components/Forms/Components/Button';

export default function Interests() {

    const [interests, setInterests] = useState(null as any);

    async function deleteInterest(id) {
        if (confirm("Opravdu chcete tuto úrokovou sazbu odstranit? Akce je nevratná")) {
            axios.delete('/api/interests/delete', { data: { id: id } }).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    setInterests(interests.filter(interest => interest.id != id))
                }
            })
        }

    }

    useEffect(() => {
        axios.get('/api/interests/all').then((res) => {
            console.log(res)
            setInterests(res?.data?.interests)
        })
    }, []);

    return (
        <AdminLayout>
            <div className="min-h-screen px-5 lg:px-32  pt-32">
                <div className="flex justify-between items-baseline">
                    <Title level={2}>Variabilní úrokové sazby</Title>
                    <div className="inline-flex">
                        <Button theme="secondary" link="/admin/urokove-sazby/nova">+ Přidat</Button>
                    </div>

                </div>

                <div className="mt-4">
                    {interests ?
                        <table className="table-auto data-table-design">
                            <thead>
                                <tr>
                                    <th className='w-5'>ID</th>
                                    <th>Stav</th>
                                    <th>Platí od</th>
                                    <th>Úroková sazba</th>
                                    <th>Akce</th>
                                </tr>
                            </thead>

                            <tbody>
                                {interests?.sort(((interest1, interest2) => getDate(interest2.from).getTime() - getDate(interest1.from).getTime())).map((interest, i) => (
                                    <tr>
                                        <td>{interest?.id}</td>
                                        <td><State interest={interest} previous={interests[i - 1]} /></td>
                                        <td> <SimpleDate>{interest?.from}</SimpleDate></td>


                                        <td>{interest?.interest} %</td>
                                        <td>
                                            <span className="text-red-400 font-semibold cursor-pointer" onClick={() => deleteInterest(interest?.id)}>Smazat</span>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>


                        </table>
                        :
                        <>
                        <div className="w-full h-10 bg-white-10"></div>
                        <div className="h-4"></div>
                        <div className="w-full h-40 bg-white-10"></div>
                        </>
                    }
                </div>
            </div>
        </AdminLayout>
    );
}


function State({ interest, previous }) {

    const now = new Date()

    if (getDate(interest.from) > now) {
        return (
            <div className="bg-blue-400 bg-opacity-50 text-white font-semibold text-sm text-center px-4 rounded-full">Budoucí</div>
        )
    }

    else if ((getDate(interest.from) < now) && (getDate(previous?.from) > now || !previous)) {
        return (
            <div className="bg-green text-white font-semibold text-sm rounded-full text-center px-4">Aktivní sazba</div>
        )
    }

    else if (getDate(interest.from) < now) {
        return (
            <div className=" bg-white bg-opacity-30 text-white font-semibold text-sm rounded-full text-center px-4">Minulá sazba</div>
        )
    }

    else return (
        <div className=""></div>
    )
}