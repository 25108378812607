import { AppContext } from "components/Contexts/AppContextProvider";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useInView } from "react-intersection-observer";

export default function NewEraOfTheInternet(props) {

    const { show } = props

    const [ref, inView] = useInView();
    const [viewed, setViewed] = useState(false);
    const [newAnimation, setNewAnimation] = useState(true)

    const [height, setHeight] = useState(null as any);

    const { windowSize } = useContext(AppContext);

    const refInside = useRef(null as any)

    function getCircleCoordinates(angle) {
        var radius = windowSize.width > 768 ? 270 : 180;
        var x = radius * Math.sin((Math.PI * 2 * angle) / 360);
        var y = radius * Math.cos((Math.PI * 2 * angle) / 360);

        return { x: x, y: y };
    }

    const circles = Array.from(Array(12).keys())

    function repeatController(repeats, delay) {

        const numOfRepeats = Array.from(Array(repeats - 1).keys())

        numOfRepeats.map((repeat) => {
            setTimeout(() => {
                setNewAnimation(false)
            }, ((repeat) * delay + delay))
            setTimeout(() => {
                setNewAnimation(true)
            }, ((repeat) * delay + delay + 1))
        })


    }

    useEffect(() => {
        if (newAnimation === false) {
            setNewAnimation(true)
        }
    }, [newAnimation]);

    useEffect(() => {
        if (inView) {
            setViewed(true)
        }
        if (!viewed) {
            // repeatController(3, 4200)
        }
    }, [inView]);

    useEffect(() => {
        if (refInside) {
            setHeight(refInside?.current?.offsetHeight)
        }
    }, [refInside]);

    return (
        <section
            id="princip"
            className="relative lg:min-h-screen flex items-cemter md:justify-center lg:pb-40 lg:pt-24 md:py-0"
        >
            {(inView || (isMobile && viewed) || show) && (
                <div ref={refInside} className=" flex items-end md:items-center space-y-4  w-full md:w-92 relative">
                    <div className="bg-black lg:bg-transparent z-10 px-6  md:translate-x-5  lg:px-0 lg:transform translate-y-40 md:translate-y-0">
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ ease: "easeOut" }}
                            className=" text-white-60"
                        >
                            Nová éra internetu
                        </motion.div>
                        <div className="text-3xl font-semibold mt-2 flex flex-col">
                            <motion.div
                                animate={{ opacity: 1, y: 0 }}
                                initial={{ opacity: 0, y: 20 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{
                                    ease: "easeOut",
                                    delay: 0.4,
                                    duration: 0.5,
                                }}
                                className=""
                            >
                                Blockchain je jedním
                            </motion.div>
                            <motion.div
                                animate={{ opacity: 1, y: 0 }}
                                initial={{ opacity: 0, y: 20 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{
                                    ease: "easeOut",
                                    delay: 0.6,
                                    duration: 0.5,
                                }}
                                className=""
                            >
                                z nejvýznamějších
                            </motion.div>
                            <motion.div
                                animate={{ opacity: 1, y: 0 }}
                                initial={{ opacity: 0, y: 20 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{
                                    ease: "easeOut",
                                    delay: 0.8,
                                    duration: 0.5,
                                }}
                                className="mb-5"
                            >
                                vynálezů 21. století
                            </motion.div>

                        </div>
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ ease: "easeOut", delay: 1 }}
                            className=" text-white-60"
                        >
                            <Text>
                                Internet změnil způsob, kterým si předáváme informace.
                                Blockchain vytvořil těmto informacím konkrétní hodnotu.
                                Blockchain si lze představit jako nekonečnou knihu
                                digitálních záznamů, na jejímž provozu se podílejí přímo
                                její uživatelé. Jedná se o zcela bezpečnou distribuovanou
                                decentralizovanou databázi.
                            </Text>
                        </motion.div>
                    </div>
                    {newAnimation &&
                        circles.map((i) => (
                            <motion.div
                                style={{
                                    y: getCircleCoordinates(-((i) * 30 + 180)).y,
                                    x: getCircleCoordinates(-((i) * 30 + 180)).x,
                                    zIndex: -1
                                }}
                                animate={{ opacity: [0.1, 1, 1, 0.1] }}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                transition={{
                                    times: [0, 0.1, 0.9, 1],
                                    delay: i * 0.4,
                                    duration: 5 - (i / 11),
                                    easings: "easeOut",
                                }}
                                onAnimationComplete={() => {
                                    setTimeout(() => {
                                        if (i == 11) {
                                            setNewAnimation(false)
                                        }
                                    }, 200)
                                }}
                                className="absolute pointer-events-none top-0 bottom-0 left-0 right-0 flex items-center justify-center"
                            >
                                <div className="bg-white rounded-full h-4 w-4 lg:w-9 lg:h-9"></div>
                            </motion.div>
                        ))
                    }

                </div>
            )}
        </section>
    );
}
