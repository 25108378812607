import React from 'react';
import { useForm } from '@inertiajs/inertia-react';

import Button from './Components/Button';
import Input from './Components/Input';

export default function NewsletterForm() {
    const form = useForm({ email: '' });
    const { data, setData, errors, clearErrors, post } = form;

    const handleSubmit = async (e) => {
        e.preventDefault();
    };

    return (
        <form onSubmit={handleSubmit} className="mt-8">
            <Input
                label="E-mail"
                type="email"
                name="email"
                placeholder="jan@priklad.cz"
                values={data}
                setValues={setData}
                error={errors.email}
                className="lg:w-full"
            />

            <Button className="mt-6">Odebírat</Button>
        </form>
    );
}
