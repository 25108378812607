import DepositCard from "components/Cards/DepositCard";
import { AnimatePresence, motion } from "framer-motion";
import React, { FC, useRef, useState } from "react";

type Props = {
    items: Array<{
        number: string;
        amountBTC?: number;
        amountCzk?: number;
        expiration: string;
        interest: string;
        user_UUID?: string;
        type: string;
        name?:string
    }>;
    setItem: Function;
};

const Deposits: FC<Props> = (props) => {
    const { items, setItem } = props;

    const [selectedDeposits, setSelectedDeposits] = useState(null as any);

    const makeValidTimeFormat = (date): string => {
        return date
        const splitted = date.split(". ");
        return `${splitted[2]}-${(splitted[1].length > 1) ? splitted[1] : "0" + splitted[1]}-${splitted[0]}`;
    };

    const ref1 = useRef(null as any)
    const ref2 = useRef(null as any)

    console.log("itemss ", items);

    return (
        <div className="relative lg:w-2/5 mt-8 lg:mt-0 overflow-x-hidden">
            <div className="relative flex justify-between border-b border-white-30 cursor-pointer">
                <div
                    onClick={() => setSelectedDeposits(0)}
                    className="w-1/2 py-6 text-center"
                >
                    Aktivní úložky
                </div>
                <div
                    onClick={() => setSelectedDeposits(1)}
                    className="w-1/2 py-6 text-center"
                >
                    Dokončené úložky
                </div>
                <div
                    className={`absolute w-1/2 left-0 top-full border-b transform ${(selectedDeposits == 0 || selectedDeposits == null) ? "" : "translate-x-full"
                        } transition-all duration-300 ease-in-out`}
                />
            </div>
            <div className="relative w-full" style={{ height: 50 + ((selectedDeposits == 2) ? ref2 : ref1)?.current?.offsetHeight }}>
                <AnimatePresence>
                    {(selectedDeposits == null || selectedDeposits == 0) &&
                        <motion.div
                            animate={{ opacity: 1, x: 0 }}
                            initial={{ opacity: 0, x: selectedDeposits === null ? 0 : -500 }}
                            exit={{ opacity: 0, x: -500 }}
                            className={` pt-8 space-y-4 absolute top-0 left-0 right-0 `}
                            ref={ref1}
                        >
                            {items.filter(item => (new Date(item?.expiration?.replace(" ", "T"))) >= new Date() || !item.expiration).map((item, i) => (
                                <DepositCard
                                    type={item.type}
                                    name={item?.name}
                                    amountBTC={item.amountBTC ?? item.amountCzk}
                                    isCzk={item.amountCzk ? true : false}
                                    expiration={item.expiration}
                                    userUUID={item.user_UUID}
                                    id={item.number}
                                    interest={item.interest}
                                    onClick={() => setItem(item)}
                                />
                            ))}
                        </motion.div>
                    }

                </AnimatePresence>
                <AnimatePresence>
                    {(selectedDeposits == 1) &&
                        <motion.div
                            animate={{ opacity: 1, x: 0 }}
                            initial={{ opacity: 0, x: 500 }}
                            exit={{ opacity: 0, x: 500 }}
                            className={` pt-8 space-y-4 absolute top-0 left-0 right-0 `}
                            ref={ref2}
                        >
                            {items.filter(item => ((new Date(makeValidTimeFormat(item.expiration))) < new Date() && item.expiration)).map((item, i) => (
                                <DepositCard
                                    type={item.type}
                                    amountBTC={item.amountBTC}
                                    expiration={item.expiration}
                                    id={item.number}
                                    userUUID={item.user_UUID}
                                    interest={item.interest}
                                    onClick={() => {
                                        let f_item = item as any
                                        f_item.ended = true
                                        setItem(f_item)
                                    }}
                                />
                            ))}
                        </motion.div>
                    }
                </AnimatePresence>
            </div>

        </div>
    );
};

export default Deposits;
