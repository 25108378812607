import AppLayout from "components/Layouts/AppLayout";
import Navbar from "components/Navbar";
import CreateDeposit from "fragments/Profile/CreateDeposit";
import React from "react";

export default function NewDeposit() {
    return (
        <AppLayout>
            <Navbar back noDot />
            <CreateDeposit />
        </AppLayout>
    );
}
