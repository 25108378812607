import React from 'react';

export default function Footer() {
    return (
        <footer className="p-6 lg:p-20 xl:p-32 2xl:px-60 space-y-4 text-xs border-t border-white-20 text-white-30">
            <p>
            Investice do digitálních aktiv jsou spekulativní investice, které zahrnují vysoký stupeň rizika, včetně částečné nebo úplné ztráty investovaných prostředků. Produkty prezentované na těchto webových stránkách nejsou vhodné pro Klienta, který si nemůže dovolit ztrátu celé investice. Společnost nepřebírá odpovědnost za ztrátu nebo škodu způsobenou použitím jakékoliv informace obsažené na těchto webových stránek.
            </p>{' '}
            <p>
            Veškeré uvedené informace, hodnocení a závěry, které poskytujeme formou různých textů a multimédií, reflektují názory autora daného textu v době jeho zveřejnění. Na tyto informace nelze spoléhat jako na výzkum, investiční poradenství nebo doporučení týkající se jakýchkoliv produktů a strategií. Veškeré materiály mají výlučně ilustrativní, vzdělávací nebo informační účely a mohou se měnit. 
            </p>
            <p>
            Poskytovatelem a Provozovatelem webu je společnost xxx, zapsaná v obchodním rejstříku vedeném u Krajského soudu v Ostravě, oddíl C, vložka č. 78290, která je v rámci živnostenského oprávnění oprávněna poskytovat služby spojené s virtuálním aktivem - nákup, prodej, směna, uchovávání, správa pro jiného, převádění nebo zprostředkování nákupu nebo prodeje virtuálního aktiva.
            </p>
        </footer>
    );
}
