import { useForm } from "@inertiajs/inertia-react";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Button from "./Forms/Components/Button";
import InputPhone from "./Forms/Components/InputPhone";
import Gdpr from "./Forms/Gdpr";
import GoBackButton from "./Icons/GoBackButton";
import PhoneButton from "./Icons/PhoneButton";
import SpektrumLogo from "./Icons/Spektrum";
import Text from "./Typography/Text";


export default function PhoneModal() {

    const [opened, setOpened] = useState(false);
    const [formSent, setFormSent] = useState(false);
    const [clicked, setClicked] = useState(false);

    const form = useForm({
        tel: "+420",
    });
    const { data, setData, errors, post } = form;

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(data);

        if (formSent) {
            setOpened(false)
        }
        else if (!clicked) {
            if (data.tel.length > 8) {
                axios.post("/api/call_me", data).then(res => {
                    if (res.status == 200) {
                        setClicked(false)
                        setFormSent(true)
                    }
                })
            }

        }
        if (data.tel.length > 8) {
            setClicked(true)
        }

    };

    return (
        <div className="lg:hidden">
            <AnimatePresence>
                {!opened &&
                    <motion.div
                        animate={{ opacity: 1, scale: 1 }}
                        initial={{ opacity: 0, scale: 0.6 }}
                        exit={{ opacity: 0, scale: 0.6 }}
                        onClick={() => setOpened(true)} className="fixed cursor-pointer right-5 bottom-5 z-30">
                        <PhoneButton />
                    </motion.div>
                }
            </AnimatePresence>
            <AnimatePresence>
                {opened &&
                    <motion.div
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="fixed top-0 left-0 right-0 bottom-0 bg-black p-5 z-50">
                        <form onSubmit={handleSubmit} className="h-full">
                            <motion.div
                                initial={{ x: "10%" }}
                                exit={{ x: "10%" }}
                                animate={{ x: 0 }}
                                className="flex flex-col h-full justify-between">
                                <div className=" w-full flex justify-between items-center">
                                    <div onClick={() => setOpened(false)}>
                                        <GoBackButton />
                                    </div>
                                    <div className="hidden lg:block">
                                        <SpektrumLogo
                                            width={"60"}
                                        />
                                    </div>
                                    <div className="w-16" />
                                </div>
                                <div className="">
                                    {formSent ? (
                                        <div className="h-20 flex items-center">
                                            <p>Děkujeme. V nejbližší pracovní den se Vám ozveme.</p>
                                        </div>
                                    ) : (
                                        <div className="">
                                            <InputPhone
                                                name="tel"
                                                values={data}
                                                setValues={setData}
                                                error={errors.tel}
                                                className="w-full"
                                                label="Zadejte tel. číslo"
                                                required
                                            />
                                            <div className="font-semibold text-white text-xs mt-4">Zavoláme vám zpět nejpozději následující pracovní den a probereme s vámi, jaký způsob práce s kryptoměnami je pro vás nejvhodnější</div>
                                        </div>
                                    )}
                                </div>
                                <div className="">
                                    <div
                                        className={`transform transition-all w-full duration-300 ease-in-out`}
                                    >
                                        {!formSent &&
                                            <Gdpr />
                                        }
                                        <Button theme={formSent ? "secondary" : "primary"} className={"h-14 mt-4 w-full flex items-center " + (clicked && " bg-white-30 cursor-wait")}>
                                            {formSent ? "Zavřít" : "Zavolejte mi"}
                                        </Button>

                                    </div>
                                </div>
                            </motion.div>
                        </form>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}