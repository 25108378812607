import axios from "axios";


export async function getVariableInterestCurrent() {

    axios.get("/api/interests/all").then(res => {
        console.log(res)
    })
}

export function compound(value: number, times: number) {
    let futureValue = value * (Math.pow(1 + value/100, times) - 1) / (value/100)
    return futureValue
}

export function compoundValue(amount:number, value: number, times: number) {
    let futureValue = amount * (Math.pow(1 + value/100, times) - 1) / (value/100)
    return futureValue
}

export function secondsFromMonthly(value: number) {
    const sample = 0.5
    const percentage = sample / 100 + 1
    const raise = Math.pow(percentage, 1/30)
    const subtract = raise - 1
    const multiply = subtract * 100
    return multiply
}