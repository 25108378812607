import React, { useContext, useEffect, useState } from "react";
import { Inertia } from "@inertiajs/inertia";
import { useForm } from "@inertiajs/inertia-react";

import Button from "./Components/Button";
import Checkbox from "./Components/CheckBox";
import Input from "./Components/Input";
import { RegisterContext } from "components/Contexts/RegisterContextProvider";
import axios from "axios";
import route from "ziggy-js";
import Gdpr from "./Gdpr";
import { useDebouncedCallback } from "components/Hooks/useDebouncedCallback";
import PasswordInput from "./Components/PasswordInput";

export default function BeginForm() {
    const { setStep, setCheckCode, setExists } = useContext(RegisterContext);
    const form = useForm({
        email: "",
        password: "",
        privacyPolicy: true,
        newsletter: false,
    });

    const [clicked, setClicked] = useState(false);
    const [registered, setRegistered] = useState(0)

    const location = window?.location.search
    const query = new URLSearchParams(location);
    const email = (query.get("email")); // is the number 123
    const step = (query.get("step")); // is the number 123

    const { data, setData, errors, post, clearErrors } = form;

    const handleSubmit = (e) => {
        if (clicked == false) {
            e.preventDefault();

            clearErrors();
            setClicked(true)
            if (registered != 1 && !data.password) {
                axios.post("/api/begin", data).then((res) => {
                    if (res.status == 200 || res.status == 201) {
                        setCheckCode(res.data.code)
                        // console.log(res)
                        // console.log(res.data.code)
                        setStep(2)
                    }
                    else {
                        Inertia.visit(route('Login'), { data: { email: data.email } })
                        setClicked(false)
                    }

                });
            }
            else if (registered == 1 || data.password) {
                post("/auth/login", {
                    onSuccess: () => Inertia.visit("/profil")
                })
            }

        }

    };

    const search = useDebouncedCallback((d: string) => {
        axios.post("/api/check_email", { data: data }).then(res => {
            console.log(res)
            if (res.data.status == 1) {
                setRegistered(1)
                setExists("yes")
            }
            else if (res.data.status == 2) {
                setRegistered(2)
                setExists("no")
            }
            else {
                setRegistered(3)
                setExists("no")
            }
        })
    }, 1000);

    useEffect(() => {
        if (data.email) {
            search(data.email)
        }

    }, [data.email]);

    useEffect(() => {
        if (email) {
            setData({ ...data, email: email })
            if (step == "2") {
                axios.post("/api/begin", { email: email, privacyPolicy: true }).then(res => {
                    setCheckCode(res.data.code)
                })
                setStep(2)
            }

        }
    }, [email]);


    return (
        <form onSubmit={handleSubmit}>
            <Input
                type="email"
                name="email"
                label="E-mail"
                values={data}
                setValues={setData}
                error={errors.email}
                required
            />

            <PasswordInput
                name="password"
                label="Heslo"
                className={registered == 1 ? "opacity-1 h-auto mt-6" : "opacity-0 h-0 mt-0"}
                values={data}
                setValues={setData}
                error={errors.email}
            />


            <Checkbox
                name="privacyPolicy"
                values={{ privacyPolicy: true }}
                setValues={setData}
                error={errors.privacyPolicy}
                className="mt-0 opacity-0 pointer-events-none h-0"
            >
                Souhlasím s obchodními podmínkami a se&nbsp;zpracováním osobních
                údajů.
            </Checkbox>
            {registered == 3 &&
                <Checkbox
                    name="newsletter"
                    values={data}
                    setValues={setData}
                    error={errors.newsletter}
                    checked
                    className="mt-4"
                >
                    Chci odebírat newsletter
                </Checkbox>
            }

            {errors?.email?.length > 0 && (
                <div className="mt-6">
                    Chcete dokončit registraci?{" "}
                    <span
                        className="underline cursor-pointer"
                        onClick={() => Inertia.visit("/registrace")}
                    >
                        Klikněte zde.
                    </span>
                </div>
            )}
            {registered == 2 || registered == 3 &&
                <Gdpr className="mt-6" />
            }

            {registered == 0 || registered == 1 ?
                ""
                :
                <Button className={"mt-6 " + (clicked && " bg-white-50")}>{clicked ? "Ověřování..." : "Začít"}</Button>
            }
            <Button type="submit" className={" " + (registered == 1 ? " mt-6 " : " mt-0 pointer-events-none opacity-0 h-0 overflow-hidden ") + (clicked && " bg-white-50 ")}>{clicked ? "Ověřování..." : "Přihlásit se"}</Button>
            {/* <div className="mt-4 text-xs">* Toto pole je povinné.</div> */}

        </form>
    );
}
