import LoggedUser from "components/LoggedUser";
import React, { ReactNode, useEffect, useState } from "react";

import Begin from "sharedFragments/Begin";
import ContactAndFaq from "sharedFragments/ContactAndFaq";
import ContactUs from "sharedFragments/ContactUs";
import Newsletter from "sharedFragments/Newsletter";
import Services from "sharedFragments/Services";
import Footer from "./Footer";

interface Props {
    children: ReactNode;
}

export default function BlogLayout(props: Props) {
    const { children } = props;

    const [top, setTop] = useState(false);


    useEffect(() => {
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#ffffff");

        return () => {
            document.querySelector("meta[name='theme-color']").setAttribute("content", "#000000");
        }
    }, []);

    useEffect(() => {
        if (!top) {
            window.scrollTo(0, 0);
            setTop(true)
        }

    }, [children]);
    return (
        <>
            {/* <LoggedUser /> */}
            <div className="bg-white text-black">
                {children}
            </div>
            {/* <Begin /> */}
            <ContactAndFaq />
            {/* <ContactUs /> */}
            <Newsletter />
            {/* <Services /> */}
            <Footer />
        </>
    );
}
