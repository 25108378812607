import { useForm } from "@inertiajs/inertia-react";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import Button from "./Forms/Components/Button";
import InputPhone from "./Forms/Components/InputPhone";
import Gdpr from "./Forms/Gdpr";
import GoBackButton from "./Icons/GoBackButton";
import PhoneButton from "./Icons/PhoneButton";
import SpektrumLogo from "./Icons/Spektrum";
import Text from "./Typography/Text";


export default function ScrollDownButton(props) {

    const { nextSlide, previousSlide, firstSlide, lastSlide } = props

    const [hoveringUp, setHoveringUp] = useState(false);
    const [hoveringDown, setHoveringDown] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (hoveringUp) {
                setHoveringUp(false)
                previousSlide()
            }
            if (hoveringDown) {
                nextSlide()
                  setHoveringDown(false)
            }
          
        }, 2000)

        if (!hoveringUp && !hoveringDown) {
            clearTimeout(timeout)
        }

        return () => clearTimeout(timeout)
    }, [hoveringUp, hoveringDown]);

    return (
        <div className="" >
            <AnimatePresence>
                {!firstSlide &&
                    <motion.div
                        animate={{ opacity: 1, scale: 1, rotate: 90 }}
                        initial={{ opacity: 0, scale: 0.6, rotate: 90 }}
                        exit={{ opacity: 0, scale: 0.6, rotate: 90 }}
                        onClick={() => previousSlide()}
                        onPointerEnter={() => setHoveringUp(true)} onPointerLeave={() => setHoveringUp(false)}
                        className={"fixed cursor-pointer left-5 z-30 " + (lastSlide ? "bottom-5" : "bottom-20")}>
                        <div className="relative">
                            <GoBackButton />
                            {hoveringUp &&
                                <div className="absolute top-0 left-0 right-0 bottom-0" >
                                    <svg className="transform -rotate-180" width={48} height={48}>
                                        <motion.circle
                                            animate={{ strokeDashoffset: 0, strokeDasharray: 150 }}
                                            exit={{ strokeDashoffset: 150, strokeDasharray: 150, transition: { duration: 0.5, delay: 1 } }}
                                            initial={{ strokeDashoffset: 150, strokeDasharray: 150 }}
                                            transition={{ duration: 2, delay: 0, ease: "easeInOut" }}
                                            cx={24} cy={24} r={24} fill="transparent" stroke="white" stroke-width="4"></motion.circle>
                                    </svg>
                                </div>
                            }
                        </div>

                    </motion.div>
                }
            </AnimatePresence>
            <AnimatePresence>
                {!lastSlide &&
                    <motion.div
                        animate={{ opacity: 1, scale: 1, rotate: -90 }}
                        initial={{ opacity: 0, scale: 0.6, rotate: -90 }}
                        exit={{ opacity: 0, scale: 0.6, rotate: -90 }}
                        onClick={() => nextSlide()}
                        onPointerEnter={() => setHoveringDown(true)} onPointerLeave={() => setHoveringDown(false)}
                        className="fixed cursor-pointer left-5 bottom-5 z-30">
                        <div className="relative">
                            <GoBackButton />
                            {hoveringDown &&
                                <div className="absolute top-0 left-0 right-0 bottom-0" >
                                    <svg className="transform -rotate-180" width={48} height={48}>
                                        <motion.circle
                                            animate={{ strokeDashoffset: 0, strokeDasharray: 150 }}
                                            exit={{ strokeDashoffset: 150, strokeDasharray: 150, transition: { duration: 0.5, delay: 1 } }}
                                            initial={{ strokeDashoffset: 150, strokeDasharray: 150 }}
                                            transition={{ duration: 2, delay: 0, ease: "easeInOut" }}
                                            cx={24} cy={24} r={24} fill="transparent" stroke="white" stroke-width="4"></motion.circle>
                                    </svg>
                                </div>
                            }
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}