import React, {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useState,
} from 'react';

type Props = {
    children: ReactNode;
};

export const RegisterContext = createContext<{
    code: string;
    setCode: Dispatch<SetStateAction<string>>;
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
    checkCode: number;
    setCheckCode: Dispatch<SetStateAction<number>>;
    exists: any;
    setExists: Dispatch<SetStateAction<any>>;
}>({
    code: '',
    setCode: () => {},
    step: 1,
    setStep: () => {},
    checkCode: null as any,
    setCheckCode: () => {},
    exists: null as any,
    setExists: () => {},
});

const RegisterContextProvider: FC<Props> = (props) => {
    const { children } = props;

    const [code, setCode] = useState('');

    const [step, setStep] = useState(1);

    const [checkCode, setCheckCode] = useState(null as any)

    const [exists, setExists] = useState(null as any)

    return (
        <RegisterContext.Provider value={{ code, setCode, step, setStep, checkCode, setCheckCode, exists, setExists }}>
            {children}
        </RegisterContext.Provider>
    );
};

export default RegisterContextProvider;
