import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Twitter: FC<Props> = (props) => {
    const { width = '24', height = '24', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.3664 6.37727C21.5997 6.71805 20.7746 6.94792 19.9089 7.05079C20.7928 6.52162 21.4706 5.68342 21.7902 4.68351C20.9634 5.17416 20.0482 5.53018 19.0732 5.72237C18.293 4.89095 17.181 4.37109 15.9503 4.37109C13.5877 4.37109 11.6721 6.28667 11.6721 8.64972C11.6721 8.98457 11.7098 9.31096 11.783 9.62465C8.22705 9.44601 5.07406 7.74294 2.96375 5.15426C2.59545 5.78587 2.38463 6.52078 2.38463 7.30564C2.38463 8.78984 3.13986 10.0996 4.28793 10.8667C3.5869 10.8443 2.92692 10.6517 2.34992 10.3312C2.34949 10.349 2.34949 10.3672 2.34949 10.3854C2.34949 12.458 3.82438 14.1869 5.78188 14.5806C5.42289 14.678 5.04485 14.7305 4.65454 14.7305C4.37853 14.7305 4.11056 14.7038 3.84936 14.6534C4.39419 16.3531 5.97407 17.5905 7.84605 17.6248C6.38174 18.7724 4.53728 19.4566 2.53237 19.4566C2.18736 19.4566 1.84657 19.4362 1.51172 19.3964C3.40571 20.611 5.65445 21.3188 8.07041 21.3188C15.9406 21.3188 20.2438 14.7995 20.2438 9.14544C20.2438 8.96002 20.24 8.77502 20.2319 8.5913C21.0676 7.98932 21.7932 7.23536 22.3664 6.37727Z"
                fill={"currentColor"}
            />
        </svg>
    );
};

export default Twitter;
