import CalcDepositForm from "components/Forms/CalcDepositForm"


export default function Calculator(props) {

    const { show } = props

    return (
        <section
            id="kalkulace"
            className="relative min-h-screen lg:max-h-screen overflow-hidden flex items-center ">
            <CalcDepositForm />

        </section>
    )

}