import React, { useContext, useEffect, useState } from "react";
import { InertiaLink, useForm } from "@inertiajs/inertia-react";

import Begin from "sharedFragments/Begin";
import Button from "./Components/Button";
import Input from "./Components/Input";
import PasswordInput from "./Components/PasswordInput";
import GoBackButton from "components/Icons/GoBackButton";
import SpektrumLogo from "components/Icons/Spektrum";
import { AppContext } from "components/Contexts/AppContextProvider";
import Gdpr from "./Gdpr";
import axios from "axios";
import { Inertia } from "@inertiajs/inertia";
import route from "ziggy-js";


export default function LoginForm() {
    const [register, setRegister] = useState(false);
    const [showPassword, setShowPassword] = useState(false)

    const location = window?.location.search
    const query = new URLSearchParams(location);
    const email = (query.get("email")); // is the number 123

    const { windowSize } = useContext(AppContext);

    const form = useForm({ email: "", password: "" });
    const { data, setData, errors, clearErrors, post } = form;

    const handleSubmit = async (e) => {
        e.preventDefault();
        clearErrors();
        if (data.email) {
            if (!data.password) {
                axios.post("/api/check_email", {
                    data: {
                        email: data.email
                    }
                }).then((res) => {
                    if (res.data.status == 1) {
                        setShowPassword(true)
                    }
                    else if (res.data.status == 2) {
                        Inertia.visit("/registrace", {data: {
                            email: data.email,
                            step: 2
                        }})
                    }
                    else {
                        Inertia.visit("/registrace", {data: {
                            email: data.email,
                            step: 1
                        }})
                    }
                })
            }
            else {
                post("/auth/login", {
                    onSuccess: () => Inertia.visit("/profil")
                });
            }

        }
    };

    useEffect(() => {
        if (email) {
            setShowPassword(true)
            setData({ ...data, email: email })
        }
    }, [email]);



    return (
        <section className="min-h-screen px-6 lg:px-0 grid place-items-center">
            <div className="absolute left-0 top-0 pt-5 w-full px-6 lg:px-20 xl:px-32 2xl:px-60 flex justify-between items-center">
                <div onClick={() => window.history.back()}>
                    <GoBackButton />
                </div>
                <div className="hidden lg:block">
                    <SpektrumLogo
                        width={windowSize.width > 1024 ? "90" : "60"}
                    />
                </div>
                <div className="w-16" />
            </div>
            {register ? (
                <Begin />
            ) : (
                <form
                    onSubmit={handleSubmit}
                    className="w-full lg:w-80 xl:w-100"
                >
                    <Input
                        name="email"
                        type="email"
                        label="E-mail"
                        values={data}
                        setValues={setData}
                        error={errors.email}
                    />
                    <PasswordInput
                        name="password"
                        label="Heslo"
                        className={showPassword ? "opacity-1 h-auto mt-6" :"opacity-0 h-1 mt-6"}
                        values={data}
                        setValues={setData}
                        error={errors.email}
                    />
                    <p className="mt-4 lg:text-center underline cursor-pointer">
                        Zapomenuté heslo
                    </p>
                    {/* <p className="mt-4 lg:text-center">
                        Nemáte účet?{" "}
                        <span
                            className="underline cursor-pointer"
                            onClick={() => setRegister(true)}
                        >
                            Zaregistrujte se zde.
                        </span>
                    </p> */}
                    <Gdpr className="mt-6" />
                    <div className="absolute left-0 bottom-5 w-full px-6 lg:px-0 lg:static lg:mt-4">
                        <Button>{showPassword ? "Přihlásit se" : "Pokračovat"}</Button>
                    </div>
                </form>
            )}
        </section>
    );
}
function useQueryParams(): [any, { pushQuery: any; replaceQuery: any; }] {
    throw new Error("Function not implemented.");
}

