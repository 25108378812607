
import AppLayout from "components/Layouts/AppLayout";
import PortfolioValue from "fragments/Profile/PortfolioValue";
import SuccessModal from "fragments/Profile/SuccessModal";

export default function Profile(props) {

    const {successModal} = props

    return (
        <AppLayout>
            <SuccessModal opened={successModal} />
            <PortfolioValue />
            {/* <SpektrumLogo />
            <LoggedUser />
            <ProductChoice /> */}
        </AppLayout>
    );
}
