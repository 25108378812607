import { InertiaLink } from "@inertiajs/inertia-react";



export default function BlogLink({href}) {

    return (
        <InertiaLink href={href}>
             <div className="mt-5 inline-block font-semibold text-black relative">
                 Číst více
                 <div className="absolute bottom-0 left-0 right-0 bg-black h-px w-full"></div>
             </div>
        </InertiaLink>
       
    )
}