import React, { FC, HTMLProps } from 'react';
import { InertiaLink } from '@inertiajs/inertia-react';

import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import FormatDate from 'fragments/Blog/FormatDate';
import BlogLink from 'fragments/Blog/BlogLink';

type Props = {
    date: string;
    coverImage: string;
    slug: string;
    subtitle: string;
    title: string;
} & HTMLProps<HTMLDivElement>;

const BlogPostCard: FC<Props> = (props) => {
    const { date, coverImage, slug, subtitle, title } = props;

    return (

            <div
                {...props}
                className="relative w-full  block  transition-all ease-out duration-300"
            >
                <div className="h-64 w-full rounded-3px relative overflow-hidden">
                    <img
                        src={coverImage}
                        className="absolute top-0 left-0 right-0 bottom-0 object-cover"
                        alt={coverImage}
                    />
                </div>


             

                <div className="py-6 lg:p-6">
                   <FormatDate>{date}</FormatDate>
                    <Title level={3} className="mt-1">
                        {title}
                    </Title>
                    <div className="mt-4 text-black-70">{subtitle}</div>
                    <BlogLink href={`/blog/${slug}`} />
                </div>
            </div>


    );
};

export default BlogPostCard;
