import React from 'react';

import BasicCard from 'components/Cards/BasicCard';
import Button from 'components/Forms/Components/Button';
import Evaluation from 'Icons/Evaluation';
import Interest from 'Icons/Interest';
import Text from 'components/Typography/Text';
import Time from 'Icons/Time';
import Title from 'components/Typography/Title';

export default function Hero() {
    return (
        <section className="min-h-screen px-6 lg:px-40 xl:px-100 2xl:px-140 pt-72 lg:pt-80 pb-20">
            <div className="lg:hidden fixed top-8 left-0 w-full h-14 bg-black" />
            <Title level={2}>Stabilní zhodnocení ve světě kryptoměn</Title>
            <Text className="mt-6">
                Hledáte příležitost stabilního zhodnocení v dynamickém světě
                kryptoměn? Nabízíme garantované měsíční zhodnocení až&nbsp;0,5%
                měsíčně.
            </Text>
            <Text className="mt-4" style={{ color: 'white' }}>
                Využít termínované úložky můžete již s kapitálem od
                25&nbsp;000&nbsp;Kč nebo ekvivalentem v Bitcoinu.
            </Text>
            <div className="my-12 lg:my-20 flex flex-col lg:flex-row space-y-16 lg:space-y-0 lg:space-x-8 lg:justify-between">
                <BasicCard
                    icon={<Time />}
                    title="Dočasně uložíte své prostředky k&nbsp;nám"
                    text="Nechejte svůj Bitcoin vydělávat."
                />
                <BasicCard
                    icon={<Evaluation />}
                    title="Zhodnocujeme vklad"
                    text="Poskytnutou likviditu bezpečně a kvalifikovaně zhodnotíme."
                />
                <BasicCard
                    icon={<Interest />}
                    title="Prostředky vrátímezpět s&nbsp;úroky"
                    text="Zpět se vám vrátíme vložený vklad s&nbsp;úroky."
                />
            </div>
            <Text>
                Samotná investice probíhá na globální platformě Iconomi, která
                vám umožňuje automaticky kopírovat (stínovat) námi spravovanou
                strategii v reálném čase. Své prostředky i kryptoměny máte stále
                k&nbsp;dispozici a můžete je kdykoliv vybrat, my s&nbsp;nimi
                nijak nedisponujeme.
            </Text>
        </section>
    );
}
