import React from 'react';

import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';

export default function Market() {
    return (
        <section className="px-6 lg:px-40 xl:px-100 2xl:px-140 pt-10 pb-20 lg:py-20 border-b border-white-30">
            <Title level={2}>Využíváme cyklického charakteru trhu</Title>
            <Text className="mt-6">
                Ve světě, ve kterém je možné jakkoliv velkou transakci
                uskutečnit během vteřiny je základem načasování. Nakupujeme,
                když jsou ceny nízko a prodáváme na vrcholu, abychom při příští
                korekci mohli opětovně dokoupit.
            </Text>
            <div className="my-10 lg:my-20 border h-22 lg:h-56" />
            <Title level={4} className="lg:text-center">
                Za naši práci mluví naše výsledky. Dle nich se odvíjí i naše
                odměna.
            </Title>
            <div className="mt-8 w-min lg:w-full grid grid-rows-3 grid-cols-2 gap-y-4 gap-x-20 lg:gap-0 lg:flex lg:justify-center items-center">
                <div className="w-max text-2xl font-semibold">0,5%</div>
                <div className="w-max lg:ml-4 text-white-60">
                    vstupní poplatek
                </div>
                <div className="w-max lg:ml-12 text-2xl font-semibold">10%</div>
                <div className="w-max lg:ml-4 text-white-60">
                    z měsíčního zisku
                </div>
                <div className="w-max lg:ml-12 text-2xl font-semibold">
                    0,5%
                </div>
                <div className="w-max lg:ml-4 text-white-60">při výběru</div>
            </div>
        </section>
    );
}
