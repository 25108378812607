import { InertiaLink } from "@inertiajs/inertia-react"


export default function Gdpr(props) {

    const {className, ...rest} = props


    return(
        <div className={className + " text-white-50 text-xs"} {...rest}>
            Odesláním formuláře souhlasíte se <span className="underline cursor-pointer">zpracováním osobních údajů</span> a <InertiaLink href="/vop" className="underline cursor-pointer">obchodními podmínkami</InertiaLink>.
        </div>
    )
}