import React, { FC, SVGProps } from 'react';

type Props = {
    width?: string;
    height?: string;
    color?: string;
} & SVGProps<SVGSVGElement>;

const Time: FC<Props> = (props) => {
    const { width = '32', height = '32', color = 'white' } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="16"
                cy="16"
                r="15.3333"
                stroke={color}
                strokeWidth="1.33333"
            />
            <path
                d="M16 7.67969L16 17.9197"
                stroke={color}
                strokeWidth="1.33333"
            />
            <path
                d="M16.4446 17.4218C16.1694 17.1772 15.7481 17.2019 15.5034 17.4771C15.2588 17.7523 15.2836 18.1737 15.5588 18.4183L16.4446 17.4218ZM22.2046 22.5418L16.4446 17.4218L15.5588 18.4183L21.3188 23.5383L22.2046 22.5418Z"
                fill={color}
            />
        </svg>
    );
};

export default Time;
