import React from 'react';

import AppLayout from 'components/Layouts/AppLayout';
import Arrange from 'fragments/Profile/Arrange';
import Back from 'components/Back';
import IndividualPortfolioLogo from 'components/Logos/IndividualPortfolioLogo';
import LoggedUser from 'components/LoggedUser';

export default function IndividualPortfolioMeeting() {
    return (
        <AppLayout>
            <Back to="/profil" />
            <IndividualPortfolioLogo />
            <LoggedUser />
            <Arrange meeting="IndividualPortfolio" />
        </AppLayout>
    );
}
