import { InertiaLink } from "@inertiajs/inertia-react";
import { AppContext } from "components/Contexts/AppContextProvider";
import Button from "components/Forms/Components/Button";
import Head from "components/Head";
import Help from "components/Helps/Help";
import GoBackButton from "components/Icons/GoBackButton";
import PayFailed from "components/Icons/PayFailed";
import SpektrumLogo from "components/Icons/Spektrum";
import AppLayout from "components/Layouts/AppLayout";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { useContext } from "react";


export default function PaymentFailed(props) {



    const { windowSize } = useContext(AppContext)
    const { deposit } = props

    console.log(deposit)

    return (
        <AppLayout>
            <Head title={"Platba "+deposit?.number+" zrušena | Spektrum"} />
            <section className="relative min-h-screen flex flex-col w-full items-stretch justify-between px-5 pb-5 lg:px-20 xl:px-32 2xl:px-60">
                <div className="mt-4 lg:mt-10 flex justify-between items-center">
                    <div className="relative w-1/3">
                        <InertiaLink
                            className="w-max"
                            href="/profil"
                        >

                            <GoBackButton />

                        </InertiaLink>
                    </div>

                    <div className="w-1/3 flex justify-center">
                        <div className="block">
                            <InertiaLink href="/">
                                <SpektrumLogo
                                    width={windowSize.width > 640 ? "90" : "48"}
                                    color={"rgba(255,255,255,1)"}
                                />
                            </InertiaLink>
                        </div>
                    </div>

                    <div className="w-1/3" />
                </div>
                <div className="flex items-center justify-center flex-col gap-4">
                    <div className="">
                        <PayFailed />
                    </div>
                    <Title level={2}>Převod se nezdařil</Title>
                    <Text>Prosím, zkuste to ještě jednou</Text>
                    <a className="mt-4" >
                        <Button link="/profil/nova-ulozka" className="px-10 h-14 bg-green-darker hover:bg-green text-white">Opakovat</Button>
                    </a>
                </div>
                <div className="flex w-full justify-start lg:justify-center">
                    <Help wrap />
                </div>
            </section>
        </AppLayout>
    )
}